<template>
  <form class="invite-form sm:tlw-px-10 tlw-py-4" @submit="submit">
    <hs-loading v-if="isLoading" />
    <div v-else>
      <h4 class="tlw-text-2xl tlw-font-bold tlw-text-center tlw-mb-6">
        {{ $t('my-account.views.my-team.modal.title') }}
      </h4>

      <div class="tlw-mb-8 tlw-flex tlw-justify-center avatar">
        <img src="@/assets/images/SparkMembers/commons/avatar_purple.svg" alt="avatar-purple" />
      </div>

      <hs-group :label="$t('my-account.views.my-team.modal.form.name.label')" label-for="name">
        <hs-input
          id="name"
          v-model="form.name"
          :placeholder="$t('my-account.views.my-team.modal.form.name.placeholder')"
          :state="$v.form.name.$error ? false : null"
          @blur="$v.form.name.$touch()"
        />
      </hs-group>

      <hs-group :label="$t('my-account.views.my-team.modal.form.email.label')" label-for="email" class="invalid">
        <hs-input
          id="email"
          type="email"
          v-model="form.email"
          :class="$v.form.email.$invalid ? 'invalid' : ''"
          :placeholder="$t('my-account.views.my-team.modal.form.email.placeholder')"
          :state="$v.form.email.$error ? false : null"
          @blur="$v.form.email.$touch()"
        >
          <template slot="feedback" v-if="$v.form.email.$invalid">
            <hs-form-invalid-feedback :state="$v.form.email.email.$invalid">
              {{ $t('my-account.views.my-team.modal.form.email.validation') }}
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </hs-group>

      <hs-group
        :label="
          `${$t('my-account.views.my-team.modal.form.comission.label')} (até ${decimalPrecision(remainingPercentage)}%)`
        "
        label-for="email"
        class="invalid"
      >
        <hs-input
          id="commission"
          v-model="form.commission"
          type="number"
          :step="0.01"
          :class="$v.form.commission.$invalid ? 'invalid' : ''"
          :placeholder="$t('my-account.views.my-team.modal.form.comission.placeholder')"
          :state="$v.form.commission.$error ? false : null"
          @blur="$v.form.commission.$touch()"
          :text="$t('my-account.views.my-team.modal.form.comission.text')"
        >
          <template slot="feedback" v-if="$v.form.email.$invalid">
            <hs-form-invalid-feedback :state="$v.form.email.email.$invalid">
              {{ $t('my-account.views.my-team.modal.form.comission.validation') }}
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </hs-group>

      <MCheckbox v-model="accepted" :inline="true">
        <span class="checkout-label-text">
          Estou ciente que a porcentagem da comissão é definida na adição de um(a) coprodutor(a), e que depois disso o
          percentual não poderá mais ser alterado.
        </span>
      </MCheckbox>

      <MButton
        variant="primary"
        type="submit"
        size="lg"
        class="sm:tlw-w-9/12 tlw-mt-6 invite-btn"
        :disabled="$v.form.$invalid || !accepted"
        :label="$t('my-account.views.my-team.btn.btn-send-invite')"
      />
    </div>
  </form>
</template>

<script>
import { required, email, between } from 'vuelidate/lib/validators';
import { hsLoading } from '@/components';
import MButton from '@/shared/components/MButton.vue';
import MCheckbox from '@/shared/components/MCheckbox.vue';
import toastHelper from '@/shared/helpers/toast';

const MIN_ALLOWED_COMMISSION = 0;

export default {
  name: 'CoproductionInviteForm',
  components: {
    MButton,
    MCheckbox,
    hsLoading,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    submit: {
      type: Function,
      required: true,
    },
    remainingPercentage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      accepted: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        email: { required, email },
        commission: {
          required,
          between: between(MIN_ALLOWED_COMMISSION, this.remainingPercentage),
        },
      },
    };
  },
  methods: {
    decimalPrecision(num) {
      return (num || 0).toFixed(2);
    },
    onSubmit() {
      try {
        this.isLoading = true;
        this.submit();
        toastHelper.successMessage('Convite de coprodução enviado.');
      } catch (error) {
        logging(error);
        toastHelper.dangerMessage('Falha ao convidar para coprodução');
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    'form.email': {
      handler(newEmail) {
        this.form.email = newEmail.toLowerCase();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.invite-form {
  button {
    img {
      opacity: 0.3;
    }

    &.active img {
      opacity: 1;
    }
  }
  .checkout-label-text {
    font-size: 14px;
  }
}
.invite-btn {
  width: 100%;
  height: 45px;
}
</style>
