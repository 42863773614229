






































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';

const ProductModule = namespace('product');

@Component({
  components: {},
})
export default class SalesPagesEditorNew extends Vue {
  @ProductModule.State selectedProduct!: any;
  @ProductModule.Action createProductPage!: () => Promise<any>;

  readonly i18nPath = 'sparkmembers.setup.sales-page.editor.new-page';
  isLoading: boolean = false;

  createNewPage() {
    this.isLoading = true;

    this.createProductPage()
      .then(({ school_product_id, id }) => {
        TrackingHelper.trackProductSalesPageCreated(school_product_id, id);
        ToastHelper.successMessage(this.$t(`${this.i18nPath}.sucess-message`));
      })
      .catch(() => ToastHelper.dangerMessage(this.$t(`${this.i18nPath}.error-message`)))
      .finally(() => (this.isLoading = false));
  }
}
