












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DeleteConfirmation from './modals/DeleteConfirmation.vue';
import MAvatar from '@/shared/components/MAvatar.vue';
import DateFormatter from '@/components/DateFormatter.vue';
import { AVATAR_BOT_3 } from '@/libs/avatar';
import { ColourByStatus, InviteStatusLabel } from '@/libs/coproduction';
import MButton from '@/shared/components/MButton.vue';
import ToastHelper from '@/shared/helpers/toast';

@Component({
  components: {
    MAvatar,
    DateFormatter,
    DeleteConfirmation,
    MButton,
  },
})
export default class CoproductionDetails extends Vue {
  @Prop({ type: Object }) invite!: any[];
  @Prop({ type: Object }) program!: any[];
  colourByStatus = ColourByStatus;
  inviteStatusLabel = InviteStatusLabel;

  defaultAvatar = AVATAR_BOT_3;

  isPendingInvite(invite) {
    return invite.status == 'pending';
  }

  inviteRemoved() {
    this.$emit('invite-removed');
  }

  async copyUrlToClipboard(event, url) {
    try {
      event.preventDefault();
      event.stopPropagation();
      await navigator.clipboard.writeText(url);
      ToastHelper.successMessage('Link copiado com sucesso!');
    } catch (error) {
      ToastHelper.dangerMessage('Ocorreu um erro ao copiar seu link.');
    }
  }
}
