<template>
  <div>
    <div class="upsell-selection__breadcrumb-wrapper" @click="$emit('change-offer-type')">
      <hs-icon variant="light" icon="long-arrow-left" />
      {{ $t('after-purchase.back-to-offers') }}
    </div>

    <div v-if="selectorStep === 'selector'" class="upsell-selection">
      <div class="upsell-selection__title-wrapper">
        <h3 class="upsell-selection__title">
          {{ $t('after-purchase.selector-title') }}
        </h3>

        <p class="upsell-selection__description">
          {{ $t('after-purchase.selector-description') }}
        </p>
      </div>

      <div class="upsell-selection__options-wrapper">
        <PostPurchaseOptionCard
          data-testid="upsell-card"
          :title="$t('after-purchase.upsell-thanks.card-title')"
          :description="$t('after-purchase.upsell-thanks.card-description')"
          :selected="isUpsellSelected"
          icon="success"
          @select="updateSelectedOption(PostPurchasePage.UPSELL)"
        />

        <PostPurchaseOptionCard
          data-testid="thanks-page-card"
          :title="$t('after-purchase.thanks-page.card-title')"
          :description="$t('after-purchase.thanks-page.card-description')"
          :selected="isThanksPageSelected"
          icon="handshake"
          @select="updateSelectedOption(PostPurchasePage.THANKS_PAGE)"
        />
      </div>

      <div v-if="!hideButtons" class="upsell-selection__buttons-wrapper">
        <MButton data-testid="cancel-button" variant="primary-outline" @click="$emit('change-offer-type')">
          {{ $t('after-purchase.back-to-offers') }}
        </MButton>

        <MButton data-testid="next-button" variant="primary" :disabled="!selectedOption" @click="nextStep()">
          {{ $t('after-purchase.next') }}
        </MButton>
      </div>
    </div>

    <ThanksPageConfig
      data-testid="thanks-config-component"
      v-if="selectorStep === 'thanks-page-config'"
      @back="backToSelectorAndReset(PostPurchasePage.THANKS_PAGE)"
      @saved="backToSelector()"
    />

    <ProductUpsellConfig
      data-testid="upsell-config-component"
      v-if="selectorStep === 'upsell-config'"
      @back="backToSelectorAndReset(PostPurchasePage.UPSELL)"
      @saved="backToSelector()"
    />

    <MConfirmModal
      id="selector-delete-thanks-page-modal"
      variant="delete"
      :image="require('@/assets/images/red-trash.png')"
      :title="$t('after-purchase.thanks-page.delete-title')"
      :text="$t('after-purchase.thanks-page.delete-text')"
      :confirm-button-label="$t('after-purchase.thanks-page.modal-confirm-button')"
      :cancel-button-label="$t('after-purchase.thanks-page.modal-cancel-button')"
      @confirm="deleteThanksPageAndSetUpsell()"
      @cancel="$bvModal.hide('selector-delete-thanks-page-modal')"
    />

    <MConfirmModal
      id="selector-delete-upsell-modal"
      variant="delete"
      :image="require('@/assets/images/red-trash.png')"
      :title="$t('after-purchase.upsell.delete-title')"
      :text="$t('after-purchase.upsell.delete-text')"
      :confirm-button-label="$t('after-purchase.upsell.modal-confirm-button')"
      :cancel-button-label="$t('after-purchase.upsell.modal-cancel-button')"
      @confirm="deleteUpsellAndSetThanksPage()"
      @cancel="$bvModal.hide('selector-delete-upsell-modal')"
    />
  </div>
</template>

<script>
import { PostPurchasePage } from '@/libs/upsell';
import PostPurchaseOptionCard from './PostPurchaseOptionCard.vue';
import ThanksPageConfig from './ThanksPageConfig.vue';
import ProductUpsellConfig from '@/sparkmembers/views/ProductSetup/components/ProductUpsellConfig';
import UpsellConfigMixin from '@/sparkmembers/views/ProductSetup/mixins/UpsellConfigMixin.js';
import { mapState, mapActions, mapGetters } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';

export default {
  name: 'PostPurchaseSelector',
  mixins: [UpsellConfigMixin],
  components: {
    PostPurchaseOptionCard,
    ThanksPageConfig,
    ProductUpsellConfig,
  },
  data() {
    return {
      PostPurchasePage,
    };
  },
  computed: {
    ...mapState('upsell', ['selectedOption', 'selectorStep']),
    ...mapGetters('upsell', ['isUpsellSelected', 'isThanksPageSelected']),
    hideButtons() {
      const thanksPageEnabled = this.hasThankYouUrls && this.isThanksPageSelected;
      const upsellEnabled = this.hasUpsellSteps && this.isUpsellSelected;

      return thanksPageEnabled || upsellEnabled;
    },
  },
  methods: {
    ...mapActions('upsell', ['setSelectedOption', 'setSelectorStep', 'setIsEditingUpsell']),
    updateSelectedOption(option) {
      if (option === PostPurchasePage.UPSELL && this.hasThankYouUrls) {
        this.$bvModal.show('selector-delete-thanks-page-modal');
        return;
      }

      if (option === PostPurchasePage.THANKS_PAGE && this.hasUpsellSteps) {
        this.$bvModal.show('selector-delete-upsell-modal');
        return;
      }

      this.setSelectedOption(option);
      this.$emit('selected-upsell-config', option);
    },
    nextStep() {
      if (this.isUpsellSelected) {
        this.setSelectorStep('upsell-config');
        this.setIsEditingUpsell(true);
      } else if (this.isThanksPageSelected) {
        this.setSelectorStep('thanks-page-config');
      }
    },
    backToSelectorAndReset(selectedOption) {
      this.backToSelector();
      this.setSelectedOption(selectedOption);
    },
    backToSelector() {
      this.setSelectorStep('selector');
      this.setIsEditingUpsell(false);
    },
    backToSelectorAndShowModal() {
      this.backToSelector();
      this.$emit('selected-upsell-config', PostPurchasePage.UPSELL);
      this.$bvModal.show('modal-copy-code');
    },
    async deleteThanksPageAndSetUpsell() {
      try {
        await this.deleteThanksPage('selector-delete-thanks-page-modal');
        this.setSelectedOption(PostPurchasePage.UPSELL);
        this.$emit('selected-upsell-config', PostPurchasePage.UPSELL);

        this.nextStep();
      } catch (e) {
        ToastHelper.dangerMessage(e.message);
      }
    },
    async deleteUpsellAndSetThanksPage() {
      try {
        this.deleteUpsell('selector-delete-upsell-modal');
        this.setSelectedOption(PostPurchasePage.THANKS_PAGE);
        this.$emit('selected-upsell-config', PostPurchasePage.THANKS_PAGE);

        this.nextStep();
      } catch (e) {
        ToastHelper.dangerMessage(e.message);
      }
    },
  },
  mounted() {
    this.$root.$on('upsell-edit', data => {
      this.nextStep();
    });

    this.$root.$on('created-upsell', () => {
      this.backToSelectorAndShowModal();
    });

    if (this.hasThankYouUrls) {
      this.updateSelectedOption(PostPurchasePage.THANKS_PAGE);
    }

    if (this.hasUpsellSteps) {
      this.updateSelectedOption(PostPurchasePage.UPSELL);
    }
  },
};
</script>

<style lang="scss">
.upsell-selection {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.upsell-selection__title-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
}

.upsell-selection__title {
  color: $grey;
  font-size: 1.25rem;
  line-height: 1.563rem;
  font-weight: 700;
}

.upsell-selection__description {
  color: $grey-40;
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin: 0;
}

.upsell-selection__options-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  @media (min-width: $screen-md) {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
}

.upsell-selection__buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
}

.upsell-selection__breadcrumb-wrapper {
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-bottom: 8px;
  color: $grey;
  font-size: 0.875rem;
  line-height: 1.313rem;
  cursor: pointer;
}
</style>
