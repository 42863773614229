import { helpers } from 'vuelidate/lib/validators';

const noQuotes = value => helpers.regex(value, /^((?!"|'|`|«|»|‘|’|‚|“|”|„|‹|›).)*$/);
const cpf = value => helpers.regex(value, /(\d{3}).(\d{3}).(\d{3})-(\d{2})/);
const cnpj = value => helpers.regex(value, /(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})/);
const cpfOrCnpj = value =>
  helpers.regex(
    value,
    // eslint-disable-next-line no-useless-escape
    /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/
  );

export default {
  cpf,
  cpfOrCnpj,
  noQuotes,
  cnpj,
};
