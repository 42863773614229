<template>
  <div class="tlw-w-full tlw-bg-white tlw-p-4 lg:tlw-p-8 tlw-mb-2">
    <div class="lg:tlw-mb-2">
      <div class="col-12 row justify-content-between mx-0 p-0 mb-3 mb-lg-4">
        <h4 class="font-weight-bold tlw-text-xl">
          {{ $t('sparkmembers.setup.sales-page.info.form.basic.section') }}
        </h4>
        <m-button
          class="tlw-text-sm tlw-text-purple-500 p-0 tlw-cursor-pointer"
          variant="link"
          @click="() => $bvModal.show('preview-modal-info-description')"
        >
          <hs-icon icon="eye" class="tlw-mr-1" />
          <span class="tlw-font-normal">
            {{ $t('sparkmembers.setup.sales-page.info.form.basic.label-modal-show') }}
          </span>
        </m-button>
      </div>
      <span class="d-block font-weight-bold font-size-sm mb-1">{{
        $t('sparkmembers.setup.sales-page.info.form.basic.subtitle')
      }}</span>
      <p class="text-uppercase font-size-sm tlw-mb-6">{{ product.title }}</p>
      <b-form @submit.prevent="">
        <hs-group class="font-weight-bold" :label="$t('sparkmembers.setup.sales-page.info.form.basic.subtitle-sales')">
          <hs-input
            v-model="form.subtitle"
            class="appcues-enablement-welcome-step-7"
            type="text"
            :placeholder="$t('sparkmembers.setup.sales-page.info.form.basic.subtitle-placeholder')"
            :disabled="isSaving"
            @change="identifyDataSave"
          />
        </hs-group>

        <hs-group class="font-weight-bold" :label="$t('sparkmembers.setup.sales-page.info.form.basic.description')">
          <div @focusin="detectChangeValue(form.description)" @focusout="detectChangeValue(form.description)">
            <Editor
              v-model="form.description"
              :initial-value="form.description || ''"
              :init="{
                ...editorDefaultInit,
                placeholder: $t('sparkmembers.setup.sales-page.info.form.basic.description-placeholder'),
                setup: function(editor) {
                  editor.on('blur', () => {
                    identifyDataSave();
                  });
                },
              }"
            />
          </div>
        </hs-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MButton from '@/shared/components/MButton';
import Editor from '@tinymce/tinymce-vue';
import { editorDefaultInit } from '@/libs/tinymce';

export default {
  components: { MButton, Editor },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      isValue: null,
      editorDefaultInit: { ...editorDefaultInit },
    };
  },
  methods: {
    identifyDataSave() {
      this.$emit('autoSaveData');
    },
    detectChangeValue(val) {
      this.isValue = val;
    },
  },
  watch: {
    form(val) {
      this.form = val;
    },
    isValue(newValue, oldValue) {
      if (oldValue !== null && oldValue !== newValue) {
        this.$emit('autoSaveData');
      }
    },
  },
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
  },
};
</script>
