import { ProductId } from '@/types/products';

export enum CoproductionStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REFUSED = 'refused',
  EXPIRED = 'expired',
}

export enum CommissionType {
  LAST_CLICK = 'last_click',
  FIRST_CLICK = 'first_click',
}

export type CoproductionProgramId = number;
export type ProgramProductId = number;
export type ProgramProductOfferId = number;

export type OfferId = number;
export type OfferSlug = string;
export type OfferURL = string;

export type ProgramProductProductOfferBase<V> = V & {
  sparkecommerce_offer_id: OfferId;
  sparkecommerce_offer_slug: OfferSlug;
  offer_url: OfferURL;
};

export type ProgramProductBase<U, V> = U & {
  sparkecommerce_product_id: ProductId;
  product_offers: ProgramProductProductOfferBase<V>[];
};

export type CoproductionProgramBase<T, U, V> = T & {
  name: string;
  description: string;
  enabled: boolean;
  commission_type: CommissionType;
  program_products: ProgramProductBase<U, V>[];
};

export type CoproductionProgramForm = CoproductionProgramBase<{}, {}, {}>;

export type CoproductionProgram = CoproductionProgramBase<
  {
    id: CoproductionProgramId;
    participation_invites: ParticipationInvite[];
  },
  {
    id: ProgramProductId;
  },
  {
    id: ProgramProductOfferId;
  }
>;

export type SchoolId = number;
export type ParticipantId = number;
export type ParticipationInviteId = number;
export type CoproducerId = number;

export interface Participant {
  id: ParticipantId;
  core_school_id: SchoolId;
  name: string;
  email: string;
  programs: CoproductionProgram[];
  created_at: Date;
  updated_at: Date;
}

export interface ParticipationInvite {
  id: ParticipationInviteId;
  program_id: CoproductionProgramId;
  name: string;
  email: string;
  status: string;
  invite_code: string;
  created_at: Date;
  updated_at: Date;
  answered_at: Date;
  link: string;
}

export interface Coproducer {
  id: CoproducerId;
  commission: number;
  participant: Participant;
  participation_invite: ParticipationInvite;
}

export interface InviteForm {
  commission: string | null;
  name: string;
  email: string;
  avatar: string;
}

export interface Invite {
  program_id: CoproductionProgramId;
  school_id: number;
  commission: number;
  name: string;
  email: string;
  avatar: string;
  product_name?: string;
}
