




































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';
import BrowserHelper from '@/shared/helpers/browser';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';

const JWTModule = namespace('jwt');
const SchoolModule = namespace('school');
const ProductModule = namespace('product');

@Component({
  validations: {
    slug: {
      required,
      hasSpace: value => !!value && !!value.match(/^[a-zA-Z0-9/-]*$/),
    },
  },
})
export default class SalesPagesEditorDetails extends Vue {
  @JWTModule.State encoded!: string;
  @SchoolModule.Getter getDomainWithHttp!: string;
  @ProductModule.State selectedProduct!: any;
  @ProductModule.Action updateProductPage!: (page: any) => Promise<any>;

  @Prop({ required: true }) page: any;

  slug: string = '';
  isLoading: boolean = false;

  get pageUrl() {
    return `${this.getDomainWithHttp}/p/${this.page.name}`;
  }
  get editorUrl() {
    return `${process.env.VUE_APP_PAGES_BASE_URL}/editor/${this.page.page_id}?token=${this.encoded}`;
  }
  get customClasses() {
    return !BrowserHelper.isMobile() ? 'bg-transparent text-white border-white' : '';
  }
  get pagesPreview() {
    let preview;
    const assetUrl =
      'https://s3.us-east-1.amazonaws.com//pages.beta.eadbox.com/themes/09bb72b6-7347-464c-9edf-10382e1e132c/assets';

    if (BrowserHelper.isTablet()) preview = `${assetUrl}/preview-tablet.png`;
    if (BrowserHelper.isMobile()) preview = `${assetUrl}/preview-mobile.png`;
    preview = `${assetUrl}/preview-desktop.png`;

    return this.page.published ? {} : { 'background-size': 'cover', 'background-image': `url(${preview})` };
  }

  mounted() {
    this.slug = this.page.name;
  }

  viewPage() {
    window.open(this.pageUrl, '_blank');
  }
  editPage() {
    window.open(this.editorUrl, '_blank');
    TrackingHelper.trackProductSalesPageEditingInitiated(this.selectedProduct.id, this.page.page_id);
  }

  async copyUrlToClipboard() {
    try {
      await navigator.clipboard.writeText(this.pageUrl);
      ToastHelper.successMessage(this.$t('sparkfunnels.funnels.edit.sections.page_link.actions.copy.success'));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.edit.sections.page_link.actions.copy.error'));
    }
  }

  updateProductPageName() {
    this.isLoading = true;

    this.updateProductPage({ ...this.page, name: this.slug })
      .then(() => {
        TrackingHelper.trackProductSalesPageSlugUpdated(this.selectedProduct.id, this.page.page_id, this.slug);
        ToastHelper.successMessage(
          this.$t('sparkfunnels.funnels.edit.sections.page_link.custom_slug.input.feedback.success')
        );
      })
      .catch(() => {
        ToastHelper.dangerMessage(
          this.$t('sparkfunnels.funnels.edit.sections.page_link.custom_slug.input.feedback.error')
        );
      })
      .finally(() => (this.isLoading = false));
  }
}
