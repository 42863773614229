<template>
  <div>
    <hsPageHeader
      :title="$t('sparkmembers.sales-page.title')"
      :sub-title="$t('sparkmembers.sales-page.sub-title')"
      :back-text="$t('breadcrumb.ProductDashboard')"
      back-route="ProductDashboard"
    >
      <template slot="actions">
        <hs-button
          v-if="product.published"
          :href="`//${getDomain}/${product.slug}`"
          target="_blank"
          variant="link text-dark font-weight-light"
        >
          <hs-icon icon="eye" /> {{ $t('sparkmembers.sales-page.view-page') }}
        </hs-button>
      </template>
    </hsPageHeader>
    <Tab v-model="tab" :items="tabItems" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Tab from './components/Tab/index';
import Info from './components/Tab/Info';
import Seo from './components/Tab/Seo';
import hsPageHeader from '@/components/_structures/PageHeader';

export default {
  data() {
    return {
      tab: 'content',
      tabItems: [
        {
          id: 'content',
          label: this.$t('sparkmembers.sales-page.info.title'),
          component: Info,
        },
        {
          id: 'seo',
          label: this.$t('sparkmembers.sales-page.seo.title'),
          component: Seo,
        },
      ],
    };
  },
  components: { Tab, hsPageHeader },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
  },
};
</script>
