<template>
  <div>
    <div class="upsell-offer-config">
      <div class="upsell-offer-config__title-wrapper">
        <h3 class="upsell-offer-config__title">
          {{ $t('after-purchase.offer-upsell-config.title') }}
        </h3>
        <OfferUpsellConfigDropdown
          class="desktop-dropdown-offer-config desktop-only"
          data-testid="upsell-config-dropdown"
          @delete="deleteUpsellFlow()"
          @edit="edit()"
        />
      </div>

      <p data-testid="offer-description" class="upsell-offer-config__description">
        {{ editedOfferDescription }}
      </p>
    </div>

    <OfferUpsellConfigCard />

    <OfferUpsellConfigDropdown
      class="mobile-dropdown-offer-config mobile-only"
      @delete="deleteUpsellFlow()"
      @edit="edit()"
    />
  </div>
</template>
<script>
import OfferUpsellConfigCard from './OfferUpsellConfigCard.vue';
import OfferUpsellConfigDropdown from './OfferUpsellConfigDropdown.vue';
import UpsellConfigMixin from '@/sparkmembers/views/ProductSetup/mixins/UpsellConfigMixin.js';
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'OfferUpsellConfig',
  mixins: [UpsellConfigMixin],
  components: {
    OfferUpsellConfigCard,
    OfferUpsellConfigDropdown,
  },
  methods: {
    edit() {
      this.$root.$emit('upsell-edit');
    },
  },
  computed: {
    editedOfferDescription() {
      const formattedPrice = GeneralHelper.currency(this.offer.price);

      return `${this.offer.title} (${formattedPrice})`;
    },
  },
};
</script>

<style lang="scss">
.base-dropdown-config {
  align-items: center;
  display: grid;
  grid-template-columns: auto 50px;
}

.desktop-dropdown-offer-config {
  @extend .base-dropdown-config;

  column-gap: 28px;
}
.mobile-dropdown-offer-config {
  @extend .base-dropdown-config;

  margin-top: 25px;
}
.mobile-only {
  display: none;

  @media (max-width: 991px) {
    display: grid;
  }
}
.desktop-only {
  display: grid;

  @media (max-width: 991px) {
    display: none;
  }
}
.upsell-offer-config__title-wrapper {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.upsell-offer-config__title {
  margin-top: 8px;
  color: $grey;
  font-size: 1.25rem;
  line-height: 1.563rem;
  font-weight: 700;
}
.upsell-offer-config__description {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: rem(14);
  line-height: rem(21);
  color: $grey;
}
</style>
