import { AffiliationInvitesStatus } from '@/types/affiliates';

export const MY_AFFILIATES_TAB = 'AffiliatesListTab';
export const MY_AFFILIATIONS_TAB = 'AffiliationsListTab';
export const INVITE_AFFILIATE_TAB = 'InviteAffiliate';
export const MY_AFFILIATION_INVITES_TAB = 'AffiliationInvitesTab';
export const MY_PROGRAMS_TAB = 'ProgramListTab';
export const NEW_PROGRAM_TAB = 'NewProgramTab';
export const PROGRAM_EDITOR_FORM = 'ProgramEditorForm';
export const PROGRAM_DETAILS_EDITOR = 'ProgramDetailsEditor';

export const inviteStatusDictionary = Object.freeze({
  [AffiliationInvitesStatus.PENDING]: 'Pendente',
  [AffiliationInvitesStatus.ACCEPTED]: 'Aceito',
  [AffiliationInvitesStatus.EXPIRED]: 'Expirado',
  [AffiliationInvitesStatus.REFUSED]: 'Recusado',
});

export const COMMISSION_RULES =
  'https://ajuda.herospark.com/hc/pt-br/articles/4800774963725-Como-configurar-a-coprodu%C3%A7%C3%A3o-de-um-produto-';

export const offerId = o => o.sparkecommerce_offer_id || o.id;
export const offerReferenceId = o => o.sparkecommerce_reference_id || o.reference_id;

export const isOfferEq = (a, b) => offerReferenceId(a) === offerReferenceId(b) || offerId(a) === offerId(b);
