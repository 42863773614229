<template>
  <div>
    <InfoTabs :activeTab="activeTab" @selectedTab="tab => (activeTab = tab)" />
    <template v-if="activeTab === 'automatic'">
      <InfoHeader />
      <InfoBasicConfigs :form="product" @autoSaveData="onSubmit" />
      <InfoVideoUpload :form="product" @autoSaveData="onSubmit" />
      <InfoImageUpload :form="product" @autoSaveData="onSubmit" />
      <div class="tlw-w-full tlw-bg-white tlw-p-4 lg:tlw-p-8 tlw-mb-2">
        <div class="tlw-flex tlw-flex-wrap tlw-justify-between tlw-items-baseline">
          <h4 class="tlw-w-3/4 tlw-font-bold">{{ $t('sparkmembers.sales-page.info.form.topics.section') }}</h4>
          <m-button
            class="tlw-text-sm tlw-text-purple-500 p-0 tlw-cursor-pointer"
            variant="link"
            @click="() => $bvModal.show('preview-modal-topics')"
          >
            <hs-icon icon="eye" class="tlw-mr-1" />
            <span class="tlw-font-normal">
              {{ $t('sparkmembers.setup.sales-page.info.form.basic.label-modal-show') }}
            </span>
          </m-button>
          <p class="tlw-font-sans font-size-sm tlw-mt-1 tlw-mb-6">
            Use os tópicos para reforçar os benefícios do produto. ( Máximo de 3 tópicos)
          </p>
        </div>
        <template v-if="loadingTopics">
          <hsLoading />
        </template>
        <template v-else>
          <b-form @submit.prevent="" @change="identifyDataSave">
            <draggable
              handle=".handle"
              v-model="topics"
              v-bind="dragOptions"
              @start="drag = true"
              @end="
                () => {
                  drag = false;
                  onTopicOrder();
                }
              "
            >
              <hs-group v-for="(topic, index) in topics" :key="index">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <hs-icon icon="bars" :class="topic.id ? 'handle' : 'no-handle'" :size="16" />
                  </div>
                  <div class="w-100 flex-1">
                    <hs-input
                      type="text"
                      v-model="topic.description"
                      :placeholder="$t('sparkmembers.sales-page.info.form.topics.description-placeholder')"
                    />
                  </div>
                  <div class="ml-3">
                    <hs-button
                      v-b-tooltip.hover="$t('actions.delete')"
                      class="p-0 text-cherry"
                      variant="link"
                      @click="() => onRemoveTopic(topic, index)"
                    >
                      <hs-icon icon="trash-alt" />
                    </hs-button>
                  </div>
                </div>
              </hs-group>
            </draggable>

            <div v-if="topics.length < 3">
              <hs-button
                icon="plus"
                variant="primary"
                class="px-0 font-weight-light"
                @click="
                  () => {
                    topics.push({
                      title: $t('sparkmembers.sales-page.info.form.topics.title', { index: topics.length + 1 }),
                      description: '',
                      order: topics.length + 1,
                      school_product_id: product.id,
                    });
                  }
                "
              >
                {{ $t('sparkmembers.sales-page.info.form.topics.action') }}
              </hs-button>
            </div>
          </b-form>
        </template>
      </div>
      <InfoTeacher :form="product" @autoSaveData="onSubmit" />
      <InfoDefaultOffers />
      <InfoSeo :form="product" @autoSaveData="onSubmit" />
      <InfoUrlProduct :form="product" @copyLink="copyToClipboard()" @onSaveSlug="feedbackSlug($event)" />
      <div
        class="
          tlw-fixed tlw-flex tlw-bg-white tlw-px-5 tlw-justify-end
          lg:tlw-px-12
          tlw-py-5
          lg:tlw-py-5
          btn-save-container
        "
        :style="{ width: windowWidth >= 1024 ? (sideMenuOpened ? 'calc(100% - 194px)' : 'calc(100% - 62px)') : '100%' }"
      >
        <div class="tlw-flex tlw-flex-col tlw-justify-between lg:tlw-px-12 lg:tlw-flex-row tlw-px-0 btn-save-content">
          <div class="lg:tlw-flex tlw-hidden">
            <div class="tlw-flex tlw-flex-col tlw-mr-7">
              <p class="tlw-text-sm tlw-mb-0 tlw-font-semibold">
                {{ $t('sparkmembers.setup.sales-page.info.form.basic.label-link') }}
              </p>
              <a
                class="tlw-block tlw-font-semibold tlw-text-sm card-product-link"
                :href="linkProduct()"
                target="_blank"
              >
                {{ productLink() }}
              </a>
            </div>
            <div class="tlw-flex">
              <m-button
                class="tlw-mb-7 tlw-mr-2 lg:tlw-mb-0 tlw-h-7"
                variant="primary-outline"
                @click="copyToClipboard"
              >
                {{ $t('sparkmembers.setup.sales-page.info.form.basic.copy-link') }}
              </m-button>
              <m-button href="#edit-product-slug" class="tlw-mb-7 lg:tlw-mb-0 tlw-h-7" variant="primary-outline">
                {{ $t('sparkmembers.setup.sales-page.info.form.basic.edit-link') }}
              </m-button>
              <input type="hidden" ref="link-checkout" />
            </div>
          </div>
          <p class="tlw-mb-4 tlw-text-base" v-if="isSaving">
            <hs-icon class="tlw-mr-3" variant="light" icon="sync" size="15" />
            {{ $t(`sparkmembers.setup.sales-page.info.form.basic.saving`) }}
          </p>
          <p class="tlw-mb-4 tlw-text-base" v-if="isSaved">
            <hs-icon class="tlw-mr-2 tlw-text-green-400" variant="light" icon="check" size="18" />
            {{ $t(`sparkmembers.setup.sales-page.info.form.basic.saved-page`) }}
          </p>
          <hs-alert
            v-if="feedbackAlert"
            class="alert-save"
            variant="success"
            :show="feedbackAlert"
            :fade="true"
            @dismissed="feedbackAlert = false"
            dismissible
          >
            {{ $t('sparkmembers.setup.sales-page.info.form.basic.message-saved-page') }}
          </hs-alert>
          <m-button
            class="tlw-text-base lg:tlw-hidden lg:tlw-text-sm tlw-h-12 lg:tlw-h-auto"
            size="lg"
            variant="primary-outline"
            @click="copyToClipboard"
          >
            <hs-icon class="tlw-mr-3" variant="light" icon="link" size="19" />
            {{ $t('sparkmembers.setup.sales-page.info.form.basic.copy-link-secondary') }}
          </m-button>
        </div>
      </div>
      <hsConfirmModal
        id="delete-topic-modal"
        icon="trash-alt"
        variant="cherry"
        hide-header
        :customTitle="$t('sparkmembers.sales-page.info.alert.topic.title')"
        :description="$t('sparkmembers.sales-page.info.alert.topic.description')"
        :ok-title="$t('sparkmembers.sales-page.info.alert.topic.confirm')"
        :cancel-title="$t('sparkmembers.sales-page.info.alert.topic.cancel')"
        @ok="onDeleteTopic"
      />
      <ModalPreviewPage />
    </template>
    <SalesPagesEditor v-else />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import { analyticsService, productService } from '@/services';
import Confirm from '@/shared/mixins/Confirm';
import Clipboard from '@/shared/mixins/Clipboard';
import MButton from '@/shared/components/MButton';
import ToastHelper from '@/shared/helpers/toast';
import ProductUrlHelper from '@/sparkmembers/helpers/productUrlHelper';
import TrackingHelper from '@/shared/helpers/tracking';

import hsConfirmModal from '@/components/ConfirmModal';
import hsLoading from '@/components/Loading';

import InfoBasicConfigs from './components/InfoBasicConfigs';
import InfoDefaultOffers from './components/InfoDefaultOffers';
import InfoHeader from './components/InfoHeader';
import InfoImageUpload from './components/InfoImageUpload';
import InfoSeo from './components/InfoSeo';
import InfoTabs from './components/InfoTabs';
import InfoTeacher from './components/InfoTeacher';
import InfoUrlProduct from './components/InfoUrlProduct';
import InfoVideoUpload from './components/InfoVideoUpload';
import ModalPreviewPage from '../ModalPreviewPage';
import SalesPagesEditor from '../SalesPagesEditor/SalesPagesEditor.vue';
import WindowWidth from '@/mixins/WindowWidth.js';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  mixins: [Confirm, Clipboard, WindowWidth],
  components: {
    draggable,
    hsConfirmModal,
    hsLoading,
    InfoBasicConfigs,
    InfoDefaultOffers,
    InfoHeader,
    InfoImageUpload,
    InfoSeo,
    InfoTabs,
    InfoTeacher,
    InfoUrlProduct,
    InfoVideoUpload,
    MButton,
    ModalPreviewPage,
    SalesPagesEditor,
  },
  data() {
    return {
      isSaving: false,
      isSavingTopic: false,
      activeTab: 'automatic',
      topics: [],
      selectedTopic: {
        title: null,
        description: null,
      },
      loadingTopics: true,
      drag: false,
      feedback: {
        status: null,
      },
      isSaved: null,
      feedbackAlert: false,
    };
  },
  watch: {
    form(val) {
      this.form = val;
    },
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    ...mapState('course', {
      course: state => state.selectedCourse,
    }),
    ...mapState('sidemenu', {
      sideMenuOpened: state => state.sideMenuOpened,
    }),
    dragOptions() {
      return {
        animation: 200,
        group: 'topic',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  methods: {
    async onSubmit(callback, showFeedBackAlert = true) {
      this.isSaved = false;
      this.isSaving = true;
      this.onAddTopic();

      productService
        .update(this.form)
        .then(() => {
          this.feedbackAlert = showFeedBackAlert;
          analyticsService.track({
            event: 'Product sales page updated',
            props: { product_id: this.form.id },
          });
          this.$emit('onSave');
          if (typeof callback === 'function') callback();
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.product.error'));
        })
        .finally(() => {
          this.isSaving = false;
          this.isSaved = true;
          setTimeout(() => {
            this.feedbackAlert = false;
          }, 3000);
        });
    },
    async getTopics() {
      productService
        .getProductTopic(this.form.id)
        .then(res => {
          this.topics = res.data.school_product_topics.sort((a, b) => a.order - b.order);
        })
        .finally(() => {
          this.loadingTopics = false;
        });
    },
    async onAddTopic() {
      this.loadingTopics = true;
      try {
        await productService.updateBatchProductTopic(this.topics.filter(topic => topic.id));
        await productService.createBatchProductTopic(this.topics.filter(topic => !topic.id));
        this.getTopics();
      } catch {
        this.loadingTopics = false;
      }
    },
    onRemoveTopic(topic, index) {
      if (topic.id) {
        this.selectedTopic = topic;
        this.onDelete('delete-topic-modal');
      } else {
        this.topics.splice(index, 1);
      }
    },
    async onTopicOrder() {
      try {
        this.topics = this.topics.map((topic, index) => ({ ...topic, order: index }));
        await productService.updateBatchProductTopic(this.topics.filter(topic => topic.id));
        ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.topic.update'));
      } catch {
        ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.topic.update-error'));
      }
    },
    onDeleteTopic() {
      this.loadingTopics = true;
      productService
        .deleteProductTopic(this.selectedTopic.id)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.topic.deleted'));
          this.topics = this.topics.filter(filteredTopic => filteredTopic.id !== this.selectedTopic.id);
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.topic.deleted-error'));
        })
        .finally(() => {
          this.loadingTopics = false;
          this.selectedTopic = {};
        });
    },
    onDelete(modalId) {
      this.$bvModal.show(modalId);
    },
    generatelinkProduct(defaultDomain, productSlug) {
      return ProductUrlHelper.generateUrlProduct(defaultDomain, productSlug);
    },
    linkProduct() {
      return this.generatelinkProduct(this.getDomain, this.product.slug);
    },
    productLink() {
      return `${this.linkProduct().substr(0, 35)}...`;
    },
    copyToClipboard() {
      const ref = 'link-checkout';
      let link = this.generatelinkProduct(this.getDomain, this.product.slug);
      TrackingHelper.trackSalesLinkCopied('sales_page');
      try {
        const result = this.clipboardCopy(ref, link);
        if (!result) throw new Error('Exec command failed');
        ToastHelper.successMessage(
          this.$t('sparkmembers.setup.sales-page.url-product.message-feedback.link-copied-success')
        );
        this.selectedLink = true;
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.setup.sales-page.url-product.message-feedback.link-copied-error')
        );
      }
    },
    feedbackSlug($event) {
      this.feedback = $event;
      if (this.feedback.status) this.$emit('onSave');
    },
    identifyDataSave() {
      this.onSubmit();
    },
  },
  mounted() {
    this.getTopics();
  },
};
</script>

<style lang="scss" scoped>
.handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.no-handle {
  cursor: not-allowed;
}

.border-2 {
  border: 2px solid transparent;
}

.border-dashed {
  border-style: dashed;
}

.uploader {
  width: 91px;
  height: 91px;
}

.btn-save-container {
  bottom: 0;
  right: 0;
  z-index: 1;
}

.btn-save-content {
  width: calc(100% - 225px);
}

.tooltip {
  top: 0;
}

.card-product-link {
  color: var(--color-blue-700);
}

/deep/ {
  .alert-save {
    position: fixed;
    right: 0;
    top: 110px;
    z-index: 2;

    .d-flex {
      align-items: center;
    }
  }
}

@media only screen and (max-width: $screen-md) {
  .btn-save-container {
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 999;
  }
  .btn-save-content {
    width: 100%;
  }
}
</style>
