export const editorDefaultInit = {
  language: 'pt_BR',
  height: 350,
  menubar: false,
  plugins: 'lists code link autolink image emoticons media fullscreen',
  forced_root_block: '',
  toolbar:
    'undo redo | bold italic underline strikethrough | fontfamily fontsize fontsizeinput | \
    alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | \
    forecolor backcolor removeformat | media image link emoticons | code',
  content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 1rem; }',
  toolbar_mode: 'sliding',
};
