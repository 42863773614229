









































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';
import { hsConfirmModal, hsSwitch } from '@/components';

import SalesPagesEditorDetails from './SalesPagesEditorDetails.vue';

const ProductModule = namespace('product');

@Component({
  components: {
    hsConfirmModal,
    SalesPagesEditorDetails,
    hsSwitch,
  },
})
export default class SalesPagesEditorList extends Vue {
  @ProductModule.State selectedProduct!: any;
  @ProductModule.Action createProductPage!: () => Promise<any>;
  @ProductModule.Action deleteProductPage!: (pageId: number) => Promise<any>;
  @ProductModule.Action updateProductPage!: (page: any) => Promise<any>;

  selectedPage: any = null;
  isLoadingNewPage: boolean = false;
  new_cart_recovery: boolean = false;

  updateProductPageTitle(page) {
    if (this.selectedPage?.newTitle?.length) {
      this.selectedPage.isLoading = true;

      this.updateProductPage({ ...page, title: this.selectedPage.newTitle })
        .then(() => {
          TrackingHelper.trackProductSalesPageTitleUpdated(
            this.selectedProduct.id,
            page.page_id,
            this.selectedPage.newTitle
          );
        })
        .finally(() => (this.selectedPage = null));
    }
  }

  togglePublicationPage(page) {
    const updatedPage = { ...page, published: !page.published };

    this.selectPage(page);
    this.selectedPage.isLoading = true;

    this.updateProductPage(updatedPage)
      .then(() => {
        const track = updatedPage.published
          ? TrackingHelper.trackProductSalesPagePublished
          : TrackingHelper.trackProductSalesPageUnpublished;

        track(this.selectedProduct.id, updatedPage.page_id);
      })
      .finally(() => (this.selectedPage = null));
  }

  removeProductPage(page) {
    const { page_id } = page;

    this.deleteProductPage(page.id).then(() => {
      TrackingHelper.trackProductSalesPageDeleted(this.selectedProduct.id, page_id);
    });
  }

  selectPage(page) {
    this.selectedPage = { isEditingTitle: true, newTitle: page.title, isLoading: false, ...page };
  }

  createNewPage() {
    this.isLoadingNewPage = true;

    this.createProductPage()
      .then(({ school_product_id, id }) => {
        TrackingHelper.trackProductSalesPageCreated(school_product_id, id);
        ToastHelper.successMessage(this.$t(`sparkmembers.setup.sales-page.editor.new-page.sucess-message`));
      })
      .catch(() => ToastHelper.dangerMessage(this.$t(`sparkmembers.setup.sales-page.editor.new-page.error-message`)))
      .finally(() => (this.isLoadingNewPage = false));
  }
}
