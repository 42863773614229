<template>
  <div
    data-testid="option-card"
    :class="{ 'upsell-option-card--selected': selected }"
    class="upsell-option-card"
    @click="$emit('select')"
  >
    <img
      data-testid="option-card-icon"
      :src="require(`@/assets/images/upsell/${icon}.svg`)"
      alt="upsell"
      class="upsell-option-card__icon"
      :class="{ 'upsell-option-card__icon--selected': selected }"
    />

    <div class="upsell-option-card__content">
      <div class="upsell-option-card__title-wrapper">
        <div
          data-testid="option-card-title"
          :class="{ 'upsell-option-card__title--selected': selected }"
          class="upsell-option-card__title"
        >
          {{ title }}
        </div>

        <input type="radio" :checked="selected" disabled class="upsell-option-card__radio" />
      </div>

      <div data-testid="option-card-description" class="upsell-option-card__description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostPurchaseOptionCard',
  props: {
    icon: {
      type: String,
      default: 'success',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.upsell-option-card {
  display: grid;
  grid-template-columns: 50px auto;
  column-gap: 12px;
  padding: 20px 16px;
  background-color: $grey-16;
  border: 1px solid $grey-16;
  border-radius: 4px;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.upsell-option-card--selected {
  background-color: #ead3fe;
  border: 1px solid $purple-dark;
  cursor: default;
}

.upsell-option-card__content {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
}

.upsell-option-card__icon {
  filter: none;
}

.upsell-option-card__icon--selected {
  filter: invert(10%) sepia(89%) saturate(7477%) hue-rotate(266deg) brightness(104%) contrast(89%);
}

.upsell-option-card__title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: $grey;
  transition: all 0.2s ease-in-out;
}

.upsell-option-card__title--selected {
  color: $purple-dark;
}

.upsell-option-card__description {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $grey-40;
}

.upsell-option-card__title-wrapper {
  display: grid;
  grid-template-columns: 1fr 14px;
  align-items: flex-start;
}

.upsell-option-card__radio {
  width: 14px;
  height: 14px;
  appearance: none;
  background-color: #fff;
  margin: 0;
  border: 1px solid #bababa;
  border-radius: 50%;
  display: grid;
  place-content: center;

  &[type='radio']::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #fff;
  }

  &[type='radio']:checked {
    background-color: $purple-dark;

    &::before {
      transform: scale(1);
    }
  }
}
</style>
