import { AxiosResponse, AxiosError } from 'axios';
import { CoproductionProgram } from '@/types/coproduction';
import { CoproductionStatus } from '@/types/coproduction';

import { taggedSum } from 'daggy';

const CoproductionProgramError = taggedSum('CoproductionProgramError', {
  CoproductionProgramOtherError: ['error'],
  CoproductionProgramNotCreated: ['error'],
  CoproductionProgramAxiosError: ['error'],
});

const ColourByStatus = function(status: string) {
  return {
    [CoproductionStatus.PENDING]: 'invite-pending',
    [CoproductionStatus.ACCEPTED]: 'invite-accepted',
    [CoproductionStatus.REFUSED]: 'invite-refused',
    [CoproductionStatus.EXPIRED]: 'invite-expired',
  }[status];
};

const InviteStatusLabel = function(status: string): string {
  const table = {
    [CoproductionStatus.PENDING]: 'Aguardando',
    [CoproductionStatus.ACCEPTED]: 'Aceito',
    [CoproductionStatus.REFUSED]: 'Recusado',
    [CoproductionStatus.EXPIRED]: 'Expirado',
  };
  return table[status];
};

const {
  CoproductionProgramOtherError,
  CoproductionProgramNotCreated,
  CoproductionProgramAxiosError,
} = CoproductionProgramError;

export {
  CoproductionProgramError,
  CoproductionProgramOtherError,
  CoproductionProgramNotCreated,
  CoproductionProgramAxiosError,
  ColourByStatus,
  InviteStatusLabel,
};

export function decodeError(err: AxiosError | Error): any {
  let $error;

  let error: AxiosError = err as AxiosError;
  if (error.isAxiosError) {
    $error = error.response!.status === 404 ? CoproductionProgramNotCreated : CoproductionProgramAxiosError;
  } else {
    $error = CoproductionProgramOtherError;
  }

  return Promise.reject($error(err));
}
