










import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import hsLoading from '@/components/Loading.vue';
import SalesPagesEditorEmpty from './SalesPagesEditorEmpty.vue';
import SalesPagesEditorList from './SalesPagesEditorList.vue';
import SalesPagesEditorNew from './SalesPagesEditorNew.vue';

const ProductModule = namespace('product');
const JWTModule = namespace('jwt');

@Component({
  components: {
    hsLoading,
    SalesPagesEditorEmpty,
    SalesPagesEditorList,
    SalesPagesEditorNew,
  },
})
export default class SalesPagesEditor extends Vue {
  @ProductModule.State selectedProduct!: any;
  @ProductModule.Action getProductPageList!: () => Promise<any>;
  @JWTModule.Getter isLoaded!: boolean;
  @JWTModule.Action load!: () => any;

  pagesLoaded: boolean = false;
  isShowSalesPagesEditorEmpty: boolean = false;

  async getPageList() {
    if (!this.isLoaded) await this.load();

    await this.getProductPageList();

    this.pagesLoaded = true;
  }

  created() {
    this.getPageList();
  }
}
