import { PaymentMethodsList } from '@/types/payments';

export const formatAcceptedPaymentOptions = (paymentOptions: string[]) => {
  return paymentOptions
    .map(option => paymentOptionsDictionary[option])
    .join(', ')
    .replace(/, ([^,]*)$/, ' e $1');
};

export const paymentOptionsDictionary = Object.freeze({
  [PaymentMethodsList.CREDIT_CARD]: 'Cartão',
  [PaymentMethodsList.BANK_SLIP]: 'Boleto',
  [PaymentMethodsList.PIX]: 'Pix',
  [PaymentMethodsList.MULTICARD]: '2 Cartões de crédito',
});

export const availablePaymentMethodsForOffer = offer => offer.payment_method || [];
