<template>
  <div>
    <div class="position-relative tlw-bg-white tlw-px-4 md:tlw-px-8 tlw-py-6">
      <div v-if="typeOffer == 'list'">
        <h4 class="font-weight-bold tlw-text-xl mb-2 product-setup-offers__title">
          {{ $t('sparkmembers.offers.v2.title') }}
        </h4>
        <h3 class="tlw-text-sm mb-4 product-setup-offers__subtitle" v-html="$t('sparkmembers.offers.v2.subtitle')"></h3>
        <h3 class="font-weight-bold tlw-text-base mb-3">{{ $t('sparkmembers.offers.v2.title-type') }}</h3>
        <div class="product-setup-offers__offer-type-container">
          <CardOfferType
            v-for="(type, index) in types"
            :key="index"
            :type="type"
            @selected="changeOfferType('create', type)"
          />
        </div>
        <div class="row">
          <hs-loading v-if="isLoading || isDeleting" />
          <template v-else>
            <template v-if="offerList.length > 0">
              <div class="col-12">
                <h3 class="font-weight-bold tlw-text-base mb-3">{{ $t('sparkmembers.offers.v2.title-list') }}</h3>
              </div>
              <div class="col">
                <div class="tlw-flex flex-wrap">
                  <div
                    class="mb-2 tlw-mr-0 sm:tlw-mr-3 tlw-w-full sm:tlw-w-60"
                    v-for="(offer, index) in offerList"
                    :key="index"
                  >
                    <hs-card
                      :img-src="imageURL(offer.kind)"
                      :badges="generateBadge(offer.default)"
                      :actions="[]"
                      :style="{ '--cardColor': backgroundCard(offer.kind) }"
                      class="h-100 offer-card tlw-w-full sm:tlw-w-60"
                    >
                      <span class="reference-id tlw-mt-3 tlw-text-sm tlw-font-normal">ID {{ offer.reference_id }}</span>
                      <div class="w-100 d-flex align-items-start justify-content-between pt-1">
                        <h4 class="card-title tlw-break-all">
                          {{ offer.title || $t('sparkmembers.offers.offers-list.card.title') }}
                        </h4>
                        <m-button
                          v-if="!offer.default"
                          class="p-0 delete-button tlw-hidden md:tlw-flex"
                          variant="link"
                          @click="
                            () => {
                              offerId = offer.id;
                              $bvModal.show('delete-offer-modal');
                            }
                          "
                        >
                          <hs-icon icon="trash-alt" />
                        </m-button>
                      </div>
                      <div class="tlw-flex tlw-text-xs tlw-text-gray-600" v-if="offer.title">
                        <p class="mr-1 mb-0">
                          {{
                            offer.kind === 'subscription'
                              ? $t(`sparkmembers.offers.v2.${offer.period}`)
                              : $t('sparkmembers.offers.v2.was')
                          }}
                        </p>
                        <p v-if="offer.kind !== 'subscription'" class="base-price mb-0">
                          {{ offer.base_price | currency }}
                        </p>
                      </div>
                      <div class="tlw-flex tlw-text-base tlw-text-gray-600" v-if="offer.title">
                        <p class="mr-1 mb-0" v-if="offer.kind !== 'subscription'">
                          {{ $t('sparkmembers.offers.v2.is') }}
                        </p>
                        <p class="mb-0 tlw-font-bold tlw-text-gray-900">{{ offer.price | currency }}</p>
                      </div>
                      <p class="product-installment">
                        {{
                          $t('sparkmembers.offers.v2.installments', { installments: maxInstallmentsFromOffer(offer) })
                        }}
                      </p>
                      <div class="tlw-flex tlw-text-base tlw-text-gray-600" v-if="!offer.title">
                        <p class="mr-1 mb-0">{{ $t('sparkmembers.offers.v2.value') }}</p>
                        <p class="mb-0 tlw-font-bold tlw-text-gray-900">{{ offer.price | currency }}</p>
                      </div>
                      <p class="font-size-sm text-secondary mb-4">
                        <template v-if="availablePaymentMethodsForOffer(offer).length === 0">
                          <div>Opção de pagamento não selecionado</div>
                        </template>
                        <template v-if="availablePaymentMethodsForOffer(offer).length === 1">
                          {{ $t('sparkmembers.offers.offers-list.card.accept-only') }}
                          <strong>
                            {{
                              $t(
                                `sparkmembers.offers.offers-list.card.payment_options.${
                                  availablePaymentMethodsForOffer(offer)[0]
                                }`
                              )
                            }}
                          </strong>
                        </template>
                        <template v-else>
                          <span v-html="$t('sparkmembers.offers.offers-list.card.accept')" />
                          <strong>{{ formatAcceptedPaymentOptions(availablePaymentMethodsForOffer(offer)) }} </strong>
                        </template>
                      </p>
                      <div class="d-flex tlw-justify-between">
                        <m-button
                          variant="outline-secondary"
                          class="tlw-text-purple-500"
                          @click="copyToClipboard(offer)"
                        >
                          {{ $t('sparkmembers.offers.offers-list.card.copy') }}
                        </m-button>
                        <b-dropdown variant="p-0" class="dropdown-offers desktop-only" offset="0 5" no-caret>
                          <template v-slot:button-content>
                            <MButton icon="ellipsis-v" class="icon-dropdown-offers" />
                          </template>
                          <div class="dropdown-offer-options">
                            <b-dropdown-item class="dropdown-offers-itens" @click="editOffer(offer)">
                              <hs-icon variant="light" icon="pencil" />
                              {{ $t('sparkmembers.offers.offers-list.card.edit') }}
                            </b-dropdown-item>

                            <b-dropdown-item class="dropdown-offers-itens" @click="editUpsell(offer)">
                              <hs-icon variant="light" icon="check-circle" />
                              {{ $t('sparkmembers.offers.offers-list.card.upsell') }}
                            </b-dropdown-item>
                          </div>
                        </b-dropdown>
                        <MButton
                          icon="ellipsis-v"
                          class="icon-dropdown-offers mobile-only"
                          @click="openDropdown = !openDropdown"
                        />
                        <template v-if="openDropdown">
                          <div class="overlay-dropdown-offers" @click="openDropdown = !openDropdown" />
                          <div class="menu-overlay-dropdown-offers">
                            <MButton class="dropdown-offers-itens" @click="editOffer(offer)">
                              <hs-icon variant="light" icon="pencil" class="icon-dropdown-offers-itens" />
                              {{ $t('sparkmembers.offers.offers-list.card.edit') }}
                            </MButton>
                            <MButton class="dropdown-offers-itens" @click="editUpsell(offer)">
                              <hs-icon variant="light" icon="check-circle" class="icon-dropdown-offers-itens" />
                              {{ $t('sparkmembers.offers.offers-list.card.upsell') }}
                            </MButton>
                          </div>
                        </template>
                      </div>
                    </hs-card>
                  </div>
                </div>
              </div>
              <input type="hidden" ref="link-checkout" />
            </template>
          </template>
        </div>
        <hs-pagination
          v-if="totalNumberOfOffers > itemsPerPage"
          v-model="currentPage"
          :go-page-text="$t('sparkpay.sales.pagination.go-to-page')"
          :per-page="itemsPerPage"
          :total-rows="totalNumberOfOffers"
          align="center"
        />
      </div>
      <div v-else-if="['create', 'edit'].includes(typeOffer)">
        <div>
          <h4 class="tlw-text-sm tlw-font-semibold tlw-mb-6 tlw-cursor-pointer" @click="showModal(selectedType)">
            <svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="tlw-inline-block tlw-align-baseline"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.83288 0.384689L9.1868 4.65219C9.84489 5.03157 9.8428 5.96669 9.1868 6.34607L1.83288 10.6115C1.17061 10.997 0.320312 10.5336 0.320312 9.76458V1.23163C0.320312 0.374435 1.23537 0.0381208 1.83288 0.384689ZM1.49375 10.0476L8.84767 5.78214C9.06494 5.65499 9.06494 5.34329 8.84558 5.21615L1.49166 0.948643C1.33079 0.854311 0.988168 0.897375 0.988168 1.23164V9.76459C0.988168 10.023 1.2723 10.1727 1.49375 10.0476Z"
                fill="#7427F1"
              />
            </svg>
            {{ $t('sparkmembers.offers.tabs.back-to-offers') }}
          </h4>
          <h2 class="tlw-text-xl tlw-font-semibold tlw-mb-2">
            {{ $t('sparkmembers.offers.tabs.title') }}
          </h2>
          <p class="tlw-text-sm tlw-font-normal tlw-text-gray-700 tlw-mb-10">
            {{ $t('sparkmembers.offers.tabs.description') }}
          </p>
          <OfferWizard
            :contextType="typeOffer"
            :type="selectedType"
            :schoolId="schoolId"
            @saved="fetchPaymentOptions"
            @changeOfferType="changeOfferType('list', selectedType)"
          />
        </div>
      </div>
      <div v-if="typeOffer === PostPurchasePage.UPSELL">
        <PostPurchaseSelector
          @change-offer-type="changeOfferType('list', selectedType)"
          @selected-upsell-config="setSelectedUpsellConfig"
        />
        <ModalCopyCode />
      </div>

      <hsConfirmModal
        id="delete-offer-modal"
        icon="trash-alt"
        variant="cherry"
        :customTitle="$t('sparkmembers.offers.edit.delete-modal.title')"
        :description="$t('sparkmembers.offers.edit.delete-modal.description')"
        :ok-title="$t('sparkmembers.offers.edit.delete-modal.ok-title')"
        :cancel-title="$t('sparkmembers.offers.edit.delete-modal.cancel-title')"
        @hidden="() => (offerId = null)"
        @ok="removeOffer"
      />
    </div>

    <div v-if="displayUpsellConfigContainer" class="upsell-config-container">
      <ThanksPageConfig v-if="isThanksPageEnabled" @back="updateOfferList()" />
      <OfferUpsellConfig v-if="isUpsellEnabled" />
    </div>

    <div v-if="isUpsellEnabled" class="back-to-offers-button__container">
      <MButton variant="primary-outline" class="back-to-offers-button" @click="updateOfferList()">
        {{ $t('after-purchase.back-to-offers') }}
      </MButton>
    </div>

    <AutoPilotBanner
      v-if="typeOffer !== PostPurchasePage.UPSELL"
      :title="$t('auto-pilot-banner.offers.title')"
      :description="$t('auto-pilot-banner.offers.description')"
      image-name="credit-card-phone.svg"
      class="auto-pilot-banner-offers"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Clipboard from '@/shared/mixins/Clipboard';
import GeneralHelper from '@/shared/helpers/general';
import ToastHelper from '@/shared/helpers/toast';
import productService from '@/sparkmembers/services/product';
import OfferWizard from './components/OfferWizard';
import { hsLoading, hsConfirmModal } from '@/components';
import TrackingHelper from '@/shared/helpers/tracking';
import CardOfferType from './components/Card';
import MButton from '@/shared/components/MButton.vue';
import PostPurchaseSelector from './components/PostPurchasePageConfig/PostPurchaseSelector.vue';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import { formatAcceptedPaymentOptions, availablePaymentMethodsForOffer } from '@/libs/paymentMethods';
import { PostPurchasePage } from '@/libs/upsell';
import ThanksPageConfig from './components/PostPurchasePageConfig/ThanksPageConfig.vue';
import OfferUpsellConfig from './components/PostPurchasePageConfig/OfferUpsellConfig.vue';
import ModalCopyCode from './components/PostPurchasePageConfig/ModalCopyCode.vue';

export default {
  mixins: [Clipboard],
  components: {
    hsLoading,
    CardOfferType,
    hsConfirmModal,
    MButton,
    OfferWizard,
    PostPurchaseSelector,
    AutoPilotBanner: () => import('@/components/AutoPilotBanner.vue'),
    ThanksPageConfig,
    ModalCopyCode,
    OfferUpsellConfig,
  },
  watch: {
    currentPage: {
      handler() {
        this.fetchPaymentOptions();
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      totalNumberOfOffers: 0,
      PostPurchasePage,
      openDropdown: false,
      isLoading: false,
      isDeleting: false,
      offerList: [],
      selectedType: {},
      edit: false,
      upsell: false,
      showPayment: false,
      types: [
        {
          title: 'paid',
          kind: 'common',
          icon: 'money-bill-wave',
          backgroundColor: '#AFFFD0',
          color: '#34B469',
          badges: [],
        },
        {
          title: 'subscription',
          icon: 'file-signature',
          kind: 'subscription',
          backgroundColor: '#BCFBFF',
          disabled: !this.$FCL(['members_subscription_offers']),
          color: '#00B9C5',
          badges: [],
        },
      ],
      imagePaid: require(`@/assets/images/SparkMembers/OffersProduct/default.svg`),
      imageSubscription: require('@/assets/images/SparkMembers/OffersProduct/subscription.svg'),
      offerId: null,
      typeOffer: 'list',
      selectedUpsellConfig: '',
    };
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState('product', {
      product: state => state.selectedProduct,
      offer: state => state.productPaymentOption,
    }),
    ...mapState('upsell', ['isEditingUpsell']),
    ...mapState('offerWizard', ['isEditingOffer']),
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
    isThanksPageEnabled() {
      const isThanksPageConfigSelected = this.selectedUpsellConfig === PostPurchasePage.THANKS_PAGE;
      return isThanksPageConfigSelected && this.hasThankYouUrls;
    },
    isUpsellEnabled() {
      const isUpsellConfigSelected = this.selectedUpsellConfig === PostPurchasePage.UPSELL;
      return isUpsellConfigSelected && this.hasUpsellSteps && !this.isEditingUpsell;
    },
    hasThankYouUrls() {
      return Boolean(this.offer?.thank_you_urls?.length);
    },
    hasUpsellSteps() {
      return Boolean(this.offer.upsell_flow_steps);
    },
    displayUpsellConfigContainer() {
      return this.isThanksPageEnabled || this.isUpsellEnabled;
    },
    itemsPerPage() {
      return 15;
    },
  },
  filters: {
    currency(value) {
      return GeneralHelper.currency(value);
    },
  },
  methods: {
    availablePaymentMethodsForOffer,
    formatAcceptedPaymentOptions,
    ...mapActions('product', [
      'getProductPaymentOptions',
      'getProductPaymentOption',
      'getProductPaymentOptionsWithHeaders',
    ]),
    imageURL(kind) {
      const image = {
        common: this.imagePaid,
        subscription: this.imageSubscription,
      };
      return image[kind];
    },
    backgroundCard(kind) {
      const image = {
        common: '#AFFFD0',
        subscription: '#BCFBFF',
      };
      return image[kind];
    },
    async fetchPaymentOptions() {
      try {
        this.isLoading = true;
        const { paymentOptions, headers } = await this.getProductPaymentOptionsWithHeaders({
          id: this.$route.params.id,
          params: { items: this.itemsPerPage, page: this.currentPage },
        });

        this.totalNumberOfOffers = headers['total-count'];
        this.offerList = paymentOptions.map(payment => ({
          ...payment,
          price: payment.price / 100,
          base_price: payment.base_price / 100,
        }));
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    updateOfferList() {
      this.fetchPaymentOptions();
      this.typeOffer = 'list';
      this.openDropdown = false;
      this.setSelectedUpsellConfig('');
    },
    openCreateModal(type) {
      this.selectedType = type;
      this.$bvModal.show('offer-creator-modal');
    },
    generateBadge(badge) {
      return badge
        ? [
            {
              label: this.$t('sparkmembers.offers.offers-list.card.badge'),
              variant: 'primary',
            },
          ]
        : [];
    },
    copyToClipboard(paymentOption) {
      const ref = 'link-checkout';
      let link = makeCheckoutUrl(this.hasSparkCheckout, paymentOption, this.product, this.getDomain);

      TrackingHelper.trackCheckoutLinkCopied('offers_list');
      try {
        const result = this.clipboardCopy(ref, link);
        if (!result) throw new Error('Exec command failed');
        ToastHelper.successMessage(this.$t('sparkmembers.offers.offers-list.copy-link.success'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.offers-list.copy-link.error'));
      }
    },
    async editOffer(offer) {
      this.edit = true;
      this.typeOffer = 'edit';
      this.selectedType = offer;
      await this.getProductPaymentOption(offer);
      this.$bvModal.show('offer-edit-modal');
    },
    async editUpsell(offer) {
      this.upsell = true;
      this.typeOffer = 'upsell';
      this.selectedType = offer;
      await this.getProductPaymentOption(offer);
    },
    async removeOffer() {
      this.isDeleting = true;

      productService
        .deleteProductPaymentOption(this.product.id, this.offerId)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.offers.edit.toast.success'));
          this.fetchPaymentOptions();
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.toast.error'));
        })
        .finally(() => (this.isDeleting = false));
    },
    changeOfferType(offer, type) {
      this.typeOffer = offer;
      this.selectedType = type;
      this.setSelectedUpsellConfig('');
    },
    showModal(selectedType) {
      if (this.isEditingOffer) {
        this.$bvModal.show('exit-offer-settings-modal');
      } else {
        this.changeOfferType('list', selectedType);
      }
    },
    maxInstallmentsFromOffer(offer) {
      return Math.max(offer.installments_amount_limit, offer.pix_max_installments, offer.bank_slip_max_installments);
    },
    setSelectedUpsellConfig(config) {
      this.selectedUpsellConfig = config;
    },
  },
  mounted() {
    this.fetchPaymentOptions();
  },
};
</script>

<style lang="scss" scoped>
.mobile-only {
  display: none;

  @media (max-width: 640px) {
    display: flex;
    align-items: center;
  }
}
.desktop-only {
  display: flex;

  @media (max-width: 640px) {
    display: none;
  }
}
.overlay-dropdown-offers {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 4;

  @media (min-width: 640px) {
    display: none;
  }
}

.menu-overlay-dropdown-offers {
  height: 160px;
  left: 0;
  width: 100%;
  display: grid;
  padding: 32px 20px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 5;

  @media (min-width: 640px) {
    display: none;
  }
}
/deep/ .dropdown-menu {
  padding: 0;
}
.dropdown-offer-options {
  padding: 10px 12px;
}

.icon-dropdown-offers {
  font-size: 25px;
  border-radius: 4px;
  width: 38px;
  height: 39px;

  &:hover {
    border: 2px solid #7427f1;
  }
}
/deep/ .dropdown-item {
  background-color: transparent;
  color: #171717;
}

/deep/ .dropdown-toggle {
  &.btn {
    padding: 0;
  }

  &.btn:focus {
    box-shadow: none;
    outline: unset;
  }

  &[aria-expanded='true'] {
    .icon-dropdown-offers {
      border: 2px solid #7427f1;
    }
  }
}

.dropdown-offers-itens {
  display: flex;
  column-gap: 4px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid transparent;

  &:hover {
    background: #ead3fe !important;
    border: 1px solid #7427f1;
  }
}
/deep/ .offer-card {
  border: 2px solid #d3d3d3;
  .reference-id {
    color: #525252;
  }
  .card-badges {
    font-size: 12px;
    left: 1rem;
  }
  .badge {
    background-color: #5e0ee0;
    padding: 6px;
  }
  .delete-button {
    color: var(--color-red-500) !important;
  }
  .product-installment {
    color: #898989;
    font-size: 12px;
    margin-bottom: 16px;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }
  .base-price {
    text-decoration: line-through;
  }
  @media (max-width: $screen-sm) {
    .card-img {
      display: none;
    }
    .card-badges {
      top: 1px;
    }
    .card-body {
      padding: 1rem;
      position: relative;

      &::before {
        content: ' ';
        height: 10px;
        background-color: var(--cardColor);
        width: 100%;
        position: absolute;
        left: 0;
        top: 0px;
      }
    }
  }
}

.product-setup-offers__offer-type-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  margin-bottom: 36px;

  @media (min-width: $screen-md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.product-setup-offers__subtitle {
  color: #6f6f6f;
}

.product-setup-offers__title {
  color: $grey;
}

.auto-pilot-banner-offers {
  margin-left: 16px;
  margin-right: 16px;

  @media (min-width: $screen-md) {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
}

.upsell-config-container {
  background-color: $white;
  border-radius: 4px;
  padding: 20px;
  margin-top: 24px;
  margin-left: 16px;
  margin-right: 16px;

  @media (min-width: $screen-md) {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
}

.back-to-offers-button__container {
  display: flex;
  margin: 24px 0 35px;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: $screen-md) {
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 0;
  }
}
.back-to-offers-button {
  width: 100%;

  @media (min-width: $screen-md) {
    width: auto;
  }
}
</style>
