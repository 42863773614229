<template>
  <div>
    <hs-loading v-if="isLoading" />
    <div v-else>
      <div class="pb-3 d-flex flex-column position-relative pt-5 pt-md-3">
        <hs-button variant="link" class="close-icon" @click="$emit('close')">
          <hs-icon icon="times" />
        </hs-button>
        <div class="hs-popover__content">
          <h4 class="font-weight-bold mb-1">{{ $t('sparkmembers.v2-product-list.popover.title') }}</h4>
          <p class="mb-4">{{ $t('sparkmembers.v2-product-list.popover.sub-title') }}</p>
          <div class="d-flex links mb-2 links-offer">
            <a class="product-offer-page" target="_blank" :href="offerPage">{{ offerPage }}</a>
            <hs-button variant="icon" @click="copyToClipboard(null, offerPage)">
              <hs-icon
                :class="[
                  'hs-popover__link-offer mr-1 pointer primary',
                  selectedPayment === offerPage && 'hs-popover__link-offer--active',
                ]"
                :icon="selectedPayment === offerPage ? 'check' : 'paste'"
                size="35px"
              />
            </hs-button>
          </div>
        </div>
        <hs-icon class="hs-popover__title-link-offer mr-1" icon="file-invoice-dollar" size="35px" />
      </div>
      <div class="py-3 d-flex border-top flex-column position-relative" v-if="payment_options.length > 0">
        <div class="hs-popover__content">
          <h4 class="font-weight-bold mb-1">{{ $t('sparkmembers.v2-product-list.popover.offer-title') }}</h4>
          <p class="mb-4">{{ $t('sparkmembers.v2-product-list.popover.offer-sub-title') }}</p>
          <div v-for="(payment_option, index) in offers" class="mb-3" :key="index">
            <p class="title font-size-md font-weight-bold mb-1">{{ payment_option.title || 'Preço padrão' }}</p>
            <div class="d-flex links mb-2">
              <a target="_blank" :href="urlLink(product, payment_option)">{{ urlLink(product, payment_option) }}</a>
              <hs-button variant="icon" @click="copyToClipboard(payment_option)">
                <hs-icon
                  :class="[
                    'hs-popover__link-offer mr-1 pointer primary',
                    selectedPayment === payment_option && 'hs-popover__link-offer--active',
                  ]"
                  :icon="selectedPayment === payment_option ? 'check' : 'paste'"
                  size="35px"
                />
              </hs-button>
            </div>
          </div>
          <a target="_blank" class="sales-link" v-if="payment_options.length > 3" @click="goToSalesPage">
            Ver todas as promoções</a
          >
        </div>
        <hs-icon class="hs-popover__title-link mr-1" icon="credit-card-front" size="35px" />
      </div>
    </div>
    <input type="hidden" ref="link-checkout" />
  </div>
</template>

<script>
import ToastHelper from '@/shared/helpers/toast';
import { hsLoading } from '@/components';
import Clipboard from '@/shared/mixins/Clipboard';
import TrackingHelper from '@/shared/helpers/tracking';

export default {
  name: 'ProductLinks',
  components: { hsLoading },
  mixins: [Clipboard],
  props: {
    tracking: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: () => {},
    },
    getProductOffers: {
      type: Function,
      default: () => {},
    },
    urlLink: {
      type: Function,
      default: () => {},
    },
    offerPageLink: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    payment_options: [],
    isLoading: true,
    selectedPayment: null,
    offerPage: null,
  }),
  mounted() {
    this.fetchData();
  },
  computed: {
    offers() {
      return this.payment_options.slice(0, 3);
    },
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        this.payment_options = await this.getProductOffers(this.product);
        this.offerPage = this.offerPageLink(this.product);
      } catch (error) {
        logging("failed to load product's payment options", error);
        ToastHelper.dangerMessage('Falha ao carregar as ofertas do produto.');
      } finally {
        this.isLoading = false;
      }
    },
    goToSalesPage() {
      this.$router.push({ name: 'ProductSetup', params: { id: this.product.id } });
    },
    copyToClipboard(paymentOption, offerURL) {
      const ref = 'link-checkout';
      const link = paymentOption
        ? (TrackingHelper.trackCheckoutLinkCopied(this.tracking), this.urlLink(this.product, paymentOption))
        : (TrackingHelper.trackSalesLinkCopied(this.tracking), offerURL);

      try {
        const result = this.clipboardCopy(ref, link);
        if (!result) throw new Error('Exec command failed');
        ToastHelper.successMessage(this.$t('sparkmembers.offers.offers-list.copy-link.success'));
        this.selectedPayment = offerURL || paymentOption;
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.offers-list.copy-link.error'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hs-popover {
  border: 0;
  background-color: white;
  min-width: 400px;
  a {
    color: #0fa0a9;
  }
  /deep/ .popover-body {
    border: 1px solid #d3d3d3;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &__title-link-offer {
    position: absolute;
    top: 18px;
    left: 10px;
  }
  &__title-link {
    position: absolute;
    top: 18px;
    left: 10px;
  }
  &__link {
    position: absolute;
    bottom: 21px;
    color: #0fa0a9;
    right: 10px;
  }
  &__link-offer {
    color: #0fa0a9;
    &--active {
      color: $green-dark;
    }
  }
  &__content {
    padding-left: 35px;
    h4 {
      font-size: 16px;
    }
  }
  .close-icon {
    display: none;
  }
  .links {
    justify-content: space-between;
    align-items: center;
    a {
      width: 80%;
    }
  }
}
.sales-link {
  cursor: pointer;
  text-decoration: underline !important;
}
@media screen and (max-width: 576px) {
  .hs-popover {
    min-width: 100vw;
    height: 100vh;
    position: fixed !important;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.5);
    transform: translateY(0) !important;
    /deep/ .popover-body {
      min-width: 100vw;
      max-width: 100vw;
      position: absolute;
      bottom: 0;
      border-radius: 0;
      background-color: white;
    }
    /deep/ .arrow {
      display: none;
    }
    .close-icon {
      position: absolute;
      display: flex;
      top: 0;
      right: 0;
      color: black;
    }
    &__title-link-offer {
      top: 52px;
    }
  }
}
</style>
