<template>
  <div class="px-3 text-center">
    <p>
      {{ $t('sparkmembers.product-dashboard.components.request-approval.sub-title') }}
    </p>
    <div class="p-3">
      <div class="d-flex mb-2" v-for="n in 2" :key="`item_${n}`">
        <hs-icon
          variant="light"
          icon="check"
          class="p-2 align-self-start rounded-circle bg-info-light text-primary"
          :size="15"
        />
        <p class="text-left font-size-sm ml-1 mb-2">
          {{ $t(`sparkmembers.product-dashboard.components.request-approval.certification-items.item${n}`) }}
        </p>
      </div>
    </div>
    <p v-html="$t('sparkmembers.product-dashboard.components.request-approval.product-approval-text')"></p>
  </div>
</template>
<script>
export default {
  name: 'RequestApproval',
};
</script>
