<template>
  <div class="upsell-buttons-preview">
    <div class="upsell-buttons-preview-text">
      <h5 class="upsell-buttons-preview-title">
        {{ $t('after-purchase.upsell-buttons-preview.card-title') }}
      </h5>
      <div
        v-html="$t('after-purchase.upsell-buttons-preview.card-description')"
        class="upsell-buttons-preview-description"
      />
    </div>
    <div class="upsell-buttons-preview-buttons">
      <div class="upsell-buttons-preview-offer-wrapper">
        <div class="upsell-buttons-preview-product">
          {{ productTitle }}
        </div>

        <div class="upsell-buttons-preview-price">
          R$ <span class="upsell-buttons-preview-price-highlight">{{ upsellPriceText }}</span>
        </div>
      </div>
      <MButton class="upsell-buttons-preview-accept">
        {{ acceptTextFallback }}
      </MButton>
      <MButton class="upsell-buttons-preview-refuse">
        {{ rejectTextFallback }}
      </MButton>
    </div>
  </div>
</template>
<script>
import MButton from '@/shared/components/MButton.vue';
export default {
  name: 'UpsellButtonsPreview',
  components: { MButton },
  props: {
    acceptText: {
      type: String,
      default: '',
    },
    rejectText: {
      type: String,
      default: '',
    },
    upsellOffer: {
      type: Object,
      required: true,
    },
    upsellPriceText: {
      type: String,
      default: '',
    },
    productTitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    acceptTextFallback() {
      if (this.acceptText) return this.acceptText;

      return this.$t('after-purchase.upsell-buttons-preview.button-accept');
    },
    rejectTextFallback() {
      if (this.rejectText) return this.rejectText;

      return this.$t('after-purchase.upsell-buttons-preview.button-refuse');
    },
  },
};
</script>

<style lang="scss">
.upsell-buttons-preview-text {
  padding: 24px 4px 0;
}
.upsell-buttons-preview-title {
  font-weight: 700;
  line-height: 150%;
}
.upsell-buttons-preview-description {
  line-height: 150%;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #3f3f3f;
}
.upsell-buttons-preview {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 337px;
  background: #e3e3e3;
  padding: 20px;
  border-radius: 4px;
}
.upsell-buttons-preview-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin-bottom: 18px;
}
.upsell-buttons-preview-accept {
  padding: 20px;
  background-color: #348535;
  border: 1px solid #348535;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}
.upsell-buttons-preview-refuse {
  padding: 20px;
  color: #262626;
  border: 1px solid #262626;
  background-color: transparent;
  font-size: 14px;
}
.upsell-buttons-preview-offer-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
}
.upsell-buttons-preview-product {
  font-size: 16px;
  font-weight: 700;
  color: #262626;
  text-align: center;
}
.upsell-buttons-preview-price {
  font-size: 12px;
  color: #262626;

  &-highlight {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
