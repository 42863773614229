export default {
  methods: {
    wrapper(contentArray) {
      return this.$createElement(
        'div',
        {
          class: ['d-flex', 'flex-column', 'justify-content-center', 'align-items-center'],
        },
        contentArray
      );
    },
    icon(icon) {
      return this.$createElement('hs-icon', {
        class: ['mb-4', 'mt-3', 'far', `text-${icon ? icon.color : 'cyan'}`],
        style: {
          fontSize: '47px',
          color: '#2cc3cc',
        },
        props: {
          icon: icon ? icon.name || 'bell' : 'bell',
        },
      });
    },
    title(contentTitle) {
      return this.$createElement('h4', {
        class: ['mb-4', 'font-weight-bold', 'text-center'],
        domProps: {
          innerHTML: contentTitle,
        },
      });
    },
    description(contentDescription) {
      return this.$createElement('p', {
        class: ['description', 'mx-4', 'my-0', 'text-center'],
        domProps: {
          innerHTML: contentDescription,
        },
      });
    },
    /**
     * Shows an ok modal with Herospark design style
     * @see https://bootstrap-vue.js.org/docs/components/modal/#message-box-advanced-usage
     */
    showOkMessageBox({ okTitle, contentTitle, contentDescription, icon, hideFooter, size }) {
      const body = this.wrapper([this.icon(icon), this.title(contentTitle), this.description(contentDescription)]);

      const content = this.$createElement('div', {}, [body]);

      return this.$bvModal.msgBoxOk([content], {
        hideHeader: false,
        bodyClass: 'pt-1',
        okTitle,
        centered: true,
        headerClass: 'px-3 py-1 border-bottom-0',
        hideHeaderClose: false,
        footerClass: hideFooter ? 'd-none' : 'ok-footer d-flex justify-content-center border-top-0 pt-0 mb-3',
        size: size ? size : 'sm',
      });
    },
  },
};
