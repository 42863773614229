<template>
  <div class="thanks-page-preview">
    <div class="thanks-page-preview__container">
      <div class="thanks-page-preview__header">
        <span>Pré-visualização da página de obrigado</span>
        <button @click="onClose">
          <hs-icon variant="solid" icon="times" />
        </button>
      </div>
      <div v-if="!!previewUrl" class="thanks-page-preview__content">
        <div class="thanks-page-preview__checkout-info">
          <img :src="require('@/assets/images/SparkMembers/ProductSetup/thanks-page-preview.svg')" />
        </div>
        <iframe :src="previewUrl" allowfullscreen="false" frameborder="0" class="thanks-page-preview__iframe" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThanksPagePreview',
  props: {
    previewUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.thanks-page-preview {
  align-items: center;
  background: rgba(#262626, 0.64);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
}

.thanks-page-preview__container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(63, 63, 63, 0.08);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 625px;
  max-width: 948px;
  width: 100%;
}

.thanks-page-preview__header {
  align-items: center;
  box-shadow: 0px 2.6335px 5.26701px rgba(63, 63, 63, 0.08);
  box-sizing: border-box;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 24px;
}

.thanks-page-preview__content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
}

.thanks-page-preview__checkout-info {
  box-shadow: 0px 2.6335px 5.26701px rgba(63, 63, 63, 0.08);
  height: 60px;
}

.thanks-page-preview__iframe {
  flex-grow: 1;
  margin-top: 8px;
}
</style>
