




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NoProductsWarning extends Vue {
  @Prop({ required: true, default: 'Informe uma mensagem' }) text: string;

  noFilteredDataImg: String = require('@/assets/images/SparkMembers/MembersList/components/NoFilteredMembersWarning/no_filtered_data.svg');
}
