<template>
  <div id="webinar_herospark">
    <div class="webinar-evento">
      <b-form @submit.prevent="">
        <hs-group
          :label="$t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.descricao.label')"
          label-for="webinar-description"
          label-class="font-weight-bold"
        >
          <hs-text-editor
            @onUpdate="event => updateDescriptionInput(event.getHTML())"
            editor="webinar_description"
            v-model="webinar_description"
            :initialContent="webinar_description || ''"
            :placeholder="$t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.descricao.placeholder')"
          />
        </hs-group>
      </b-form>
    </div>
    <div class="link-divider" />
    <!-- Link  -->
    <div class="tlw-pt-8">
      <h4 class="tlw-mb-2 tlw-mt-5 tlw-font-bold">
        {{ $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.title') }}
      </h4>
      <p class="tlw-text-sm tlw-font-normal tlw-text-gray-700">
        {{ $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.description') }}
      </p>
      <div class="webinar-radio-group">
        <hs-radio class="mt-2 purple-radio" v-model="plataform_streaming" name="youtube" value="youtube">
          {{ $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.radio-button.youtube') }}
        </hs-radio>
        <hs-radio class="mt-2 purple-radio" v-model="plataform_streaming" name="vimeo" value="vimeo">
          {{ $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.radio-button.vimeo') }}
        </hs-radio>
        <hs-radio class="mt-2 purple-radio" v-model="plataform_streaming" name="outro" value="outro">
          {{ $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.radio-button.outro') }}
        </hs-radio>
      </div>
      <hs-group class="tlw-border-solid tlw-mt-4">
        <!-- youtube -->
        <div v-if="plataform_streaming === 'youtube'" class="tlw-max-w-md">
          <label class="tlw-text-sm">
            {{ $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.title') }}
          </label>
          <hs-input
            @change="handleUrlInput"
            checked
            :value="origin_url"
            :text="$t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.input_text.default')"
            :placeholder="
              $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.placeholders.youtube')
            "
          />
        </div>
        <!-- Vimeo -->
        <div v-if="plataform_streaming === 'vimeo'" class="tlw-max-w-md">
          <div>
            <label class="tlw-text-sm">
              {{ $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.title') }}
            </label>
            <hs-input
              @change="handleUrlInput"
              :value="origin_url"
              @blur="$v.vimeo_embed.$touch()"
              :text="$t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.input_text.default')"
              :placeholder="
                $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.placeholders.vimeo_embed')
              "
            />
          </div>
        </div>
        <!-- Outro -->
        <div v-if="plataform_streaming === 'outro'" class="tlw-grid tlw-grid-cols-2 tlw-gap-5 tlw-max-w-4xl tlw-pb-16">
          <div>
            <label class="tlw-text-sm">
              {{ $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.title') }}
            </label>
            <hs-input
              @change="handleUrlInput"
              :value="origin_url"
              @blur="$v.outro_link.$touch()"
              :placeholder="
                $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.placeholders.outro_embed')
              "
            />
          </div>
          <div>
            <label class="tlw-text-sm">
              {{ $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.title_chat') }}
            </label>
            <hs-input
              @change="handleUrlChatInput"
              :value="chat_url"
              @blur="$v.outro_link.$touch()"
              :placeholder="
                $t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.placeholders.outro_chat')
              "
            />
          </div>
        </div>
      </hs-group>
      <div class="tlw-pb-2 tlw-flex tlw-flex-col" v-if="plataform_streaming !== 'outro'">
        <div class="tlw-bg-gray-100 tlw-rounded tlw-max-w-3xl tlw-p-3 tlw-mt-5">
          <p class="tlw-text-sm tlw-text-gray-700 tlw-mb-0">
            <hs-icon
              variant="solid"
              icon="info"
              class="tlw-p-1.5 tlw-text-gray-700 tlw-rounded-xl tlw-border-gray-700 tlw-border tlw-text-xxs tlw-w-6 tlw-h-6 tlw-text-center tlw-mr-1.5"
            />
            <span v-html="$t('sparkmembers.product-creator.setup.type-webinar.sobre-o-evento.link.help')"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidatorsHelper from '@/shared/helpers/validators';
export default {
  name: 'WebinarHerospark',
  props: {
    webinar_description: String,
    chat_url: String,
    origin_url: String,
  },
  data() {
    return {
      url: '',
      url_other: '',
      plataform_streaming: 'youtube',
    };
  },

  validations: {
    name: {
      noQuotes: ValidatorsHelper.noQuotes(),
    },
  },
  methods: {
    updateDescriptionInput(input) {
      this.description_webinar = input;
      if (this.description_webinar === '<p></p>') this.description_webinar = null;
      this.$emit('createDefinitionParams', this.description_webinar);
    },
    handleUrlInput(input) {
      this.url = !_.isEmpty(input) && input;
      this.$emit('updatedUrl', this.url);
    },
    handleUrlChatInput(input) {
      this.url_other = !_.isEmpty(input) && input;
      this.$emit('updatedUrlChat', this.url_other);
    },
  },
};
</script>

<style lang="scss" scoped>
#webinar_herospark {
  .custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
  .custom-control-input:hover ~ .custom-control-label:before {
    border-color: $purple-dark !important;
  }
  .custom-control-input:not(:focus) ~ .custom-control-label:before {
    border: 1px solid #adb5bd;
  }
  .custom-control-input:active ~ .custom-control-label:before {
    background-color: transparent;
  }
  .link-divider {
    background: #e3e3e3;
    width: 100%;
    position: absolute;
    height: 21px;
    left: 0;
    border: none;
  }
  .webinar-radio-group {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
}
</style>
