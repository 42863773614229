<template>
  <b-form @submit.prevent="onSubmit">
    <h4 class="font-weight-bold tlw-text-xl mb-3">{{ $t('sparkmembers.access-rules.form.indeterminate.title') }}</h4>
    <h3 class="font-size-sm mb-5 tlw-leading-5">
      {{ $t('sparkmembers.access-rules.form.indeterminate.subtitle') }}
    </h3>
    <div class="p-0 px-sm-5">
      <div class="mb-4">
        <hs-radio v-model="form.available_time_type" name="availability" value="indeterminate">
          {{ $t('sparkmembers.access-rules.form.indeterminate.label') }}
        </hs-radio>
        <p
          class="font-size-sm pl-4 mt-2 mb-0"
          v-html="$t('sparkmembers.access-rules.form.indeterminate.description')"
        />
      </div>
      <div class="mb-4">
        <hs-radio v-model="form.available_time_type" name="availability" value="time">
          {{ $t('sparkmembers.access-rules.form.time.label') }}
        </hs-radio>
        <p class="font-size-sm pl-4 mt-2 mb-0" v-html="$t('sparkmembers.access-rules.form.time.description')" />
        <hs-group class="form-inputs pl-4 mt-2">
          <hs-input
            min="0"
            type="number"
            :placeholder="$t('sparkmembers.access-rules.form.time.placeholder')"
            v-model="form.available_time_length"
            :disabled="form.available_time_type !== 'time'"
          />
        </hs-group>
      </div>
      <div>
        <hs-radio v-model="form.available_time_type" name="availability" value="date">
          {{ $t('sparkmembers.access-rules.form.date.label') }}
        </hs-radio>
        <p class="font-size-sm pl-4 mt-2 mb-0" v-html="$t('sparkmembers.access-rules.form.date.description')" />
        <hs-group class="form-inputs mt-2 pl-4">
          <hs-date
            id="date"
            v-model="expireDate"
            :label-no-date-selected="$t('date.format')"
            :disabled="form.available_time_type !== 'date'"
            :min="new Date()"
            value-as-date
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </hs-group>
      </div>
    </div>
    <div class="d-flex w-100 justify-content-center mt-5">
      <m-button class="tlw-w-full md:tlw-w-auto" type="submit" variant="primary" :disabled="isSaving">
        <b-spinner small type="grow" v-if="isSaving" />
        {{ $t(`sparkmembers.access-rules.form.${isSaving ? 'saving' : 'save'}`) }}
      </m-button>
    </div>
  </b-form>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';
import { analyticsService, productService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import dayjs from 'dayjs';

export default {
  components: { MButton },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isSaving = true;

      productService
        .update({
          ...this.form,
          ...this.normalizeFields(),
          available_time_unit: 'day',
        })
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.access-rules.toast.success'));
          analyticsService.track({
            event: 'Access rule updated',
            props: {
              product_id: this.form.id,
              type: this.checkType(this.form.available_time_type),
            },
          });
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkmembers.access-rules.toast.error')))
        .finally(() => (this.isSaving = false));
    },
    normalizeFields() {
      const { available_time_type, available_time_length, expire_date } = this.form;
      const normalize = {
        available_time_length: available_time_type !== 'time' ? null : available_time_length,
        expire_date: available_time_type !== 'date' ? null : expire_date,
      };
      this.form.available_time_length = normalize.available_time_length;
      this.form.expire_date = normalize.expire_date;
      return normalize;
    },
    checkType(type) {
      const types = ['indeterminate', 'time', 'date'];
      const description = ['unlimited', 'relative_date', 'fixed_date'];
      return description[types.indexOf(type)];
    },
  },
  computed: {
    expireDate: {
      get() {
        return this.form.expire_date ? dayjs(this.form.expire_date).toDate() : null;
      },
      set(newDate) {
        this.form.expire_date = newDate.toISOString();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-inputs {
  width: 100%;
}
@media screen and (min-width: $screen-md) {
  .form-inputs {
    width: 30%;
  }
}
</style>
