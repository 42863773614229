import debug from 'debug';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { ProductId, BaseProduct } from '@/types/products';
import { CoproductionProgram, Invite, Coproducer, ParticipationInvite } from '@/types/coproduction';
import { CoproductionProgramError, decodeError } from '@/libs/coproduction';
import { Offer } from '@/types/offers';
import httpClient from '@/sparkaffiliates/services/http_client';
import productService from '@/sparkmembers/services/product';
import GeneralHelper from '@/shared/helpers/general';

type Product = BaseProduct;

type OfferLinkFunction = (string, Product) => string;

const logging = debug('hs:coproduction');

export default {
  getProductProgram(productId: ProductId): Promise<AxiosResponse<CoproductionProgram>> {
    return httpClient.get(`/coproducer_api/v1/coproduction/by-product/${productId}`).catch(err => {
      return decodeError(err);
    });
  },
  acceptInvite(invite_code, platform_name, email, name) {
    const data = {
      invite: { invite_code, email, name },
      platform_name,
    };
    return httpClient.post('/invite_api/v1/invite/accept', data);
  },

  async createProgram(
    productId: ProductId,
    makeOfferLink: OfferLinkFunction
  ): Promise<AxiosResponse<CoproductionProgram>> {
    try {
      const {
        data: product,
        data: { title },
      } = await productService.get(productId);

      const offersP = productService.getProductPaymentOptions(productId) as Promise<
        AxiosResponse<{ payment_options: Offer[] }>
      >;

      const {
        data: { payment_options: offers },
      } = await offersP;

      const productOffers = offers.map(offer => ({
        sparkecommerce_offer_id: offer.id,
        sparkecommerce_offer_slug: offer.slug,
        offer_url: makeOfferLink(offer, product),
      }));

      const program = {
        name: title,
        description: title,
        enabled: true,
        commission_type: 'last_click',
        program_products: [
          {
            sparkecommerce_product_id: productId,
            product_offers: productOffers,
          },
        ],
      };

      return await httpClient.post('/coproducer_api/v1/coproduction', program);
    } catch (err) {
      return decodeError(err);
    }
  },
  invite(invite: Invite): Promise<AxiosResponse<ParticipationInvite>> {
    return httpClient.post('/coproducer_api/v1/coproduction/invite', { invite });
  },
  delete(coproducer_id) {
    return httpClient.delete(`/coproducer_api/v1/coproductions/${coproducer_id}`);
  },
  all(params) {
    const queryParams = GeneralHelper.parseToQueryString(params);
    return httpClient.get(`/coproducer_api/v1/coproductions?${queryParams}`);
  },
  getProducts(product_ids: number[]) {
    const params = [{ key: 'ids', value: product_ids }];
    const queryParams = GeneralHelper.parseToQueryString(params);
    return httpClient.get(`/coproducer_api/v1/coproduction/products?${queryParams}`);
  },
  programProductOffers(id) {
    return httpClient.get(`/coproducer_api/v1/coproduction/${id}/offers`);
  },
};
