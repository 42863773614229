<template>
  <div>
    <transition-group name="slide-fade" mode="out-in">
      <Sidebar
        :key="sidebar && selectedProduct ? 'sidebar' : 'sidebar-closed'"
        v-if="sidebar && selectedProduct"
        :product="selectedProduct"
        :loading="isLoading"
        @close="
          sidebar = false;
          selectedProduct = null;
        "
        @deleted="onDeleteProduct"
        @isLoading="isLoading = $event"
        @openImageSidebar="handleOpenImageSidebar"
      />

      <ImageSidebar
        :key="showImageSidebar && selectedProduct ? 'image-sidebar' : 'image-sidebar-closed'"
        v-if="showImageSidebar && selectedProduct"
        :product="selectedProduct"
        :initial-horizontal-image="selectedProduct.logo"
        :initial-vertical-image="selectedProduct.logo_vertical"
        @closeImageSidebar="
          showImageSidebar = false;
          sidebar = true;
        "
        @close="
          sidebar = false;
          selectedProduct = null;
          showImageSidebar = false;
        "
        @save="handleSaveImages"
      />
    </transition-group>

    <page-tab-layout tab-class="affiliations-list__tab" :current-tab="currentTab" :tabs="tabs" @change-tab="changeTab">
      <template v-slot:header>
        <PageHeader
          :class="sidebar ? 'sidebar--open ' : 'sidebar--closed'"
          :title="$t('sparkmembers.v2-product-list.title')"
          :sub-title="$t('sparkmembers.v2-product-list.sub-title')"
        >
          <template v-if="canCreateProduct" #actions>
            <CreateProductButton />
          </template>
        </PageHeader>
      </template>

      <div class="md:tlw-py-8 md:tlw-px-10 tlw-py-4 tlw-px-6">
        <component
          ref="tab"
          :is="currentTab"
          :sidebar="sidebar || showImageSidebar"
          :selected-product="selectedProduct"
          @on-selected-product="onSelectedProduct"
          @on-change-page="sidebar = false"
          @openImageSidebar="onOpenImageSidebar"
        />
      </div>
    </page-tab-layout>
  </div>
</template>

<script>
import debug from 'debug';
import { mapActions, mapMutations } from 'vuex';
import PageTabLayout from '@/layout/PageTabLayout.vue';
import PageHeader from '@/components/_structures/PageHeader.vue';
import Products from './components/Products';
import Coproductions from './components/Coproductions.vue';
import Sidebar from './components/Sidebar';
import TrackingHelper from '@/shared/helpers/tracking';
import CreateProductButton from '@/components/CreateProductButton.vue';
import ImageSidebar from './components/ImageSidebar.vue';

const logging = debug('hs:product-list');

export default {
  name: 'ProductList',
  components: {
    PageTabLayout,
    PageHeader,
    Products,
    Coproductions,
    Sidebar,
    CreateProductButton,
    ImageSidebar,
  },
  data() {
    const tabs = [
      {
        id: 'Products',
        label: 'Produtos',
        permission: true,
        component: Products,
      },
    ];

    this.hasCoproductorFlag() &&
      tabs.push({
        id: 'Coproductions',
        label: 'Coprodução',
        permission: true,
        component: Coproductions,
      });

    return {
      sidebar: false,
      currentTab: 'Products',
      tabs,
      selectedProduct: null,
      isLoading: false,
      showImageSidebar: false,
    };
  },
  created() {
    if (this.$route.params?.goToCoprodutions) {
      this.changeTab('Coproductions');
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    canCreateProduct() {
      return this.$ACL.canCreateProduct();
    },
  },
  methods: {
    ...mapActions('course', ['getCourse', 'getPath']),
    ...mapMutations('course', ['setSelectedCourse', 'setSelectedPath']),

    hasCoproductorFlag() {
      return this.$FCL.isCoproductor();
    },

    routeToProductCreator() {
      TrackingHelper.trackProductCreationInitiated('product_catalog');
      this.$router.push({ name: 'ProductCreator', query: { location: 'product_catalog' } });
    },

    handleSaveImages() {
      this.showImageSidebar = false;
    },

    changeTab(tab) {
      logging('changing tab', tab);
      this.currentTab = tab;
    },

    onDeleteProduct() {
      this.sidebar = false;
      this.$refs.tab.reloadFirstPage();
    },

    handleOpenImageSidebar() {
      this.sidebar = false;
      setTimeout(() => {
        this.showImageSidebar = true;
      }, 100);
    },

    onSelectedProduct(product) {
      if (this.selectedProduct === product) {
        this.sidebar = false;
        this.selectedProduct = null;
        this.showImageSidebar = false;
        return;
      }

      this.selectedProduct = product;
      this.sidebar = true;
    },

    onOpenImageSidebar(product) {
      this.selectedProduct = product;
      this.showImageSidebar = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#product-list {
  position: relative;
  transition: all 200ms linear;
  @media screen and (max-width: 576px) {
    position: initial;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active em versões anteriores a 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
