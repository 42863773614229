<template>
  <div class="container-fluid">
    <div class="row px-4 pt-5 pb-4 bg-white">
      <div class="col-12 col-md-4">
        <router-link :to="{ name: 'ProductsList' }" class="text-dark font-size-sm text-decoration-none">
          <hs-icon icon="long-arrow-left" /> {{ $t('sparkmembers.v2-product-list.title') }}
        </router-link>
        <h3 class="font-weight-bold mt-2">
          <div v-if="isLoading">
            <hs-placeholder animated width="100px" height="14px" />
          </div>
          <div v-else>
            {{ product.title }}
            <hs-button
              variant="link"
              icon="pencil-alt"
              v-b-tooltip.hover.bottom="$t('actions.edit')"
              v-b-modal="'edit-product-title-modal'"
            />
          </div>
        </h3>
        <EditProductTitleModal />
      </div>
      <div class="col-12 col-md-6 offset-md-2 col-xl-3 mt-4 mt-lg-0">
        <div v-if="isLoading">
          <hs-placeholder animated width="200px" height="14px" />
        </div>
        <div v-else>
          <MSwitch
            v-model="product.published"
            :disabled="productPublished.disabled || canEditProduct || !this.selectedSchool.validated_info.valid"
            :labelTitle="productPublished.title"
            :labelDescription="productPublished.subtitle"
            v-b-tooltip.left="setPublishDisabledTooltip"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-3 mt-4 mt-lg-0">
        <div class="d-flex">
          <hs-icon variant="light" :icon="productReady.icon" :class="`text-${productReady.color}`" :size="21" />
          <div class="d-flex flex-column mb-2">
            <h5 class="m-0 font-weight-bold ml-2">
              {{ productReady.title }}
            </h5>
            <p class="font-size-sm m-0 ml-2 mt-1">
              {{ productReady.subtitle }}
            </p>
            <hs-button
              v-if="productReady.requestApproval"
              v-b-modal="modalId.requestModal"
              class="text-primary text-underline text-left px-2 pt-1"
              variant="link"
              ><u>{{ productReady.requestApproval }}</u></hs-button
            >
          </div>
        </div>
      </div>
    </div>
    <section class="row mx-1 mb-4">
      <div class="col-md-6 col-lg-4 col-xl-4 mt-4" v-for="(item, index) in productMenuItems" :key="index">
        <CardMenu :item="item" :loading="isLoading" @click="!isLoading && item.onClick()" />
      </div>
    </section>

    <hsPremiumModal :id="modalId.premium" @click="onPremiumBeforeEveryone" />

    <hsConfirmModal
      :id="modalId.requestModal"
      icon="comment-alt-check"
      hide-header
      :customTitle="$t('sparkmembers.product-dashboard.components.request-approval.title')"
      :ok-title="$t('sparkmembers.product-dashboard.components.request-approval.ok-title')"
      :cancel-title="$t('actions.cancel')"
      @ok="onRequestApproval"
    >
      <RequestApproval />
    </hsConfirmModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { productService } from '@/services';
import { hsConfirmModal, hsPremiumModal } from '@/components';
import CardMenu from './components/CardMenu';
import ToastHelper from '@/shared/helpers/toast';
import EditProductTitleModal from './components/EditProductTitleModal.vue';
import RequestApproval from './components/RequestApproval.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import OkMessage from '@/shared/mixins/OkMessage';
import { analyticsService, courseService } from '@/services';

export default {
  name: 'ProductDashboard',
  components: {
    hsConfirmModal,
    hsPremiumModal,
    CardMenu,
    EditProductTitleModal,
    RequestApproval,
    MSwitch,
  },
  mixins: [OkMessage],
  data() {
    return {
      coursesInReview: JSON.parse(localStorage.getItem('courses_in_review')) || [],
      selectedMenuItem: '',
      modalId: {
        premium: 'premium-modal',
        requestModal: 'request-modal',
      },
      isLoading: true,
      productMenuItems: [
        {
          title: this.$t('sparkmembers.product-dashboard.index.cards.content.title'),
          subtitle: this.$t('sparkmembers.product-dashboard.index.cards.content.subtitle'),
          icon: 'comment-alt-edit',
          onClick: this.contentItemClicked,
          id: 'card-content',
        },
        {
          title: this.$t('sparkmembers.product-dashboard.index.cards.certification.title'),
          subtitle: this.$t('sparkmembers.product-dashboard.index.cards.certification.subtitle'),
          icon: 'user-graduate',
          onClick: () => this.$router.push({ name: 'Certificates' }),
          id: 'card-certification',
        },
        {
          title: this.$t('sparkmembers.product-dashboard.index.cards.comments.title'),
          subtitle: this.$t('sparkmembers.product-dashboard.index.cards.comments.subtitle'),
          icon: 'comments',
          premium: false,
          onClick: () => this.$router.push({ name: 'Comments' }),
          id: 'card-comments',
        },
      ],
      lessonId: null,
    };
  },
  computed: {
    ...mapState({
      course: state => state.course.selectedCourse,
      path: state => state.course.selectedPath,
      product: state => state.product.selectedProduct,
      loggedUser: state => state.auth.loggedUser,
    }),
    ...mapState('school', ['selectedSchool']),
    ...mapGetters('school', ['isNewSchool']),
    productType() {
      return this.product.library_resource.resource.type;
    },
    courseReady() {
      return this.productType === 'course' ? this.course.ready : true;
    },
    setPublishDisabledTooltip() {
      return this.selectedSchool?.validated_info?.valid
        ? this.productPublished.disabled
          ? this.$t('sparkmembers.product-dashboard.index.publication-toggle.disabled-tooltip-message')
          : ''
        : this.isNewSchool
        ? this.$t('sparkmembers.v2-product-list.sidebar.publish-product-tooltip.new-account')
        : this.$t('sparkmembers.v2-product-list.sidebar.publish-product-tooltip.old-account');
    },
    productPublished() {
      if (this.product && this.product.published) {
        return {
          title: this.$t('sparkmembers.product-dashboard.index.publication-toggle.published.title'),
          subtitle: this.$t('sparkmembers.product-dashboard.index.publication-toggle.published.subtitle'),
          disabled: !this.courseReady,
        };
      }

      return {
        title: this.$t('sparkmembers.product-dashboard.index.publication-toggle.unpublished.title'),
        subtitle: this.$t(
          `sparkmembers.product-dashboard.index.publication-toggle.unpublished.subtitle${
            this.course.ready ? '-approved' : ''
          }`
        ),
        disabled: !this.courseReady,
      };
    },
    productReady() {
      if (this.courseReady) {
        return {
          title: this.$t('sparkmembers.product-dashboard.index.ready.title'),
          subtitle: this.$t('sparkmembers.product-dashboard.index.ready.subtitle'),
          color: 'success-dark',
          icon: 'check-circle',
        };
      }

      const course = this.coursesInReview.find(item => item.courseId === this.course.id && item.inReview === true);

      if (course) {
        return {
          title: this.$t('sparkmembers.product-dashboard.index.in-review.title'),
          subtitle: this.$t('sparkmembers.product-dashboard.index.in-review.subtitle'),
          color: 'warning',
          icon: 'clock',
        };
      }

      return {
        title: this.$t('sparkmembers.product-dashboard.index.unready.title'),
        subtitle: this.$t('sparkmembers.product-dashboard.index.unready.subtitle'),
        requestApproval: this.$t('sparkmembers.product-dashboard.index.unready.request-approval'),
        color: 'primary',
        icon: 'pause-circle',
      };
    },
    canEditProduct() {
      return !this.$ACL.canProductConfig();
    },
    reportsMenuData() {
      return {
        title: this.$t('sparkmembers.product-dashboard.index.cards.reports.title'),
        subtitle: this.$t('sparkmembers.product-dashboard.index.cards.reports.subtitle'),
        icon: 'table',
        premium: true,
        onClick: () => this.onReportsMenuClick(),
        id: 'card-reports',
      };
    },
    ebooksConfigMenuData() {
      {
        return {
          title: this.$t('sparkmembers.product-dashboard.index.cards.ebook.title'),
          subtitle: this.$t('sparkmembers.product-dashboard.index.cards.ebook.subtitle'),
          icon: 'file-pdf',
          premium: false,
          onClick: () => this.onEbooksConfigClick(),
          id: 'card-ebooks',
        };
      }
    },
  },
  watch: {
    'product.published': {
      handler(newValue, oldValue) {
        if (oldValue !== undefined && oldValue !== newValue) {
          let product = { ...this.product, published: newValue };
          productService
            .update(product)
            .then(() => {
              ToastHelper.successMessage(this.$t('sparkmembers.product-dashboard.index.toast.success'));
              if (product.published) {
                analyticsService.track({
                  event: 'Product published',
                  props: {
                    product_id: this.product.id,
                    niche: this.product.niche,
                    school_id: this.selectedSchool.id,
                  },
                });
              }
            })
            .catch(({ response }) => {
              if (response) {
                const errors = response.data.errors;
                const firstError = Object.keys(errors)[0];
                ToastHelper.dangerMessage(errors[firstError][0]);
              }
            });
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.onFetchProduct();
    this.productCardMenu();
  },
  methods: {
    ...mapActions('product', ['getProduct', 'setProduct']),
    ...mapActions('course', ['getCourse', 'getPath']),
    onPremiumBeforeEveryone() {
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide('premium-modal');
    },
    onRequestApproval() {
      analyticsService.track({
        event: 'Product Approval Requested',
        props: {
          product_title: this.product.title,
          product_id: this.product.id,
          product_type: this.course.kind,
          product_niche: this.product.niche,
          resource_id: this.product.library_resource.id,
        },
      });

      this.coursesInReview.push({ courseId: this.course.id, inReview: true });
      localStorage.setItem('courses_in_review', JSON.stringify(this.coursesInReview));

      this.showOkMessageBox({
        icon: { name: 'comment-alt-check' },
        size: 'smd',
        okTitle: this.$t('sparkmembers.product-dashboard.index.modal.request-approval.ok-title'),
        contentTitle: this.$t('sparkmembers.product-dashboard.index.modal.request-approval.content-title'),
        contentDescription: this.$t('sparkmembers.product-dashboard.index.modal.request-approval.content-description'),
      });
    },
    onReportsMenuClick() {
      if (!this.$FCL.canProgressReport()) {
        this.$bvModal.show(this.modalId.premium);
        this.selectedMenuItem = 'Reports';
        analyticsService.track({
          event: 'Paywall displayed',
          props: {
            paywallReason: `product ${this.selectedMenuItem.toLowerCase()}`,
          },
        });
      } else this.$router.push({ name: 'Reports' });
    },
    onEbooksConfigClick() {
      this.$router.push({ name: 'EbooksConfig' });
    },
    async onFetchProduct() {
      try {
        const product = await this.getProduct({ id: this.$route.params.id });
        const id = product.library_resource.resource.id;

        if (product.library_resource.resource.type !== 'path') {
          const course = await this.getCourse(id);
          this.setProduct({ ...product, course: course });

          if (course.kind !== 'online_course') {
            const { data: module } = await courseService.getCourseModules(course.id);
            this.lessonId = module.course_modules[0].course_content_ids[0];
          }
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    contentItemClicked() {
      if (this.product.library_resource.resource.type !== 'path' && this.course.kind === 'online_course')
        this.$router.push({ name: 'ContentsList' });
      else if (this.product.library_resource.resource.type === 'path') this.$router.push({ name: 'ContentPaths' });
      else if (this.course.kind === 'live_meet')
        this.$router.push({
          name: 'ContentLives',
          params: { courseId: this.course.id },
        });
      else
        this.$router.push({
          name: 'ContentEditor',
          params: { contentId: this.lessonId },
          query: { type: this.course.kind.charAt(0).toUpperCase() + this.course.kind.slice(1) },
        });
    },
    productCardMenu() {
      if (this.product.library_resource.resource.type !== 'path') {
        if (this.$FCL.hasDrmOptionsEnabled()) {
          this.productMenuItems.push(this.ebooksConfigMenuData);
        }
        this.productMenuItems.push(this.reportsMenuData);
        if (this.course.kind === 'live_meet' || this.product.course?.kind === 'live_meet') {
          this.productMenuItems = this.productMenuItems.filter(
            item => item.id != 'card-comments' && item.id != 'card-members-analytics'
          );
        }
      }
      if (this.loggedUser.role.name == 'Suporte ao Aluno') {
        this.productMenuItems = this.productMenuItems.filter(
          item => item.id != 'card-members-analytics' && item.id != 'card-reports'
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-published-sub-title {
  line-height: 160%;
}

/deep/ .custom-checkbox {
  input[type='checkbox']:disabled + label {
    cursor: not-allowed !important;
  }
}
</style>
