<template>
  <div class="d-flex flex-column align-items-left p-1 bg-white rounded card-menu" :id="item.id" v-on="$listeners">
    <div class="card-title-container d-flex px-3 mt-3">
      <div class="mb-2" v-if="loading">
        <hs-placeholder animated width="100px" height="14px" />
      </div>
      <template v-else>
        <hs-icon variant="light" :icon="item.icon" class="card-menu-icon bg-info-light rounded-circle" :size="32" />
        <h5 class="info-card-title ml-3">
          {{ item.title }}
        </h5>
        <hs-icon v-if="item.premium" variant="solid" icon="star" class="ml-1 p-1 rounded-circle bg-yellow" :size="9" />
      </template>
    </div>
    <div class="d-flex px-3">
      <template v-if="loading">
        <hs-placeholder animated width="80px" height="12px" />
      </template>
      <template v-else>
        <p class="info-card-subtitle mt-2 font-size-sm">{{ item.subtitle }}</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'CardMenu',
  props: {
    item: {
      type: Object,
      required: true,
    },
    loading: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.card-menu {
  cursor: pointer;
  height: 100%;
  min-height: 109px;
  .bg-yellow {
    color: $yellow-dark;
    background: $yellow-light-02;
  }
}

.card-title-container {
  align-items: center;
}

.info-card-title {
  font-size: 20px;
  font-weight: 600;
}

.info-card-subtitle {
  color: #6f6f6f;
}

.card-menu-icon {
  color: var(--color-purple-500);
}
</style>
