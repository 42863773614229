<template>
  <div class="rounded-lg">
    <Form :form="form" :offerType="offerType" :loading="isSaving" @submit="onFormPaidSubmited" @back="goToBack" />
  </div>
</template>

<script>
import Form from './Form.vue';

export default {
  name: 'FormTabs',
  components: { Form },
  props: {
    form: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
    },
    offerType: {
      type: String,
    },
  },
  methods: {
    onFormPaidSubmited(payload) {
      this.$emit('input', { ...payload });
      this.$emit('confirm');
    },
    goToBack() {
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss">
.hs-feature-flag__new {
  border-radius: 20px;
  right: -35px;
  top: 15px;
  font-size: 0.75rem;
  background: var(--cherry);
}
.hs-offer__tabs {
  border-bottom-width: 3px;
}
.hs-offer__tab--active {
  border-bottom: 3px solid var(--cherry) !important;
  margin-bottom: -2px;
}
@media only screen and (max-width: 576px) {
  .hs-feature-flag__new {
    right: 10px;
  }
}
</style>
