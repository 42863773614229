import { mapActions, mapState } from 'vuex';
import MConfirmModal from '@/shared/components/MConfirmModal.vue';
import MButton from '@/shared/components/MButton.vue';
import ToastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';

export default {
  components: {
    MButton,
    MConfirmModal,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('product', {
      offer: state => state.productPaymentOption,
    }),
    hasThankYouUrls() {
      return Boolean(this.offer.thank_you_urls.length);
    },
    hasUpsellSteps() {
      return Boolean(this.offer.upsell_flow_steps);
    },
  },
  methods: {
    ...mapActions('product', ['getProductPaymentOption']),
    async deleteThanksPage(modalName) {
      try {
        await this.deleteThanksUrls();
        this.$bvModal.hide(modalName);
      } catch (error) {
        throw new Error('Erro ao excluir página de obrigado');
      }
    },
    async deleteUpsell(modalName) {
      try {
        await this.deleteUpsellFlow();
        this.$bvModal.hide(modalName);
      } catch (error) {
        throw new Error('Erro ao excluir página de obrigado com upsell');
      }
    },
    async deleteThanksUrls() {
      try {
        this.isLoading = true;

        const payload = {
          ...this.offer,
          price: GeneralHelper.valueInCents(this.offer.price),
          base_price: GeneralHelper.valueInCents(this.offer.base_price),
          thank_you_urls: [],
        };

        const { data } = await SparkEcommerceApiClient.offers.update(payload);

        const formattedOfferData = SparkEcommerceApiClient.offers.translateOfferToPaymentOption(data);

        this.getProductPaymentOption({
          ...formattedOfferData,
          price: formattedOfferData.price / 100,
          base_price: formattedOfferData.base_price / 100,
        });

        ToastHelper.successMessage('Página de obrigado personalizada excluída com sucesso');
      } catch (e) {
        throw new Error('Erro ao excluir página de obrigado');
      } finally {
        this.isLoading = false;
      }
    },
    async deleteUpsellFlow() {
      try {
        this.isLoading = true;

        await SparkEcommerceApiClient.upsell.delete(this.offer.upsell_flow_steps.id);

        this.getProductPaymentOption({
          ...this.offer,
          upsell_flow_steps: null,
        });

        ToastHelper.successMessage('Página de obrigado com upsell excluída com sucesso');
      } catch {
        throw new Error('Erro ao excluir upsell flow');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
