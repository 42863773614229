<template>
  <section class="offer-upsell-config-card">
    <section class="offer-upsell-config__cards-container">
      <section class="offer-upsell-config__card-1">
        <div class="upsell-offer-config-card-title">
          {{ $t('after-purchase.offer-upsell-config.card-title') }}
        </div>
        <div class="upsell-offer-config-card-subtitles">
          <div class="upsell-offer-config-card-subtitle">
            <hs-icon variant="light" icon="box-open" class="icon-upsell-offer-config" />
            {{ $t('after-purchase.offer-upsell-config.product-offered') }}
            <div data-testid="offer-title" class="offer-upsell-config__card-text">
              {{ offeredOffer.title }}
            </div>
          </div>
          <div class="upsell-offer-config-card-subtitle">
            <hs-icon variant="light" icon="tags" class="icon-upsell-offer-config" />
            {{ $t('after-purchase.offer-upsell-config.offer') }}
            <div data-testid="offer-price" class="offer-upsell-config__card-text">
              {{ offeredPrice }}
            </div>
          </div>
        </div>
      </section>

      <section class="offer-upsell-config__card-2">
        <div class="upsell-offer-config-card-title">
          {{ $t('after-purchase.offer-upsell-config.where-to-sen') }}
        </div>
        <div class="upsell-offer-config-card-2-subtitles">
          <div class="upsell-offer-config-card-subtitle">
            <hs-icon variant="light" icon="check" class="icon-upsell-offer-config" />
            {{ $t('after-purchase.offer-upsell-config.accept') }}
            <div data-testid="accept-url" class="offer-upsell-config__card-text">
              {{ getActionPage(upsellActions.accept.url) }}
            </div>
          </div>
          <div class="upsell-offer-config-card-subtitle">
            <hs-icon variant="light" icon="arrow-down" class="icon-upsell-offer-config" />
            {{ $t('after-purchase.offer-upsell-config.refuse') }}
            <div data-testid="reject-url" class="offer-upsell-config__card-text">
              {{ getActionPage(upsellActions.reject.url) }}
            </div>
          </div>
        </div>
      </section>
    </section>
    <div class="upsell-offer-config-guidance-text">
      {{ $t('after-purchase.offer-upsell-config.copy-the-upsell-code') }}
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'OfferUpsellConfigCard',
  computed: {
    ...mapState('product', {
      offer: state => state.productPaymentOption,
    }),
    upsellFlow() {
      return this.offer.upsell_flow_steps;
    },
    offeredOffer() {
      return this.upsellFlow.steps[0].offer;
    },
    upsellActions() {
      return this.upsellFlow.steps[0].actions.reduce((object, action) => {
        object[action.type] = action;
        return object;
      }, {});
    },
    offeredPrice() {
      return GeneralHelper.currency(this.offeredOffer.price_cents / 100);
    },
  },
  methods: {
    getActionPage(url) {
      const externalPageText = this.$t('after-purchase.offer-upsell-config.custom-external-page');
      const heroSparkPageText = this.$t('after-purchase.offer-upsell-config.thank-you-page');

      return url ? externalPageText : heroSparkPageText;
    },
  },
};
</script>

<style lang="scss">
.upsell-offer-config-guidance-text {
  font-weight: 400;
  font-size: 15px;
  color: #6f6f6f;
  margin-top: 24px;
}
.upsell-offer-config-card-title {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: $grey;
}
.upsell-offer-config-card-subtitle {
  line-height: 1.125rem;
  color: #3f3f3f;
  font-weight: 700;
  font-size: 14px;
  margin-top: 12px;
}
.page__content {
  background: #e3e3e3;
}

.icon-upsell-offer-config {
  color: #262626;
  font-size: 12px;
  margin: 3px;
}

.offer-upsell-config__card-text {
  width: 100%;
  font-size: 0.875rem;
  color: #3f3f3f;
  line-height: 1.125rem;
  margin: 0;
  margin-top: 4px;
  font-weight: 400;
}
.upsell-offer-config-card-subtitles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px;
}
.upsell-offer-config-card-2-subtitles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px;

  @media screen and (max-width: 778px) {
    display: block;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
.offer-upsell-config__cards-container {
  display: grid;
  gap: 24px;
  grid-template-columns: 44% 1fr;
}

.offer-upsell-config__card-1 {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #e3e3e3;
  padding: 16px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .offer-upsell-config__cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
  }
}
.offer-upsell-config__card-2 {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  padding: 12px;
  width: 100%;

  @media (min-width: $screen-md) {
    padding: 16px;
  }
}
.offer-upsell-config-card {
  margin-top: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}
</style>
