<template>
  <div class="md:tlw-bg-white">
    <div class="row">
      <div class="col-12">
        <hs-loading v-if="isSaving" />
        <template v-else>
          <div class="tlw-px-8 tlw-pt-6">
            <p class="tlw-text-base">
              <span class="tlw-mr-1">{{ $t('sparkmembers.product-creator.setup.title') }}</span>
              <span
                class="tlw-border-solid tlw-border tlw-border-purple-400 tlw-text-purple-500 tlw-rounded-3xl tlw-text-xs tlw-py-0.5 tlw-px-1.5"
              >
                {{ customLabel }}
              </span>
            </p>
            <h4 class="tlw-mb-2 tlw-text-xl	tlw-mt-5 tlw-font-bold">
              {{ $t(`sparkmembers.product-creator.pricing.${offerType ? offerType : 'common'}.subtitle`) }}
            </h4>
            <p class="tlw-text-sm tlw-mb-6 tlw-text-gray-700 tlw-w-full">
              {{ $t('sparkmembers.product-creator.pricing.price-input.subtitle') }}
            </p>
          </div>
          <div class="options_offers tlw-justify-center md:tlw-justify-start" v-if="!offerType">
            <card-offer
              v-for="offerType in offerTypes"
              :key="offerType.id"
              :img="offerType.img"
              :offerType="offerType.name"
              @selectOfferType="selectOfferType"
            />
          </div>

          <div v-else>
            <hr class="tlw-bg-gray-100 m-4" />
            <FormTabs
              class="appcues-enablement-welcome-step-4"
              v-model="form"
              :form="form"
              :isSaving="isSaving"
              :offerType="offerType"
              @confirm="save"
              @back="backToSelectOfferType"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormTabs from './components/FormTabs.vue';
import { productService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';
import TrackingHelper from '@/shared/helpers/tracking';
import { hsLoading } from '@/components';
import { PaymentMethodsList } from '@/types/payments';
import CardOffer from './components/CardOffer.vue';
import { OfferType } from '@/types/offers';

export default {
  name: 'Pricing',
  components: { FormTabs, hsLoading, CardOffer },
  props: ['props'],
  data() {
    return {
      form: {
        price: '1.99',
        payment_method: [],
        default: null,
        period: 'unitary',
        kind: 'common',
        installments_amount_limit: null,
        frequency_type: 'unlimited',
        overdue_type: 'none',
        smart_installment: false,
      },
      isSaving: false,
      offerType: null,
    };
  },
  computed: {
    ...mapState('offerWizard', ['checkoutSettings']),
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    customLabel() {
      const label = {
        online_course: this.$t('sparkmembers.product-creator.setup.types.online_course'),
        ebook: this.$t('sparkmembers.product-creator.setup.types.ebook'),
        path: this.$t('sparkmembers.product-creator.setup.types.path'),
        Quiz: this.$t('sparkmembers.product-creator.setup.types.Quiz'),
        webinar: this.$t('sparkmembers.product-creator.setup.types.webinar'),
        live_meet: this.$t('sparkmembers.product-creator.setup.types.live_meet'),
      };
      return this.props ? label[this.props[1].type] : '';
    },
    offerTypes() {
      return [
        {
          id: 1,
          name: OfferType.COMMON,
          img: 'sell_unitary',
        },
        {
          id: 2,
          name: OfferType.SUBSCRIPTION,
          img: 'sell_reccorency',
        },
      ];
    },
  },
  methods: {
    ...mapActions('product', ['updateProduct']),
    ...mapActions('course', ['updateCourse']),
    async save() {
      try {
        this.isSaving = true;
        const payload = {
          ...this.form,
          price: this.form.price > 0 ? GeneralHelper.valueInCents(this.form.price) : 0,
          school_product_id: this.props[0].product.id,
          checkout_settings: this.checkoutSettings,
        };
        const { data } = await productService.createProductPaymentOption(this.props[0].product.id, payload);
        const analitcsOfferBaseProps = {
          id: data.id,
          title: this.props[0].product.title,
          type: this.form.kind,
          recurrence: this.offerType,
          product_type: this.props[1].type,
          product_id: this.props[0].product.id,
          facebook_pixel: payload.facebook_pixel !== undefined && payload.facebook_pixel !== '',
          google_analytics: payload.ga_tracking_id !== undefined && payload.ga_tracking_id !== '',
          default: true,
          price: GeneralHelper.valueNormalizeCents(payload.promotional ? payload.base_price : payload.price),
          installments: payload.installments_amount_limit,
          credit_card: payload.payment_method.includes(PaymentMethodsList.CREDIT_CARD),
          boleto: payload.payment_method.includes(PaymentMethodsList.BANK_SLIP),
          pix: payload.payment_method.includes(PaymentMethodsList.PIX),
        };

        TrackingHelper.trackProductPriceDefined(
          analitcsOfferBaseProps.id,
          analitcsOfferBaseProps.title,
          analitcsOfferBaseProps.type,
          analitcsOfferBaseProps.recurrence,
          analitcsOfferBaseProps.product_type,
          analitcsOfferBaseProps.facebook_pixel,
          analitcsOfferBaseProps.google_analytics,
          analitcsOfferBaseProps.price,
          analitcsOfferBaseProps.installments,
          analitcsOfferBaseProps.credit_card,
          analitcsOfferBaseProps.boleto,
          analitcsOfferBaseProps.pix
        );
        await this.updateProduct(this.props[0].product);
        if (this.props[2].course) this.props[1].type !== 'path' && (await this.updateCourse(this.props[2].course));
        this.$emit('save');
      } catch {
        ToastHelper.dangerMessage(this.$t('sparkmembers.product-creator.index.toast-messages.product-creation-error'));
      } finally {
        this.isSaving = false;
      }
    },
    selectOfferType(offerType) {
      this.form.period = 'monthly';
      this.form.kind = offerType;
      this.offerType = offerType;
    },
    backToSelectOfferType() {
      this.offerType = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.options_offers {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0 32px 32px;
}
</style>
