<template>
  <section>
    <b-form @submit.prevent="onSubmit">
      <div v-if="showPayment">
        <div>
          <hs-group class="tlw-px-8" v-if="isCommonOffer">
            <label for="price" class="font-weight-bold">{{
              $t('sparkmembers.product-creator.pricing.price-input.label')
            }}</label>
            <div class="w-100 mb-2 d-flex align-items-center">
              <money
                id="price"
                name="price"
                v-model="form.price"
                class="form-control"
                :disabled="loading"
                decimal=","
                thousands="."
                :masked="false"
              />
            </div>
            <p class="font-size-xs tlw-mb-6">
              {{ $t('sparkmembers.product-creator.pricing.price-input.warning-label') }}
            </p>
            <hr class="tlw-bg-gray-100 tlw-border tlw-mb-0 mb-4" />
          </hs-group>

          <div>
            <hs-group class="tlw-px-8" v-if="!isCommonOffer && hasSparkCheckout()">
              <label for="price" class="font-weight-bold">Preço da assinatura</label>
              <div class="w-100 mb-2 d-flex align-items-center">
                <money
                  id="price"
                  name="price"
                  v-model="form.price"
                  class="form-control col-md-6 col-sm-12 col-lg-6"
                  :disabled="loading"
                  decimal=","
                  thousands="."
                  :masked="false"
                />
              </div>
              <p class="font-size-xs tlw-mb-0">Este é o valor que será exibido aos compradores.</p>
            </hs-group>
            <hs-group class="tlw-px-8" v-if="!isCommonOffer && hasSparkCheckout()">
              <label for="period" class="font-weight-bold">{{
                $t('sparkmembers.product-creator.pricing.period-title')
              }}</label>
              <div class="mb-2 d-flex align-items-center col-md-6 col-sm-12 col-lg-6 p-0">
                <select v-model="form.period" class="form-control">
                  <option v-for="item in periods" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
              <p class="font-size-xs col-md-6 col-sm-12 col-lg-6 p-0">
                {{ $t('sparkmembers.product-creator.pricing.period-label') }}
              </p>
              <span class="alert-default tlw-flex tlw-align-center col-md-6 col-sm-12 col-lg-6">
                <hs-icon variant="light" icon="info-circle mr-1" :size="16" />
                <span class="mr-1">{{ $t('sparkmembers.product-creator.pricing.period-text') }}</span>
                <span class="font-weight-bold">{{
                  $t(`sparkmembers.product-creator.pricing.period.${form.period}`)
                }}</span
                >.
              </span>
            </hs-group>
            <hr class="tlw-bg-gray-100 tlw-border m-4" v-if="!isCommonOffer && hasSparkCheckout()" />

            <div class="tlw-px-8">
              <PaymentMethodSelect
                :offerPrice="price"
                :periodicity="form.period"
                :offer-kind="form.kind"
                class="mb-4"
                @select-payment-method="selectPaymentMethod"
                @select-installment="selectInstallment"
              />
            </div>

            <div
              class="tlw-px-8 d-flex flex-column automatic-renew"
              v-if="!isCommonOffer && hasSparkCheckout() && canSubscriptionFrequency()"
            >
              <label class="tlw-font-bold tlw-text-base">
                Renovação automática
                <hs-icon
                  variant="light"
                  icon="info-circle"
                  :size="14"
                  v-b-tooltip.hover.top="
                    `Você pode definir um limite de renovações ou permitir que a assinatura renove automaticamente, até o cliente cancelar.`
                  "
                />
              </label>
              <div class="d-flex align-items-baseline">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="radio-frequency"
                    v-model="form.frequency_type"
                    name="radio-options"
                    :options="frequencies"
                    :aria-describedby="ariaDescribedby"
                    @change="trackOption($event)"
                    stacked
                  ></b-form-radio-group>
                </b-form-group>
              </div>
              <div
                class="col-md-6 col-sm-12 col-lg-6 pl-4"
                v-if="
                  !isCommonOffer &&
                    hasSparkCheckout() &&
                    form.frequency_type === 'limited' &&
                    canSubscriptionFrequency()
                "
              >
                <MInput id="automatic-renew" v-model="form.frequency_limit" type="number" />
                <p class="tlw-text-xs tlw-mb-4">Quantidade máxima de renovações para essa assinatura.</p>
              </div>
            </div>
          </div>
        </div>

        <hr class="tlw-border-0 tlw-bg-gray-10 tlw-h-6 tlw-w-full tlw-mb-0" />
      </div>
      <div class="tlw-flex tlw-w-full tlw-justify-end tlw-items-center tlw-bg-gray-10 tlw-pt-8">
        <hs-button
          id="cancel-product"
          variant="outline-primary"
          class="setup__cancel-button tlw-mr-4"
          @click="goToBack()"
        >
          {{ $t('sparkmembers.product-creator.pricing.back-label') }}
        </hs-button>
        <hs-button
          :variant="$v.form.$invalid || payment_methods.length == 0 ? 'light' : 'primary'"
          :disabled="$v.form.$invalid || payment_methods.length == 0"
          class="setup__save-button"
          :class="{ 'btn-block': isMobile }"
          type="submit"
        >
          <b-spinner small type="grow" v-if="loading" />
          {{
            loading ? $t('sparkmembers.offers.form.saving') : $t('sparkmembers.product-creator.pricing.button-label')
          }}
        </hs-button>
      </div>
    </b-form>
  </section>
</template>

<script>
import { Money } from 'v-money';
import BrowserHelper from '@/shared/helpers/browser';
import { mapState } from 'vuex';
import { gatewayService } from '@/services';
import { required, minValue } from 'vuelidate/lib/validators';
import { frequenciesData, periodsData, paymentOptionsPending } from '@/libs/offers';
import PaymentMethodSelect from '@/components/PaymentMethodSelect';
import MInput from '@/components/MInput.vue';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    showPayment: {
      type: Boolean,
      default: true,
    },
    offerType: {
      type: String,
    },
  },
  data() {
    return {
      payment_methods: [],
      absorventFees: false,
      status_tracking_fields: false,
      frequencies: frequenciesData,
      periods: periodsData,
      paymentOptionsPending: paymentOptionsPending,
    };
  },
  components: {
    Money,
    PaymentMethodSelect,
    MInput,
  },
  validations() {
    return {
      form: {
        price: {
          required,
          ...(this.showPayment && { minValue: minValue(1.99) }),
        },
      },
    };
  },
  methods: {
    selectPaymentMethod(payment) {
      this.payment_methods = payment;
    },
    selectInstallment(installment) {
      this.form.installments_amount_limit = installment;
    },
    canSubscriptionFrequency() {
      return this.$FCL.canSubscriptionFrequency();
    },
    canSubscriptionOverduePayments() {
      return this.$FCL.canSubscriptionOverduePayments();
    },
    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
    goToBack() {
      this.$emit('back');
    },
    normalizeValueZeroFrequency(type, limit, defaultValue) {
      if (type != defaultValue && limit < 1) {
        this.form.frequency_type = defaultValue;
        this.form.frequency_limit = null;
      }
    },
    normalizeValueZeroOverdue(type, limit, defaultValue) {
      if (type != defaultValue && limit < 1) {
        this.form.overdue_type = defaultValue;
        this.form.overdue_limit = null;
      }
    },
    onSubmit() {
      const typeFrequencies = this.form.frequency_type;
      const limitFrequencies = this.form.frequency_limit;
      const typeOverdue = this.form.overdue_type;
      const limitOverdue = this.form.overdue_limit;
      this.normalizeValueZeroFrequency(typeFrequencies, limitFrequencies, 'unlimited');
      this.normalizeValueZeroOverdue(typeOverdue, limitOverdue, 'none');
      const payload = {
        ...this.form,
        payment_method: this.payment_methods,
        default: true,
        installments_amount_limit: this.installmentNumber,
        overdue_limit: '3',
        overdue_type: 'count_in_sequence',
      };

      this.$emit('submit', payload);
    },
    optionSelectedPeriod(period) {
      this.$set(this.form, 'period', period.value);
    },
    trackOption(option) {
      let _option = option !== 'unlimited' ? option : '';
      this.$set(this.form, 'frequency_type', _option);
    },
    trackOptionOverdue(option) {
      let _option = option !== 'none' ? option : 'none';
      this.form.overdue_type = _option;
    },
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    installmentNumber() {
      return this.form.installments_amount_limit;
    },
    isMobile() {
      return BrowserHelper.isMobile();
    },
    mustShowGAField() {
      return this.$FCL.hasSparkCheckout();
    },
    price() {
      return this.form.price;
    },
    isCommonOffer() {
      return this.offerType === 'common';
    },
    isCreditCardCommonOffer() {
      return this.isCommonOffer && this.payment_methods.some(method => 'credit_card' === method);
    },
  },
  mounted() {
    this.payment_methods = this.form.payment_method.length ? this.form.payment_method : ['credit_card'];

    gatewayService.getGateway(this.schoolId, [{ key: 'gateway', value: 'spark_pay' }]).then(res => {
      this.absorventFees = Boolean(res.gateway_credentials[0].absorbent_fees);
    });
  },
};
</script>

<style lang="scss" scoped>
.box-subscription-overdue-payments {
  margin-top: 24px;
  .overdue-payments-options {
    margin-top: 8px;
  }
  .overdue-number {
    padding-top: 4px;
  }
}
.option-work {
  @import '@/assets/scss/custom-switch.scss';
}
.alert-default {
  background: #e3e3e3;
  color: #525252;
  margin: 0px 0px;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
}
.setup {
  &__save-button {
    background-color: $purple-dark;
    width: 152px;

    &:hover,
    &:focus {
      background-color: $purple-dark;
    }
  }
  &__cancel-button {
    background-color: transparent;
    border-color: $purple-dark;
    color: $purple-dark;
    width: 140px;

    &:hover,
    &:focus {
      border-color: $purple-dark;
      color: $purple-dark;
    }
  }
}
.btn {
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
}
.tlw-bg-gray-10 {
  background: $grey-16;
}

.multicard-helper {
  /deep/ a {
    color: #7427f1;
  }
}

/deep/ .custom-control.custom-radio + .custom-radio {
  margin-top: 8px;
}

/deep/ .custom-control-label {
  font-size: 14px;

  > span {
    display: block;
    margin-top: 2px;
  }
}

/deep/ .custom-control-input:checked ~ .custom-control-label:before {
  border-color: var(--color-purple-500);
  background-color: var(--color-purple-500);
}

.automatic-renew {
  label > i {
    color: #3575d4;
  }
}
</style>
