<template>
  <b-modal
    :id="modalId"
    :title="$t('sparkmembers.product-creator.setup.niche-input.niches-modal.title')"
    size="lg"
    header-class="p-3"
    body-class="p-3"
    centered
    hide-footer
    @hidden="onHidden"
  >
    <div class="d-flex flex-wrap ">
      <hs-badge
        v-for="(niche, index) in niches.filter(n => n.isHidden)"
        :key="index"
        pill
        :variant="niche.variant"
        class="mr-3 mt-3"
        @click="onNicheSelected(niche)"
      >
        {{ $t(`sparkmembers.product-creator.setup.niche-options.${niche.name}`) }}
      </hs-badge>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    niches: {
      required: true,
      type: Array,
    },
  },
  computed: {
    modalId() {
      return 'other-niches-modal';
    },
  },
  methods: {
    onNicheSelected(niche) {
      this.$emit('selected', niche);
      this.$bvModal.hide(this.modalId);
    },
    onHidden() {
      this.$emit('onHiddenWithoutSelect');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .badge {
  cursor: pointer;
  user-select: none;
}
</style>
