<template>
  <div class="tlw-w-full tlw-bg-white tlw-px-4 tlw-py-8 lg:tlw-p-8 tlw-mb-2">
    <div class="d-flex">
      <div>
        <h4 class="tlw-font-bold tlw-text-xl">{{ $t('sparkmembers.setup.sales-page.info.column.title') }}</h4>
        <p class="tlw-text-sm tlw-mt-1 tlw-mb-3">{{ $t('sparkmembers.setup.sales-page.info.column.text') }}</p>
        <m-button
          class="tlw-text-sm p-0 tlw-cursor-pointer"
          variant="link"
          @click="() => $bvModal.show('preview-modal')"
        >
          <hs-icon icon="eye" class="tlw-mr-1" />
          <span class="tlw-font-normal">
            {{ $t('sparkmembers.setup.sales-page.info.column.link') }}
          </span>
        </m-button>
      </div>
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton';
export default {
  components: { MButton },
};
</script>
