<template>
  <div
    id="offer-type-card"
    class="offer-type tlw-w-full tlw-flex tlw-relative"
    :class="[{ 'tlw-cursor-pointer': !type.disabled }, { 'offer-type__card-disabled': type.disabled }]"
    data-testid="offer_type_card"
    @click="!type.disabled && $emit('selected', type)"
  >
    <div class="offer-type__icon tlw-mr-3" :style="{ backgroundColor: type.backgroundColor, color: type.color }">
      <hs-icon variant="solid" :icon="type.icon" />
    </div>
    <hs-badge v-if="showNewBadge" class="offer-type__new" data-testid="bagde_new" pill variant="success">
      {{ $t('sparkmembers.offers.card-type.new') }}
    </hs-badge>
    <div class="offer-type__content tlw-flex tlw-flex-col">
      <span class="tlw-text-sm">{{ $t('sparkmembers.offers.card-type.title') }}</span>
      <strong class="tlw-text-sm">{{ $t(`sparkmembers.offers.card-type.${type.title}`) }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    showNewBadge() {
      return this.type.badges.includes('new');
    },
  },
};
</script>
<style lang="scss" scoped>
.offer-type {
  border: 1px solid #d3d3d3;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 12px;
  .fa,
  .fas {
    font-weight: 400;
    font-size: 20px;
  }
  &__new {
    position: absolute;
    top: -10px;
    right: 10px;
    padding: 6px;
  }
  &__new {
    background-color: #ff305c;
  }
  &__card-disabled {
    background-color: #e3e3e3;
  }
  &__icon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }
  &__premium {
    background-color: #ffedba;
    color: #deb741;
    height: 29px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
</style>
