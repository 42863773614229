<template>
  <div>
    <div class="tlw-w-full tlw-bg-white tlw-p-4 lg:tlw-p-8 tlw-mb-2">
      <div class="d-flex tlw-flex-wrap tlw-justify-between tlw-items-baseline">
        <div>
          <h4 class="tlw-w-3/4 tlw-font-bold tlw-text-xl tlw-mb-2">
            {{ $t('sparkmembers.sales-page.info.form.cover.image.title') }}
          </h4>
          <p class="tlw-font-sans font-size-sm">
            A imagem será usada como capa e foto do produto. Ao alterar, você impactará também sua Página de vendas,
            Checkout e Área de membros.
          </p>
        </div>
        <m-button
          class="tlw-text-sm tlw-text-purple-500 p-0 tlw-cursor-pointer eye-button"
          variant="link"
          @click="() => $bvModal.show('preview-modal-image-product')"
        >
          <hs-icon icon="eye" class="tlw-mr-1" />
          <span class="tlw-font-normal">
            {{ $t('sparkmembers.setup.sales-page.info.form.basic.label-modal-show') }}
          </span>
        </m-button>
      </div>
      <div class="d-flex flex-wrap mt-3 tlw-items-center tlw-gap-6">
        <div
          class="image-preview vertical tlw-flex tlw-justify-center tlw-items-center tlw-rounded-lg tlw-border tlw-bg-gray-100"
          :style="imagePreviewStyle"
        />
        <div>
          <MButton
            :label="imageButtonLabel"
            :icon="imageButtonIcon"
            variant="primary-outline"
            :loading="isSaving"
            class="upload-button"
            @click="handleImageUpload"
          />
          <input
            type="file"
            @change="onFileInputChange"
            ref="file"
            accept="image/jpg, image/jpeg, image/png"
            style="display: none"
          />
        </div>
        <CropImage
          v-if="selectedImage != null"
          :aspect-ratio="Number(1280 / 720)"
          subtitle="Ajuste o tamanho da sua imagem para aproveitar o máximo dela."
          :src="selectedImage"
          :is-saving="isSaving"
          @close="selectedImage = null"
          @save="onFile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { uploadService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import Confirm from '@/shared/mixins/Confirm';
import MButton from '@/shared/components/MButton';
import courseService from '@/sparkmembers/services/course';
import pathService from '@/sparkmembers/services/path';
import CropImage from '@/components/CropImage.vue';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  mixins: [Confirm],
  components: {
    MButton,
    CropImage,
  },
  data() {
    return {
      isSaving: false,
      selectedImage: null,
    };
  },
  validations: {
    form: {
      subtitle: {
        required,
      },
      description: {
        required,
      },
    },
  },
  watch: {
    form(val) {
      this.form = val;
    },
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    ...mapState('course', {
      course: state => state.selectedCourse,
      path: state => state.selectedPath,
    }),
    imageButtonLabel() {
      if (this.isSaving) return 'Carregando';
      return this.form.logo ? 'Alterar' : 'Enviar imagem';
    },
    imageButtonIcon() {
      return this.form.logo ? 'arrows-rotate' : 'cloud-upload';
    },
    imagePreviewStyle() {
      return {
        backgroundImage: `url(${this.form.logo})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '158px',
        height: '89px',
      };
    },
  },
  methods: {
    handleImageUpload(e) {
      e.preventDefault();
      this.$refs.file.click();
    },
    onFileInputChange(e) {
      this.loadImageToCrop(e.target.files[0]);
      e.target.value = '';
    },
    loadImageToCrop(file) {
      const reader = new FileReader();
      reader.onload = blob => {
        this.selectedImage = blob.target?.result;
      };
      reader.readAsDataURL(file);
    },
    async onFile(file) {
      this.isSaving = true;
      try {
        const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
        const image_url = await uploadService.upload(data.url, data.fields, file);
        this.form.logo = image_url;

        this.$emit('autoSaveData');

        if (this.product.library_resource.resource.type === 'path') {
          await pathService.update({ ...this.path, image_url });
        } else {
          await courseService.update({ ...this.course, image_url });
        }

        ToastHelper.successMessage(this.$t('Imagem carregada com sucesso!'));
      } catch (error) {
        const message = this.$t('sparkmembers.sales-page.toast.upload.error');
        ToastHelper.dangerMessage(message);
      } finally {
        this.isSaving = false;
        this.selectedImage = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-button {
  padding: 8px 16px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .eye-button {
    position: absolute;
    right: 20px;
  }
}
</style>
