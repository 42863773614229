<template>
  <div>
    <b-modal
      scrollable
      centered
      hide-footer
      body-class="p-0"
      id="preview-modal"
      size="lg"
      :title="$t('sparkmembers.sales-page.info.column.modal-title')"
    >
      <div class="position-relative">
        <div class="position-absolute w-100 h-100" />
        <b-img-lazy
          v-bind="imageProps"
          :src="require('@/assets/images/SparkMembers/SalesPage/preview-sales-page.png')"
        />
      </div>
    </b-modal>
    <b-modal
      scrollable
      centered
      hide-footer
      body-class="p-0"
      id="preview-modal-info-description"
      size="lg"
      :title="$t('sparkmembers.sales-page.info.column.modal-title')"
    >
      <div class="position-relative">
        <div class="position-absolute w-100 h-100" />
        <b-img-lazy
          v-bind="imageProps"
          :src="require('@/assets/images/SparkMembers/SalesPage/preview-sales-page-info-description.png')"
        />
      </div>
    </b-modal>
    <b-modal
      scrollable
      centered
      hide-footer
      body-class="p-0"
      id="preview-modal-image-product"
      size="lg"
      :title="$t('sparkmembers.sales-page.info.column.modal-title')"
    >
      <div class="position-relative">
        <div class="position-absolute w-100 h-100" />
        <b-img-lazy
          v-bind="imageProps"
          :src="require('@/assets/images/SparkMembers/SalesPage/preview-sales-page-image-product.png')"
        />
      </div>
    </b-modal>
    <b-modal
      scrollable
      centered
      hide-footer
      body-class="p-0"
      id="preview-modal-video"
      size="lg"
      :title="$t('sparkmembers.sales-page.info.column.modal-title')"
    >
      <div class="position-relative">
        <div class="position-absolute w-100 h-100" />
        <b-img-lazy
          v-bind="imageProps"
          :src="require('@/assets/images/SparkMembers/SalesPage/preview-sales-page-video.png')"
        />
      </div>
    </b-modal>
    <b-modal
      scrollable
      centered
      hide-footer
      body-class="p-0"
      id="preview-modal-topics"
      size="lg"
      :title="$t('sparkmembers.sales-page.info.column.modal-title')"
    >
      <div class="position-relative">
        <div class="position-absolute w-100 h-100" />
        <b-img-lazy
          v-bind="imageProps"
          :src="require('@/assets/images/SparkMembers/SalesPage/preview-sales-page-topics.png')"
        />
      </div>
    </b-modal>
    <b-modal
      scrollable
      centered
      hide-footer
      body-class="p-0"
      id="preview-modal-productor"
      size="lg"
      :title="$t('sparkmembers.sales-page.info.column.modal-title')"
    >
      <div class="position-relative">
        <div class="position-absolute w-100 h-100" />
        <b-img-lazy
          v-bind="imageProps"
          :src="require('@/assets/images/SparkMembers/SalesPage/preview-sales-page-productor.png')"
        />
      </div>
    </b-modal>
    <b-modal
      scrollable
      centered
      hide-footer
      body-class="p-0"
      id="preview-modal-offer"
      size="lg"
      :title="$t('sparkmembers.sales-page.info.column.modal-title')"
    >
      <div class="position-relative">
        <div class="position-absolute w-100 h-100" />
        <b-img-lazy
          v-bind="imageProps"
          :src="require('@/assets/images/SparkMembers/SalesPage/preview-sales-page-price.png')"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#f4f4f4',
        height: 500,
      },
    };
  },
};
</script>
