



































































import { Component, Vue } from 'vue-property-decorator';
import { ConfigTypes } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import { OfferType } from '@/types/offers';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';

@Component({
  components: {
    OrderBumpList: () => import('./components/OrderBumpList.vue'),
    OrderBumpCreate: () => import('./components/OrderBumpCreate.vue'),
    OrderBumpEdit: () => import('./components/OrderBumpEdit.vue'),
    MButton: () => import('@/shared/components/MButton.vue'),
  },
})
export default class OrderBumpConfig extends Vue {
  configType: string = ConfigTypes.LIST;
  selectedOrderBumpEdit: number | null = null;
  hasOtherProductsUnitaryOffers: boolean = false;

  get enableCreateButton() {
    return this.hasUnitaryOffer && this.hasOtherProductsUnitaryOffers;
  }

  get isListType() {
    return this.configType === ConfigTypes.LIST;
  }

  get isCreateType() {
    return this.configType === ConfigTypes.CREATE;
  }

  get isEditType() {
    return this.configType === ConfigTypes.EDIT;
  }

  get hasUnitaryOffer() {
    const offers = this.$store.state.product.productPaymentOptions;
    return offers.some(offer => offer.kind === OfferType.COMMON);
  }

  selectOrderBumpToEdit(orderBumpId) {
    this.selectedOrderBumpEdit = orderBumpId;
    this.changeConfigTypeToEdit();
  }

  changeConfigTypeToCreate() {
    this.configType = ConfigTypes.CREATE;
  }

  changeConfigTypeToList() {
    this.configType = ConfigTypes.LIST;
  }

  changeConfigTypeToEdit() {
    this.configType = ConfigTypes.EDIT;
  }

  async mounted() {
    try {
      const coreProductId = this.$route.params.id;
      const response = await SparkEcommerceApiClient.products.all({
        exclude_product: coreProductId,
        only_common_offers: true,
        items: 999,
      });

      const filteredAvailableOffers = response.filter(product => Object.keys(product.default_offer).length > 0);

      this.hasOtherProductsUnitaryOffers = Boolean(filteredAvailableOffers.length);
    } catch {
      this.hasOtherProductsUnitaryOffers = false;
    }
  }
}
