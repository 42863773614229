<template>
  <section id="product-list" class="d-flex flex-column flex-fill">
    <div class="tlw-flex tlw-gap-6 tlw-w-full tlw-mb-0.5">
      <div class="d-flex ma-0 flex-fill flex-column pt-3 pt-sm-0 pt-2">
        <div class="product-list__content" :class="sidebar ? 'sidebar--open ' : 'sidebar--closed'">
          <div v-if="isLoading" class="tlw-flex tlw-justify-center tlw-items-center tlw-flex-1">
            <HsLoading />
          </div>

          <template v-else>
            <template v-if="hasProducts || noSearchResults">
              <div class="product-search-wrapper">
                <ProductSearch class="product-list-search" @searchProduct="loadProducts(1, $event)" />

                <SegmentedControl
                  v-model="selectedType"
                  :items="[
                    { value: 'all', label: 'Todos' },
                    { value: 'published', label: 'Publicados' },
                    { value: 'not-published', label: 'Não publicado' },
                  ]"
                  class="product-list-filter"
                />
              </div>

              <div v-if="isSearching" class="tlw-flex tlw-justify-center tlw-items-center tlw-flex-1">
                <HsLoading />
              </div>

              <template v-else>
                <NoProductsWarning
                  v-if="noSearchResults"
                  :text="$t('sparkmembers.v2-product-list.no-found-products-warning.title')"
                />

                <template v-for="(product, index) in products">
                  <ProductCard
                    :id="index"
                    :active="selectedProduct === product"
                    :key="index"
                    :product="product"
                    @selected="$listeners['on-selected-product']"
                    @openImageSidebar="$listeners['openImageSidebar']"
                  >
                    <template v-slot:offer-popover>
                      <div @click="openPopover(`popover-target-${product.id}`)">
                        <hs-icon class="mr-1 pointer popover-trigger tlw-text-sm" icon="link" />
                        <span class="title popover-trigger">
                          {{ $t('sparkmembers.v2-product-list.card.link-popover') }}
                        </span>
                        <hs-icon
                          :id="`popover-target-${product.id}`"
                          class="ml-2 pointer popover-trigger"
                          size="14"
                          icon="chevron-down"
                        />
                      </div>

                      <b-popover
                        class=""
                        :ref="`popover-target-${product.id}`"
                        :target="`popover-target-${product.id}`"
                        custom-class="hs-popover"
                        placement="bottomright"
                      >
                        <ProductLinks
                          v-if="openedPopover == `popover-target-${product.id}`"
                          tracking="popOver_catalog"
                          :product="product"
                          :get-product-offers="getProductOffers"
                          :url-link="urlLink"
                          :offer-page-link="offerPageLink"
                        />
                      </b-popover>
                    </template>
                  </ProductCard>
                </template>

                <div
                  v-if="numberOfProducts > per_page"
                  class="mb-4 mt-6 products-list-pagination d-flex flex-fill flex-column justify-content-end"
                >
                  <hs-pagination
                    v-model="currentPage"
                    align="center"
                    :go-page-text="$t('sparkmembers.products-list.pagination.go-to-page')"
                    :per-page="per_page"
                    :total-rows="numberOfProducts"
                    @change="$listeners['on-change-page']"
                  />
                </div>
              </template>
            </template>

            <NoProductsWarning v-else :text="$t('sparkmembers.v2-product-list.no-result-products-warning.title')" />
          </template>
        </div>
      </div>
    </div>

    <ProductCreateModal :is-clone="$route.params.clone" v-if="shouldCreateProducts" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import productService from '@/sparkmembers/services/product';
import courseService from '@/sparkmembers/services/course';
import pathService from '@/sparkmembers/services/path';
import ProductSearch from './ProductSearch';
import NoProductsWarning from './NoProductsWarning';
import ProductCard from './ProductCard';
import ProductLinks from './ProductLinks';
import toastHelper from '@/shared/helpers/toast';
import ProductCreateModal from './ProductCreateModal';
import HsLoading from '@/components/Loading';
import PopoverControllerMixin from '@/sparkmembers/mixins/PopoverControllerMixin';
import SegmentedControl from '@/components-new/SegmentedControl';

export default {
  name: 'Products',
  components: {
    HsLoading,
    ProductSearch,
    NoProductsWarning,
    ProductLinks,
    ProductCard,
    ProductCreateModal,
    SegmentedControl,
  },
  mixins: [PopoverControllerMixin],
  props: {
    sidebar: {
      type: Boolean,
    },
    selectedProduct: {
      type: Object,
    },
  },
  data() {
    return {
      products: [],
      paramSearch: '',
      noSearchResult: false,
      isLoading: true,
      isSearching: false,
      numberOfProducts: 0,
      per_page: 8,
      currentPage: 1,
      noSearchResults: null,
      selectedType: 'all',
    };
  },
  created() {
    this.installEventListener();
  },
  destroyed() {
    this.removeEventListener();
  },
  async mounted() {
    await this.loadProducts(this.currentPage);

    if (this.shouldCreateProducts) {
      this.$bvModal.show('new-product-created-modal');
    }
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    shouldCreateProducts() {
      return this.$route.params.new || this.$route.params.clone;
    },
    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
    hasProducts() {
      return this.products.length > 0;
    },
    dynamicStyle() {
      return {
        width: this.sidebar ? 'calc(100% - 350px)' : '100%',
      };
    },
    translatedPublishedFilter() {
      if (this.selectedType === 'all') return null;

      return {
        key: 'published',
        value: this.selectedType === 'published' ? true : false,
      };
    },
  },
  methods: {
    ...mapActions('product', ['getProductPaymentOptions']),
    offerPageLink(product) {
      return `https://${this.getDomain}/${product.slug}`;
    },
    getProductOffers(product) {
      return this.getProductPaymentOptions({ id: product.id });
    },
    urlLink(product, paymentOption) {
      return makeCheckoutUrl(this.hasSparkCheckout, paymentOption, product, this.getDomain);
    },
    reloadFirstPage() {
      this.loadProducts(this.currentPage);
    },
    async loadProducts(page, search = null) {
      try {
        this.isSearching = true;
        this.paramSearch = search;

        const query = [
          { key: 'per_page', value: this.per_page },
          { key: 'page', value: page },
          { key: 'request_attributes', value: 'library_resource' },
        ];

        if (search && search.length) query.push({ key: 'title', value: `${search}` });

        if (this.translatedPublishedFilter) {
          query.push(this.translatedPublishedFilter);
        }

        const { school_products, total_count } = await productService.getAll(query);
        const products = await Promise.all(
          school_products.map(async product => {
            const { library_resource } = product;
            let customPayload;
            if (library_resource) {
              customPayload =
                library_resource.resource.type === 'course'
                  ? {
                      course: (await courseService.get(product.library_resource.resource.id)).data,
                    }
                  : {
                      path: (await pathService.get(product.library_resource.resource.id)).data,
                    };
            }

            return {
              ...product,
              ...customPayload,
            };
          })
        );

        this.products = products;
        const hasSearchingParams = search !== null || this.translatedPublishedFilter !== null;
        this.noSearchResults = hasSearchingParams && !this.hasProducts;
        this.numberOfProducts = total_count;
      } catch (error) {
        toastHelper.dangerMessage(this.$t('sparkmembers.products-list.toast-messages.products-loading-error'));
      } finally {
        this.isLoading = false;
        this.isSearching = false;
      }
    },
  },
  watch: {
    async currentPage(page) {
      window.scrollTo(0, 0);
      await this.loadProducts(page, this.paramSearch);
    },
    async selectedType() {
      await this.loadProducts(1, this.paramSearch);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-sidebar {
  height: 83em;
}
.sidebar--closed,
.sidebar--open {
  transition: all 0.2s ease;
}

.sidebar--closed {
  width: 100%;
}

.sidebar--open {
  width: calc(100% - 350px);
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active em versões anteriores a 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.product-search-wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.product-list-search {
  width: 100%;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    width: 450px;
  }
}

.product-list-filter {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 100%;

  @media (min-width: 768px) {
    margin-bottom: 2.5rem;
    width: 315px;
  }
}
</style>
