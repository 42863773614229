export const frequenciesData = [
  { text: 'Até o cliente cancelar', value: 'unlimited' },
  { text: 'Definir a quantidade de renovações', value: 'limited' },
];

export const paymentOptionsPending = [
  { text: 'Cancelar após o primeiro pagamento pendente', value: 'none' },
  { text: 'Definir quantidade de pagamentos pendentes antes do cancelamento', value: 'count_in_sequence' },
];

export const contentAccess = [
  { text: 'Remover acesso de assinantes inadimplentes', value: false },
  { text: 'Manter acesso de assinantes mesmo inadimplentes', value: true },
];

export const periodsData = [
  { text: 'Semanal', value: 'weekly' },
  { text: 'Mensal', value: 'monthly' },
  { text: 'Bimestral', value: 'bimonthly' },
  { text: 'Trimestral', value: 'quarterly' },
  { text: 'Semestral', value: 'semiannual' },
  { text: 'Anual', value: 'annual' },
];
