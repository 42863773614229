




































import { Component, Vue, Prop } from 'vue-property-decorator';
import TrackingHelper from '@/shared/helpers/tracking';
import coproductionService from '@/sparkaffiliates/services/coproduction';
import MButton from '@/shared/components/MButton.vue';

@Component({
  components: {
    MButton,
  },
})
export default class DeleteConfirmation extends Vue {
  @Prop({ type: Object }) invite: any;

  async removeInvite() {
    await coproductionService.delete(this.invite.id);
    TrackingHelper.trackCoproductionDeleted(
      this.$store.state.school.selectedSchool.id,
      this.$store.state.course.selectedCourse.name,
      this.$store.state.course.selectedCourse.kind,
      this.invite.commission
    );
    this.$emit('invite-removed');
    this.closeModal();
  }

  closeModal() {
    this.$bvModal.hide('delete-confirmation-modal');
  }
}
