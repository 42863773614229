





import { Component, Vue } from 'vue-property-decorator';
import Info from './components/Info/index.vue';
import { namespace } from 'vuex-class';

const ProductModule = namespace('product');

@Component({
  components: { Info },
})
export default class ProductSalesPage extends Vue {
  @ProductModule.State selectedProduct!: any;

  onSave() {
    this.$emit('save');
  }
}
