<template>
  <div>
    <hsModal
      :id="modalId"
      :size="'md'"
      :title="$t('sparkmembers.product-dashboard.components.edit-product-title-modal.title')"
      @hidden="hidden"
    >
      <hs-loading v-if="isLoading" />
      <template v-else>
        <hsForm @submit="save">
          <div class="mb-5">
            <label for="edit-product-title">{{
              $t('sparkmembers.product-dashboard.components.edit-product-title-modal.title-label.text')
            }}</label>
            <hs-input
              id="edit-product-title"
              v-model="title"
              :autofocus="true"
              :state="!$v.title.$error ? null : false"
              @blur="$v.title.$touch()"
            >
              <template slot="feedback" v-if="$v.title.$error">
                <hs-form-invalid-feedback :state="false">
                  <span v-if="!$v.title.required">
                    {{
                      $t(
                        'sparkmembers.product-dashboard.components.edit-product-title-modal.title-label.validation.required'
                      )
                    }}
                  </span>
                  <span class="font-size-xs" v-if="!$v.title.noQuotes">
                    {{ $t('sparkmembers.product-creator.setup.name-input.validation.no-quotes') }}
                  </span>
                </hs-form-invalid-feedback>
              </template>
            </hs-input>
          </div>

          <div class="d-flex flex-column flex-md-row justify-content-end align-items-center">
            <hs-button id="cancel-button" class="py-2" variant="link" @click="$bvModal.hide(modalId)">
              {{ $t('actions.cancel') }}
            </hs-button>
            <hs-button id="save-button" class="py-2" variant="primary" @click="save" :disabled="$v.title.$invalid">
              {{ $t('sparkmembers.product-dashboard.components.edit-product-title-modal.buttons.save-title') }}
            </hs-button>
          </div>
        </hsForm>
      </template>
    </hsModal>
  </div>
</template>

<script>
import { hsForm, hsLoading, hsModal } from '@/components';
import { required } from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';
import ValidatorsHelper from '@/shared/helpers/validators';

export default {
  name: 'EditProductTitleModal',
  components: {
    hsForm,
    hsLoading,
    hsModal,
  },
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    ...mapState('course', {
      course: state => state.selectedCourse,
    }),
    modalId() {
      return 'edit-product-title-modal';
    },
  },
  data() {
    return {
      isLoading: false,
      title: null,
    };
  },
  validations: {
    title: {
      required,
      noQuotes: ValidatorsHelper.noQuotes(),
    },
  },
  methods: {
    ...mapActions('product', ['updateProduct']),
    ...mapActions('course', ['updateCourse']),
    hidden() {
      if (!this.isLoading) this.title = this.product.title;
    },
    async save() {
      this.isLoading = true;
      try {
        await this.updateCourse({
          id: this.course.id,
          name: this.title,
        });
        await this.updateProduct({
          id: this.product.id,
          title: this.title,
        });
        ToastHelper.successMessage(
          this.$t('sparkmembers.product-dashboard.components.edit-product-title-modal.toast.success')
        );
        this.$bvModal.hide(this.modalId);
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.product-dashboard.components.edit-product-title-modal.toast.error')
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.title = this.product.title;
  },
};
</script>
