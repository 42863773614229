export default () => [
  {
    type: 'live',
    badge: {
      label: 'Novo',
    },
    query: 'lives',
    img: require(`@/assets/images/SparkMembers/ProductCreator/live.svg`),
    title: 'sparkmembers.product-creator.type-select.products-cards.live.title',
    description: 'sparkmembers.product-creator.type-select.products-cards.live.description',
    action: {
      variant: 'primary',
      label: 'sparkmembers.product-creator.type-select.products-cards.live.button-label',
    },
    children: [
      {
        type: 'live_meet',
        badge: {
          label: 'Produto pago',
        },
        query: 'live_meet',
        img: require(`@/assets/images/SparkMembers/ProductCreator/live_meet.svg`),
        title: 'sparkmembers.product-creator.type-select.products-cards.live_meet.title',
        description: 'sparkmembers.product-creator.type-select.products-cards.live_meet.description',
        provided: 'sparkmembers.product-creator.type-select.products-cards.live_meet.provided',
        action: {
          variant: 'primary',
          label: 'sparkmembers.product-creator.type-select.products-cards.live_meet.button-label',
        },
        pills: {
          pill_1: 'sparkmembers.product-creator.type-select.products-cards.live_meet.pills.pill_1',
          pill_2: 'sparkmembers.product-creator.type-select.products-cards.live_meet.pills.pill_2',
          pill_3: 'sparkmembers.product-creator.type-select.products-cards.live_meet.pills.pill_3',
        },
      },
      {
        type: 'webinar',
        query: 'webinar',
        img: require(`@/assets/images/SparkMembers/ProductCreator/webinar.svg`),
        title: 'sparkmembers.product-creator.type-select.products-cards.webinar.title',
        description: 'sparkmembers.product-creator.type-select.products-cards.webinar.description',
        action: {
          variant: 'primary',
          label: 'sparkmembers.product-creator.type-select.products-cards.ebook.button-label',
        },
        pills: {
          pill_1: 'sparkmembers.product-creator.type-select.products-cards.webinar.pills.pill_1',
          pill_2: 'sparkmembers.product-creator.type-select.products-cards.webinar.pills.pill_2',
          pill_3: 'sparkmembers.product-creator.type-select.products-cards.webinar.pills.pill_3',
        },
      },
    ],
  },
  {
    type: 'course',
    query: 'online_course',
    img: require(`@/assets/images/SparkMembers/ProductCreator/course.svg`),
    title: 'sparkmembers.product-creator.type-select.products-cards.course.title',
    description: 'sparkmembers.product-creator.type-select.products-cards.course.description',
    action: {
      variant: 'primary',
      label: 'sparkmembers.product-creator.type-select.products-cards.course.button-label',
    },
  },
  {
    type: 'ebook',
    query: 'ebook',
    img: require(`@/assets/images/SparkMembers/ProductCreator/ebook.svg`),
    title: 'sparkmembers.product-creator.type-select.products-cards.ebook.title',
    description: 'sparkmembers.product-creator.type-select.products-cards.ebook.description',
    action: {
      variant: 'primary',
      label: 'sparkmembers.product-creator.type-select.products-cards.ebook.button-label',
    },
  },
  {
    type: 'combo',
    query: 'path',
    title: 'sparkmembers.product-creator.type-select.products-cards.combo.title',
    img: require(`@/assets/images/SparkMembers/ProductCreator/combo.svg`),
    description: 'sparkmembers.product-creator.type-select.products-cards.combo.description',
    action: {
      label: 'sparkmembers.product-creator.type-select.products-cards.combo.button-label',
      variant: 'primary',
    },
  },
  {
    type: 'file',
    query: 'file',
    img: require(`@/assets/images/SparkMembers/ProductCreator/file.svg`),
    title: 'sparkmembers.product-creator.type-select.products-cards.file.title',
    description: 'sparkmembers.product-creator.type-select.products-cards.file.description',
    action: {
      variant: 'primary',
      label: 'sparkmembers.product-creator.type-select.products-cards.file.button-label',
    },
  },
  {
    type: 'webinar',
    badge: {
      label: 'Novo',
    },
    query: 'webinar',
    img: require(`@/assets/images/SparkMembers/ProductCreator/combo.svg`),
    title: 'sparkmembers.product-creator.type-select.products-cards.webinar.title',
    description: 'sparkmembers.product-creator.type-select.products-cards.webinar.description',
    action: {
      variant: 'primary',
      label: 'sparkmembers.product-creator.type-select.products-cards.ebook.button-label',
    },
  },
];
