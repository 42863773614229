














































































import debug from 'debug';
import _ from 'lodash';
import { namespace } from 'vuex-class';
import { AxiosResponse } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { CoproductionProgram, InviteForm, Coproducer, Invite, ParticipationInvite } from '@/types/coproduction';
import { ProductId, BaseProduct, PaymentOption } from '@/types/products';
import { hsLoading } from '@/components';
import { COMMISSION_RULES } from '@/libs/affiliates';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import MButton from '@/shared/components/MButton.vue';
import CoproductionParticipant from './components/CoproductionParticipant.vue';
import { AVATAR_BOT_1 } from '@/libs/avatar';
import CoproductionInviteForm from '@/components/CoproductionInviteForm.vue';
import coproductionService from '@/sparkaffiliates/services/coproduction';
import RightDrawer from '@/components/RightDrawer.vue';
import CoproductionDetails from './components/CoproductionDetails.vue';
import TrackingHelper from '@/shared/helpers/tracking';
import ToastHelper from '@/shared/helpers/toast';
import organizationService from '@/sparkaffiliates/services/organization';
import ParticipationInviteInformation from '@/components/ParticipationInviteInformation.vue';

const logging = debug('hs:coproduction');

type Product = BaseProduct;

const AuthModule = namespace('auth');
const SchoolModule = namespace('school');
const CourseModule = namespace('course');

const defaultInviteForm: InviteForm = {
  commission: null,
  name: '',
  email: '',
  avatar: AVATAR_BOT_1,
};

const MAX_COMMISSION = 80;

@Component({
  components: {
    hsLoading,
    MButton,
    CoproductionParticipant,
    CoproductionInviteForm,
    RightDrawer,
    CoproductionDetails,
    ParticipationInviteInformation,
  },
})
export default class CoproductList extends Vue {
  @AuthModule.State loggedUser!: any;
  @SchoolModule.Getter getDefaultDomain!: string;
  @SchoolModule.State selectedSchool!: any;
  @CourseModule.State selectedCourse!: any;
  @CourseModule.State selectedPath!: any;

  isLoading = false;
  program: CoproductionProgram | null = null;
  inviteForm: typeof defaultInviteForm | null = null;

  invite: ParticipationInvite | null = null;

  selectedInvite: any = null;

  commissionRules = COMMISSION_RULES;

  mounted() {
    this.fetchData();
  }

  get remainingCommissionPercentage() {
    return this.invites.reduce((acc, invite) => acc - invite.commission, MAX_COMMISSION);
  }

  get hasSelectedInvite() {
    return Boolean(this.selectedInvite);
  }

  get hasSparkCheckout() {
    return this.$FCL.hasSparkCheckout();
  }

  get productId() {
    return parseInt(this.$route.params.id);
  }

  get invites(): Coproducer[] {
    return _.get(this.program, 'participation_invites', []) as Coproducer[];
  }

  get isOpenInviteLinkModal() {
    return Boolean(this.invite);
  }

  closeInviteInformationModal() {
    this.invite = null;
  }

  inviteMore() {
    this.invite = null;
    this.openInviteForm();
  }

  openInviteForm() {
    this.inviteForm = _.cloneDeep(defaultInviteForm);
    this.$bvModal.show('invite-modal');
  }

  closeInviteForm() {
    this.$bvModal.hide('invite-modal');
  }

  urlLink(paymentOption: PaymentOption, product: Product): string {
    return makeCheckoutUrl(this.hasSparkCheckout, paymentOption, product, this.getDefaultDomain);
  }

  findProgram(productId: ProductId): Promise<AxiosResponse<CoproductionProgram>> {
    return coproductionService.getProductProgram(productId);
  }

  processError(error: any): Promise<AxiosResponse<CoproductionProgram>> {
    logging('coproduction program not yet created. trying to create one', error);
    return error.cata({
      CoproductionProgramNotCreated: () => coproductionService.createProgram(this.productId, this.urlLink),
      CoproductionProgramOtherError: this.panic,
      CoproductionProgramAxiosError: this.panic,
    });
  }

  panic(e: any) {
    logging('failed to get the program', e);
    return Promise.reject(e);
  }

  inviteRemoved() {
    this.fetchData();
    this.selectedInvite = null;
  }

  async fetchData() {
    try {
      this.isLoading = true;

      await organizationService.createOrganization({
        organization: {
          platform_name: this.selectedSchool.account_type,
        },
      });

      const { data: program } = await this.findProgram(this.productId).catch(error => {
        return this.processError(error);
      });
      logging('loaded coproduction program:', program);

      this.program = program;
    } catch (error) {
      logging('this is a catastrofe scenario.', error);
    } finally {
      this.isLoading = false;
    }
  }

  async sendInvite(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.closeInviteForm();

    try {
      const contentKind = !_.isEmpty(this.selectedCourse.name) ? this.selectedCourse : this.selectedPath;

      const invite: Invite = {
        ...this.inviteForm!,
        commission: parseFloat(this.inviteForm!.commission!),
        program_id: this.program!.id,
        school_id: this.selectedSchool.id,
        product_name: contentKind.name,
      };
      const { data: participation_invite } = await coproductionService.invite(invite);
      this.program!.participation_invites.push(participation_invite);
      this.invite = participation_invite;

      TrackingHelper.trackCoproductionCreated(
        this.selectedSchool.id,
        contentKind.name,
        contentKind.kind,
        invite.commission
      );
      logging('successifully invited', participation_invite);
      this.inviteForm = null;

      ToastHelper.successMessage(this.$t('sparkmembers.coproduction.views.list.components.toast.success'));
    } catch (e) {
      logging('failed to send invitation', e);
      ToastHelper.dangerMessage(this.$t('sparkmembers.coproduction.views.list.components.toast.danger'));
    }
  }

  onSelectedInvite(participantInvite: ParticipationInvite) {
    this.selectedInvite = participantInvite;
  }

  closeSelectedInvite() {
    this.selectedInvite = null;
  }
}
