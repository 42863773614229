<template>
  <div>
    <hs-table :responsive="true" :fields="fields" :items="coupons" :selectable="false">
      <template slot="name" slot-scope="row">
        <strong>{{ row.item.name }}</strong>
      </template>
      <template slot="actions" slot-scope="row">
        <hs-button class="delete-button-coupon" variant="icon" @click="$emit('delete', row.item.id)">
          <hs-icon variant="regular" icon="trash-alt" />
        </hs-button>
      </template>
    </hs-table>
  </div>
</template>

<script>
export default {
  name: 'CouponsTable',
  props: {
    coupons: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('sparkmembers.coupons.views.list.components.coupons-table.fields.name'),
          sortable: true,
        },
        {
          key: 'discount',
          label: this.$t('sparkmembers.coupons.views.list.components.coupons-table.fields.discount'),
          sortable: true,
        },
        {
          key: 'quantity',
          label: this.$t('sparkmembers.coupons.views.list.components.coupons-table.fields.quantity'),
          sortable: true,
        },
        {
          key: 'usedQuantity',
          label: this.$t('sparkmembers.coupons.views.list.components.coupons-table.fields.used-quantity'),
          sortable: true,
        },
        {
          key: 'availableUntil',
          label: this.$t('sparkmembers.coupons.views.list.components.coupons-table.fields.available-until'),
          sortable: true,
        },
        { key: 'actions', label: '', sortable: false },
      ],
    };
  },
};
</script>

.<style lang="scss">
.delete-button-coupon {
  color: red;
}
</style>
