<template>
  <div class="step-badge">
    <div data-testid="check-image" v-if="isDone" class="step-badge__step-done">
      <img src="@/assets/images/white-check.svg" height="16" width="16" alt="check" />
    </div>
    <div
      v-else
      data-testid="step-number"
      class="step-badge__label-step"
      :class="{ 'step-badge__label-step--active': isActive }"
    >
      {{ stepNumber }}
    </div>

    <span
      v-if="labelText"
      data-testid="label-text"
      class="step-badge__text"
      :class="{ 'step-badge__text--done': isActive }"
    >
      {{ labelText }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'StepBadge',
  props: {
    stepNumber: {
      type: Number,
      default: 1,
    },
    isDone: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@mixin circle-badge {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-badge {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.step-badge__label-step {
  @include circle-badge;

  background-color: #e3e3e3;
  color: #bababa;
  border: 2px solid #bababa;
  font-size: rem(14);
  line-height: rem(21);
  font-weight: 700;
}

.step-badge__label-step--active {
  background-color: #ead3fe;
  color: #35126e;
  border: 2px solid #35126e;
}

.step-badge__step-done {
  @include circle-badge;

  background-color: $purple-dark;
  color: $white;
}

.step-badge__text {
  font-size: rem(14);
  line-height: rem(21);
  font-weight: 600;
  color: $grey-ba;
}

.step-badge__text--done {
  color: #35126e;
}
</style>
