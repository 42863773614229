<template>
  <div id="edit-product-slug" class="tlw-w-full tlw-bg-white tlw-p-4 lg:tlw-p-8 tlw-mb-32 lg:tlw-mb-5 url-product">
    <div class="tlw-flex tlw-flex-col">
      <h4 class="tlw-font-bold tlw-text-xl">
        {{ $t('sparkmembers.setup.sales-page.url-product.title') }}
      </h4>
    </div>
    <div class="tlw-w-full tlw-mt-2">
      <b-form @submit.prevent="onSaveSlug">
        <div class="tlw-flex tlw-mb-6">
          <a
            class="tlw-block tlw-font-normal tlw-text-sm url-product-link"
            :href="this.linkDefaultProduct(this.getDomain, this.product.slug)"
            target="_blank"
          >
            {{ this.linkDefaultProduct(this.getDomain, this.product.slug) }}
          </a>
          <m-button variant="icon" class="py-0 url-product-button" @click="copyLink">
            <hs-icon
              variant="regular"
              :icon="selectedLink ? 'check' : 'copy'"
              :class="selectedLink ? 'url-product-icon' : 'url-product-link'"
            />
          </m-button>
        </div>
        <hs-group>
          <label class="tlw-font-bold tlw-m-0">{{ $t('sparkmembers.setup.sales-page.url-product.form.label') }}</label>
          <div class="tlw-flex">
            <hs-input type="text" :value="linkDefaultDomain()" :disabled="true" />
            <hs-input type="text" v-model="form.slug" :disabled="isSaving" />
            <m-button type="submit" class="tlw-ml-3 tlw-hidden lg:tlw-block" variant="primary-outline">
              {{ $t('sparkmembers.setup.sales-page.url-product.form.save-button') }}
            </m-button>
          </div>
          <p class="tlw-block lg:tlw-hidden tlw-text-sm tlw-font-normal tlw-text-gray--modified tlw-mt-2 tlw-mb-6">
            {{ this.linkDefaultProduct(this.getDomain, this.product.slug) }}
          </p>
          <m-button type="submit" class="tlw-block lg:tlw-hidden tlw-h-9" variant="primary-outline">
            {{ $t('sparkmembers.setup.sales-page.url-product.form.save-button') }}
          </m-button>
        </hs-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ProductUrlHelper from '@/sparkmembers/helpers/productUrlHelper';
import ToastHelper from '@/shared/helpers/toast';
import MButton from '@/shared/components/MButton';
import Confirm from '@/shared/mixins/Confirm';
import { productService } from '@/services';

export default {
  components: { MButton },
  mixins: [Confirm],
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      selectedLink: null,
    };
  },
  validations: {
    form: {
      slug: {
        required,
      },
    },
  },
  watch: {
    form(val) {
      this.form = val;
    },
  },
  methods: {
    linkDefaultProduct(defaultDomain, productSlug) {
      return ProductUrlHelper.generateUrlProduct(defaultDomain, productSlug);
    },
    linkDefaultDomain() {
      return `https://${this.getDomain}/`;
    },
    copyLink() {
      this.$emit('copyLink');
      this.selectedLink = true;
    },
    async onSaveSlug() {
      const confirmed = await this.showConfirmBox({
        icon: 'info-circle',
        variant: 'cherry',
        okTitle: this.$t('sparkmembers.setup.sales-page.url-product.modal-confirm.button-ok'),
        cancelTitle: this.$t('sparkmembers.setup.sales-page.url-product.modal-confirm.button-cancel'),
        contentTitle: this.$t('sparkmembers.setup.sales-page.url-product.modal-confirm.title'),
        contentDescription: this.$t('sparkmembers.setup.sales-page.url-product.modal-confirm.description'),
        modalClass: 'save-product-slug',
      });

      if (confirmed) {
        try {
          this.isSaving = true;
          await productService.updateProductSlug({
            id: this.product.id,
            slug: this.product.slug,
          });
          ToastHelper.successMessage(
            this.$t('sparkmembers.setup.sales-page.url-product.message-feedback.alter-link-success')
          );
          this.$emit('onSaveSlug', { status: true });
        } catch (error) {
          ToastHelper.dangerMessage(
            this.$t('sparkmembers.setup.sales-page.url-product.message-feedback.alter-link-error')
          );
        } finally {
          this.isSaving = false;
        }
      }
    },
  },
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    ...mapGetters('school', ['getDomain']),
  },
};
</script>

<style lang="scss" scoped>
.url-product {
  &-link {
    color: var(--color-blue-700);
  }
  &-button {
    color: var(--color-blue-700);
    cursor: pointer;
    line-height: 10px;
    padding: 13px;
  }
  &-icon {
    color: #8ed147;
  }
  .tlw-text-gray--modified {
    color: #6f6f6f;
  }

  /deep/.hs-input {
    input:disabled {
      border-right-width: 0;
      color: #6f6f6f;
    }
  }
}
</style>
