<template>
  <div>
    <div class="d-flex w-100 px-3 px-lg-5 bg-white border-top">
      <b-link
        class="text-decoration-none py-3 text-dark border-bottom font-size-sm"
        :class="[
          { 'font-weight-bold': item.id === value },
          `mr-${index + 1 === items.length ? '0' : '3'}`,
          `border-${item.id === value ? 'cherry' : 'white'}`,
        ]"
        style="border-bottom-width: 3px !important;"
        :active="item.id === value"
        v-for="(item, index) in items"
        :key="index"
        @click.prevent="selectTab(item)"
      >
        {{ item.label }}
      </b-link>
    </div>
    <div class="m-2 m-lg-5">
      <transition name="fade" mode="out-in">
        <component :is="component" :form="product" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: String,
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      component: this.items.find(({ id }) => id === this.value).component,
    };
  },
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
  },
  methods: {
    selectTab(item) {
      this.component = item.component;
      this.$emit('input', item.id);
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  margin-top: -50px;
}
</style>
