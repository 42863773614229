import { TranslateResult } from 'vue-i18n';

export enum OfferType {
  COMMON = 'common',
  FREE = 'free',
  SUBSCRIPTION = 'subscription',
}

export enum SubscriptionType {
  RECURRENCY = 'recurrency',
  SMART_INSTALLMENT = 'smart-installment',
}

export enum PeriodEnum {
  MONTHLY = 'monthly',
  UNITARY = 'unitary',
}

export enum ThanksPageTypeEnum {
  BANK_SLIP = 'bank_slip',
  CREDIT_CARD = 'credit_card',
  PIX = 'pix',
}

export interface IOffer {
  banner_url: string | null;
  base_price: number;
  allow_overdue_access: boolean;
  default: boolean;
  facebook_pixel: string | null;
  facebook_api_token: string | null;
  ga_tracking_id: string | null;
  google_ads_tracking_id: string | null;
  google_ads_tracking_label: string | null;
  installments_amount_limit: number;
  id: string | null;
  kind: OfferType;
  payment_method: string[];
  period: PeriodEnum;
  price: number;
  price_format: string | null;
  promotional: string | null;
  school_product_id: string | null;
  title: string | null;
  thank_you_urls: IThanksPage[];
  url: string | null;
  frequency_type: string | 'unlimited';
  frequency_limit: string | null;
  overdue_type: string | 'none';
  overdue_limit: string | null;
  smart_installment: boolean;
  bank_slip_installment: boolean;
  bank_slip_max_installments: number;
  pix_installment: boolean;
  pix_max_installments: number;
  checkout_settings?: {
    require_phone: boolean | null;
    require_address: boolean | null;
    email_confirmation: boolean | null;
    whatsapp_settings: {
      whatsapp_text: string | TranslateResult | null;
      whatsapp_number: string | null;
    } | null;
    timer_settings: {
      text?: string;
      background_color?: string;
      text_color?: string;
      time?: string;
    } | null;
    background_settings: {
      color?: string;
      image?: {
        desktop?: string;
        mobile?: string;
      } | null;
    };
  };
}

export type Offer = IOffer & {
  slug: string;
};

export interface IThanksPage {
  url_type: ThanksPageTypeEnum;
  url: string;
}
