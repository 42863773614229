<template>
  <div class="segmented-control" data-testid="segmented-control">
    <div class="segmented-control__container" ref="container">
      <div class="segmented-control__slider" :style="sliderStyle" ref="slider"></div>
      <button
        v-for="(item, index) in items"
        :key="index"
        class="segmented-control__button"
        :class="{ 'segmented-control__button--active': value === item.value }"
        @click="$emit('input', item.value)"
        :data-testid="`segmented-control-button-${item.value}`"
        :data-label="item.label"
        ref="buttons"
      >
        {{ item.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SegmentedControl',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    items: {
      type: Array,
      required: true,
      validator: value => {
        return value.every(item => 'value' in item && 'label' in item);
      },
    },
  },
  data() {
    return {
      sliderStyle: {
        transform: 'translateX(0)',
        width: '0px',
      },
    };
  },
  methods: {
    updateSliderPosition() {
      if (!this.$refs.buttons) return;

      const activeIndex = this.items.findIndex(item => item.value === this.value);
      if (activeIndex === -1) return;

      const activeButton = this.$refs.buttons[activeIndex];
      if (!activeButton) return;

      this.sliderStyle = {
        transform: `translateX(${activeButton.offsetLeft - 2}px)`,
        width: `${activeButton.offsetWidth}px`,
      };
    },
  },
  mounted() {
    this.$nextTick(this.updateSliderPosition);
    window.addEventListener('resize', this.updateSliderPosition);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSliderPosition);
  },
  watch: {
    value() {
      this.$nextTick(this.updateSliderPosition);
    },
    items: {
      handler() {
        this.$nextTick(this.updateSliderPosition);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.segmented-control {
  display: inline-block;

  &__container {
    display: inline-flex;
    justify-content: space-between;
    background-color: $grey-15;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    position: relative;
    height: 37px;
    align-items: center;
    width: 100%;
    padding: 0 2px;
  }

  &__slider {
    position: absolute;
    height: 31px;
    background-color: white;
    border-radius: 8px;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1), 0px 2px 1px 0px rgba(0, 0, 0, 0.03);
    z-index: 0;
  }

  &__button {
    position: relative;
    border: none;
    background: none;
    padding: 6px 16px;
    font-size: 14px;
    color: $grey-32;
    cursor: pointer;
    z-index: 1;
    border-radius: 8px;
    transition: color 0.25s ease;
    outline: none;

    &::before {
      content: attr(data-label);
      display: block;
      height: 0;
      font-weight: 700;
      overflow: hidden;
      visibility: hidden;
    }

    &:hover:not(&--active) {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &--active {
      color: $grey;
      font-weight: 700;
    }
  }
}
</style>
