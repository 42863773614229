<template>
  <div class="d-flex justify-content-center align-items-center flex-column mt-3">
    <b-img-lazy class="image" :src="noDataImg"></b-img-lazy>
    <h3 id="title" class="font-weight-bold description text-center mt-3 mb-1">
      {{ $t('sparkmembers.coupons.views.list.components.no-coupons.title') }}
    </h3>
    <h3 id="title" class="font-weight-bold description text-center mb-3">
      {{ $t('sparkmembers.coupons.views.list.components.no-coupons.sub-title') }}
    </h3>
    <div>
      <m-button variant="primary" @click="$bvModal.show('coupon-creator-modal')">
        {{ $t('sparkmembers.coupons.views.list.components.no-coupons.button') }}
      </m-button>
    </div>
  </div>
</template>
<script>
import MButton from '@/shared/components/MButton.vue';
export default {
  components: { MButton },
  data() {
    return {
      noDataImg: require('@/assets/images/SparkMembers/Coupons/first-coupon.svg'),
    };
  },
};
</script>

<style lang="scss">
.image {
  height: 170px;
}
.description {
  color: #3f3f3f;
  font-size: 1rem;
}
</style>
