<template>
  <div class="tlw-w-full tlw-bg-white tlw-p-4 lg:tlw-p-8 tlw-mb-2">
    <div class="tlw-flex tlw-justify-between">
      <h4 class="tlw-font-bold tlw-text-xl">
        {{ $t('sparkmembers.sales-page.info.form.teacher.section') }}
      </h4>
      <m-button
        class="tlw-text-sm tlw-text-purple-500 p-0 tlw-cursor-pointer"
        variant="link"
        @click="() => $bvModal.show('preview-modal-productor')"
      >
        <hs-icon icon="eye" class="tlw-mr-1" />
        <span class="tlw-font-normal">
          {{ $t('sparkmembers.setup.sales-page.info.form.basic.label-modal-show') }}
        </span>
      </m-button>
    </div>
    <div class="tlw-mt-6 lg:tlw-mt-8">
      <hs-multiselect
        searchable
        close-on-select
        :placeholder="$t('sparkmembers.sales-page.info.form.teacher.search')"
        label="full_name"
        track-by="full_name"
        :show-labels="false"
        :options="teachers"
        :loading="loadingTeachers"
        :disabled="teachers.length === 0 || isSaving"
        @select="onSelectTeacher"
      />
    </div>
    <template v-if="form.class_teachers[0] && form.class_teachers[0].id">
      <div
        class="tlw-border tlw-rounded-lg tlw-p-3 tlw-mb-3"
        v-for="(teacher, index) in form.class_teachers"
        :key="index"
      >
        <div class="tlw-flex tlw-items-center tlw-justify-between">
          <div class="d-flex align-items-center mr-auto">
            <b-img-lazy class="rounded-circle mr-3 uploader-image" :src="selectedTeacher.photo_url"></b-img-lazy>
            <h4 class="font-weight-bold">{{ selectedTeacher.full_name }}</h4>
          </div>
          <hs-button
            v-b-tooltip.hover.bottom="$t('actions.edit')"
            class="py-0"
            variant="link"
            @click="() => (showFormTeacher = true)"
          >
            <hs-icon icon="edit" />
          </hs-button>
          <hs-button
            v-b-tooltip.hover.bottom="$t('actions.remove')"
            class="tlw-py-0"
            variant="link"
            @click="onDelete('delete-teacher-modal')"
          >
            <hs-icon icon="trash" />
          </hs-button>
        </div>
      </div>
    </template>
    <FormTeacher :form="form.class_teachers[0]" v-if="showFormTeacher" @input="onSelectTeacher" />
    <div v-if="form.class_teachers.length < 1">
      <hs-button
        id="btn-addteacher"
        v-if="!showFormTeacher"
        icon="plus"
        variant="primary"
        class="px-0 font-weight-light"
        @click="addNewTeacher"
      >
        {{ $t('sparkmembers.sales-page.info.form.teacher.action') }}
      </hs-button>
    </div>
    <hsConfirmModal
      id="delete-teacher-modal"
      icon="trash-alt"
      variant="cherry"
      hide-header
      :customTitle="$t('sparkmembers.sales-page.info.alert.teacher.title')"
      :description="$t('sparkmembers.sales-page.info.alert.teacher.description')"
      :ok-title="$t('sparkmembers.sales-page.info.alert.teacher.confirm')"
      :cancel-title="$t('sparkmembers.sales-page.info.alert.teacher.cancel')"
      @ok="onDeleteTeacher"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { analyticsService, productService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import Confirm from '@/shared/mixins/Confirm';
import MButton from '@/shared/components/MButton';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  mixins: [Confirm],
  components: {
    FormTeacher: () => import('../../FormTeacher'),
    hsConfirmModal: () => import('@/components/ConfirmModal'),
    MButton,
  },
  data() {
    return {
      isSaving: false,
      teachers: [],
      loadingTeachers: true,
      showFormTeacher: false,
    };
  },
  validations: {
    form: {
      subtitle: {
        required,
      },
      description: {
        required,
      },
    },
  },
  watch: {
    form(val) {
      this.form = val;
    },
  },
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    selectedTeacher() {
      return this.form.class_teachers[0]?.id ? this.form.class_teachers[0] : null;
    },
  },
  methods: {
    async getTeachers() {
      productService
        .getProductTeachers()
        .then(res => {
          this.teachers = res.data.class_teachers;
        })
        .finally(() => {
          this.loadingTeachers = false;
        });
    },
    onAddTeacher() {
      this.form.class_teachers.push({
        full_name: '',
        about: '',
        photo_url: '',
      });

      this.showFormTeacher = true;
    },
    onDelete(modalId) {
      this.$bvModal.show(modalId);
    },
    addNewTeacher() {
      this.form.class_teachers = [{ full_name: '', about: '', photo_url: '' }];
      this.showFormTeacher = true;
    },
    onSelectTeacher(teacher) {
      this.form.class_teachers = [teacher];
      this.form.class_teacher_ids = [teacher.id];
      const index = this.teachers.findIndex(item => item.id === teacher.id);
      if (index === -1) this.teachers.push(teacher);
      else this.teachers[index] = teacher;
      this.$emit('autoSaveData', undefined, false);
      this.showFormTeacher = false;
    },
    onDeleteTeacher() {
      productService
        .deleteProductTeacher(this.selectedTeacher.id)
        .then(() => {
          this.teachers.splice(this.teachers.indexOf(this.selectedTeacher), 1);
          this.form.class_teachers.shift();
          this.form.class_teacher_ids.shift();
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.teacher.removed'));
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.teacher.remove-error'));
        });
    },
  },
  mounted() {
    this.form.class_teacher_ids = this.form.class_teacher_ids || [];
    this.getTeachers();
  },
};
</script>

<style lang="scss" scoped>
.uploader {
  width: 91px;
  height: 91px;

  &-image {
    width: 70px;
    height: 70px;
  }
}
</style>
