



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import MAvatar from '@/shared/components/MAvatar.vue';
import { namespace } from 'vuex-class';
import { AVATAR_BOT_1 } from '@/libs/avatar';
import { ColourByStatus, InviteStatusLabel } from '@/libs/coproduction';

const AuthModule = namespace('auth');
const SchoolModule = namespace('school');

@Component({
  components: {
    MAvatar,
  },
})
export default class CoproductionCard extends Vue {
  @AuthModule.Getter name!: any;
  @SchoolModule.Action updateSchool!: (school: any) => Promise<any>;
  @SchoolModule.State selectedSchool!: any;
  @Prop({ type: Array, required: true }) invites!: any[];
  colourByStatus = ColourByStatus;
  inviteStatusLabel = InviteStatusLabel;

  defaultAvatar = AVATAR_BOT_1;

  get producerCommission(): number {
    return this.decimalPrecision(this.invites.reduce((acc, { commission }) => acc - commission, 100));
  }

  decimalPrecision(num) {
    return (num || 0).toFixed(2);
  }

  onSelected(invite) {
    this.$emit('selected', invite);
  }

  openInviteForm() {
    this.$emit('openInvite');
  }
}
