<template>
  <div class="d-flex flex-column">
    <div v-if="isLoading" class="d-flex flex-fill flex-column justify-content-center">
      <hs-loading />
    </div>
    <div v-else>
      <TypeSelect
        v-if="step === 1"
        id="type-select"
        :products="availableProducts"
        @selected="typeSelected"
        @liveSelected="liveTypeProducts"
        @backToTypes="backToTypes"
        :isChoosingLive="liveSelection"
      />
      <div v-if="step === 2" class="d-flex flex-fill flex-column w-100">
        <hsPageHeader
          :title="$t('sparkmembers.product-creator.index.title')"
          :sub-title="$t('sparkmembers.product-creator.index.sub-title')"
          :back-text="$t('sparkmembers.product-creator.index.back-text')"
          back-route="ProductCreator"
          @onBackRoute="step = 1"
        >
        </hsPageHeader>
        <hs-tab
          :lazy="false"
          id="product-tabs"
          :tabActive="tab"
          @onSelectDisabledTab="checkProduct"
          :isLoading="isLoadingTab"
          @onSave="onSave"
          :items="tabs"
          class="tlw-pl-11 ml-custom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import TypeSelect from './components/TypeSelect';
import Setup from './components/Setup';
import { courseService, productService, lessonService, analyticsService, pathService, mediaService } from '@/services';
import { hsLoading, hsTab } from '@/components';
import Confirm from '@/shared/mixins/Confirm';
import toastHelper from '@/shared/helpers/toast';
import hsPageHeader from '@/components/_structures/PageHeader';
import Pricing from './components/Pricing';
import ProductUrlHelper from '@/sparkmembers/helpers/productUrlHelper';
import AvailableProducts from '@/data/product_types';

export default {
  name: 'ProductCreatorV2',
  mixins: [Confirm],
  components: {
    TypeSelect,
    hsLoading,
    hsPageHeader,
    hsTab,
  },
  data() {
    return {
      availableProducts: [],
      isLoading: true,
      step: 1,
      tab: 0,
      course: null,
      type: null,
      disabledStepPrecification: true,
      isSaved: false,
      isLoadingTab: false,
      product: null,
      payment_options: [],
      liveSelection: false,
      clone_data: null,
      is_cloning: false,
    };
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
      schoolEmail: state => state.selectedSchool.email,
    }),
    ...mapGetters('school', ['getDomain', 'isSchoolValidated']),
    tabs() {
      return [
        {
          title: this.$t('sparkmembers.product-creator.index.tabs.setup'),
          icon: 'cog',
          component: Setup,
          disabled: false,
          props: [{ type: this.type, product_clone: this.clone_data }],
        },
        {
          title: this.$t('sparkmembers.product-creator.index.tabs.offer'),
          icon: 'credit-card-front',
          component: Pricing,
          disabled: this.disabledStepPrecification,
          props: [{ product: this.product }, { type: this.type }, { course: this.course }],
        },
      ];
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isSaved || this.step === 1) {
      next();
      return;
    }
    const confirmed = await this.showConfirmBox({
      icon: 'info-circle',
      variant: 'cherry',
      okTitle: this.$t('sparkmembers.product-creator.exit-modal.ok-title'),
      cancelTitle: this.$t('sparkmembers.product-creator.exit-modal.cancel-title'),
      contentTitle: this.$t('sparkmembers.product-creator.exit-modal.content-title'),
      contentDescription: this.$t('sparkmembers.product-creator.exit-modal.content-description'),
      modalClass: 'product-creator-modal',
    });
    if (confirmed) {
      if (this.product && this.product.id) productService.delete(this.product.id);
      next();
    }
  },
  methods: {
    typeSelected(t) {
      this.type = t;
      this.step += 1;
    },
    ...mapActions('product', ['getProductPaymentOptions']),
    ...mapActions('course', ['getCourse', 'getPath']),
    async checkProduct(tab) {
      if (tab.icon === 'cog') return;
      const confirmed = await this.showConfirmBox({
        icon: 'save',
        variant: 'primary',
        okTitle: this.$t('sparkmembers.product-creator.confirm-modal.ok-title'),
        cancelTitle: this.$t('sparkmembers.product-creator.confirm-modal.cancel-title'),
        contentTitle: this.$t('sparkmembers.product-creator.confirm-modal.content-title'),
        contentDescription: this.$t('sparkmembers.product-creator.confirm-modal.content-description'),
        modalClass: 'product-creator-modal',
      });
      if (confirmed) {
        document.getElementById('save-product').click();
      }
    },
    async onSave(tab, content) {
      if (tab === 0) this.setupFinished(content);
      else {
        const location = this.$route?.query?.location || 'product_catalog';
        this.payment_options = await this.getProductPaymentOptions({ id: this.product.id });
        this.payment_options = this.payment_options.map(payment => ({
          ...payment,
          price: payment.price / 100,
        }));
        analyticsService.track({
          event: 'Product published',
          props: {
            product_id: this.product.id,
            niche: this.product.niche,
            school_id: this.schoolId,
          },
        });
        analyticsService.track({
          event: 'Product created',
          props: {
            product_title: this.product.title,
            product_id: this.product.id,
            product_type: this.type,
            product_niche: this.product.niche,
            resource_id: this.is_cloning ? null : this.product.library_resource.id,
            school_id: this.schoolId,
            location: location,
            email: this.schoolEmail,
            product_price: this.payment_options[0].price,
            product_link: this.offerPage(this.getDomain, this.product.slug),
          },
          context: {
            traits: {
              email: this.schoolEmail,
              product_price: this.payment_options[0].price,
              product_title: this.product.title,
              product_link: this.offerPage(this.getDomain, this.product.slug),
            },
          },
        });
        this.isSaved = true;
        this.type === 'path' && (await this.getPath(this.product.path.id));
        const paramsObj = this.is_cloning ? { clone: true } : { new: true };
        this.$router.push({ name: 'ProductsList', params: paramsObj });
      }
    },
    async setupFinished({ name, niche, imgURL, description, origin_url, chat_url, verticalURL }) {
      this.isLoadingTab = true;
      try {
        let course;
        let product;
        if (this.is_cloning) {
          product = await productService.create(this.schoolId, {
            title: name,
            description,
            niche,
            logo: imgURL,
            logo_vertical: verticalURL,
            duplicate_course_id: this.clone_data.course.id,
          });
          this.product = { ...product.data };
          analyticsService.track({
            event: 'Product duplicated',
            props: {
              product_title: this.product.title,
              product_id: this.product.id,
              product_type: this.type,
              product_niche: this.product.niche,
              school_id: this.schoolId,
              email: this.schoolEmail,
            },
          });
        } else if (this.product && this.course) {
          await courseService.update({
            id: this.course.id,
            kind: this.type,
            image_url: imgURL,
            logo_vertical: verticalURL,
          });
          await productService.update({
            id: this.product.id,
            title: name,
            niche,
            origin_url,
            chat_url,
            library_resource_id: this.course.library_resource.id,
            logo: imgURL,
            logo_vertical: verticalURL,
            published: this.isSchoolValidated,
          });
          this.product = {
            ...this.product,
            niche,
            origin_url,
            chat_url,
            logo_vertical: verticalURL,
            logo: imgURL,
            library_resource_id: this.course.library_resource.id,
            title: name,
          };
          this.course = { ...this.course, kind: this.type, image_url: imgURL };
        } else {
          if (this.type === 'path') {
            await this.createPath({ name, niche, imgURL });
            return;
          }
          course = await courseService.create({
            name,
            kind: this.type,
            image_url: imgURL,
            logo_vertical: verticalURL,
            ready: true,
          });
          product = await productService.create(this.schoolId, {
            title: name,
            description,
            niche,
            library_resource_id: course.library_resource.id,
            logo: imgURL,
            logo_vertical: verticalURL,
          });
          await productService.update({
            id: product.data.id,
            published: this.isSchoolValidated,
          });
          if (this.type !== 'online_course') {
            const { data: module } = await courseService.createCourseModule(course.id, {
              name: this.type.toUpperCase(),
              course_id: course.id,
            });
            if (this.type !== 'live_meet') {
              const lesson = await lessonService.create({
                title: name,
                type: 'ContentLesson',
                description,
              });
              await courseService.createCourseContent(module.id, {
                course_module_id: module.id,
                content_id: lesson.id,
                content_type: 'Lesson',
                available: true,
              });
              await lessonService.addCommentsInLesson({
                title: name,
                school_product_id: product.data.id,
                lesson_id: lesson.id,
              });
              if (this.type === 'webinar') {
                const media = await mediaService.create({
                  type: 'WebinarHerospark',
                  title: name,
                  description,
                  origin_url,
                  chat_url,
                });
                await lessonService.update({
                  id: lesson.id,
                  media_id: media.data.id,
                });
              }
            }
          }
          this.course = course;
          this.product = { ...product.data, published: this.isSchoolValidated };
        }
        this.isLoadingTab = false;
        this.disabledStepPrecification = false;
        setTimeout(() => {
          this.tab = 1;
        }, 3);
      } catch (e) {
        toastHelper.dangerMessage(this.$t('sparkmembers.product-creator.index.toast-messages.product-creation-error'));
        this.isLoading = false;
      }
    },
    async createPath({ name, niche, imgURL }) {
      const path = await pathService.create({
        name,
        all_courses: false,
        course_ids: [],
        image_url: imgURL,
      });

      const product = await productService.create(this.schoolId, {
        title: name,
        niche,
        library_resource_id: path.library_resource.id,
        logo: imgURL,
      });

      this.product = { ...product.data, path: path, published: this.isSchoolValidated };

      this.isLoadingTab = false;
      this.disabledStepPrecification = false;
      setTimeout(() => {
        this.tab = 1;
      }, 500);
    },
    offerPage(defaultDomain, productSlug) {
      return ProductUrlHelper.generateUrlProduct(defaultDomain, productSlug);
    },
    checkAllowedProducts() {
      const products = AvailableProducts();
      this.availableProducts = !this.$FCL.canCreateComboProduct()
        ? products.filter(product => product.type !== 'combo')
        : products;
      this.availableProducts = !this.$FCL.canLiveMeet()
        ? this.availableProducts.filter(product => product.type !== 'live')
        : this.availableProducts.filter(product => product.type !== 'webinar');
    },
    liveTypeProducts() {
      const products = AvailableProducts();
      this.availableProducts = products.filter(product => product.type == 'live');
      this.availableProducts = products[0].children;
      this.liveSelection = true;
    },
    backToTypes() {
      this.liveSelection = false;
      this.checkAllowedProducts();
    },
  },
  created() {
    if (this.$route.params?.is_cloning) {
      this.is_cloning = true;
      this.clone_data = this.$route.params.product_clone;
      this.typeSelected(this.$route.params?.product_clone.course.kind);
    } else if (this.$route.query?.type) {
      if (this.$route.query.type === 'lives') {
        this.liveTypeProducts();
      } else {
        this.typeSelected(this.$route.query.type);
      }
    } else {
      this.checkAllowedProducts();
    }
    this.isLoading = false;
  },
};
</script>
<style lang="scss" scoped>
/deep/ {
  .ml-custom {
    padding-left: 2.75rem;
    .tabs {
      .tabs-list {
        padding-right: 2px;
      }
    }
    @media screen and (max-width: $screen-md) {
      padding-left: 0;
    }
  }
  @media only screen and (min-width: $screen-lg) {
    .tabs.row.no-gutters {
      margin-right: -15px;
    }
    .no-gutters > .col,
    .no-gutters > [class*='col-'] {
      padding-right: 15px;
    }
  }
}
@media only screen and (max-width: $screen-sm) {
  /deep/ .product-creator-modal {
    .modal-dialog {
      margin: 0;
      width: 100%;
      max-width: inherit;
    }
    .modal-dialog-centered {
      align-items: flex-end;
      height: 100vh;
    }
    .modal-content {
      border-radius: 0;
      border: 0;
    }
  }
}
</style>
