import httpClient from '@/sparkaffiliates/services/http_client.js';

export default {
  createProduct(payload) {
    return httpClient.post(`producer_api/v1/program_products`, payload);
  },
  getProgramProducts(programId) {
    return httpClient.get(`producer_api/v1/programs/${programId}/program_products`);
  },
  getAffiliateProgramProducts(programId) {
    return httpClient.get(`affiliate_api/v1/programs/${programId}/program_products`);
  },
  updateProgramProductsInBatch(programId, payload) {
    return httpClient.put(`producer_api/v1/programs/${programId}/program_products/batch`, {
      program_products: payload,
    });
  },
};
