<template>
  <div class="thanks-page-config">
    <div class="thanks-page-config__header">
      <h3 class="thanks-page-config__title">
        {{ $t('after-purchase.thanks-page.title') }}
      </h3>

      <p class="thanks-page-config__description">
        {{ $t('after-purchase.thanks-page.description') }}
      </p>
    </div>
    <hs-group>
      <label for="thanks_page_bankslip">
        {{ $t('after-purchase.thanks-page.bankslip') }}
      </label>
      <div
        data-testid="bank-slip-field"
        class="thanks-page-config__field"
        :class="{
          'thanks-page-config__field-error': $v.thanksPageBankslip.$dirty && $v.thanksPageBankslip.$invalid,
        }"
      >
        <div class="input-field">
          <hs-input
            name="thanks_page_bankslip"
            id="thanks_page_bankslip"
            type="text"
            v-model="thanksPageBankslip"
            @blur="$v.thanksPageBankslip.$touch()"
            :placeholder="$t('after-purchase.thanks-page.bankslip-placeholder')"
          />
          <span v-if="$v.thanksPageBankslip.$dirty && $v.thanksPageBankslip.$invalid" class="input-field-message">
            <hs-icon variant="regular" icon="info-circle" />
            {{ $t('after-purchase.thanks-page.thanks-page-input-error') }}
          </span>
        </div>
        <hs-button
          data-testid="bank-slip-preview-button"
          class="thanks-page-config__preview-button"
          variant="outline-primary"
          :disabled="$v.thanksPageBankslip.$invalid || !thanksPageBankslip"
          @click="onShowThanksPagePreview(thanksPageBankslip)"
        >
          <hs-icon variant="regular" icon="eye" />
          <span class="desktop-only">
            Pré-visualização
          </span>
        </hs-button>
      </div>
    </hs-group>
    <hs-group>
      <label for="thanks_page_credit_card">
        {{ $t('after-purchase.thanks-page.credit-card') }}
      </label>
      <div
        data-testid="credit-card-field"
        class="thanks-page-config__field"
        :class="{
          'thanks-page-config__field-error': $v.thanksPageCreditCard.$dirty && $v.thanksPageCreditCard.$invalid,
        }"
      >
        <div class="input-field">
          <hs-input
            name="thanks_page_credit_card"
            id="thanks_page_credit_card"
            type="text"
            v-model="thanksPageCreditCard"
            @blur="$v.thanksPageCreditCard.$touch()"
            :placeholder="$t('after-purchase.thanks-page.credit-card-placeholder')"
          />
          <span v-if="$v.thanksPageCreditCard.$dirty && $v.thanksPageCreditCard.$invalid" class="input-field-message">
            <hs-icon variant="regular" icon="info-circle" />
            {{ $t('after-purchase.thanks-page.thanks-page-input-error') }}
          </span>
        </div>
        <hs-button
          variant="outline-primary"
          class="thanks-page-config__preview-button"
          :disabled="$v.thanksPageCreditCard.$invalid || !thanksPageCreditCard"
          @click="onShowThanksPagePreview(thanksPageCreditCard)"
        >
          <hs-icon variant="regular" icon="eye" />
          <span class="desktop-only">
            Pré-visualização
          </span>
        </hs-button>
      </div>
    </hs-group>
    <hs-group>
      <label for="thanks_page_pix">
        {{ $t('after-purchase.thanks-page.pix') }}
      </label>
      <div
        data-testid="pix-field"
        class="thanks-page-config__field"
        :class="{ 'thanks-page-config__field-error': $v.thanksPagePix.$dirty && $v.thanksPagePix.$invalid }"
      >
        <div class="input-field">
          <hs-input
            name="thanks_page_pix"
            id="thanks_page_pix"
            type="text"
            v-model="thanksPagePix"
            @blur="$v.thanksPagePix.$touch()"
            :placeholder="$t('after-purchase.thanks-page.pix-placeholder')"
          />
          <span v-if="$v.thanksPagePix.$dirty && $v.thanksPagePix.$invalid" class="input-field-message">
            <hs-icon variant="regular" icon="info-circle" />
            {{ $t('after-purchase.thanks-page.thanks-page-input-error') }}
          </span>
        </div>
        <hs-button
          variant="outline-primary"
          class="thanks-page-config__preview-button"
          :disabled="$v.thanksPagePix.$invalid || !thanksPagePix"
          @click="onShowThanksPagePreview(thanksPagePix)"
        >
          <hs-icon variant="regular" icon="eye" />
          <span class="desktop-only">
            Pré-visualização
          </span>
        </hs-button>
      </div>
    </hs-group>

    <div class="thanks-page-config__hints">
      {{ $t('after-purchase.thanks-page.hint') }}
    </div>

    <div
      class="thanks-page-config__buttons-wrapper"
      :class="{ 'thanks-page-config__buttons-wrapper--flex-end': !hasThankYouUrls }"
    >
      <MButton
        v-if="hasThankYouUrls"
        variant="critical-outline"
        icon="trash-alt"
        :label="$t('after-purchase.delete')"
        class="thanks-page-config__exclude-button"
        @click="$bvModal.show('delete-thanks-page-modal')"
      />

      <div class="thanks-page-config__action-buttons">
        <MButton variant="primary-outline" @click="$emit('back')">
          {{ backButtonText }}
        </MButton>

        <MButton
          data-testid="save-button"
          variant="primary"
          class="thanks-page-config__save-button"
          :disabled="Boolean(isLoading || !isThanksPageURLsValid)"
          @click="saveThanksUrls()"
        >
          {{ $t('after-purchase.save') }}
        </MButton>
      </div>
    </div>

    <ThanksPagePreview
      v-if="showThanksPagePreview"
      data-testid="thanks-page-preview"
      :preview-url="thanksPagePreviewUrl"
      @close="onCloseThanksPagePreview"
    />

    <MConfirmModal
      id="delete-thanks-page-modal"
      variant="delete"
      :image="require('@/assets/images/red-trash.png')"
      :title="$t('after-purchase.thanks-page.delete-title')"
      :text="$t('after-purchase.thanks-page.delete-text')"
      :confirm-button-label="$t('after-purchase.thanks-page.modal-confirm-button')"
      :cancel-button-label="$t('after-purchase.thanks-page.modal-cancel-button')"
      @confirm="deleteThanksPage('delete-thanks-page-modal')"
      @cancel="$bvModal.hide('delete-thanks-page-modal')"
    />
  </div>
</template>

<script>
import { url } from 'vuelidate/lib/validators';
import { ThanksPageTypeEnum } from '@/types/offers';
import ThanksPagePreview from './ThanksPagePreview.vue';
import UpsellConfigMixin from '@/sparkmembers/views/ProductSetup/mixins/UpsellConfigMixin.js';
import ToastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';

export default {
  name: 'ThanksPageConfig',
  mixins: [UpsellConfigMixin],
  components: {
    ThanksPagePreview,
  },
  data() {
    return {
      thanksPageBankslip: '',
      thanksPageCreditCard: '',
      thanksPagePix: '',
      thanksPagePreviewUrl: '',
      showThanksPagePreview: false,
      displayCancelButton: false,
    };
  },
  methods: {
    onShowThanksPagePreview(url) {
      if (url) {
        this.thanksPagePreviewUrl = url;
        this.showThanksPagePreview = true;
      }
    },
    onCloseThanksPagePreview() {
      this.showThanksPagePreview = false;
      this.thanksPagePreviewUrl = '';
    },
    async saveThanksUrls() {
      try {
        this.isLoading = true;

        const thank_you_urls = [
          {
            url_type: ThanksPageTypeEnum.BANK_SLIP,
            url: this.thanksPageBankslip,
          },
          {
            url_type: ThanksPageTypeEnum.CREDIT_CARD,
            url: this.thanksPageCreditCard,
          },
          {
            url_type: ThanksPageTypeEnum.PIX,
            url: this.thanksPagePix,
          },
        ];

        const payload = {
          ...this.offer,
          price: GeneralHelper.valueInCents(this.offer.price),
          base_price: GeneralHelper.valueInCents(this.offer.base_price),
          thank_you_urls,
        };

        const { data } = await SparkEcommerceApiClient.offers.update(payload);

        const formattedOfferData = SparkEcommerceApiClient.offers.translateOfferToPaymentOption(data);

        this.getProductPaymentOption({
          ...formattedOfferData,
          price: formattedOfferData.price / 100,
          base_price: formattedOfferData.base_price / 100,
        });

        ToastHelper.successMessage(this.$t('sparkmembers.offers.edit.success'));

        this.$emit('saved');
      } catch {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.error'));
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    isBankSlipValid() {
      return !this.$v.thanksPageBankslip.$invalid || this.thanksPageBankslip === '';
    },
    isCreditCardValid() {
      return !this.$v.thanksPageCreditCard.$invalid || this.thanksPageCreditCard === '';
    },
    isPixValid() {
      return !this.$v.thanksPagePix.$invalid || this.thanksPagePix === '';
    },
    isThanksPageURLsValid() {
      return this.isBankSlipValid && this.isCreditCardValid && this.isPixValid;
    },
    backButtonText() {
      return this.displayCancelButton ? this.$t('after-purchase.cancel') : this.$t('after-purchase.back-to-offers');
    },
  },
  validations: {
    thanksPageBankslip: { url },
    thanksPageCreditCard: { url },
    thanksPagePix: { url },
  },
  mounted() {
    this.displayCancelButton = true;

    if (this.hasThankYouUrls) {
      this.offer.thank_you_urls.forEach(({ url_type, url }) => {
        if (url_type === ThanksPageTypeEnum.BANK_SLIP) {
          this.thanksPageBankslip = url || '';
        } else if (url_type === ThanksPageTypeEnum.CREDIT_CARD) {
          this.thanksPageCreditCard = url || '';
        } else {
          this.thanksPagePix = url || '';
        }
      });

      this.displayCancelButton = false;
    }
  },
};
</script>

<style lang="scss">
.thanks-page-config__header {
  margin-bottom: 32px;
}

.thanks-page-config__title {
  font-size: 1.25rem;
  line-height: 1.563rem;
  color: $grey;
  font-weight: 700;
}

.thanks-page-config__description {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey-40;
  margin-top: 8px;
  margin-bottom: 0;
}

.thanks-page-config__field {
  display: flex;

  .input-field {
    flex-grow: 1;
  }

  .btn-outline-primary,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-primary.dropdown-toggle {
    border-color: #7427f1;
    border-radius: 4px;
    color: #7427f1;
    font-size: 14px;
    height: 35px;
    margin-left: 18px;
  }
}

.thanks-page-config__field-error {
  .input-field-message {
    color: #db3939;
    font-size: 12px;
  }

  /deep/ .hs-input input {
    border: 2px solid #db3939;
  }
}

.thanks-page-config__hints {
  background-color: $grey-16;
  padding: 20px 16px;
  border-radius: 4px;
  color: $grey-40;
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin-top: 32px;
}

.thanks-page-config__buttons-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  row-gap: 12px;

  @media (min-width: $screen-md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.thanks-page-config__buttons-wrapper--flex-end {
  @media (min-width: $screen-md) {
    justify-content: flex-end;
  }
}

.thanks-page-config__exclude-button {
  order: 2;

  @media (min-width: $screen-md) {
    order: 1;
  }
}

.thanks-page-config__action-buttons {
  display: grid;
  grid-template-columns: 193px 1fr;
  column-gap: 20px;
  order: 1;

  @media (min-width: $screen-md) {
    order: 2;
  }
}

.thanks-page-config__save-button {
  width: 100%;
}

.desktop-only {
  display: none;

  @media (min-width: $screen-md) {
    display: block;
  }
}

.thanks-page-config__preview-button {
  display: flex;
  align-items: center;
  column-gap: 4px;
}
</style>
