<template>
  <div>
    <label class="upsell-config-step-one__url-input-label">
      URL da página do upsell
    </label>
    <hs-input
      v-model="url"
      text="Essa é a página que o cliente vai ser direcionado ao realizar a compra no checkout."
      placeholder="https://"
      @blur="$v.url.$touch()"
    />
    <span v-if="$v.url.$dirty && $v.url.$invalid" class="input-field-message">
      <hs-icon variant="regular" icon="info-circle" />
      {{ $t('after-purchase.thanks-page.thanks-page-input-error') }}
    </span>

    <h4 class="upsell-config-step-one__offer-section-title">
      Qual produto oferecer na página de Upsell?
    </h4>
    <div class="upsell-config-step-one__offer-input-wrapper">
      <span>
        Produto upsell para oferecer
      </span>
      <hs-multiselect
        v-model="selectedProduct"
        :options="productsOptions"
        track-by="id"
        label="title"
        placeholder="Selecione o produto"
      />
    </div>
    <div class="upsell-config-step-one__offer-input-wrapper">
      <span>Oferta</span>
      <hs-multiselect
        v-model="selectedOffer"
        :disabled="!selectedProduct"
        :options="offerSelectOptions"
        group-values="offers"
        group-label="kind"
        track-by="id"
        label="labelTitle"
        placeholder="Selecione a oferta"
      />
    </div>

    <div class="upsell-config-step-one__buttons-container">
      <MButton variant="primary-outline" @click="$emit('backTab')">
        {{ $t('sparkmembers.offers.tabs.form.btn-cancel') }}
      </MButton>
      <MButton variant="primary" @click="emitData" :disabled="!enableNext">
        {{ $t('after-purchase.next') }}
      </MButton>
    </div>
  </div>
</template>

<script>
import { url } from 'vuelidate/lib/validators';
import programsService from '@/sparkaffiliates/services/programs.js';
import { mapState } from 'vuex';
import MButton from '@/shared/components/MButton.vue';
import GeneralHelper from '@/shared/helpers/general';
import { OfferType } from '@/types/offers';

export default {
  name: 'ProductUpsellStepOne',
  components: {
    MButton,
  },
  props: {
    dataStepOne: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    async selectedProduct() {
      if (!this.selectedProduct?.id) return;

      try {
        const {
          data: { payment_options },
        } = await programsService.getProductPaymentOptions(this.selectedProduct.id);
        this.offers = payment_options.map(option => ({ ...option, labelTitle: this.formatLabel(option) }));
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    },
  },
  data() {
    return {
      selectedProduct: this.dataStepOne.product,
      selectedOffer: this.dataStepOne.offer,
      offers: [],
      url: this.dataStepOne.url,
      productsOptions: [],
    };
  },
  async mounted() {
    const { school_products } = await programsService.getUserProducts([{ key: 'published', value: true }]);
    const products = school_products.filter(product => product.id !== this.selectedProductId);
    this.productsOptions = products;

    if (this.upsell_flow_steps !== null) {
      this.url = this.upsell_flow_steps.url;
      const selectedOffer = this.upsell_flow_steps.steps[0].offer;
      const formattedOffer = { ...selectedOffer, labelTitle: this.formatLabel(selectedOffer) };
      (this.selectedProduct = this.upsell_flow_steps.steps[0].product), (this.selectedOffer = formattedOffer);
    }
  },
  methods: {
    emitData() {
      this.$emit('update', {
        product: this.selectedProduct,
        offer: this.selectedOffer,
        url: this.url,
      });
    },
    formatLabel(offer) {
      const price = (offer.price || offer.price_cents) / 100;
      return `${offer.title} (${GeneralHelper.currency(price)})`;
    },
  },
  computed: {
    ...mapState('product', {
      selectedProductId: state => state.selectedProduct.id,
      upsell_flow_steps: state => state.productPaymentOption?.upsell_flow_steps,
    }),
    enableNext() {
      const validURL = !this.$v.url.$invalid && this.url;
      return validURL && this.selectedProduct && this.selectedOffer;
    },
    offerSelectOptions() {
      const result = [];

      this.offers.forEach(obj => {
        let kindValue = obj.kind;
        if (kindValue === OfferType.COMMON) {
          kindValue = 'Única';
        } else if (kindValue === OfferType.SUBSCRIPTION) {
          kindValue = 'Assinatura';
        }

        const found = result.find(item => item.kind === kindValue);

        if (found) {
          found.offers.push(obj);
        } else {
          result.push({ kind: kindValue, offers: [obj] });
        }
      });

      return result;
    },
  },
  validations: {
    url: { url },
  },
};
</script>

<style lang="scss">
.upsell-config-step-one__url-input-label {
  margin-bottom: 8px;
}

.upsell-config-step-one__offer-section-title {
  margin-top: 32px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin-bottom: 0;
}

.upsell-config-step-one__offer-input-wrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.upsell-config-step-one__buttons-container {
  display: flex;
  justify-content: flex-end;
  column-gap: 21px;
  margin-top: 32px;
}
.hs-multiselect .multiselect__option--group {
  font-weight: bold;
}
</style>
