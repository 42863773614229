

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class InfoTabs extends Vue {
  @Prop({ default: 'automatic' }) activeTab: string;

  get hasEditorFlag() {
    return this.$FCL.canSparkSalesPageEditor();
  }

  tabs: Array<string> = ['automatic', 'custom'];

  selectTab(tab: string) {
    this.$emit('selectedTab', tab);
  }
}
