function clickInsidePopover(element) {
  return document.querySelector('.popover')?.contains(element);
}

export default {
  data() {
    return {
      openedPopover: null,
    };
  },
  methods: {
    installEventListener() {
      document.addEventListener('click', this.closePopovers);
    },
    removeEventListener() {
      document.removeEventListener('click', this.closePopovers);
    },
    closePopovers(e) {
      if (e) {
        const classes = e.target.classList;
        if (classes.contains('popover-trigger') || clickInsidePopover(e.target)) {
          return;
        }
      }

      this.closePopover(this.openedPopover);
    },
    openPopover(target) {
      if (target !== this.openedPopover) {
        let element = this.$refs[target];
        if (!element._isVue) {
          element = element[0];
        }

        element.$emit('open');
        this.closePopover(this.openedPopover);
        this.openedPopover = target;
      }
    },

    closePopover(target) {
      if (target) {
        let element = this.$refs[target];
        if (!element._isVue) {
          element = element[0];
        }
        element.$emit('close');
      }

      this.openedPopover = null;
    },
  },
};
