<template>
  <div>
    <MButton data-testid="modal-button" variant="primary" size="sm" class="button-copy-code" @click="showModalCopyCode">
      {{ $t('after-purchase.offer-upsell-config.copy-code') }}
    </MButton>

    <b-dropdown variant="transparent" class="dropdown-offer-config" no-caret right>
      <template v-slot:button-content>
        <MButton icon="ellipsis-v" class="icon-dropdown-offers" />
      </template>
      <div class="dropdown-upsell-config-options">
        <b-dropdown-item class="dropdown-upsell-config-itens" @click="$emit('edit')">
          <hs-icon variant="light" icon="pencil" />
          {{ $t('after-purchase.offer-upsell-config.edit') }}
        </b-dropdown-item>

        <b-dropdown-item class="dropdown-upsell-config-itens" @click="$emit('delete')">
          <hs-icon variant="light" icon="trash-alt" />
          {{ $t('after-purchase.offer-upsell-config.delete') }}
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  name: 'OfferUpsellConfigDropdown',
  components: {
    MButton,
  },
  methods: {
    showModalCopyCode() {
      this.$bvModal.show('modal-copy-code');
    },
  },
};
</script>

<style lang="scss">
/deep/ .dropdown-toggle {
  &.btn {
    padding: 0;
  }
  &.btn:focus {
    box-shadow: none;
    outline: unset;
  }
  &[aria-expanded='true'] {
    .icon-dropdown-offers {
      border: 2px solid #7427f1;
    }
  }
}
/deep/ .dropdown-item {
  justify-content: center;
  align-items: center;
}
/deep/ .dropdown-menu {
  padding: 0;

  @media (max-width: 641px) {
    display: inline-block;
  }
}
.button-copy-code {
  padding: 2px;
  width: 145px;
  height: 29px;
}
.icon-dropdown-offer-config-config {
  padding: 5px;
  font-size: 25px;
  border-radius: 4px;
  width: 38px;
  height: 39px;

  &:hover {
    border: 2px solid #7427f1;
  }
}
.dropdown-offer-config {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
}
.text-dropdown-offer-config-edit {
  color: #171717;
}
.text-dropdown-offer-config-delete {
  color: #db3939;

  &:hover {
    color: #db3939;
  }
}

.dropdown-upsell-config-itens {
  display: flex;
  column-gap: 4px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid transparent;

  &:hover {
    background: #ead3fe !important;
    border: 1px solid #7427f1;
  }
}

.dropdown-upsell-config-options {
  padding: 10px 12px;
}

.icon-dropdown-offers {
  font-size: 25px;
  border-radius: 4px;
  width: 38px;
  height: 39px;

  &:hover {
    border: 2px solid #7427f1;
  }
}
</style>
