<template>
  <div class="tlw-w-full tlw-bg-white tlw-p-4 lg:tlw-p-8 tlw-mb-5">
    <div class="tlw-flex tlw-flex-col">
      <h4 class="tlw-font-bold tlw-text-xl">{{ $t('sparkmembers.setup.sales-page.seo.column.title') }}</h4>
      <p class="tlw-font-sans font-size-sm tlw-mt-2 tlw-mb-0">
        {{ $t('sparkmembers.setup.sales-page.seo.column.text') }}
        <a
          class="text-purple"
          href="https://hero-spark.zendesk.com/hc/pt-br/articles/4408207232141-Otimizando-minha-p%C3%A1gina-de-vendas-para-os-buscadores-SEO-"
          target="_blank"
        >
          <u>{{ $t('sparkmembers.setup.sales-page.seo.column.link') }}</u>
        </a>
      </p>
    </div>
    <div class="tlw-w-full tlw-mt-6">
      <b-form @submit.prevent="onSubmit" @change="identifyDataSave">
        <hs-group>
          <label class="tlw-font-bold tlw-m-0">{{ $t('sparkmembers.sales-page.seo.form.title') }}</label>
          <hs-input type="text" v-model="form.meta_title" :disabled="isSaving" />
        </hs-group>
        <hs-group>
          <label class="tlw-font-bold tlw-m-0">{{ $t('sparkmembers.sales-page.seo.form.keys') }}</label>
          <hs-input type="text" text="Separe por vírgulas" v-model="form.meta_keys" :disabled="isSaving" />
        </hs-group>
        <hs-group>
          <label class="tlw-font-bold tlw-m-0">{{ $t('sparkmembers.sales-page.seo.form.description') }}</label>
          <b-form-textarea no-resize rows="6" max-rows="6" v-model="form.meta_description" :disabled="isSaving" />
        </hs-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  validations: {
    form: {
      subtitle: {
        required,
      },
      description: {
        required,
      },
    },
  },
  methods: {
    identifyDataSave() {
      this.$emit('autoSaveData');
    },
  },
  watch: {
    form(val) {
      this.form = val;
    },
  },
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
  },
};
</script>
