<template>
  <div class="tlw-w-full tlw-bg-white tlw-p-4 lg:tlw-p-8 tlw-mb-2" v-if="showVideoUpload">
    <div id="video-upload-container">
      <div class="tlw-flex tlw-flex-wrap tlw-justify-between tlw-items-baseline">
        <h4 class="tlw-w-3/4 tlw-font-bold tlw-text-xl tlw-mb-1">
          {{ $t('sparkmembers.sales-page.info.form.cover.video.title') }}
        </h4>
        <m-button
          class="tlw-text-sm tlw-text-purple-500 p-0 tlw-cursor-pointer"
          variant="link"
          @click="() => $bvModal.show('preview-modal-video')"
        >
          <hs-icon icon="eye" class="tlw-mr-1" />
          <span class="tlw-font-normal">
            {{ $t('sparkmembers.setup.sales-page.info.form.basic.label-modal-show') }}
          </span>
        </m-button>
        <p class="tlw-font-sans font-size-sm">{{ $t('sparkmembers.sales-page.info.form.cover.video.subtitle') }}</p>
      </div>
      <template v-if="loadingMedia">
        <hsLoading />
      </template>
      <template v-else>
        <template v-if="galleryVideos && galleryVideos.id">
          <b-embed
            allowfullscreen
            type="iframe"
            aspect="16by9"
            :src="onVideoPreview(galleryVideos.media.host, galleryVideos.media.host_key)"
          />
        </template>
        <div class="d-flex mt-3" v-else>
          <hs-button-circle
            class="video-button mr-2"
            icon="play-circle"
            title="YouTube"
            variant="primary"
            @click="
              () => {
                videoPresentation = 'youtube';
                $bvModal.show('medial-modal');
              }
            "
          />
          <hs-button-circle
            class="video-button"
            icon="vimeo-v"
            iconVariant="brand"
            title="Vimeo"
            variant="primary"
            @click="
              () => {
                videoPresentation = 'vimeo';
                $bvModal.show('medial-modal');
              }
            "
          />
        </div>
        <div class="w-100 text-right mt-4">
          <hs-button v-if="galleryVideos && galleryVideos.id" @click="onDelete('delete-video-modal')">
            {{ $t('actions.remove') }}
          </hs-button>
        </div>
      </template>
    </div>
    <hsConfirmModal
      id="delete-video-modal"
      icon="trash-alt"
      variant="cherry"
      hide-header
      :customTitle="$t('sparkmembers.sales-page.info.alert.video.title')"
      :description="$t('sparkmembers.sales-page.info.alert.video.description')"
      :ok-title="$t('sparkmembers.sales-page.info.alert.video.confirm')"
      :cancel-title="$t('sparkmembers.sales-page.info.alert.video.cancel')"
      @ok="onDeleteGallery"
    />
    <ModalMedia :media="videoPresentation" @input="onCreateGallery" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { analyticsService, productService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import VideoPreviewHelper from '@/sparkmembers/helpers/videoPreviewHelper';
import Confirm from '@/shared/mixins/Confirm';
import MButton from '@/shared/components/MButton';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  mixins: [Confirm],
  components: {
    hsLoading: () => import('@/components/Loading'),
    hsConfirmModal: () => import('@/components/ConfirmModal'),
    ModalMedia: () => import('../../ModalMedia'),
    MButton,
  },
  data() {
    return {
      teachers: [],
      galleryVideos: {},
      loadingMedia: false,
      videoPresentation: '',
    };
  },
  validations: {
    form: {
      subtitle: {
        required,
      },
      description: {
        required,
      },
    },
  },
  watch: {
    form(val) {
      this.form = val;
    },
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    ...mapState('course', {
      course: state => state.selectedCourse,
    }),
    showVideoUpload() {
      return this.course && this.course.kind === 'online_course';
    },
  },
  methods: {
    async onSubmit(callback) {
      if (this.$v.form.$invalid) return;
      this.isSaving = true;

      return productService
        .update(this.form)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.product.success'));
          analyticsService.track({
            event: 'Product sales page updated',
            props: { product_id: this.form.id },
          });
          analyticsService.track({
            event: 'Product SEO info updated',
            props: { product_id: this.form.id },
          });
        })
        .catch(e => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.product.error'));
          throw e;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async onDeleteGallery() {
      this.loadingMedia = true;
      productService
        .deleteProductGallery(this.galleryVideos.id)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.video.success'));
          this.onSubmit();
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.video.error'));
        })
        .finally(() => {
          this.loadingMedia = false;
          this.form.gallery_media[0] = {};
          this.galleryVideos = {};
        });
    },
    async onCreateGallery(media) {
      this.loadingMedia = true;

      productService
        .createProductGallery({
          media_id: media.id,
          school_product_id: this.form.id,
          gallery_tag_ids: 14,
        })
        .then(gallery => {
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.video.success'));
          this.onSubmit().then(() => {
            this.galleryVideos = {
              id: gallery.data.id,
              gallery_tags: [{ name: 'video_cover' }],
              media,
            };
          });
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.video.error'));
        })
        .finally(() => {
          this.loadingMedia = false;
        });
    },
    onVideoPreview(provider, videoId) {
      return VideoPreviewHelper.urlEmbed(provider, videoId);
    },
    onDelete(modalId) {
      this.$bvModal.show(modalId);
    },
  },
  mounted() {
    this.form.class_teacher_ids = this.form.class_teacher_ids || [];
    this.galleryVideos = this.form.gallery_media.filter(
      gallery => gallery.media.type === 'Video' && gallery.gallery_tags.find(({ name }) => name === 'video_cover')
    )[0];
  },
};
</script>

<style lang="scss" scoped>
/deep/ .video-button {
  .btn-circle-icon {
    background-color: #bcfbff;
    color: #00e4f5;
  }
}
</style>
