const PIX_MINIMUM_PRICE = 1.99;
const CREDIT_CARD_MINIMUM_PRICE = 1.99;
const BANK_SLIP_MINIMUM_PRICE = 5;
const MULTICARD_MINIMUM_PRICE = 100;

export default translate => [
  {
    text: translate('payment-method-select.pix.title'),
    value: 'pix',
    minimumPrice: PIX_MINIMUM_PRICE,
  },
  {
    text: translate('payment-method-select.bank-slip.title'),
    helper: translate('payment-method-select.bank-slip.helper'),
    value: 'bank_slip',
    minimumPrice: BANK_SLIP_MINIMUM_PRICE,
  },
  {
    text: translate('payment-method-select.credit-card.title'),
    helper: translate('payment-method-select.credit-card.helper'),
    value: 'credit_card',
    minimumPrice: CREDIT_CARD_MINIMUM_PRICE,
  },
  {
    text: translate('payment-method-select.multicard.title'),
    value: 'multicard',
    helper: translate('payment-method-select.multicard.helper'),
    minimumPrice: MULTICARD_MINIMUM_PRICE,
  },
];
