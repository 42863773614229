<template>
  <div class="select-installments">
    <div v-if="label" data-testid="label" class="select-installments__title">
      {{ label }}
    </div>

    <div
      :class="{ 'select-installments__container--margin-top': label }"
      data-testid="installments-container"
      class="select-installments__container"
    >
      <span class="select-installments__label">
        {{ $t('payment-method-select.installment-label') }}
      </span>

      <div class="select-installments__badges-wrapper">
        <div
          v-for="(installmentItem, index) in badgeItems"
          :key="index"
          :class="
            `select-installments__badge
            select-installments__badge--${installmentItem.variant}`
          "
          pill
          data-testid="badge-item"
          @click="emitSelectedInstallment(installmentItem)"
        >
          {{ installmentItem.text }}
        </div>
      </div>
    </div>

    <div v-if="paymentSimulation.length" data-testid="payment-simulation" class="select-installments__value">
      {{ selectedInstallment }}x de <span class="green-text">{{ selectedInstallmentPrice }}</span>
    </div>
  </div>
</template>

<script>
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'SelectInstallments',
  props: {
    label: {
      type: String,
      default: '',
    },
    badgeItems: {
      type: Array,
      default: () => [],
    },
    paymentSimulation: {
      type: Array,
      default: () => [],
    },
    selectedInstallment: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    selectedInstallmentPrice() {
      const installment = this.paymentSimulation[this.selectedInstallment - 1];
      const installmentPriceInCents = installment.installment_amount_cents;

      return this.formatCentsToCurrency(installmentPriceInCents);
    },
  },
  methods: {
    emitSelectedInstallment(installmentItem) {
      this.$emit('select-installment', installmentItem);
    },
    formatCentsToCurrency(valueInCents) {
      const value = valueInCents / 100;

      return value ? GeneralHelper.currency(value) : 'R$ 0,00';
    },
  },
};
</script>

<style lang="scss" scoped>
.select-installments {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.select-installments__title {
  color: $grey;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.select-installments__container {
  display: grid;
  grid-template-columns: 50px auto;
}

.select-installments__container--margin-top {
  margin-top: 16px;
}

.select-installments__label {
  color: $grey;
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 400;
}

.select-installments__badges-wrapper {
  display: flex;
  gap: 10px;
  margin-left: 8px;
  width: 100%;
  flex-wrap: wrap;
}

.select-installments__badge {
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 600;
  padding: 4px 12px;
  cursor: pointer;
}

.select-installments__badge--primary {
  color: #fff;
  background-color: #7427f1;
}

.select-installments__badge--disabled {
  color: #262626;
  background-color: #bababa;
}

.select-installments__value {
  color: $grey;
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 400;
  margin-top: 8px;

  .green-text {
    color: #348535;
  }
}
</style>
