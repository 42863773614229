<template>
  <div class="product-card" :active="active" @click="onCardClick(product, $event)">
    <div
      role="button"
      tabindex="0"
      aria-label="Abrir sidebar de imagens"
      @click="openImageSidebar(product, $event)"
      @keydown.enter="openImageSidebar(product, $event)"
      @keydown.space="openImageSidebar(product, $event)"
      class="product-card__image"
      :style="cardBackgroundStyle"
      data-testid="product-card-image"
    >
      <div class="product-card__image-overlay">
        <div class="rounded-circle bg-white p-2">
          <Icon
            name="pencil"
            prefix="fal"
            class="d-flex align-items-center justify-content-center tlw-text-neutral-500"
          />
        </div>
      </div>
    </div>

    <div class="product-card__content">
      <div class="product-card__content__info">
        <div>
          <hs-badge v-if="displayTypePill" pill class="mr-1" :variant="getBadgeVariant(productType)">
            {{ $t(`sparkmembers.v2-product-list.${productType}`) }}
          </hs-badge>
          <hs-badge v-if="product.published" id="badge-published" pill class="mr-1 custom-badge">
            {{ $t('sparkmembers.v2-product-list.card.published') }}
          </hs-badge>
          <hs-badge v-else pill id="badge-not-published" class="mr-1">
            {{ $t('sparkmembers.v2-product-list.card.not-published') }}
          </hs-badge>
        </div>

        <h4 class="product-card__content__info-title">{{ product.title }}</h4>

        <div class="product-card__content__info-links">
          <slot name="offer-popover" />
        </div>
      </div>

      <div class="product-card__content__right">
        <div class="tlw-flex lg:tlw-justify-end lg:tlw-w-48 lg:tlw-max-w-xs tlw-justify-start">
          <div class="description-icon tlw-mr-2 tlw-flex">
            <hs-icon class="tlw-text-xs tlw-mt-1" icon="users" />
          </div>
          <span class="product-members-count tlw-text-sm">{{ membersCountText }}</span>
        </div>

        <slot name="right" />
      </div>

      <hs-icon v-if="hasAction" icon="chevron-right" class="product-card__content__chevron-right" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
      required: true,
    },
    product: {
      type: Object,
      default: () => {},
      required: true,
    },
    hasAction: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: () => ({
    courseCardDefaultImg: require(`@/assets/images/SparkMembers/ProductsList/course-card-default.svg`),
  }),
  computed: {
    productType() {
      return this.product.course ? this.product.course.kind : 'path';
    },
    displayTypePill() {
      return this.product.path || this.product.course;
    },
    membersCountText() {
      const qtdMembers = this.product.count_total_members_number;
      const text = this.$tc('sparkmembers.v2-product-list.sidebar.registered-members', qtdMembers);

      return `${qtdMembers} ${text}`;
    },
    cardBackgroundStyle() {
      return {
        backgroundImage: `url(${this.product.logo || this.courseCardDefaultImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
    },
  },
  methods: {
    openImageSidebar(product, event) {
      event.stopPropagation();
      this.$emit('openImageSidebar', product);
    },
    getBadgeVariant(status) {
      const types = ['quiz', 'webinar', 'ebook', 'file', 'online_course', 'path', 'live_meet'];
      const variants = [
        'outline-purple',
        'outline-danger',
        'outline-primary',
        'outline-primary',
        'outline-info',
        'outline-purple',
        'outline-purple',
      ];
      return variants[types.indexOf(status)];
    },
    onCardClick(product, e) {
      e.stopPropagation();
      if (e && e.target.classList.contains('popover-trigger')) {
        return;
      }
      this.isProductLinksOpen = false;
      this.$emit('selected', product);
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-body {
  padding: 0;
}

.product-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 1.5rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.5s;

  &[active],
  &:hover {
    box-shadow: 0 0 0 2px var(--color-purple-500);
  }

  @media only screen and (min-width: $screen-bs-md) {
    flex-direction: row;
  }

  &__image {
    border-radius: 5px;
    width: 100%;
    height: 79px;
    position: relative;

    @media only screen and (min-width: $screen-bs-md) {
      width: 140px;
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.2s ease;
      border-radius: 5px;
      cursor: pointer;
    }

    &-icon {
      color: white;
      font-size: 1.2rem;
    }

    &:hover {
      .product-card__image-overlay {
        opacity: 1;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    padding-left: 0;

    @media only screen and (min-width: $screen-bs-md) {
      flex-direction: row;
      align-items: center;
      padding-left: 1rem;
    }

    &__info {
      flex: 1;
      margin: 1rem 0;

      @media only screen and (min-width: $screen-bs-md) {
        margin: 0;
      }

      &-title {
        word-break: all;
        font-size: 1rem;
        font-weight: bold;
        margin: 8px 0 1rem;

        @media only screen and (min-width: $screen-bs-md) {
          font-size: 1.1rem;
          margin: 8px 0;
        }
      }

      &-links {
        display: flex;
        align-items: center;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media only screen and (min-width: $screen-bs-md) {
        align-items: flex-end;
      }
    }

    &__chevron-right {
      display: none;

      @media only screen and (min-width: $screen-bs-md) {
        display: block;
        margin-left: 1rem;
      }
    }
  }

  .custom-badge {
    background-color: #269353;
  }
}
</style>
