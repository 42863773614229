






































import debug from 'debug';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AvailablePaymentOptions from '@/data/payment_methods';
import MSwitch from '@/shared/components/MSwitch.vue';
import SelectInstallments from '@/components/SelectInstallments.vue';
import { PaymentMethodsList } from '@/types/payments';
import PaymentService from '@/services/sparkecommerce/payments.js';
import { range, debounce } from 'lodash';

const logging = debug('hs:payment-method-select');

const INITIAL_SELECTED_INSTALLMENT = 12;

const PERIOD_INSTALLMENTS_MAP = {
  weekly: 1,
  monthly: 1,
  bimonthly: 2,
  quarterly: 3,
  semiannual: 6,
  annual: 12,
};

const INSTALLMENTS_VALID_PERIODS = ['bimonthly', 'quarterly', 'semiannual', 'annual'];

@Component({
  components: {
    MSwitch,
    BannerPaymentHelper: () => import('@/components/BannerPaymentHelper.vue'),
    SelectInstallments,
  },
})
export default class PaymentMethodSelect extends Vue {
  @Prop({ default: 'common' }) offerKind: string;
  @Prop({ default: '' }) periodicity: string;
  @Prop({ required: true }) offerPrice: number;
  @Prop({ default: INITIAL_SELECTED_INSTALLMENT }) initialSelectedInstallment: number;
  @Prop({
    default: () => [
      PaymentMethodsList.CREDIT_CARD,
      PaymentMethodsList.BANK_SLIP,
      PaymentMethodsList.PIX,
      PaymentMethodsList.MULTICARD,
    ],
  })
  initialEnabledPaymentMethodsList: string[];

  paymentOptions: any[] = AvailablePaymentOptions(key => this.$t(key));
  paymentMethodEnabledStatus: object = {
    [PaymentMethodsList.CREDIT_CARD]: true,
    [PaymentMethodsList.BANK_SLIP]: true,
    [PaymentMethodsList.PIX]: true,
    [PaymentMethodsList.MULTICARD]: true,
  };

  paymentSimulation: any[] = [];
  maxInstallments: number = 1;
  selectedInstallment: number = 1;

  syncPaymentMethodEnabledStatus() {
    const initialEnabled = this.initialEnabledPaymentMethodsList;

    const checkStatusMap: object = {
      [PaymentMethodsList.PIX]: this.checkPix,
      [PaymentMethodsList.BANK_SLIP]: this.checkBankSlip,
      [PaymentMethodsList.MULTICARD]: this.checkMulticard,
      [PaymentMethodsList.CREDIT_CARD]: true,
    };

    Object.entries(this.paymentMethodEnabledStatus).forEach(([paymentMethod, _status]) => {
      this.paymentMethodEnabledStatus[paymentMethod] =
        initialEnabled.includes(paymentMethod) && checkStatusMap[paymentMethod];
    });
  }

  fetchPaymentSimulation() {
    if (!this.offerPrice) return;

    PaymentService.payment_simulation({ amount: this.offerPrice })
      .then(simulation => {
        this.paymentSimulation = simulation;
        this.maxInstallments = this.isSubscription
          ? Math.min(this.paymentSimulation.length, PERIOD_INSTALLMENTS_MAP[this.periodicity])
          : this.paymentSimulation.length;

        this.selectedInstallment = Math.min(this.selectedInstallment, this.maxInstallments);
      })
      .catch(error => logging(error));
  }

  onSelectInstallment(installmentItem) {
    this.selectedInstallment = installmentItem.installments;
  }

  paymentMethodBadgeText(paymentMethod) {
    if (paymentMethod === PaymentMethodsList.MULTICARD) return 'Novo';

    return;
  }

  displayPaymentOption(paymentMethod) {
    if (paymentMethod === PaymentMethodsList.PIX) return this.checkPix;
    if (paymentMethod === PaymentMethodsList.MULTICARD) return this.checkMulticard;
    if (paymentMethod === PaymentMethodsList.BANK_SLIP) return this.checkBankSlip;

    return true;
  }

  get checkPix() {
    const hasPixSubscription = this.isSubscription && this.hasPixSubscriptionFeature;
    return this.isCommonOffer || hasPixSubscription;
  }

  get checkBankSlip() {
    const hasBankSlipSubscription = this.isSubscription && this.hasBankSlipSubscriptionFeature;

    return this.isCommonOffer || hasBankSlipSubscription;
  }

  get checkMulticard() {
    return !this.isSubscription && this.hasMulticardPaymentOptionFeature;
  }

  get displayBanner() {
    const enabledList = Object.entries(this.paymentMethodEnabledStatus).filter(([key, value]) => {
      if (this.filteredListByMinimumPrice.includes(key) && value) return this.paymentMethodEnabledStatus[key];
    }).length;

    const totalItems = Object.keys(this.paymentMethodEnabledStatus).filter(paymentMethod => {
      const paymentOptionItem = this.paymentOptions.find(option => option.value === paymentMethod);

      return this.offerPrice >= paymentOptionItem.minimumPrice;
    }).length;

    const disabled = totalItems - enabledList;

    return disabled > 0 && !this.isSubscription;
  }

  get filteredListByMinimumPrice() {
    return Object.keys(this.paymentMethodEnabledStatus).filter(paymentMethod => {
      const paymentOptionItem = this.paymentOptions.find(option => option.value === paymentMethod);

      return this.paymentMethodEnabledStatus[paymentMethod] && this.offerPrice >= paymentOptionItem.minimumPrice;
    });
  }

  get formattedInstallmentsBadgeItems() {
    return range(1, this.maxInstallments + 1).map(num => {
      return {
        text: `${num}x`,
        variant: num <= this.selectedInstallment ? 'primary' : 'disabled',
        installments: num,
      };
    });
  }

  get displayInstallmentOptions() {
    const validPeriodicity = this.isCommonOffer || INSTALLMENTS_VALID_PERIODS.includes(this.periodicity);

    return (
      (this.filteredListByMinimumPrice.includes(PaymentMethodsList.CREDIT_CARD) ||
        this.filteredListByMinimumPrice.includes(PaymentMethodsList.MULTICARD)) &&
      validPeriodicity
    );
  }

  get isSubscription() {
    return this.offerKind === 'subscription';
  }

  get hasMulticardPaymentOptionFeature() {
    return this.$FCL.hasMulticardPaymentOption();
  }

  get hasBankSlipSubscriptionFeature() {
    return this.$FCL.hasBankSlipSubscription();
  }

  get hasPixSubscriptionFeature() {
    return this.$FCL.hasPixSubscription();
  }

  get isCommonOffer() {
    return this.offerKind === 'common';
  }

  mounted() {
    this.syncPaymentMethodEnabledStatus();
    this.fetchPaymentSimulation();

    this.selectedInstallment = this.initialSelectedInstallment;
  }

  @Watch('paymentMethodEnabledStatus', { deep: true })
  onChangePaymentMethodEnabledStatus() {
    this.$emit('select-payment-method', this.filteredListByMinimumPrice);
  }

  @Watch('selectedInstallment', { deep: true })
  onChangeSelectedInstallment() {
    this.$emit('select-installment', this.selectedInstallment);
  }

  @Watch('filteredListByMinimumPrice')
  onChangeFilteredListByMinimumPrice() {
    this.$emit('select-payment-method', this.filteredListByMinimumPrice);
  }

  @Watch('offerPrice')
  onChangeOfferPrice = debounce(this.fetchPaymentSimulation, 600);

  @Watch('periodicity')
  onChangePeriodicity() {
    if (!this.isSubscription || !this.paymentSimulation.length) return;

    this.maxInstallments = Math.min(this.paymentSimulation.length, PERIOD_INSTALLMENTS_MAP[this.periodicity]);
    this.selectedInstallment = this.maxInstallments;
  }
}
