import debug from 'debug';
import httpClient from '@/sparkaffiliates/services/http_client.js';
import { paginationFromHTTPHeader } from '@/sparkaffiliates/helpers/paginationHelper';
import { buildParams } from '@/sparkaffiliates/helpers/serviceParamsHelper';
import productService from '@/sparkmembers/services/product.js';
import mediaKitsService from '@/sparkaffiliates/services/media_kit.js';
import programProductsService from '@/sparkaffiliates/services/program_products.js';

const logging = debug('hs:program-service');

export default {
  getUserProducts(params = []) {
    return productService.getAll([
      { key: 'request_attributes', value: 'library_resource' },
      { key: 'per_page', value: 50 },
      ...params,
    ]);
  },
  getProductPaymentOptions(productId, params = {}) {
    return productService.getProductPaymentOptions(productId, params);
  },
  getOrganizationPrograms(params = {}) {
    const searchParams = buildParams(params, ['name', 'sort_by', 'order', 'page', 'items']);
    const url = `producer_api/v1/programs?${searchParams}`;

    return httpClient.get(url).then(({ data, headers }) => {
      return {
        programs: data,
        ...paginationFromHTTPHeader(headers),
      };
    });
  },
  getAffiliatePrograms(params = {}) {
    const searchParams = buildParams(params, ['name', 'sort_by', 'order', 'page', 'items']);
    const url = `affiliate_api/v1/programs?${searchParams}`;

    return httpClient.get(url).then(({ data, headers }) => {
      return {
        programs: data,
        ...paginationFromHTTPHeader(headers),
      };
    });
  },
  // TODO(dias): rename to getProducerProgram
  getProgram(id) {
    return httpClient.get(`producer_api/v1/programs/${id}`);
  },
  getAffiliateProgram(id) {
    return httpClient.get(`affiliate_api/v1/programs/${id}`);
  },
  createBaseProgram(payload) {
    return httpClient.post(`producer_api/v1/programs`, payload);
  },
  async createProgram(program, products, makeOfferURL) {
    const { data: newProgram } = await this.createBaseProgram(program);

    logging('create program', newProgram);

    for (const [_productId, { media_kits, ...product }] of products) {
      const { commission, sparkecommerce_product_id } = product;

      const { data: newProgramProduct } = await programProductsService.createProduct({
        commission,
        sparkecommerce_product_id,
        program_id: newProgram.id,
        offers: product.product_offers.map(offer => ({
          sparkecommerce_reference_id: offer.reference_id,
          sparkecommerce_offer_id: offer.id,
          sparkecommerce_offer_slug: offer.slug,
          offer_url: makeOfferURL(offer, product),
        })),
      });

      logging('created program product', newProgramProduct);

      const { data: newMediaKit } = await mediaKitsService.createMediaKit({
        ...media_kits[0],
        program_product_id: newProgramProduct.id,
      });

      logging('created media kit', newMediaKit);
    }

    return { data: newProgram };
  },
  async editProgram(program, products, makeOfferURL) {
    const { data: updatedProgram } = await this.updateProgram(program.id, program);

    logging('update program', updatedProgram);

    const { data: updatedProgramProducts } = await programProductsService.updateProgramProductsInBatch(
      program.id,
      products.map(([_productId, { media_kits, commission, sparkecommerce_product_id, ...product }]) => {
        return {
          commission,
          sparkecommerce_product_id,
          media_kits,
          program_id: program.id,
          offers: product.product_offers.map(offer => {
            if (offer.sparkecommerce_offer_id) {
              return offer;
            }
            return {
              sparkecommerce_reference_id: offer.reference_id,
              sparkecommerce_offer_id: offer.id,
              sparkecommerce_offer_slug: offer.slug,
              offer_url: makeOfferURL(offer, product),
            };
          }),
        };
      })
    );

    logging('update program product', updatedProgramProducts);

    return { data: updatedProgram };
  },
  updateProgram(programId, payload) {
    return httpClient.put(`producer_api/v1/programs/${programId}`, payload);
  },
  deleteProgram(programId) {
    return httpClient.delete(`producer_api/v1/programs/${programId}`);
  },
  disableProgram(programId) {
    return httpClient.post(`producer_api/v1/programs/${programId}/disabled`);
  },
  enableProgram(programId) {
    return httpClient.post(`producer_api/v1/programs/${programId}/enabled`);
  },
  updateFacebookTrackingFields(programId, payload) {
    return httpClient.put(`affiliate_api/v1/programs/${programId}/update_facebook_tracking_fields`, payload);
  },
};
