<template>
  <div class="tlw-w-full tlw-bg-white tlw-px-4 lg:tlw-p-8 tlw-pt-4 tlw-pb-5 tlw-mb-2">
    <div>
      <div class="tlw-flex tlw-flex-wrap tlw-justify-between tlw-items-baseline">
        <h4 class="tlw-w-3/4 tlw-font-bold tlw-text-xl">
          {{ $t('sparkmembers.setup.sales-page.offers.column.title') }}
        </h4>
        <m-button
          class="tlw-text-sm tlw-text-purple-500 p-0 tlw-cursor-pointer"
          variant="link"
          @click="() => $bvModal.show('preview-modal-offer')"
        >
          <hs-icon icon="eye" class="tlw-mr-1" />
          <span class="tlw-font-normal">
            {{ $t('sparkmembers.setup.sales-page.info.form.basic.label-modal-show') }}
          </span>
        </m-button>
        <p class="font-size-sm mt-1 tlw-mb-0">
          {{ $t('sparkmembers.setup.sales-page.offers.column.text') }}
        </p>
      </div>
      <OfferList :isSalesPageModal="isSalesPageModal" />
    </div>
    <ModalNewOffer />
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton';
export default {
  components: {
    OfferList: () => import('../../Offers/index'),
    ModalNewOffer: () => import('../../ModalNewOffer'),
    MButton,
  },
  data() {
    return {
      isSalesPageModal: false,
    };
  },
};
</script>
