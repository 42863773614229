<template>
  <div class="tlw-w-full">
    <div v-if="isLoading" class="tlw-flex tlw-justify-center tlw-items-center tlw-flex-1">
      <Loading v-if="isLoading" />
    </div>
    <div v-else>
      <ProductCard
        v-for="product in products"
        :id="product.id"
        :product="product"
        :hasAction="false"
        :key="`product-${product.title}`"
      >
        <template v-slot:offer-popover>
          <div @click="openPopover(`popover-target-${product.id}`)">
            <hs-icon class="mr-1 pointer popover-trigger tlw-text-sm" icon="link" />
            <span class="title popover-trigger">
              {{ $t('sparkmembers.v2-product-list.card.link-popover') }}
            </span>
            <hs-icon
              :id="`popover-target-${product.id}`"
              class="ml-2 pointer popover-trigger"
              size="14"
              icon="chevron-down"
            />
          </div>

          <b-popover
            :ref="`popover-target-${product.id}`"
            :target="`popover-target-${product.id}`"
            custom-class="hs-popover"
            placement="bottomright"
          >
            <ProductLinks
              v-if="openedPopover == `popover-target-${product.id}`"
              tracking="popOver_catalog"
              :product="product"
              :get-product-offers="getProductOffers"
              :url-link="urlLink"
              :offer-page-link="offerPageLink"
            />
          </b-popover>
        </template>

        <template #right>
          <p class="tlw-m-0">
            <hs-icon class="tlw-text-xs tlw-mt-1 tlw-mr-1" icon="tag" /> {{ coproductions[product.id].commission }}% de
            comissão
          </p>
        </template>
      </ProductCard>
      <div class="mb-4 mt-6 products-list-pagination d-flex flex-fill flex-column justify-content-end">
        <hs-pagination v-model="currentPage" align="center" :per-page="per_page" :total-rows="numberOfProducts" />
      </div>
    </div>
  </div>
</template>

<script>
import debug from 'debug';
import _ from 'lodash';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import ToastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';
import coproductionService from '@/sparkaffiliates/services/coproduction';
import Loading from '@/components/Loading.vue';
import ProductCard from './ProductCard.vue';
import ProductLinks from './ProductLinks.vue';
import PopoverControllerMixin from '@/sparkmembers/mixins/PopoverControllerMixin';

const logging = debug('hs:coproductions');

export default {
  name: 'Coproductions',
  components: {
    Loading,
    ProductCard,
    ProductLinks,
  },
  mixins: [PopoverControllerMixin],
  data() {
    return {
      isLoading: false,
      coproductions: null,
      products: [],
      subdomain: null,
      currentPage: 1,
      per_page: 5,
      numberOfProducts: 0,
    };
  },
  created() {
    this.installEventListener();
  },
  destroyed() {
    this.removeEventListener();
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    offerPageLink(product) {
      const domain = GeneralHelper.accountDomain({
        accountType: GeneralHelper.accountTypes.HEROSPARK,
        subDomain: this.subdomain,
      });
      return `https://${domain}/${product.slug}`;
    },
    urlLink(product, paymentOption) {
      const domain = GeneralHelper.accountDomain({
        accountType: GeneralHelper.accountTypes.HEROSPARK,
        subDomain: this.subdomain,
      });
      return makeCheckoutUrl(true, paymentOption, product, domain);
    },
    async getProductOffers(product) {
      const id = this.coproductions[product.id].id;
      const {
        data: { offers, subdomain },
      } = await coproductionService.programProductOffers(id);
      this.subdomain = subdomain;
      return offers.map(offer => {
        return {
          title: product.name,
          url: offer.offer_url,
          ...offer,
        };
      });
    },
    async loadData(page = 1) {
      try {
        this.isLoading = true;

        const response = await coproductionService.all([
          { key: 'page', value: page },
          { key: 'items', value: this.per_page },
        ]);
        const { data: coproductions } = response;

        const { headers } = response;

        this.numberOfProducts = Number(headers['total-count']);

        this.coproductions = _.reduce(
          coproductions,
          (acc, coproduction) => ((acc[coproduction.program_product_id] = coproduction), acc),
          {}
        );

        logging('loaded coproductions', coproductions);

        const product_ids = _.map(coproductions, 'program_product_id');
        const { data: products } = await coproductionService.getProducts(product_ids);

        logging("loaded coproductions' products", products);

        this.products = products;
      } catch (error) {
        logging('failed to load coproductions', error);
        ToastHelper.dangerMessage('Falha ao carregar as coproduções');
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    async currentPage(page) {
      window.scrollTo(0, 0);
      await this.loadData(page);
    },
  },
};
</script>
