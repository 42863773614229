<template>
  <div class="md:tlw-bg-white tlw-px-4 md:tlw-px-8 tlw-py-6">
    <div class="rounded-lg">
      <Form :form="product" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Form from './components/Form';

export default {
  computed: {
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
  },
  components: { Form },
};
</script>
