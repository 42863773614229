<template>
  <div class="card_offer">
    <div class="img_offer">
      <img
        :src="require(`@/assets/images/SparkMembers/OffersProduct/${img}.png`)"
        :style="{ width: `${offerType == 'subscription' ? '100%' : 'auto'}` }"
      />
    </div>
    <h4>{{ $t(`sparkmembers.product-creator.pricing.options-offers.${offerType}.title`) }}</h4>
    <h5>{{ $t(`sparkmembers.product-creator.pricing.options-offers.${offerType}.subtitle`) }}</h5>
    <div class="button-section">
      <MButton
        :label="$t('sparkmembers.product-creator.pricing.options-offers.select-label')"
        id="select-product"
        variant="primary"
        class="setup__save-button"
        @click="selectOfferType(offerType)"
      />
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  components: { MButton },
  props: {
    img: {
      type: String,
    },
    offerType: {
      type: String,
    },
  },
  methods: {
    selectOfferType(offerType) {
      this.$emit('selectOfferType', offerType);
    },
  },
};
</script>

<style lang="scss">
.card_offer {
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 5px;
  width: 265px;
  float: left;
  display: flex;
  flex-flow: column;
  align-items: center;
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: #3f3f3f;
    margin-top: 1em;
    padding: 0 16px;
  }
  h5 {
    padding: 0 16px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #6f6f6f;
    height: 4em;
  }
  .button-section {
    padding: 16px;
    padding-top: 0;
    width: 100%;
  }
  #select-product {
    color: #ffffff;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 145%;
  }
  .img_offer {
    background: #bcfbff;
    width: 263px;
    height: 138.59px;
    border-radius: 5px 5px 0px 0px;
    img {
      margin: 0 auto;
      height: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .card_offer {
    width: 100%;
    background-color: #fff;

    .img_offer {
      width: 100%;
    }
  }
}
</style>
