






import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';

@Component({})
export default class DateFormatter extends Vue {
  @Prop({ type: String }) date: string;
  @Prop({ type: String }) format: string;
  @Prop({ type: String, default: 'span' }) useComponent: string;
  @Prop({ type: String, default: '' }) defaultValue: string;

  get allowed(): Record<string, any> {
    const { date, format, useComponent, ...extra } = this.$props;
    return extra;
  }

  get formatted(): string {
    return _.isEmpty(this.date)
      ? this.defaultValue
      : _.isEmpty(this.format)
      ? this.date
      : dayjs(this.date).format(this.format);
  }
}
