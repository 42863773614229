import { OfferType } from '@/types/offers';

export interface IOrderBumpItem {
  id: number;
  title: string;
  link: string;
  items_count: number;
  enabled: boolean;
  cover_image_url: string;
  offer_id: number | null;
  offer_kind: OfferType;
  slug: string;
  school_product_id: string;
  core_product_id: string;
  offer_url: string;
  items: Array<IOrderBumpRelatedProduct>;
}

export interface IOrderBumpRelatedProduct {
  id: number;
  product_name: string;
  cover_url: string;
  base_price: number;
  anchor_price: number;
}

export interface IOrderBumpCreatePayload {
  offer: OfferPayload;
  orderBumpList: Array<OrderBumpPayload>;
}

export interface OfferPayload {
  id: number | null;
  title: string | null;
  school_product_id: number | null;
  kind: OfferType;
  enabled?: boolean;
}

export interface OrderBumpPayload {
  id: number | null;
  title: string | null;
  coverImageUrl: string | null;
  anchorPrice: number;
  basePrice: number | null;
  description: string | null;
  callToAction: string | null;
}

export enum ConfigTypes {
  LIST = 'list',
  CREATE = 'create',
  EDIT = 'edit',
}
