<template>
  <div class="avatar tlw-text-center">
    <img :src="src" />
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    src: String,
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  align-items: center;
  display: grid;

  img {
    width: 80px;
    border-radius: 100px;
    height: auto;
  }
}
</style>
