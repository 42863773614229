<template>
  <b-modal
    modalClass="coupon-creator-modal"
    id="coupon-creator-modal"
    :scrollable="false"
    size="lg"
    hide-footer
    centered
    :hideHeader="true"
    @hidden="onHidden"
  >
    <hs-button
      @click="$bvModal.hide('coupon-creator-modal')"
      variant="link"
      class="md:tlw-invisible tlw-absolute tlw-top-0 tlw-right-0"
    >
      <hs-icon icon="times" />
    </hs-button>
    <h4 class="text-center font-size-lg font-weight-bold mb-5">
      {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.title') }}
    </h4>
    <hs-group>
      <label class="font-weight-bold">
        {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.name-group.label') }}
      </label>
      <hs-input
        v-model="form.name"
        :placeholder="
          $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.name-group.input-placeholder')
        "
        :state="$v.form.name.$error ? false : null"
        @blur="$v.form.name.$touch"
      />
      <p class="font-size-xs">
        {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.name-group.span') }}
      </p>
      <hs-form-invalid-feedback :state="!$v.form.name.$error">
        <span class="font-size-xs" v-if="!$v.form.name.required">
          {{
            $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.name-group.invalid-feedback.required')
          }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>
    <div class="d-flex tlw-flex-col md:tlw-flex-row">
      <hs-group class="tlw-w-full md:tlw-w-2/4">
        <label class="font-weight-bold">
          {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.quantity-group.label') }}
        </label>
        <hs-input
          v-model="form.quantity"
          :placeholder="
            $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.quantity-group.input-placeholder')
          "
          type="number"
          :state="$v.form.quantity.$error ? false : null"
          @blur="$v.form.quantity.$touch"
        />
        <hs-form-invalid-feedback :state="!$v.form.quantity.$error">
          <span class="font-size-xs" v-if="!$v.form.quantity.required">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.quantity-group.invalid-feedback.required'
              )
            }}
          </span>
          <span class="font-size-xs" v-if="!$v.form.quantity.integer">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.quantity-group.invalid-feedback.integer'
              )
            }}
          </span>
        </hs-form-invalid-feedback>
      </hs-group>
      <hs-group class="tlw-w-full md:tlw-w-2/4 md:tlw-pl-4">
        <label class="font-weight-bold">
          {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.available-until-group.label') }}
        </label>
        <hs-date
          v-model="form.expirationDate"
          :label-no-date-selected="$t('date.format')"
          :min="new Date()"
          value-as-date
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </hs-group>
    </div>
    <div class="d-flex">
      <hs-group class="tlw-w-full">
        <div class="d-flex">
          <label class="font-weight-bold">
            {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.label') }}
          </label>
          <div v-b-tooltip.hover.html="customMessage" class="ml-2 py-0 coupon-tooltip">
            ?
          </div>
        </div>
        <div class="w-100 mb-2 d-flex align-items-center">
          <span class="bg-primary text-white price__prefix">%</span>
          <money
            v-model="form.value"
            class="form-control"
            :placeholder="
              $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.input-placeholder')
            "
            maxLength="5"
            :masked="false"
            :state="$v.form.value.$error ? false : null"
            @blur.native="$v.form.value.$touch"
          />
        </div>
        <hs-form-invalid-feedback :state="!$v.form.value.$error">
          <span class="font-size-xs" v-if="!$v.form.value.required">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.invalid-feedback.required'
              )
            }}
          </span>
          <span class="font-size-xs" v-if="!$v.form.value.minValue">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.invalid-feedback.min-value'
              )
            }}
          </span>
          <span class="font-size-xs" v-if="form.percentual && !$v.form.value.maxValue">
            {{
              $t(
                'sparkmembers.coupons.views.list.components.coupon-creator-modal.value-group.invalid-feedback.max-value'
              )
            }}
          </span>
        </hs-form-invalid-feedback>
      </hs-group>
    </div>
    <div class="d-flex justify-content-center tlw-flex-col-reverse md:tlw-flex-row">
      <m-button variant="primary-outline" class="md:tlw-mr-2" @click="$bvModal.hide('coupon-creator-modal')">
        {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.cancel-button-label') }}
      </m-button>
      <m-button
        variant="primary"
        class="tlw-mb-3 md:tlw-ml-3 md:tlw-mb-0"
        :disabled="$v.form.$invalid"
        @click="createButtonClicked"
      >
        {{ $t('sparkmembers.coupons.views.list.components.coupon-creator-modal.create-button-label') }}
      </m-button>
    </div>
  </b-modal>
</template>

<script>
import { required, integer } from 'vuelidate/lib/validators';
import { Money } from 'v-money';
import MButton from '@/shared/components/MButton.vue';
export default {
  name: 'CouponCreatorModal',
  data() {
    return {
      form: {
        name: null,
        expirationDate: new Date(),
        quantity: null,
        value: 0,
        percentual: true,
      },
      money: {
        decimal: '.',
        precision: 2,
      },
    };
  },
  computed: {
    customMessage() {
      let message = this.$t('sparkmembers.coupons.views.list.components.coupon-creator-modal.tooltip');
      return message;
    },
  },
  methods: {
    createButtonClicked() {
      if (this.$v.form.$invalid) return;
      this.$emit('createButtonClick', {
        ...this.form,
        value: this.form.percentual ? this.form.value : this.form.value * 100,
      });
      this.resetForm();
    },
    resetForm() {
      this.form.name = null;
      this.form.expirationDate = new Date();
      this.form.quantity = null;
      this.form.value = 0;
      this.form.percentual = true;
      this.$v.$reset();
    },
    onHidden() {
      this.resetForm();
    },
  },
  components: {
    Money,
    MButton,
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        quantity: {
          required,
          integer,
        },
        value: {
          required,
          minValue: v => v >= 1,
          ...(this.form.percentual && {
            maxValue: v => v <= 90,
          }),
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
#coupon-creator-modal {
  h4 {
    font-size: 1.5rem;
  }
  .coupon-tooltip {
    height: 20px;
    cursor: pointer;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9e9e9;
    border-radius: 100%;
  }
  .price__prefix {
    padding: 0.435rem 0.75rem;
  }
}
/deep/ .coupon-creator-modal {
  .coupon-tooltip-modal {
    color: #0fa0a9;
  }
}
</style>
<style lang="scss" scoped>
@media only screen and (max-width: $screen-sm) {
  /deep/ .coupon-creator-modal {
    .modal-dialog {
      margin: 0;
      width: 100%;
      max-width: inherit;
      position: relative;
    }
    .modal-dialog-centered {
      align-items: flex-end;
      height: 100vh;
    }
    .modal-content {
      border-radius: 0;
      border: 0;
    }
    .modal-body {
      position: relative;
    }
  }
}
</style>
