























































import { Mixins, Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import analyticsService from '@/services/analytics';
import productService from '@/sparkmembers/services/product';
import ToastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import PixelTrack from '@/shared/mixins/PixelTrack';
import { IOffer, OfferType, PeriodEnum } from '@/types/offers';
import ExitOfferSettingsModal from '../ExitOfferSettingsModal.vue';


const SchoolStore = namespace('school');

@Component({
  components: {
    StepOne: () => import('./StepOne.vue'),
    StepTwo: () => import('./StepTwo.vue'),
    StepThree: () => import('./StepThree.vue'),
    ExitOfferSettingsModal,
  },
  computed: {
    ...mapGetters('offerWizard', ['checkoutSettings']),
    ...mapState('offerWizard', ['isTimerEnabled']),
    ...mapState('product', {
      product: (state: any) => state.selectedProduct,
    }),
  },
  methods: {
    ...mapActions('offerWizard', ['setOfferCheckoutSettings', 'resetOfferCheckoutSettings']),
    ...mapMutations('offerWizard', ['setIsEditingOffer'])
  },
})
export default class OfferWizard extends Mixins(PixelTrack) {
  @SchoolStore.Getter getDefaultDomain!: string;
  @SchoolStore.State selectedSchool!: any;

  @Prop({ type: String }) contextType: string;
  @Prop(Number) schoolId: number;
  @Prop({}) type: IOffer;
  @Prop({}) location: any;

  checkoutSettings!: any;
  isLoading = false;
  tab_options: Array<any>;
  context: string = '';
  offer: IOffer = {
    banner_url: null,
    base_price: 0,
    default: false,
    facebook_pixel: null,
    facebook_api_token: null,
    ga_tracking_id: null,
    google_ads_tracking_id: null,
    google_ads_tracking_label: null,
    id: null,
    installments_amount_limit: 12,
    kind: OfferType.COMMON,
    payment_method: [],
    period: PeriodEnum.MONTHLY,
    overdue_type: 'none',
    overdue_limit: '' || null,
    frequency_type: '',
    allow_overdue_access: false,
    frequency_limit: '',
    promotional: null,
    price: 0,
    title: null,
    thank_you_urls: [],
    price_format: null,
    school_product_id: null,
    url: null,
    smart_installment: false,
    bank_slip_installment: false,
    pix_installment: false,
    bank_slip_max_installments: 1,
    pix_max_installments: 1,
  };
  stepsTabs: number = 1;
  stepsComponents = ['step-one', 'step-two', 'step-three'];
  product!: any;
  enabledSteps: Array<string> = ['step-two'];
  setOfferCheckoutSettings!: any;
  resetOfferCheckoutSettings!: any;
  setIsEditingOffer!: any;

  created() {
    if (this.contextType === 'create') {
      this.offer = { ...this.offer, kind: this.type.kind };
      this.context = 'create'
    } else if (this.contextType === 'edit') {
      this.offer = { ...this.offer, ...this.type };
      this.setOfferCheckoutSettings(this.type?.checkout_settings);
      this.context = 'edit'
    }

    this.tab_options = [
      {
        text: this.$t('sparkmembers.offers.tabs.steps.precification'),
        badge_number: 1,
        icon: 'usd-circle',
        status: 'initial',
      },
      {
        text: this.$t('sparkmembers.offers.tabs.steps.checkout'),
        badge_number: 2,
        icon: 'list-alt',
        status: 'initial',
      },
      {
        text: this.$t('sparkmembers.offers.tabs.steps.tracking-pixel'),
        badge_number: 3,
        icon: 'qrcode',
        status: 'initial',
      },
    ];
  }

  mounted() {
    this.setIsEditingOffer(true);
  }

  beforeDestroy() {
    this.resetOfferCheckoutSettings();
    this.setIsEditingOffer(false);
  }

  goToTab(tabNumber) {
    this.stepsTabs = tabNumber;
  }

  get finishWizard() {
    return this.stepsTabs === 4;
  }

  get exitWizard() {
    return this.stepsTabs === 0;
  }

  get currentStepIsEnabled() {
    return this.enabledSteps.includes(this.stepComponent);
  }

  get hasSparkCheckout() {
    return this.$FCL.hasSparkCheckout();
  }

  updateEnabledSteps(stepConfirmation) {
    if (stepConfirmation.status) {
      this.enabledSteps.push(stepConfirmation.step);
    } else {
      this.enabledSteps = this.enabledSteps.filter(e => e !== stepConfirmation.step);
    }
  }

  backTab() {
    this.stepsTabs--;

    if (this.exitWizard) {
      this.$emit('changeOfferType');
    }
  }

  async saveModal() {
    this.$bvModal.hide('exit-offer-settings-modal');
  }

  cancelModal() {
    this.$emit('changeOfferType')
  }

  async nextTab() {
    if (this.contextType === 'create') {
      await this.createOffer();
    } else if (this.contextType === 'edit') {
      await this.editOffer();
    }

    this.stepsTabs++;

    if (this.finishWizard) {
      this.$emit('changeOfferType');
    } else {
      this.tab_options[this.stepsTabs - 1].status = 'changed';
    }
  }

  urlLink(paymentOption, product) {
    return makeCheckoutUrl(this.hasSparkCheckout, paymentOption, product, this.getDefaultDomain);
  }

  normalizeValueZeroFrequency(type, limit, defaultValue) {
    if (type != defaultValue && limit < 1) {
      this.offer.frequency_type = defaultValue;
      this.offer.frequency_limit = null;
    }
  }

  normalizeValueZeroOverdue(type, limit, defaultValue) {
    if (type != defaultValue && limit < 1) {
      this.offer.overdue_type = defaultValue;
      this.offer.overdue_limit = null;
    }
  }

  async createOffer() {
    try {
      this.isLoading = true;
      const typeFrequencies = this.offer.frequency_type;
      const limitFrequencies = this.offer.frequency_limit;
      const typeOverdue = this.offer.overdue_type;
      const limitOverdue = this.offer.overdue_limit;
      this.normalizeValueZeroFrequency(typeFrequencies, limitFrequencies, 'unlimited');
      this.normalizeValueZeroOverdue(typeOverdue, limitOverdue, 'none');

      const payload = {
        ...this.offer,
        price: this.offer.price > 0 ? GeneralHelper.valueInCents(this.offer.price) : 0,
        base_price: this.offer.base_price > 0 ? GeneralHelper.valueInCents(this.offer.base_price) : 0,
        school_product_id: this.product.id,
        checkout_settings: this.checkoutSettings,
        period: this.offer.period
          ? this.offer.period
          : this.offer.kind === OfferType.SUBSCRIPTION
          ? PeriodEnum.MONTHLY
          : PeriodEnum.UNITARY,
      };

      const { data } = await productService.createProductPaymentOption(this.product.id, payload);

      this.contextType = 'edit';
      this.offer = { ...this.offer, ...data };
      this.setOfferCheckoutSettings(data.checkout_settings);

      ToastHelper.successMessage(this.$t('sparkmembers.offers.create.success'));

      const location = this.location || 'offers';
      this.sendAnalyticsData('Offer created', payload, { location });
      this.sendPixelTrackingData(payload);
      this.sendAppearanceEvents(payload);
      this.$emit('saved');
    } catch {
      this.isLoading = false;
      ToastHelper.dangerMessage(this.$t('sparkmembers.offers.create.error'));
    } finally {
      this.isLoading = false;
    }
  }

  async editOffer() {
    const typeFrequencies = this.offer.frequency_type;
    const limitFrequencies = this.offer.frequency_limit;
    const typeOverdue = this.offer.overdue_type;
    const limitOverdue = this.offer.overdue_limit;
    this.normalizeValueZeroFrequency(typeFrequencies, limitFrequencies, 'unlimited');
    this.normalizeValueZeroOverdue(typeOverdue, limitOverdue, 'none');
    try {
      this.isLoading = true;

      const payload = {
        ...this.offer,
        price: this.offer.price > 0 && GeneralHelper.valueInCents(this.offer.price),
        base_price: this.offer.base_price > 0 && GeneralHelper.valueInCents(this.offer.base_price),
        checkout_settings: this.checkoutSettings,
      } as IOffer;

      const { data } = await SparkEcommerceApiClient.offers.update(payload);
      this.offer = { ...this.offer, ...data };

      ToastHelper.successMessage(this.$t('sparkmembers.offers.edit.success'));
      this.sendAnalyticsData('Offer updated', payload);
      this.sendPixelTrackingData(payload);
      this.sendAppearanceEvents(payload);
      this.$emit('saved');
    } catch {
      ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.error'));
    } finally {
      this.isLoading = false;
    }
  }

  sendAnalyticsData(event: string, offer: IOffer, customProps: any = {}) {
    const analyticsDefaultParams = { props: {}, context: {}, callback: {} };

    analyticsService.track({
      ...analyticsDefaultParams,
      event,
      props: {
        boleto: offer.payment_method.includes('boleto'),
        credit_card: offer.payment_method.includes('credit_card'),
        default: offer.default,
        facebook_pixel: offer.facebook_pixel !== null,
        id: offer.id,
        name: offer.title,
        price: offer.promotional
          ? GeneralHelper.valueNormalizeCents(offer.base_price)
          : GeneralHelper.valueNormalizeCents(offer.price),
        product_id: this.product.id,
        promotional_price: offer.promotional ? GeneralHelper.valueNormalizeCents(offer.price) : 0.0,
        promotional: Boolean(offer.promotional),
        recurrence: offer.kind === OfferType.SUBSCRIPTION ? PeriodEnum.MONTHLY : '',
        school_id: this.selectedSchool.id,
        type: offer.kind,
        ...customProps,
      },
    });
  }

  @Watch('stepsTabs')
  detectTabs(value: number, oldValue: number) {
    if (oldValue === 1) {
      this.tab_options[0].status = 'changed';
    }
  }

  get stepComponent() {
    return this.stepsComponents[this.stepsTabs - 1];
  }

  get isFreeOffer() {
    return this.offer.kind === OfferType.FREE;
  }
}
