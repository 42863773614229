<template>
  <div>
    <b-modal
      id="exit-offer-settings-modal"
      title="Exit Offer Settings"
      content-class="teste"
      body-class="exit-offer-settings-modal-body"
      @ok="saveSettings"
      @cancel="cancelSettings"
      centered
      hide-header
      hide-footer
    >
      <div class="exit-offer-modal__content">
        <hs-icon :size="47" icon="info-circle" />
        <h3>Deseja sair?</h3>
        <p>As informações modificadas na sua oferta serão perdidas caso você não clique em salvar.</p>

        <div>
          <MButton size="sm" variant="link" @click="cancelSettings">Sair</MButton>
          <MButton size="sm" variant="primary" @click="saveSettings">Continuar edição</MButton>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  name: 'ExitOfferSettingsModal',
  data() {
    return {
      exitOfferType: '',
      exitOfferTypeOptions: [{ text: 'Exit Intent', value: 'exit_intent' }, { text: 'Timed', value: 'timed' }],
    };
  },
  methods: {
    saveSettings() {
      this.$emit('save');
    },
    cancelSettings() {
      this.$emit('cancel');
    },
  },
  components: { MButton },
};
</script>

<style lang="scss" scoped>
/deep/.exit-offer-settings-modal-body {
  display: flex;
  padding: 20px;
}

.exit-offer-modal__content {
  display: flex;
  flex-direction: column;

  i {
    text-align: center;
    color: #db3939;
    margin-bottom: 20px;
  }

  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #000;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 32px;
    text-align: center;
    color: #262626;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 21px;
  }
}

/deep/.modal-dialog {
  max-width: 341px;
  margin: 0 auto !important;
}
</style>
