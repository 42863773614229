<template>
  <b-modal id="modal-copy-code" title="Código do upsell" hide-footer centered>
    <h4>Script de incorporação</h4>
    <p>
      Copie o código abaixo e insira dentro da tag <strong>{{ tagDescription }}</strong> da sua página de Upsell.
    </p>

    <div class="code">
      <div class="code__input">
        <CodeEditor
          :read_only="true"
          :value="script"
          :hide_header="true"
          :wrap_code="true"
          theme="light"
          :languages="[['html', 'HTML']]"
          width="100%"
        />
      </div>
      <MButton
        variant="link"
        size="sm"
        icon="copy"
        label="Copiar"
        data-testid="copy-head-button"
        @click="copyToClipboard"
      />
    </div>

    <p class="code__description">
      E o codigo abaixo você insere dentro do <strong>body</strong> da sua página, onde quer que os botões sejam
      exibidos
    </p>
    <div class="code">
      <div class="code__input">
        <CodeEditor
          :read_only="true"
          :value="scriptBody"
          :hide_header="true"
          :wrap_code="true"
          theme="light"
          :languages="[['html', 'HTML']]"
          width="100%"
        />
      </div>
      <MButton
        variant="link"
        size="sm"
        icon="copy"
        label="Copiar"
        data-testid="copy-body-button"
        @click="copyBodyToClipboard"
      />
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import Clipboard from '@/shared/mixins/Clipboard';
import ToastHelper from '@/shared/helpers/toast';
import MButton from '@/shared/components/MButton.vue';
import CodeEditor from 'simple-code-editor';

export default {
  mixins: [Clipboard],
  components: {
    MButton,
    CodeEditor,
  },
  name: 'ModalCopyCode',
  computed: {
    ...mapState('product', {
      offer: state => state.productPaymentOption,
    }),
    tagDescription() {
      return '<head>';
    },
    script() {
      const envUrlMap = {
        development: 'http://localhost:3030',
        staging: 'https://pay.beta.herospark.com',
        production: 'https://pay.herospark.com',
      };
      const closingScriptTag = '</' + 'script>';
      const cdnScriptUrl = `${envUrlMap[process.env.NODE_ENV]}/scripts/upsell-cta-script-${process.env.NODE_ENV}.js`;

      return `<script id="upsell-cta-script" src="${cdnScriptUrl}" data-upsell-id="${
        this.upsellStepPublicId
      }">${closingScriptTag}`;
    },
    scriptBody() {
      const closingScriptTag = '</' + 'div>';

      return `<div id="upsell-herospark">${closingScriptTag}`;
    },
    upsellStepPublicId() {
      const upsellStep = this.offer.upsell_flow_steps?.steps[0];

      return upsellStep?.public_id;
    },
  },
  methods: {
    copyToClipboard() {
      const result = this.clipboardCopy('script-code', this.script);
      if (result) {
        ToastHelper.successMessage('Link copiado.');
      } else {
        ToastHelper.dangerMessage('Falha ao copiar o link');
      }
    },
    copyBodyToClipboard() {
      const result = this.clipboardCopy('body-code', this.scriptBody);
      if (result) {
        ToastHelper.successMessage('Link copiado.');
      } else {
        ToastHelper.dangerMessage('Falha ao copiar o link');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 748px) {
  /deep/.modal-dialog {
    margin: 0 auto;
    width: 95% !important;
    min-width: unset !important;
  }
}
/deep/.modal-dialog {
  width: 100%;
  min-width: 748px;

  .modal-body {
    padding: 20px;
    padding-top: 12px;
    user-select: none;

    h4 {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.56rem;
    }

    p {
      margin-bottom: 24px;
    }
  }

  .modal-header {
    padding: 20px;
    border-bottom: 0;
    h5 {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.56rem;
    }
  }
  .code {
    background-color: #f2f2f2;
    padding: 20px;
    font-size: rem(14);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 748px) {
      flex-direction: column;
      gap: 10px;
    }

    &__input {
      min-width: 20px;
      display: flex;
    }

    &__description {
      margin-top: 36px;
    }

    .str {
      color: #b71858;
    }
  }
}

/deep/ .code_editor {
  display: flex;
  background: transparent;
  min-width: 20px;
  .code_area {
    display: flex;
    pre {
      display: flex;

      code {
        background: transparent;
        padding: 0;

        .hljs-name {
          font-weight: bold;
          color: #000000;
        }

        .hljs-attr {
          color: #000000;
        }

        .hljs-string {
          color: #b71858;
        }
      }
    }
  }
}
</style>
