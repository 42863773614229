<template>
  <div class="product-upsell-config">
    <h1 class="product-upsell-config__title">Estratégia de Upsell</h1>
    <p class="product-upsell-config__description">
      Oferte um novo produto na página de obrigado e permita que seu cliente efetue uma nova compra com apenas um
      clique. Configurando botões para incluir em sua página de obrigado.
    </p>

    <section class="product-upsell-config__steps-container">
      <div class="offer-tabs">
        <div
          class="offer-tabs__item"
          :class="{ active: stepsTabs === tab.badgeNumber }"
          :key="index"
          v-for="(tab, index) in tabOptions"
        >
          <StepBadge
            :step-number="tab.badgeNumber"
            :is-done="!(stepsTabs === tab.badgeNumber) && !(tab.status === 'initial')"
            :label-text="tab.text"
            :is-active="stepsTabs === tab.badgeNumber"
          />
        </div>
      </div>
      <div class="content-tabs">
        <StepOne v-if="stepsTabs === 1" :dataStepOne="stepOneData" @update="nextTabStepOne" @backTab="backTab" />
        <StepTwo
          v-else-if="stepsTabs === 2"
          :upsell-offer="formData.offer"
          :product-title="stepOneData.product.title"
          @endFlow="endFlow"
          @updateFlow="updateFlow"
          @backTab="backTab"
        />
      </div>
    </section>
  </div>
</template>

<script>
import StepOne from './Steps/StepOne.vue';
import StepTwo from './Steps/StepTwo.vue';
import ToastHelper from '@/shared/helpers/toast';
import { mapState, mapActions } from 'vuex';
import StepBadge from './StepBadge.vue';

export default {
  name: 'ProductUpsellConfig',
  components: {
    StepOne,
    StepTwo,
    StepBadge,
  },
  data() {
    return {
      stepsTabs: 1,
      tabOptions: [
        {
          text: 'Produto upsell',
          badgeNumber: 1,
          status: 'initial',
        },
        {
          text: 'Botões',
          badgeNumber: 2,
          status: 'initial',
        },
      ],
      formData: {
        product: null,
        offer: null,
        url: '',
        selectAccepOfferSendClientTo: null,
        selectDeclineOfferSendClientTo: null,
        upsellOneClick: false,
        declineText: '',
        acceptText: '',
      },
    };
  },
  computed: {
    ...mapState('product', {
      offer: state => state.productPaymentOption,
      upsell_flow_steps: state => state.productPaymentOption?.upsell_flow_steps,
    }),
    exitWizard() {
      return this.stepsTabs === 1;
    },
    stepOneData() {
      return {
        product: this.formData?.product,
        offer: this.formData?.offer,
        url: this.formData?.url,
      };
    },
  },
  mounted() {
    if (this.upsell_flow_steps !== null) {
      this.tabOptions.forEach(option => {
        option.status = 'done';
      });
    }
  },
  methods: {
    ...mapActions('product', ['getProductPaymentOption']),
    ...mapActions('upsell', ['createUpsellFlow', 'udpdateUpsellFlow']),
    nextTabStepOne(data) {
      this.formData.product = data.product;
      this.formData.offer = data.offer;
      this.formData.url = data.url;

      const currentTabIndex = this.tabOptions.findIndex(option => option.badgeNumber === this.stepsTabs);
      this.tabOptions[currentTabIndex].status = 'done';

      this.stepsTabs++;
    },
    async endFlow(upsellData) {
      try {
        const flow = this.formatCreateUpsellBody(upsellData);

        await this.createUpsellFlow(flow);

        ToastHelper.successMessage(this.$t('sparkmembers.offers.edit.success'));
        this.$root.$emit('created-upsell');
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.error'));
      }
    },
    async updateFlow(upsellData) {
      try {
        const flow = this.formatCreateUpsellBody(upsellData);

        await this.udpdateUpsellFlow({
          flowId: upsellData.id,
          flow,
        });

        ToastHelper.successMessage(this.$t('sparkmembers.offers.edit.success'));
        this.$emit('saved');
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.offers.edit.error'));
      }
    },
    formatCreateUpsellBody(data) {
      return {
        flow: {
          frictionless: data.upsellOneClick,
          offer_reference_id: this.offer.reference_id,
          url: this.formData.url,
          steps_attributes: [
            {
              ...(data.stepId ? { id: data.stepId } : {}),
              offer_reference_id: this.formData.offer.reference_id,
              payment_method: 'credit_card',
              actions_attributes: data.actions_attributes,
            },
          ],
        },
      };
    },
    backTab() {
      if (this.exitWizard) this.$emit('back');

      this.stepsTabs--;
    },
  },
};
</script>

<style lang="scss">
.product-upsell-config__title {
  font-size: 1.25rem;
  font-weight: 700;
  color: $grey;
  line-height: 1.5rem;
  margin: 0;
}

.product-upsell-config__description {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey;
  margin-bottom: 0;
  margin-top: 8px;
}

.product-upsell-config__steps-container {
  margin-top: 32px;
}

.offer-tabs {
  display: flex;
  width: 100%;
  overflow-x: auto;

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 3rem;
    pointer-events: none;

    &-number {
      background-color: #e3e3e3;
      border: 2px solid #bababa;
      border-radius: 100%;
      color: #bababa;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      margin-right: 0.625rem;
      width: 28px;
      min-width: 28px;
      height: 28px;
      text-align: center;
    }
    &-icon {
      background-color: #388e3c;
      border: 2px solid #388e3c;
      color: $white;

      i {
        font-weight: 100;
      }
    }
    &-text {
      color: #bababa;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  &__item.active {
    pointer-events: auto;

    .offer-tabs__item-number {
      background-color: #ead3fe;
      border: 2px solid #1f0773;
      color: #1f0773;
    }
    .offer-tabs__item-text {
      color: #1f0773;
    }
  }
  &:last-child {
    margin-right: 0;
  }
}

.content-tabs {
  margin-top: 32px;
}
</style>
