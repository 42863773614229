<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="bg-white rounded-lg py-3 px-4 py-lg-4 px-lg-5 mb-3 mt-lg-0 mb-lg-5">
          <h5 class="font-weight-bold mb-3 mb-lg-4">{{ $t('sparkmembers.sales-page.info.form.basic.section') }}</h5>
          <b-form @submit.prevent="">
            <hs-group>
              <label class="font-weight-bold">{{ $t('sparkmembers.sales-page.info.form.basic.subtitle') }}</label>
              <hs-input
                type="text"
                v-model="form.subtitle"
                :placeholder="$t('sparkmembers.sales-page.info.form.basic.subtitle-placeholder')"
                :text="
                  `${$t('sparkmembers.sales-page.info.form.basic.subtitle-helper')}: ${
                    form.subtitle ? form.subtitle.length : '0'
                  }`
                "
                :disabled="isSaving"
                @blur="$v.form.subtitle.$touch()"
              />
            </hs-group>
            <hs-group>
              <label class="font-weight-bold">{{ $t('sparkmembers.sales-page.info.form.basic.description') }}</label>
              <hs-text-editor
                editor="description"
                :placeholder="$t('sparkmembers.sales-page.info.form.basic.description-placeholder')"
                v-model="form.description"
                :initialContent="form.description || ''"
                :disabled="isSaving"
                :editable="isSaving"
                @onUpdate="
                  event => {
                    form.description = event.getHTML();
                    if (form.description === '<p></p>') form.description = '';
                  }
                "
                @onBlur="event => $v.form.description.$touch()"
              />
            </hs-group>
            <div class="d-flex w-100 justify-content-end mt-5">
              <hs-button
                :variant="isSaving ? 'light' : 'primary'"
                :disabled="isSaving || $v.form.$invalid"
                @click="onSubmit"
              >
                <b-spinner small type="grow" v-if="isSaving" />
                {{ $t(`sparkmembers.sales-page.info.form.basic.${isSaving ? 'saving' : 'save'}`) }}
              </hs-button>
            </div>
          </b-form>
        </div>

        <div class="bg-white rounded-lg py-3 px-4 py-lg-4 px-lg-5 my-3 my-lg-5">
          <h5 class="font-weight-bold mb-4">{{ $t('sparkmembers.sales-page.info.form.topics.section') }}</h5>
          <template v-if="loadingTopics">
            <hsLoading />
          </template>
          <template v-else>
            <b-form @submit.prevent="">
              <draggable
                handle=".handle"
                v-model="topics"
                v-bind="dragOptions"
                @start="drag = true"
                @end="
                  () => {
                    drag = false;
                    onTopicOrder();
                  }
                "
              >
                <hs-group v-for="(topic, index) in topics" :key="index">
                  <div class="d-flex align-items-center">
                    <div class="mr-3">
                      <hs-icon icon="bars" :class="topic.id ? 'handle' : 'no-handle'" :size="16" />
                    </div>
                    <div class="w-100 flex-1">
                      <hs-input
                        type="text"
                        v-model="topic.description"
                        :placeholder="$t('sparkmembers.sales-page.info.form.topics.description-placeholder')"
                      />
                    </div>
                    <div class="ml-3">
                      <hs-button
                        v-b-tooltip.hover.bottom="$t('actions.delete')"
                        class="p-0 text-cherry"
                        variant="link"
                        @click="() => onRemoveTopic(topic, index)"
                      >
                        <hs-icon icon="trash" />
                      </hs-button>
                    </div>
                  </div>
                </hs-group>
              </draggable>

              <div v-if="topics.length < 3">
                <hs-button
                  icon="plus"
                  variant="primary"
                  class="px-0 font-weight-light"
                  @click="
                    () => {
                      topics.push({
                        title: $t('sparkmembers.sales-page.info.form.topics.title', { index: topics.length + 1 }),
                        description: '',
                        order: topics.length + 1,
                        school_product_id: product.id,
                      });
                    }
                  "
                >
                  {{ $t('sparkmembers.sales-page.info.form.topics.action') }}
                </hs-button>
              </div>

              <div class="d-flex w-100 justify-content-end mt-5">
                <hs-button variant="primary" @click="onAddTopic">
                  {{ $t('sparkmembers.sales-page.info.form.topics.save') }}
                </hs-button>
              </div>
            </b-form>
          </template>
        </div>

        <div class="row my-3 my-lg-5">
          <div class="col-12 col-lg-6">
            <div class="bg-white rounded-lg pt-4 h-100 px-4 px-lg-5">
              <h5 class="font-weight-bold mb-1">{{ $t('sparkmembers.sales-page.info.form.cover.image.title') }}</h5>
              <p class="font-size-xs">{{ $t('sparkmembers.sales-page.info.form.cover.image.subtitle') }}</p>
              <div class="d-flex flex-wrap mt-3">
                <div class="position-relative mr-2" v-if="form.logo">
                  <div class="position-absolute w-100 text-right">
                    <hs-icon class="text-primary mr-2" variant="solid" icon="check-circle" />
                  </div>
                  <b-img-lazy height="91" width="91" class="border-primary rounded-lg border-2" :src="form.logo" />
                </div>
                <hsImageUploader height="91" width="91" :image="null" @onFile="onFile">
                  <div class="font-size-xs text-center mb-1">
                    {{ this.$t('sparkmembers.sales-page.info.form.cover.image.upload') }}
                  </div>
                  <hs-icon class="text-primary text-center font-size-lg" variant="light" icon="cloud-upload" />
                </hsImageUploader>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6" id="video-upload-container" v-if="showVideoUpload">
            <div class="bg-white rounded-lg py-3 px-4 py-lg-4 px-lg-5 h-100">
              <h5 class="font-weight-bold mb-1">{{ $t('sparkmembers.sales-page.info.form.cover.video.title') }}</h5>
              <p class="font-size-xs">{{ $t('sparkmembers.sales-page.info.form.cover.video.subtitle') }}</p>
              <template v-if="loadingMedia">
                <hsLoading />
              </template>
              <template v-else>
                <template v-if="galleryVideos && galleryVideos.id">
                  <b-embed
                    allowfullscreen
                    type="iframe"
                    aspect="16by9"
                    :src="onVideoPreview(galleryVideos.media.host, galleryVideos.media.host_key)"
                  />
                </template>
                <div class="d-flex mt-3" v-else>
                  <hs-button-circle
                    variant="info-light"
                    icon="play-circle"
                    title="YouTube"
                    class="mr-2"
                    @click="
                      () => {
                        videoPresentation = 'youtube';
                        $bvModal.show('medial-modal');
                      }
                    "
                  />
                  <hs-button-circle
                    variant="info-light"
                    icon="vimeo-v"
                    iconVariant="brand"
                    title="Vimeo"
                    @click="
                      () => {
                        videoPresentation = 'vimeo';
                        $bvModal.show('medial-modal');
                      }
                    "
                  />
                </div>
                <div class="w-100 text-right mt-4">
                  <hs-button v-if="galleryVideos && galleryVideos.id" @click="onDelete('delete-video-modal')">
                    {{ $t('actions.remove') }}
                  </hs-button>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="bg-white rounded-lg py-3 px-4 py-lg-4 px-lg-5 mb-3 mb-lg-5">
          <h5 class="font-weight-bold mb-3 mb-lg-4">
            {{ $t('sparkmembers.sales-page.info.form.teacher.section') }}
          </h5>

          <div class="mb-3" v-if="!showFormTeacher">
            <hs-multiselect
              searchable
              close-on-select
              :placeholder="$t('sparkmembers.sales-page.info.form.teacher.search')"
              label="full_name"
              track-by="full_name"
              :show-labels="false"
              :options="teachers"
              :loading="loadingTeachers"
              :disabled="!teachers.length || isSaving"
              @select="onSelectTeacher"
            />
          </div>

          <template v-if="selectedTeacher && !showFormTeacher">
            <div class="bg-white border rounded-lg p-3 mb-3">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center mr-auto">
                  <b-img-lazy class="rounded-circle mr-3 uploader-image" :src="selectedTeacher.photo_url"></b-img-lazy>
                  <h4 class="font-weight-bold">{{ selectedTeacher.full_name }}</h4>
                </div>

                <hs-button
                  v-b-tooltip.hover.bottom="$t('actions.edit')"
                  class="py-0"
                  variant="link"
                  @click="() => (showFormTeacher = true)"
                >
                  <hs-icon icon="edit" />
                </hs-button>

                <hs-button
                  v-b-tooltip.hover.bottom="$t('actions.remove')"
                  class="py-0"
                  variant="link"
                  @click="onDelete('delete-teacher-modal')"
                >
                  <hs-icon icon="trash" />
                </hs-button>
              </div>
            </div>
          </template>

          <FormTeacher
            id="form-teacher"
            :form="form.class_teachers[0]"
            v-if="showFormTeacher"
            @input="onSelectTeacher"
          />

          <hs-button
            id="btn-addteacher"
            v-if="!showFormTeacher"
            icon="plus"
            variant="primary"
            class="px-0 font-weight-light"
            @click="addNewTeacher"
          >
            {{ $t('sparkmembers.sales-page.info.form.teacher.action') }}
          </hs-button>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="d-flex bg-white rounded-lg py-3 px-4 py-lg-4 px-lg-5">
          <hs-icon icon="usd-circle" :size="36" />
          <div class="ml-3">
            <h5 class="font-weight-bold">{{ $t('sparkmembers.sales-page.info.column.title') }}</h5>
            <p class="mt-1">{{ $t('sparkmembers.sales-page.info.column.text') }}</p>
            <hs-button variant="outline-primary" @click="() => $bvModal.show('preview-modal')">
              {{ $t('sparkmembers.sales-page.info.column.button') }}
            </hs-button>
          </div>
        </div>
      </div>
    </div>
    <hsConfirmModal
      id="delete-topic-modal"
      icon="trash-alt"
      variant="cherry"
      hide-header
      :customTitle="$t('sparkmembers.sales-page.info.alert.topic.title')"
      :description="$t('sparkmembers.sales-page.info.alert.topic.description')"
      :ok-title="$t('sparkmembers.sales-page.info.alert.topic.confirm')"
      :cancel-title="$t('sparkmembers.sales-page.info.alert.topic.cancel')"
      @ok="onDeleteTopic"
    />
    <hsConfirmModal
      id="delete-teacher-modal"
      icon="trash-alt"
      variant="cherry"
      hide-header
      :customTitle="$t('sparkmembers.sales-page.info.alert.teacher.title')"
      :description="$t('sparkmembers.sales-page.info.alert.teacher.description')"
      :ok-title="$t('sparkmembers.sales-page.info.alert.teacher.confirm')"
      :cancel-title="$t('sparkmembers.sales-page.info.alert.teacher.cancel')"
      @ok="onDeleteTeacher"
    />
    <hsConfirmModal
      id="delete-video-modal"
      icon="trash-alt"
      variant="cherry"
      hide-header
      :customTitle="$t('sparkmembers.sales-page.info.alert.video.title')"
      :description="$t('sparkmembers.sales-page.info.alert.video.description')"
      :ok-title="$t('sparkmembers.sales-page.info.alert.video.confirm')"
      :cancel-title="$t('sparkmembers.sales-page.info.alert.video.cancel')"
      @ok="onDeleteGallery"
    />
    <ModalMedia :media="videoPresentation" @input="onCreateGallery" />
    <ModalPreviewPage />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { analyticsService, productService, uploadService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import VideoPreviewHelper from '@/sparkmembers/helpers/videoPreviewHelper';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      isSavingTopic: false,
      teachers: [],
      galleryVideos: {},
      topics: [],
      selectedTopic: {
        title: null,
        description: null,
      },
      loadingTeachers: true,
      loadingTopics: true,
      loadingMedia: false,
      showFormTeacher: false,
      drag: false,
      videoPresentation: '',
    };
  },
  validations: {
    form: {
      subtitle: {
        required,
      },
      description: {
        required,
      },
    },
  },
  components: {
    draggable: () => import('vuedraggable'),
    FormTeacher: () => import('./components/FormTeacher'),
    hsConfirmModal: () => import('@/components/ConfirmModal'),
    hsImageUploader: () => import('@/components/ImageUploader'),
    hsLoading: () => import('@/components/Loading'),
    ModalMedia: () => import('./components/ModalMedia'),
    ModalPreviewPage: () => import('./components/ModalPreviewPage'),
  },
  watch: {
    form(val) {
      this.form = val;
    },
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    ...mapState('course', {
      course: state => state.selectedCourse,
    }),
    showVideoUpload() {
      return this.course && this.course.kind === 'online_course';
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'topic',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    selectedTeacher() {
      return this.form.class_teachers[0]?.id ? this.form.class_teachers[0] : null;
    },
  },
  methods: {
    async onSubmit(callback) {
      if (this.$v.form.$invalid) return;
      this.isSaving = true;

      productService
        .update(this.form)
        .then(() => {
          analyticsService.track({
            event: 'Product sales page updated',
            props: { product_id: this.form.id },
          });
          if (typeof callback === 'function') callback();
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.product.error'));
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async onDeleteGallery() {
      this.loadingMedia = true;
      productService
        .deleteProductGallery(this.galleryVideos.id)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.video.success'));
          this.onSubmit();
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.video.error'));
        })
        .finally(() => {
          this.loadingMedia = false;
          this.form.gallery_media[0] = {};
          this.galleryVideos = {};
        });
    },
    async getTeachers() {
      productService
        .getProductTeachers()
        .then(res => {
          this.teachers = res.data.class_teachers;
        })
        .finally(() => {
          this.loadingTeachers = false;
        });
    },
    async getTopics() {
      productService
        .getProductTopic(this.form.id)
        .then(res => {
          this.topics = res.data.school_product_topics.sort((a, b) => a.order - b.order);
        })
        .finally(() => {
          this.loadingTopics = false;
        });
    },
    async onAddTopic() {
      this.loadingTopics = true;
      try {
        await productService.updateBatchProductTopic(this.topics.filter(topic => topic.id));
        await productService.createBatchProductTopic(this.topics.filter(topic => !topic.id));
        ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.topic.update'));
        this.getTopics();
      } catch {
        ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.topic.update-error'));
        this.loadingTopics = false;
      }
    },
    onRemoveTopic(topic, index) {
      if (topic.id) {
        this.selectedTopic = topic;
        this.onDelete('delete-topic-modal');
      } else {
        this.topics.splice(index, 1);
      }
    },
    async onTopicOrder() {
      try {
        this.topics = this.topics.map((topic, index) => ({ ...topic, order: index }));
        await productService.updateBatchProductTopic(this.topics.filter(topic => topic.id));
        ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.topic.update'));
      } catch {
        ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.topic.update-error'));
      }
    },
    onDeleteTopic() {
      this.loadingTopics = true;
      productService
        .deleteProductTopic(this.selectedTopic.id)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.topic.deleted'));
          this.topics = this.topics.filter(filteredTopic => filteredTopic.id !== this.selectedTopic.id);
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.topic.deleted-error'));
        })
        .finally(() => {
          this.loadingTopics = false;
          this.selectedTopic = {};
        });
    },
    async onCreateGallery(media) {
      this.loadingMedia = true;

      productService
        .createProductGallery({
          media_id: media.id,
          school_product_id: this.form.id,
          gallery_tag_ids: 14,
        })
        .then(gallery => {
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.video.success'));
          this.onSubmit(() => {
            this.galleryVideos = {
              id: gallery.data.id,
              gallery_tags: [{ name: 'video_cover' }],
              media,
            };
            this.loadingMedia = false;
          });
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.video.error'));
        });
    },
    onVideoPreview(provider, videoId) {
      return VideoPreviewHelper.urlEmbed(provider, videoId);
    },
    async onFile(file) {
      const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
      try {
        this.form.logo = await uploadService.upload(data.url, data.fields, file);
        this.onSubmit();
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.upload.error'));
      }
    },
    onDelete(modalId) {
      this.$bvModal.show(modalId);
    },
    addNewTeacher() {
      this.form.class_teachers = [{ full_name: '', about: '', photo_url: '' }];
      this.showFormTeacher = true;
    },
    onSelectTeacher(teacher) {
      this.form.class_teachers = [teacher];
      this.form.class_teacher_ids = [teacher.id];

      const index = this.teachers.findIndex(item => item.id === teacher.id);
      if (index === -1) this.teachers.push(teacher);
      else this.teachers[index] = teacher;

      this.onSubmit();

      this.showFormTeacher = false;
    },
    onDeleteTeacher() {
      productService
        .deleteProductTeacher(this.selectedTeacher.id)
        .then(() => {
          this.teachers.splice(this.teachers.indexOf(this.selectedTeacher), 1);
          this.form.class_teachers.shift();
          this.form.class_teacher_ids.shift();

          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.teacher.removed'));
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.teacher.remove-error'));
        });
    },
  },
  mounted() {
    this.form.class_teacher_ids = this.form.class_teacher_ids || [];
    this.galleryVideos = this.form.gallery_media.filter(
      gallery => gallery.media.type === 'Video' && gallery.gallery_tags.find(({ name }) => name === 'video_cover')
    )[0];

    this.getTeachers();
    this.getTopics();
  },
};
</script>

<style lang="scss" scoped>
.handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.no-handle {
  cursor: not-allowed;
}

.border-2 {
  border: 2px solid transparent;
}

.border-dashed {
  border-style: dashed;
}

.uploader {
  width: 91px;
  height: 91px;

  &-image {
    width: 70px;
    height: 70px;
  }
}
</style>
