<template>
  <div class="d-flex flex-column p-0 md:tlw-bg-white tlw-m-0">
    <div class="row flex-fill m-0 md:tlw-bg-white">
      <hs-loading v-if="isLoading" />
      <div v-else class="col-12 col-sm-12 p-0 tlw-bg-white">
        <div>
          <form @submit.prevent="onCreate" class="tlw-px-0 md:tlw-px-8 tlw-py-6 appcues-enablement-welcome-step-3">
            <p class="tlw-text-base px-3 px-md-0">
              <span class="tlw-mr-1">{{ $t('sparkmembers.product-creator.setup.title') }}</span>
              <span
                class="tlw-border-solid tlw-border tlw-border-purple-400 tlw-text-purple-500 tlw-rounded-3xl tlw-text-xs tlw-py-0.5 tlw-px-1.5"
              >
                {{ customLabel }}
              </span>
            </p>
            <h4 class="tlw-mb-2 tlw-mt-8 tlw-font-bold px-3 px-md-0">
              {{ $t('sparkmembers.product-creator.setup.subtitle') }}
            </h4>
            <p class="tlw-mb-6 tlw-text-sm tlw-font-normal tlw-text-gray-700 px-3 px-md-0">
              {{ $t('sparkmembers.product-creator.setup.description') }}
            </p>
            <!--Step-->
            <section v-if="customLabel === 'Webinar'" class="tlw-px-4 lg:tlw-px-0">
              <div class="webinar-stepper tlw-grid tlw-grid-cols-2 tlw-gap-4">
                <div class="stepper tlw-flex tlw-gap-3 tlw-items-center">
                  <div
                    @click="steps = 1"
                    :class="{ stepActive: steps === 2 }"
                    class="stepper-item tlw-rounded-full tlw-h-8 tlw-w-8 tlw-flex tlw-items-center tlw-cursor-pointer tlw-justify-center tlw-text-white"
                  >
                    <hs-icon v-if="steps === 2" variant="solid" icon="check" class="check-icon" />
                    <span v-else> 1 </span>
                  </div>
                  <span> Dados básicos </span>
                </div>
                <div class="stepper tlw-flex tlw-gap-4 tlw-items-center">
                  <div
                    @click="steps = 2"
                    :disabled="steps === 1"
                    :class="{ stepDisabled: steps === 1 }"
                    class="stepper-item tlw-cursor-pointer tlw-rounded-full tlw-h-8 tlw-w-8 tlw-flex tlw-items-center tlw-justify-center tlw-text-white"
                  >
                    <span> 2 </span>
                  </div>
                  <span> Sobre o evento</span>
                </div>
              </div>
              <hr class="webinar-divider" />
            </section>

            <template v-if="customLabel === 'Webinar' && steps === 2">
              <div class="px-3 px-md-0">
                <WebinarHerospark
                  @createDefinitionParams="onEmitDescription"
                  @updatedUrl="onEmitUrl"
                  @updatedUrlChat="onEmitUrlChat"
                  :description_webinar="webinar_description"
                  :origin_url="origin_url"
                  :chat_url="chat_url"
                />
              </div>
            </template>

            <template v-else>
              <hs-group class="tlw-border-solid tlw-border-b tlw-border-gray-300 tlw-pb-6 px-3 px-md-0">
                <label class="font-weight-bold">{{
                  this.$t('sparkmembers.product-creator.setup.name-input.label')
                }}</label>
                <hs-input
                  v-model="name"
                  :text="$t('sparkmembers.product-creator.setup.name-input.text')"
                  @blur="$v.name.$touch()"
                />
                <template v-if="$v.name.$error">
                  <hs-form-invalid-feedback :state="false">
                    <span class="font-size-xs" v-if="!$v.name.required">
                      {{ $t('sparkmembers.product-creator.setup.name-input.validation.required') }}
                    </span>
                    <span class="font-size-xs" v-if="!$v.name.noQuotes">
                      {{ $t('sparkmembers.product-creator.setup.name-input.validation.no-quotes') }}
                    </span>
                  </hs-form-invalid-feedback>
                </template>
              </hs-group>
              <hs-group
                class="tlw-border-solid tlw-border-b tlw-border-gray-300 tlw-pb-2 px-3 px-md-0"
                :label="$t('sparkmembers.product-creator.setup.niche-input.label')"
                label-for="niches"
                label-class="font-weight-bold tlw-m-0 tlw-text-base"
              >
                <p
                  class="tlw-mb-6 tlw-text-sm tlw-font-normal tlw-text-gray-700"
                  v-html="$t('sparkmembers.product-creator.setup.segment-description')"
                ></p>
                <div class="d-flex flex-wrap">
                  <hs-badge
                    id="niches"
                    v-for="(niche, index) in niches.filter(n => !n.isHidden)"
                    :key="index"
                    pill
                    :variant="selectedNiche === niche ? 'primary' : 'outline-secondary'"
                    class="tlw-mr-4 tlw-mb-4"
                    @click="onNicheSelected(niche)"
                  >
                    {{ $t(`sparkmembers.product-creator.setup.niche-options.${niche.name}`) }}
                  </hs-badge>
                  <hs-badge
                    pill
                    :variant="otherNiche.variant"
                    class="tlw-mr-4 tlw-mb-4"
                    @click="onNicheSelected(otherNiche)"
                  >
                    {{ $t(`sparkmembers.product-creator.setup.niche-options.${otherNiche.name}`) }}
                  </hs-badge>
                </div>
              </hs-group>
              <NichesModal :niches="niches" @selected="onNicheSelected" @onHiddenWithoutSelect="onSelectDefaultNiche" />
              <hs-group class="tlw-mt-4">
                <label class="font-weight-bold tlw-mb-0 tlw-text-base px-3 px-md-0">{{
                  this.$t('sparkmembers.product-creator.setup.image-input.label')
                }}</label>
                <p class="tlw-text-sm tlw-text-gray-700 px-3 px-md-0">
                  {{ this.$t('sparkmembers.product-creator.setup.image-input.description') }}
                </p>
                <div class="d-flex tlw-gap-6 tlw-my-6 tlw-flex-col lg:tlw-flex-row px-3 px-md-0">
                  <div class="lg:tlw-w-1/2 tlw-flex tlw-flex-col">
                    <div>
                      <div class="d-flex tlw-gap-2 tlw-items-center tlw-mb-1">
                        <p class="tlw-mb-0 font-size-sm">
                          Horizontal
                        </p>
                        <Icon
                          name="info-circle"
                          prefix="fal"
                          size="xxs"
                          class="informative-icon"
                          v-b-tooltip.hover.top.html="
                            $t('sparkmembers.product-creator.setup.image-input.icon-tooltip-horizontal')
                          "
                        />
                      </div>
                      <p class="tlw-text-xs tlw-text-gray-700">
                        Formatos: .jpeg, .jpg ou .png,<br />
                        Dimensão ideal: 1280 x 720px
                      </p>
                    </div>
                    <div class="d-flex tlw-gap-4 tlw-items-center tlw-flex-grow">
                      <div
                        class="image-preview horizontal tlw-flex tlw-justify-center tlw-items-center tlw-rounded-lg tlw-border tlw-bg-gray-100"
                        :style="estiloImagemHorizontal"
                      >
                        <Icon
                          v-if="!imageHorizontal"
                          name="image"
                          prefix="fal"
                          size="xs"
                          class="tlw-text-gray-500 text-center"
                          variant="light"
                        />
                      </div>
                      <div>
                        <MButton
                          :label="horizontalImageButtonLabel"
                          :icon="horizontalImageButtonIcon"
                          variant="primary-outline"
                          :loading="isSavingHorizontal"
                          class="upload-button tlw-gap-2"
                          @click="e => handleImageUpload('horizontal', e)"
                        />
                        <hs-form-invalid-feedback
                          :state="false"
                          v-if="isImageFormatInvalid && currentImageType === 'horizontal'"
                        >
                          <span class="font-size-xs tlw-text-red-500">
                            {{ $t('sparkmembers.product-creator.setup.image-input.validation.invalid-format') }}
                          </span>
                        </hs-form-invalid-feedback>
                        <hs-form-invalid-feedback
                          :state="false"
                          v-if="!$v.imageHorizontal.required && $v.imageHorizontal.$dirty"
                        >
                          <Icon name="exclamation-circle" prefix="fal" size="xxs" class="tlw-text-red-500" />
                          <span class="font-size-xs tlw-text-red-500">
                            {{ $t('sparkmembers.product-creator.setup.image-input.validation.required') }}
                          </span>
                        </hs-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="lg:tlw-w-1/2">
                    <div class="d-flex tlw-gap-2 tlw-items-center tlw-mb-1">
                      <p class="tlw-mb-0 font-size-sm">Vertical <span class="tlw-text-gray-600">(Opcional)</span></p>
                      <Icon
                        name="info-circle"
                        prefix="fal"
                        size="xxs"
                        class="informative-icon"
                        v-b-tooltip.hover.top.html="
                          $t('sparkmembers.product-creator.setup.image-input.icon-tooltip-vertical')
                        "
                      />
                      <hs-badge pill variant="success" class="tlw-mb-0 badge-success">Novo</hs-badge>
                    </div>
                    <p class="tlw-text-xs tlw-text-gray-700 tlw-mb-4">
                      Formatos: .jpeg, .jpg ou .png,<br />
                      Dimensão ideal: 417 x 720px
                    </p>
                    <div class="d-flex tlw-gap-4 tlw-items-center tlw-mb-1">
                      <div
                        class="image-preview vertical tlw-flex tlw-justify-center tlw-items-center tlw-rounded-lg tlw-border tlw-bg-gray-100"
                        :style="estiloImagemVertical"
                      >
                        <Icon
                          v-if="!imageVertical"
                          name="image"
                          prefix="fal"
                          size="xs"
                          class="tlw-text-gray-500 text-center"
                          variant="light"
                        />
                      </div>
                      <div class="d-flex tlw-flex-col tlw-gap-2">
                        <div class="d-flex tlw-flex-row tlw-gap-2 tlw-items-center">
                          <MButton
                            :label="verticalImageButtonLabel"
                            :icon="verticalImageButtonIcon"
                            variant="primary-outline"
                            :loading="isSavingVertical"
                            class="tlw-gap-2 upload-button"
                            @click="e => handleImageUpload('vertical', e)"
                          />
                          <MButton
                            v-if="imageVertical"
                            class="remove-image-vertical tlw-gap-2"
                            variant="link"
                            label="Remover"
                            icon="trash-alt"
                            @click="removeImageVertical"
                          />
                        </div>
                        <hs-form-invalid-feedback
                          :state="false"
                          v-if="isImageFormatInvalid && currentImageType === 'vertical'"
                        >
                          <span class="font-size-xs tlw-text-red-500">
                            {{ $t('sparkmembers.product-creator.setup.image-input.validation.invalid-format') }}
                          </span>
                        </hs-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  @change="onFileInputChange"
                  ref="file"
                  accept="image/jpg, image/jpeg, image/png"
                  style="display: none"
                />
                <CropImage
                  v-if="selectedImage != null"
                  :aspect-ratio="currentAspectRatio"
                  :subtitle="cropSubtitle"
                  :src="selectedImage"
                  :is-saving="isSavingCropImage"
                  @close="selectedImage = null"
                  @save="onFile"
                />
                <div class="px-3 px-md-0">
                  <p class="tlw-text-sm tlw-text-neutral-800 tlw-mb-1 md:tlw-mb-0">
                    Precisa de ajuda? Use uma de nossas sugestões
                  </p>
                  <p class="tlw-text-xs tlw-text-gray-700 tlw-mb-0">
                    Selecione uma das opções abaixo caso não tenha uma imagem horizontal disponível agora.
                  </p>
                </div>
                <div class="d-flex w-100 flex-wrap img-overflow tlw-pl-4 md:tlw-pl-0">
                  <div class="position-relative mt-3" v-for="(image, index) in images" :key="index">
                    <div v-if="isSelectedImage(image.url)" class="position-absolute w-100 text-right">
                      <hs-icon class="text-primary mr-2" variant="solid" icon="check-circle" />
                    </div>
                    <figure
                      id="image-selector"
                      class="tlw-mb-0"
                      :class="{ 'border-2': isSelectedImage(image.url), 'border-primary': isSelectedImage(image.url) }"
                      :style="{ backgroundImage: `url(${image.url})` }"
                      @click="selectFigureAsBg(image)"
                    />
                  </div>
                </div>
              </hs-group>
            </template>
            <hr class="tlw-w-full tlw-mt-8 tlw-mb-0" />
          </form>
          <div class="buttons-container tlw-flex tlw-w-full tlw-justify-center lg:tlw-justify-end tlw-items-center">
            <hs-button
              id="cancel-product"
              variant="outline-primary"
              class="setup__cancel-button lg:tlw-mr-4"
              :disabled="$v.name.$invalid"
              @click="goToSparkMembers()"
            >
              {{ $t('sparkmembers.product-creator.setup.cancel-button.label') }}
            </hs-button>
            <hs-button
              v-if="customLabel === 'Webinar' && steps === 1"
              id="save-product"
              variant="primary"
              class="setup__save-button"
              size="lg"
              :disabled="$v.$invalid"
              @click="steps++"
            >
              {{ $t('sparkmembers.product-creator.setup.type-webinar.button') }}
            </hs-button>
            <hs-button
              v-else
              id="save-product"
              variant="primary"
              class="setup__save-button"
              size="lg"
              :disabled="$v.$invalid"
              @click="onCreate"
            >
              {{ $t('sparkmembers.product-creator.setup.create-button.label') }}
            </hs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MButton from '@/shared/components/MButton.vue';
import NichesModal from './components/NichesModal';
import { unsplashService, uploadService } from '@/services';
import { hsLoading } from '@/components';
import { required } from 'vuelidate/lib/validators';
import ToastHelper from '@/shared/helpers/toast';
import ValidatorsHelper from '@/shared/helpers/validators';
import WebinarHerospark from '@/components/WebinarHerospark';
import CropImage from '@/components/CropImage.vue';
import Icon from '@/components-new/Icon.vue';

const courseKindTranslationKey = {
  online_course: 'online_course',
  file: 'file',
  ebook: 'ebook',
  Quiz: 'Quiz',
  webinar: 'webinar',
  path: 'path',
  live_meet: 'live_meet',
};

export default {
  props: ['props'],
  components: {
    NichesModal,
    hsLoading,
    MButton,
    WebinarHerospark,
    CropImage,
    Icon,
  },
  data() {
    return {
      isLoading: false,
      selectedNiche: null,
      selectedImage: null,
      imageHorizontal: null,
      imageVertical: null,
      isSavingCropImage: false,
      webinar_description: '',
      isImageFormatInvalid: false,
      unsplashImageUrl: null,
      description: '',
      chat_url: '',
      origin_url: '',
      name: this.$t(`sparkmembers.product-creator.setup.name-options.${Math.floor(Math.random() * 10) + 10}`),
      steps: 1,
      niches: [
        {
          name: 'languages',
          variant: 'outline-secondary',
        },
        {
          name: 'personal-development',
          variant: 'outline-secondary',
        },
        {
          name: 'fashion-and-beauty',
          variant: 'outline-secondary',
        },
        {
          name: 'finances-and-investments',
          variant: 'outline-secondary',
        },
        {
          name: 'public-tender',
          variant: 'outline-secondary',
        },
        {
          name: 'business-and-career',
          variant: 'outline-secondary',
        },
        {
          name: 'information-technology',
          variant: 'outline-secondary',
        },
        {
          name: 'animals-and-plants',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'apps-and-software',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'home-and-construction',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'cooking-and-gastronomy',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'design',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'law',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'ecology-and-environment',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'education',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'entretainment',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'spirituality-and-religion',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'hobbies',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'internet',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'music-and-arts',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'relationships',
          variant: 'outline-secondary',
          isHidden: true,
        },
        {
          name: 'health-and-sports',
          variant: 'outline-secondary',
          isHidden: true,
        },
      ],
      otherNiche: {
        name: 'other',
        variant: 'outline-secondary',
      },
      images: [],
      currentImageType: null,
    };
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    ...mapState('course', {
      course: state => state.selectedCourse,
    }),
    selectedImageAuthor() {
      const searchItem = this.images.find(i => i.selected);
      return searchItem ? searchItem.author : '';
    },
    verticalImageButtonLabel() {
      if (this.isSavingVertical) return 'Carregando';
      return this.imageVertical ? 'Alterar' : 'Enviar imagem';
    },
    horizontalImageButtonLabel() {
      if (this.isSavingHorizontal) return 'Carregando';
      return this.imageHorizontal ? 'Alterar' : 'Enviar imagem';
    },
    selectedProfileUrlAuthor() {
      return this.images.find(i => i.selected).profileUrl;
    },
    cropSubtitle() {
      const dimensions = this.currentImageType === 'horizontal' ? '1280x720' : '417x720';
      return `Ajuste o tamanho da sua imagem para aproveitar o máximo dela nas dimensões de ${dimensions} pixels.`;
    },
    isSavingHorizontal() {
      return this.isSavingCropImage && this.currentImageType === 'horizontal';
    },
    isSavingVertical() {
      return this.isSavingCropImage && this.currentImageType === 'vertical';
    },
    horizontalImageButtonIcon() {
      return this.imageHorizontal ? 'arrows-rotate' : 'cloud-upload';
    },
    verticalImageButtonIcon() {
      return this.imageVertical ? 'arrows-rotate' : 'cloud-upload';
    },
    customLabel() {
      if (!this.props) return;
      const courseType = this.props[0].type;
      const translationKey = courseKindTranslationKey[courseType];
      return this.$t(`sparkmembers.product-creator.setup.types.${translationKey}`);
    },
    currentAspectRatio() {
      return this.currentImageType === 'horizontal' ? Number(1280 / 720) : Number(417 / 720);
    },
    estiloImagemVertical() {
      if (this.imageVertical) {
        return {
          backgroundImage: `url(${this.imageVertical})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        };
      }
      return {};
    },
    estiloImagemHorizontal() {
      if (this.imageHorizontal) {
        return {
          backgroundImage: `url(${this.imageHorizontal})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        };
      }
      return {};
    },
  },
  validations: {
    name: {
      required,
      noQuotes: ValidatorsHelper.noQuotes(),
    },
    imageHorizontal: {
      required,
    },
  },
  methods: {
    isSelectedImage(imageUrl) {
      return imageUrl === this.imageHorizontal;
    },
    removeImageVertical(e) {
      e.preventDefault();
      this.imageVertical = null;
      this.isImageFormatInvalid = false;
    },
    selectFigureAsBg(image) {
      this.imageHorizontal = image.url;
      this.unsplashImageUrl = image;
    },
    handleImageUpload(type, e) {
      e.preventDefault();
      this.isImageFormatInvalid = false;
      this.currentImageType = type;
      this.$refs.file.click();
    },
    onFileInputChange(e) {
      const file = e.target.files[0];

      if (!file) return;

      if (!file.type.match(/^image\/(jpeg|jpg|png)$/)) {
        this.isImageFormatInvalid = true;
        e.target.value = '';
        return;
      }

      this.loadImageToCrop(file);
      e.target.value = '';
      this.isImageFormatInvalid = false;
    },
    loadImageToCrop(file) {
      const reader = new FileReader();
      reader.onload = blob => {
        this.selectedImage = blob.target?.result;
      };
      reader.readAsDataURL(file);
    },
    onNicheSelected(niche) {
      this.selectedNiche = niche;
      if (niche.isHidden) niche.isHidden = false;
      if (niche.name === 'other') this.$bvModal.show('other-niches-modal');
      else this.loadImages(niche.name);
    },
    onSelectDefaultNiche() {
      this.selectedNiche = this.niches[0];
      this.loadImages(this.selectedNiche.name);
    },
    onImageSelected(image) {
      if (this.currentImageType === 'horizontal') {
        this.imageHorizontal = image;
        this.unsplashImageUrl = null;
      } else {
        this.imageVertical = image;
      }
    },
    loadImages(term, cloneObj = undefined) {
      this.isLoading = true;
      unsplashService
        .getImages([
          {
            key: 'per_page',
            value: 7,
          },
          {
            key: 'page',
            value: 1,
          },
          {
            key: 'query',
            value: term,
          },
        ])
        .then(({ results }) => {
          this.images = results.map(r => ({
            url: r.urls.regular,
            selected: false,
            author: r.user.name,
            downloadRegistrationUrl: r.links.download_location,
            profileUrl: `${r.user.links.html}?utm_source=herospark&utm_medium=referral`,
          }));
          if (cloneObj) this.images.push(cloneObj);
          this.isLoading = false;
        });
    },
    async onFile(file) {
      this.isSavingCropImage = true;
      try {
        const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
        const imgURL = await uploadService.upload(data.url, data.fields, file);
        const newImage = { selected: false, url: imgURL, uploaded: true };
        this.onImageSelected(newImage.url);
        this.selectedImage = null;
        this.isSavingCropImage = false;
        ToastHelper.successMessage(this.$t('Imagem carregada com sucesso!'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.product-creator.setup.image-input.uploader.upload-error-msg'));
        this.isSavingCropImage = false;
      }
    },
    async onCreate() {
      this.isLoading = true;
      const selectedNiche = this.selectedNiche;
      const selectedHorizontalImage = this.imageHorizontal;
      const selectedVerticalImage = this.imageVertical;
      const selectedUnsplashImage = this.unsplashImageUrl;
      try {
        let horizontalImageURL = null;

        if (selectedUnsplashImage) {
          const file = await unsplashService.getImage(selectedUnsplashImage.url);
          const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
          horizontalImageURL = await uploadService.upload(data.url, data.fields, file);
          await unsplashService.registerImageDownload(selectedUnsplashImage.downloadRegistrationUrl);
        } else {
          horizontalImageURL = selectedHorizontalImage;
        }

        this.$emit('save', {
          name: this.name,
          niche: selectedNiche.name,
          imgURL: horizontalImageURL,
          verticalURL: selectedVerticalImage,
          description: this.webinar_description,
          origin_url: this.origin_url,
          chat_url: this.chat_url,
        });
      } catch (e) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.product-creator.setup.image-input.uploader.upload-error-msg'));
      } finally {
        this.isLoading = false;
      }
    },
    async goToSparkMembers() {
      this.$router.push({ name: 'ProductsList' });
    },
    completeStep(payload) {
      this.demoSteps.forEach(step => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },
    onEmitDescription(description_webinar) {
      this.webinar_description = description_webinar;
    },
    onEmitUrl(url) {
      this.origin_url = url;
    },
    onEmitUrlChat(url_other) {
      this.chat_url = url_other;
    },
    // Executed when @active-step event is triggered
    isStepActive(payload) {
      this.demoSteps.forEach(step => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }
        }
      });
    },
  },
  created() {
    const product_clone = this.props[0].product_clone;
    if (product_clone) {
      const cloneImg = { selected: false, url: product_clone.logo, uploaded: true };
      this.name = `${product_clone.title} (Cópia)`;
      this.selectedNiche = this.niches.find(niche => niche.name === product_clone.niche);
      this.loadImages(product_clone.niche, cloneImg);
    } else {
      this.loadImages('languages');
      this.selectedNiche = this.niches[0];
    }
  },
};
</script>

<style lang="scss" scoped>
.badge-success {
  background-color: #348535;
}
.upload-button {
  padding: 8px 16px;
  font-size: 16px;
}
.remove-image-vertical {
  color: $cherry;
  padding: 8px 16px;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
}

.image-preview {
  background-color: #f0f0f0;
  &.horizontal {
    min-width: 158px;
    height: 89px;
  }
  &.vertical {
    min-width: 90px;
    height: 156px;
  }
}
.informative-icon {
  color: #3575d4;
}
.webinar-stepper {
  max-width: 380px;
}
.stepper-item {
  background: $purple-dark;
  border: 2px solid $purple-dark;
  transition: 0.4s all;
  font-weight: 600;
}

.currentstepper {
  background: $purple-dark;
}

.buttons-container {
  padding: 0 32px 32px 32px;
}

.stepActive {
  background: #388e3c;
  border: none;
}
.stepDisabled {
  background: $grey-17;
  border: 2px solid $grey-ba;
  color: $grey-ba;
}
.fa-check:after {
  transition: 0.5s all;
}
.webinar-divider {
  margin: 16px 0 26px 0;
}
/deep/ .badge {
  cursor: pointer;
  user-select: none;
}
.badge-primary {
  background-color: $purple-dark;
}
.border-primary {
  border-color: $purple-dark !important;
}
.text-primary {
  color: #388e3c !important;
}
.fa-check-circle {
  &:before {
    background: #fff;
    border-radius: 100%;
  }
}
figure {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  height: 46px;
  width: 76px;
  max-width: initial;
  border-radius: 8px;
}
.info {
  background: #b3dafe;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}
.info-icon {
  border: 2px solid #0c2678;
  color: #0c2678;
}
.border-2 {
  border: 2px solid;
}
.btn {
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  height: 36px;
}
.stepper-box .top .steps-wrapper .step .circle {
  background-color: #897e9b;
}
.setup {
  &__save-button {
    background-color: $purple-dark;
    width: 170px;

    &:hover,
    &:focus {
      background-color: #4d1aa1;
    }
    &:disabled {
      background-color: $grey-ba;
    }
  }
  &__cancel-button {
    background-color: transparent;
    border-color: $purple-dark;
    color: $purple-dark;
    width: 140px;

    &:hover,
    &:focus {
      border-color: $purple-dark;
      background-color: $purple;
      color: $purple-dark;
    }
  }
}

.img-overflow {
  gap: 12px;
}

@media only screen and (max-width: $screen-sm) {
  .buttons-container {
    padding: 0 16px 32px 16px;
    gap: 10px;
  }
  .setup {
    &__save-button {
      width: 165px;
      height: 48px;
    }
    &__cancel-button {
      width: 151px;
      height: 48px;
    }
  }
  figure {
    width: 84px;
    height: 48px;
  }
  .img-overflow {
    width: 100% !important;
    overflow-x: auto;
    flex-wrap: initial !important;
  }
  .image-preview {
    &.horizontal {
      min-width: 128px;
      height: 72px;
    }
  }
}
</style>
