<template>
  <div>
    <section class="d-flex flex-column flex-fill md:tlw-bg-white">
      <hsLoading v-if="isLoading" class="flex-fill" />
      <div class="md:tlw-bg-white tlw-px-4 md:tlw-px-8 tlw-py-6" v-else>
        <section class="d-flex flex-fill flex-column">
          <h4 class="font-weight-bold tlw-text-xl mb-3">
            {{ $t('sparkmembers.coupons.views.list.index.title') }}
          </h4>
          <h3 class="font-size-sm mb-1">
            {{ $t('sparkmembers.coupons.views.list.index.sub-title') }}
          </h3>
          <h3 class="font-size-sm mb-0">
            {{ $t('sparkmembers.coupons.views.list.index.sub-title2') }}
          </h3>
          <div class="d-flex my-4" v-if="hasCoupons">
            <m-button variant="primary" class="tlw-w-full md:tlw-w-auto" @click="$bvModal.show('coupon-creator-modal')">
              {{ $t('sparkmembers.coupons.views.list.components.page-header.create-button-label') }}
            </m-button>
          </div>
          <NoCoupons v-if="!hasCoupons" />
          <CouponsTable v-if="hasCoupons" id="coupons-table" :coupons="coupons" @delete="couponDeletionRequested" />
          <div v-if="hasCoupons && totalNumberOfCoupons > 10" class="d-flex flex-column flex-fill justify-content-end">
            <hs-pagination
              v-model="currentPage"
              :go-page-text="$t('sparkmembers.coupons.views.list.index.pagination.go-to-page')"
              :per-page="10"
              :total-rows="totalNumberOfCoupons"
              align="center"
            />
          </div>
        </section>
      </div>
      <CouponCreatorModal id="coupon-creator-modal" @createButtonClick="createCoupon" />
      <hsConfirmModal
        id="delete-coupon-modal"
        icon="trash-alt"
        variant="cherry"
        :customTitle="$t('sparkmembers.coupons.views.list.index.delete-coupon-modal.title')"
        :description="$t('sparkmembers.coupons.views.list.index.delete-coupon-modal.description')"
        :ok-title="$t('sparkmembers.coupons.views.list.index.delete-coupon-modal.ok-title')"
        :cancel-title="$t('sparkmembers.coupons.views.list.index.delete-coupon-modal.cancel-title')"
        @ok="deleteCoupon"
        @cancel="couponToDelete = null"
      />
    </section>

    <div class="global-coupon-info">
      <div class="global-coupon-info__text-wrapper">
        <Icon name="ticket" prefix="fal" />
        <p class="global-coupon-info__text">
          Crie cupons globais para todos os seus produtos.
        </p>
      </div>

      <MXButton
        variant="tertiary"
        icon="fal fa-external-link"
        class="global-coupon-info__button"
        @click="openGlobalCoupon()"
      >
        Abrir cupom global
      </MXButton>
    </div>
  </div>
</template>

<script>
import CouponsTable from './components/CouponsTable';
import { analyticsService, couponService } from '@/services';
import generalHelper from '@/shared/helpers/general';
import { hsLoading } from '@/components';
import CouponCreatorModal from './components/CouponCreatorModal';
import { hsConfirmModal } from '@/components';
import toastHelper from '@/shared/helpers/toast';
import NoCoupons from './components/NoCoupons';
import MButton from '@/shared/components/MButton.vue';
import datesHelper from '@/shared/helpers/dates';
import Icon from '@/components-new/Icon.vue';
import MXButton from '@/shared/components/MXButton.vue';

export default {
  name: 'CouponsList',
  data() {
    return {
      coupons: [],
      currentPage: 1,
      totalNumberOfCoupons: 0,
      isLoading: false,
      couponToDelete: null,
    };
  },
  watch: {
    currentPage(newVal) {
      this.mountCouponsList(newVal);
    },
  },
  computed: {
    hasCoupons() {
      return this.coupons.length > 0;
    },
  },
  methods: {
    async mountCouponsList(page) {
      this.isLoading = true;
      try {
        const res = await couponService.get([
          { key: 'per_page', value: '10' },
          { key: 'page', value: page },
          { key: 'product_ids', value: this.$route.params.id },
        ]);
        this.coupons = res.coupons.map(c => ({
          id: c.id,
          name: c.name,
          discount: c.percentual ? `${c.value}%` : generalHelper.currency(c.value / 100),
          quantity: c.quantity,
          usedQuantity: c.used_quantity,
          availableUntil: datesHelper.format({ date: c.available_until, format: 'DD/MM/YYYY' }),
        }));
        this.totalNumberOfCoupons = res.total_count;
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t('sparkmembers.coupons.views.list.index.toast-messages.coupons-list-mounting-error')
        );
      }
      this.isLoading = false;
    },
    async createCoupon({ name, expirationDate, quantity, value, percentual }) {
      this.isLoading = true;
      this.$bvModal.hide('coupon-creator-modal');
      try {
        await couponService.create({
          name,
          available_until: datesHelper.utc(expirationDate),
          availableUntil: datesHelper.utc(expirationDate),
          quantity,
          value,
          percentual,
          product_ids: [this.$route.params.id],
          global: true,
          token_type: 'manual',
        });
        toastHelper.successMessage(
          this.$t('sparkmembers.coupons.views.list.index.toast-messages.coupon-creation-success')
        );
        analyticsService.track({
          event: 'Coupon created',
          props: {
            product_id: this.$route.params.id,
            discount: value,
            percentage: percentual,
            global: false,
          },
        });
        await this.mountCouponsList(1);
      } catch ({ response }) {
        toastHelper.dangerMessage(
          this.$t('sparkmembers.coupons.views.list.index.toast-messages.coupon-creation-errors.default')
        );
      }
      this.isLoading = false;
    },
    couponDeletionRequested(cid) {
      this.couponToDelete = cid;
      this.$bvModal.show('delete-coupon-modal');
    },
    async deleteCoupon() {
      this.isLoading = true;
      try {
        await couponService.delete(this.couponToDelete);
        await this.mountCouponsList(1);
        this.couponToDelete = null;
        toastHelper.successMessage(
          this.$t('sparkmembers.coupons.views.list.index.toast-messages.coupon-deletion-success')
        );
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t('sparkmembers.coupons.views.list.index.toast-messages.coupon-deletion-error')
        );
      }
      this.isLoading = false;
    },
    openGlobalCoupon() {
      this.$router.push({
        name: 'GlobalCoupon',
      });
    },
  },
  components: {
    CouponsTable,
    hsLoading,
    CouponCreatorModal,
    hsConfirmModal,
    NoCoupons,
    MButton,
    Icon,
    MXButton,
  },
  created() {
    this.mountCouponsList(1);
  },
};
</script>

<style lang="scss" scoped>
.global-coupon-info {
  padding: 8px 16px;
  background-color: #efefef;
  border-radius: 4px;
  border: 0.5px solid #cfcfcf;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 8px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 24px;
  margin-left: 16px;
  margin-right: 16px;

  @media (min-width: $screen-sm) {
    padding: 8px 20px;
    margin-left: 0;
    margin-right: 0;
    grid-template-columns: 1fr 190px;
  }
}

.global-coupon-info__text-wrapper {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.global-coupon-info__text {
  color: $grey;
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin: 0;
}

.global-coupon-info__button {
  width: 100%;
  justify-self: end;
}
</style>
