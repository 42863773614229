



















































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TypeSelect extends Vue {
  @Prop({ default: [] }) products: Array<any>;
  @Prop({ default: Boolean }) isChoosingLive: Boolean;

  redirectToCreateProduct(type: string) {
    const { location = 'product_catalog' } = this.$route.query;

    if (type == 'lives') this.$emit('liveSelected');
    else this.$emit('selected', type);
    this.$router.push({ name: 'ProductCreator', query: { type, location } });
  }
}
