<template>
  <div class="row">
    <div class="col-12 col-lg-8 mt-2 mb-3 mt-lg-0 mb-lg-5">
      <div class="bg-white rounded-lg py-3 px-4 py-lg-4 px-lg-5">
        <b-form @submit.prevent="onSubmit">
          <hs-group>
            <label class="font-weight-bold">{{ $t('sparkmembers.sales-page.seo.form.title') }}</label>
            <hs-input type="text" v-model="form.meta_title" :disabled="isSaving" />
          </hs-group>
          <hs-group>
            <label class="font-weight-bold">{{ $t('sparkmembers.sales-page.seo.form.keys') }}</label>
            <hs-input type="text" text="Separe por vírgulas" v-model="form.meta_keys" :disabled="isSaving" />
          </hs-group>
          <hs-group>
            <label class="font-weight-bold">{{ $t('sparkmembers.sales-page.seo.form.description') }}</label>
            <b-form-textarea no-resize rows="6" max-rows="6" v-model="form.meta_description" :disabled="isSaving" />
          </hs-group>
          <div class="d-flex w-100 justify-content-end mt-5">
            <hs-button :variant="isSaving ? 'light' : 'primary'" :disabled="isSaving" type="submit">
              <b-spinner small type="grow" v-if="isSaving" />
              {{ $t(`sparkmembers.sales-page.seo.form.${isSaving ? 'saving' : 'save'}`) }}
            </hs-button>
          </div>
        </b-form>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="d-flex bg-white rounded-lg py-3 px-4 py-lg-4 px-lg-5">
        <hs-icon icon="globe" :size="36" />
        <div class="ml-3">
          <h5 class="font-weight-bold">{{ $t('sparkmembers.sales-page.seo.column.title') }}</h5>
          <p class="mt-1 mb-0">{{ $t('sparkmembers.sales-page.seo.column.text') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { analyticsService, productService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  watch: {
    form(val) {
      this.form = val;
    },
  },
  methods: {
    async onSubmit() {
      this.isSaving = true;

      productService
        .update(this.form)
        .then(() => {
          ToastHelper.successMessage(this.$t('sparkmembers.sales-page.toast.product.success'));
          analyticsService.track({
            event: 'Product SEO info updated',
            props: { product_id: this.form.id },
          });
        })
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.product.error'));
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>
