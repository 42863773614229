<template>
  <div>
    <hsModal :id="modalId" :size="'lg'" hide-header no-close-on-backdrop no-close-on-esc>
      <div
        class="d-flex product-modal-content flex-column align-items-center relative appcues-enablement-welcome-step-5"
      >
        <hs-button variant="link" class="p-3 close-btn" @click="goToCatalog">
          <hs-icon icon="times" />
        </hs-button>
        <div
          class="header-icon d-flex align-items-center justify-content-center"
          :class="{ 'tlw-mb-5': !isClone }"
          variant="primary"
        >
          <img v-if="isClone" width="80" height="80" :src="modalData.imageUrl" alt="copy-animated-icon" />
          <hs-icon v-else :icon="modalData.icon" size="64" />
        </div>
        <h4 class="mt-3 mb-2 font-weight-bold">{{ modalData.title }}</h4>
        <p class="text-center tlw-mb-5">
          {{ modalData.subtitle }}
        </p>
        <div class="card-product tlw-flex tlw-flex-col tlw-px-6 tlw-py-5 tlw-mb-3">
          <div class="d-flex flex-column tlw-gap-1 tlw-mb-4 tlw-text-sm">
            <span class="font-weight-bold">
              {{ this.$t('sparkmembers.products-list.modal.card-product.title') }}
            </span>
            <span class="tlw-mb-0">
              {{ this.product.title }}
            </span>
            <span class="tlw-mb-0" v-if="payment_options.length > 0">
              {{ normalizeProductPrice() }}
            </span>
          </div>
          <div v-if="isSchoolValidated">
            <span class="tlw-text-sm font-weight-bold">
              {{ this.$t('sparkmembers.products-list.modal.card-product.sales-page') }}
            </span>
            <div class="tlw-flex">
              <a class="tlw-block tlw-text-xs tlw-font-normal card-product-link" :href="linkProduct()" target="_blank">
                {{ linkProduct() }}
              </a>
            </div>
          </div>
        </div>
        <div v-if="hasMemberArea" class="tlw-flex tlw-flex-col lg:tlw-flex-row w-100">
          <div v-if="!isClone" class="card tlw-p-4 tlw-mr-1">
            <h5 class="mb-1">{{ this.$t('sparkmembers.products-list.modal.card.title') }}</h5>
            <p class="mb-0 font-size-sm">
              {{ this.$t('sparkmembers.products-list.modal.card.content') }}
            </p>
            <m-button
              variant="primary"
              class="card-product-button tlw-text-sm tlw-font-semibold tlw-mt-4 tlw-mb-0 tlw-bg-purple-500 hover:tlw-bg-purple-500 w-12rem"
              @click="goToContent"
            >
              {{ this.$t('sparkmembers.products-list.modal.card.content-button') }}
            </m-button>
          </div>
          <div class="card tlw-p-4 tlw-ml-1" :class="{ 'product-card-clone': isClone }">
            <h5 class="mb-1">{{ this.$t('sparkmembers.products-list.modal.card.sales-title') }}</h5>
            <p class="mb-0 font-size-sm">
              {{ this.$t('sparkmembers.products-list.modal.card.sales-content') }}
            </p>
            <m-button
              variant="primary"
              class="card-product-button tlw-text-sm tlw-font-semibold tlw-mt-4 tlw-mb-0 tlw-bg-purple-500 hover:tlw-bg-purple-500 w-16rem"
              @click="goToSales"
            >
              {{ this.$t('sparkmembers.products-list.modal.card.sales-content-button') }}
            </m-button>
          </div>
        </div>
      </div>
      <input type="hidden" ref="link-checkout" />
    </hsModal>
  </div>
</template>

<script>
import Clipboard from '@/shared/mixins/Clipboard';
import { hsModal } from '@/components';
import MButton from '@/shared/components/MButton.vue';
import { analyticsService } from '@/services';
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductUrlHelper from '@/sparkmembers/helpers/productUrlHelper';
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'new-product-modal',
  components: {
    hsModal,
    MButton,
  },
  props: {
    isClone: {
      type: Boolean,
    },
  },
  mixins: [Clipboard],
  computed: {
    ...mapState({
      product: state => state.product.selectedProduct,
      course: state => state.course.selectedCourse,
    }),
    ...mapGetters('school', ['getDomain', 'isSchoolValidated']),
    modalId() {
      return 'new-product-created-modal';
    },
    hasMemberArea() {
      return this.$FCL.hasMemberArea();
    },
  },
  data() {
    return {
      isLoading: false,
      payment_options: [],
      selectedLink: null,
      modalData: this.isClone
        ? {
            icon: '',
            imageUrl: require('@/assets/images/animations/duplicate.gif'),
            title: this.$t('sparkmembers.products-list.modal.duplicate.title'),
            subtitle: this.$t('sparkmembers.products-list.modal.duplicate.subtitle'),
          }
        : this.isSchoolValidated
        ? {
            icon: 'check-circle',
            title: this.$t('sparkmembers.products-list.modal.title'),
            subtitle: this.$t('sparkmembers.products-list.modal.sub-title'),
          }
        : {
            icon: 'check-circle',
            title: this.$t('sparkmembers.products-list.modal.title'),
            subtitle: this.$t('sparkmembers.products-list.modal.not-validated-subtitle'),
          },
    };
  },
  methods: {
    ...mapActions('product', ['getProductPaymentOptions']),
    async fetchPaymentOptions() {
      this.isLoading = true;
      try {
        this.payment_options = await this.getProductPaymentOptions({ id: this.product.id });
        this.payment_options = this.payment_options.map(payment => ({
          ...payment,
          price: payment.price / 100,
        }));
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    goToContent() {
      if (this.course.kind === 'live_meet') {
        this.$router.push({
          name: 'ContentLives',
          params: { id: this.product.id, productTitle: this.product.title, courseId: this.course.id },
        });
      } else {
        this.$router.push({ name: 'ProductDashboard', params: { id: this.product.id } });
      }
      analyticsService.track({
        event: 'Product Creation Next Step Chose',
        props: {
          location: 'content',
        },
      });
    },
    goToCatalog() {
      analyticsService.track({
        event: 'Product Creation Next Step Chose',
        props: {
          location: 'catalog',
        },
      });
      this.$bvModal.hide(this.modalId);
    },
    goToSales() {
      analyticsService.track({
        event: 'Product Creation Next Step Chose',
        props: {
          location: 'sales',
        },
      });
      this.$router.push({ name: 'ProductSetup', params: { id: this.product.id } });
    },
    generatelinkProduct(defaultDomain, productSlug) {
      return ProductUrlHelper.generateUrlProduct(defaultDomain, productSlug);
    },
    linkProduct() {
      return this.generatelinkProduct(this.getDomain, this.product.slug);
    },
    normalizeProductPrice() {
      return GeneralHelper.currency(this.payment_options[0].price);
    },
  },
  mounted() {
    this.fetchPaymentOptions();
  },
};
</script>

<style lang="scss" scoped>
.product-modal-content {
  a {
    color: #2cc3cc;
    cursor: pointer;
    &:hover {
      color: #2cc3cc;
      text-decoration: underline;
    }
  }
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  .header-icon {
    border-radius: 100%;

    i {
      color: #388e3c;
    }
  }
  h4 {
    font-size: 24px;
    color: #262626;
  }
  p {
    color: #3f3f3f;
    font-size: 16px;
    font-weight: normal;
  }
  .card {
    width: 50%;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    h5 {
      font-size: 14px;
      font-weight: 600;
      color: #262626;
      line-height: 150%;
    }
    p {
      font-size: 14px;
    }
    &-product {
      background-color: #f2f2f2;
      border-radius: 4px;
      width: 100%;

      &-text {
        font-size: 14px;
        font-weight: 600;
      }
      &-link {
        color: #2050b5;
        padding: 0 !important;

        &:hover {
          color: #2050b5;
        }
      }
      &-button {
        color: #ffffff;
        line-height: 10px;
        padding: 13px;

        &:hover {
          color: #ffffff;
        }
      }
    }
    .w-12rem {
      width: 12rem;
    }

    .w-16rem {
      width: 16rem;
    }

    &.product-card-clone {
      width: 100%;
    }

    @media screen and (max-width: $screen-md) {
      width: 100%;
    }
  }
}
</style>
