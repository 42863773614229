import { render, staticRenderFns } from "./ProductCreateModal.vue?vue&type=template&id=c320c8ec&scoped=true&"
import script from "./ProductCreateModal.vue?vue&type=script&lang=js&"
export * from "./ProductCreateModal.vue?vue&type=script&lang=js&"
import style0 from "./ProductCreateModal.vue?vue&type=style&index=0&id=c320c8ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c320c8ec",
  null
  
)

export default component.exports