<template>
  <div class="product-sidebar d-flex justify-content-end" :class="{ '--not_validated': isValidatedSchool }">
    <div
      v-if="loading || isLoading"
      class="product-sidebar__loading h-100 d-flex justify-content-center bg-white align-items-center"
    >
      <hs-loading />
    </div>

    <div v-else class="product-sidebar__content position-relative" :class="{ '--not_validated': isValidatedSchool }">
      <div class="h-5 product-sidebar__close-btn">
        <hs-button variant="link" @click="$emit('close')">
          <hs-icon icon="times" size="20" />
        </hs-button>
      </div>

      <div class="h-5 product-sidebar__badge-status bg-white d-flex">
        <hs-badge pill v-if="product.published" class="mr-1 custom-badge">
          {{ $t('sparkmembers.v2-product-list.sidebar.published') }}
        </hs-badge>
        <hs-badge v-else pill class="mr-1">
          {{ $t('sparkmembers.v2-product-list.sidebar.not-published') }}
        </hs-badge>
      </div>

      <div class="w-100 d-flex flex-column tlw-pt-4 tlw-pb-6 tlw-px-4 border-bottom">
        <div class="w-100 d-flex wrap flex-column">
          <hs-editable-text
            class="mb-2"
            id="editable-text"
            @save="save"
            @cancel="cancel"
            v-model="productName"
            :allowSave="!$v.productName.$invalid"
            :alignment="'start'"
            :customClass="['h4', 'font-weight-bold', 'lh-125']"
          >
            <div class="position-relative">
              <label for="edit-product-name" class="position-absolute mb-0 font-weight-bold custom-label">{{
                $t('sparkfunnels.funnels.edit.funnel_name')
              }}</label>
              <hs-input id="edit-product-name" v-model="productName"> </hs-input>
              <hs-form-invalid-feedback :state="false">
                <span class="font-size-xs" v-if="!$v.productName.noQuotes">
                  {{ $t('sparkmembers.product-creator.setup.name-input.validation.no-quotes') }}
                </span>
              </hs-form-invalid-feedback>
            </div>
          </hs-editable-text>

          <MButton
            label="Editar foto de capa"
            icon="image"
            iconSize="xs"
            class="edit-image-button tlw-my-4 tlw-px-2 tlw-gap-2"
            variant="primary-outline"
            @click="$emit('openImageSidebar')"
          />
          <div class="d-flex align-items-center font-size-sm mb-2">
            <div class="description-icon tlw-mr-3 d-flex justify-content-center">
              <hs-icon class="" icon="calendar-alt" />
            </div>
            <span class="title">{{ $t('sparkmembers.v2-product-list.sidebar.created-at') }} {{ createdAt }}</span>
          </div>

          <div class="d-flex align-items-center font-size-sm mb-2">
            <div class="description-icon tlw-mr-2 d-flex justify-content-center">
              <hs-icon class="tlw-text-xs" icon="users" />
            </div>
            <span class="title">{{ membersCountText }}</span>
          </div>

          <div class="d-flex align-items-center font-size-sm">
            <div class="description-icon tlw-mr-3 d-flex justify-content-center">
              <hs-icon class="pointer" icon="link" />
            </div>
            <div
              :id="`popover-target-sidebar-${product.id}`"
              @click="showProductLinks(`popover-target-sidebar-${product.id}`)"
            >
              <span class="title popover-triger pointer">
                {{ $t('sparkmembers.v2-product-list.sidebar.link-popover') }}
              </span>
              <hs-icon
                class="ml-2 pointer popover-triger"
                :id="`popover-target-sidebar-${product.id}`"
                icon="chevron-down"
              />
            </div>
            <b-popover
              :ref="`popover-target-sidebar-${product.id}`"
              :target="`popover-target-sidebar-${product.id}`"
              custom-class="hs-popover"
              placement="bottomright"
              @hidden="closeProductLinks(`popover-target-sidebar-${product.id}`)"
            >
              <ProductLinks
                v-if="isProductLinksOpen"
                tracking="popOver_drawer"
                :product="product"
                @close="closeProductLinks(`popover-target-sidebar-${product.id}`)"
                :get-product-offers="getProductOffers"
                :url-link="urlLink"
                :offer-page-link="offerPageLink"
              />
            </b-popover>
          </div>
        </div>
      </div>

      <div class="product-sidebar__publish border-bottom">
        <span class="font-weight-bold">{{ $t('sparkmembers.v2-product-list.sidebar.publish-product') }}</span>
        <MSwitch
          v-model="published"
          :disabled="!canEditProduct || !productHasNoCourse || isValidatedSchool"
          id="publish-toggle"
          @change="value => updatePublish(value)"
          class="pt-1"
          v-b-tooltip.hover.left="isValidatedSchool ? setPublishDisabledTooltip : ''"
        />
      </div>

      <div v-if="canAccessProduct" class="w-100 d-flex tlw-px-4 tlw-py-6 border-bottom flex-column">
        <h5 class="font-weight-bold">{{ $t('sparkmembers.v2-product-list.sidebar.content-config') }}</h5>
        <p class="font-size-sm mt-2 lh-150">
          {{ $t('sparkmembers.v2-product-list.sidebar.content-config-description') }}
        </p>
        <div class="tlw-inline-block tlw-mt-5 w-100 text-right">
          <span
            id="content-config-button-wrapper"
            class="content-button-wrapper"
            v-b-tooltip.hover.top="noCourseTooltip"
            tabindex="0"
          >
            <a
              id="content-config-button"
              class="custom-button-link"
              :disabled="!productHasNoCourse"
              :href="getProductSetupURL"
              @click="goToContent"
            >
              {{ $t('sparkmembers.v2-product-list.sidebar.edit') }}
            </a>
          </span>
        </div>
      </div>

      <div v-if="canAccessSale" class="w-100 d-flex tlw-px-4 tlw-py-6 border-bottom flex-column">
        <h5 class="font-weight-bold">{{ $t('sparkmembers.v2-product-list.sidebar.sales-config') }}</h5>
        <p class="font-size-sm mt-2 lh-150">
          {{ $t('sparkmembers.v2-product-list.sidebar.sales-description') }}
        </p>
        <div class="tlw-inline-block w-100 tlw-mt-5 text-right">
          <span
            id="content-config-button-wrapper"
            class="content-button-wrapper"
            v-b-tooltip.hover.top="noCourseTooltip"
          >
            <a class="custom-button-link" :disabled="!productHasNoCourse" :href="getSalesURL" @click="goToSalesPage">
              {{ $t('sparkmembers.v2-product-list.sidebar.edit') }}
            </a>
          </span>
        </div>
      </div>

      <div v-if="canAccessFunnels" class="w-100 d-flex tlw-px-4 tlw-py-6 border-bottom flex-column bg-white">
        <h5 class="font-weight-bold">{{ $t('sparkmembers.v2-product-list.sidebar.spred-product') }}</h5>
        <p class="font-size-sm mt-2 lh-150">
          {{ $t('sparkmembers.v2-product-list.sidebar.spred-product-description') }}
        </p>
        <div class="tlw-inline-block tlw-mt-5 w-100">
          <MButton
            id="go-to-funnels-button"
            type="button"
            class="custom-button"
            variant="primary-outline"
            :label="$t('sparkmembers.v2-product-list.sidebar.created-funnel')"
            @click="goToFunnels"
          />
        </div>
      </div>

      <div
        v-if="isAllowedToClone"
        data-testid="clone-container"
        class="w-100 d-flex justify-content-center tlw-px-4 tlw-pt-6 bg-white text-center"
      >
        <MButton
          data-testid="clone-product-btn"
          :disabled="!canCloneProduct"
          class="product-sidebar__clone d-flex"
          variant="link"
          @click="redirectToCloneProduct"
        >
          <hs-icon class="font-weight-light mr-2" variant="regular" icon="clone" size="16" />
          <span class="font-size-sm">{{ $t('sparkmembers.v2-product-list.sidebar.duplicate.btn') }}</span>
          <hs-icon icon="info-circle" class="info-icon ml-2" :size="16" v-b-tooltip.hover.top="tooltipToDisplay" />
        </MButton>
      </div>

      <div v-if="canDeleteProduct" class="w-100 tlw-px-4 tlw-pt-3 tlw-pb-6 bg-white d-flex justify-content-center">
        <MButton
          id="delete-product-button"
          class="product-sidebar__delete"
          variant="link"
          icon="trash-alt"
          :disabled="!productHasNoCourse"
          :label="$t('sparkmembers.v2-product-list.sidebar.delete-course')"
          @click="deleteProduct"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { hsEditableText, hsLoading } from '@/components';
import { analyticsService, productService, courseService, pathService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';
import Confirm from '@/shared/mixins/Confirm';
import ProductLinks from './ProductLinks';
import ValidatorsHelper from '@/shared/helpers/validators';
import MButton from '@/shared/components/MButton.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import { makeCheckoutUrl } from '@/libs/ecommerce';

dayjs.extend(isSameOrAfter);

export default {
  mixins: [Confirm],
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  components: { hsEditableText, hsLoading, ProductLinks, MButton, MSwitch },
  data: () => ({
    isProductLinksOpen: false,
    isLoading: false,
    productName: '',
    published: false,
    isEnableToChangeStatus: false,
  }),
  mounted() {
    this.productName = this.product.title;
    this.published = this.product.published;
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState('school', ['selectedSchool']),
    ...mapState('auth', ['loggedUser']),

    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },

    canAccessFunnels() {
      return this.$ACL.canFunnelPage();
    },

    canAccessSale() {
      return this.$ACL.canProductSale();
    },

    canAccessProduct() {
      return this.$ACL.canProductConfig() || this.loggedUser.role.name == 'Suporte ao Aluno';
    },

    canEditProduct() {
      return this.$ACL.canProductConfig();
    },

    canDeleteProduct() {
      return this.$ACL.canDeleteProduct();
    },
    createdAt() {
      return dayjs(this.product.created_at).format('DD/MM/YYYY');
    },
    membersCountText() {
      const qtdMembers = this.product.count_total_members_number;
      const text = this.$tc('sparkmembers.v2-product-list.sidebar.registered-members', qtdMembers);

      return `${qtdMembers} ${text}`;
    },
    isAllowedToClone() {
      return this.$ACL.canCreateProduct();
    },
    canCloneProduct() {
      return ['ebook', 'online_course', 'file'].includes(this.product.course?.kind);
    },
    tooltipToDisplay() {
      return this.canCloneProduct
        ? this.$t('sparkmembers.v2-product-list.sidebar.duplicate.tooltip.can-clone')
        : this.$t('sparkmembers.v2-product-list.sidebar.duplicate.tooltip.cant-clone');
    },
    noCourseTooltip() {
      return this.productHasNoCourse ? false : this.$t('O processo de duplicar o produto ainda não foi finalizado');
    },
    productHasNoCourse() {
      return this.product.path || this.product.course;
    },
    setPublishDisabledTooltip() {
      return this.isNewSchool
        ? this.$t('sparkmembers.v2-product-list.sidebar.publish-product-tooltip.new-account')
        : this.$t('sparkmembers.v2-product-list.sidebar.publish-product-tooltip.old-account');
    },
    isValidatedSchool() {
      return !this.selectedSchool?.validated_info?.valid;
    },
    getProductSetupURL() {
      const url = this.$router.resolve({ name: 'ProductDashboard', params: { id: this.product.id } });
      return url.href;
    },
    getSalesURL() {
      const url = this.$router.resolve({ name: 'ProductSetup', params: { id: this.product.id } });
      return url.href;
    },
  },
  watch: {
    product() {
      this.productName = this.product.title;
      this.published = this.product.published;
    },
    showPopover() {
      this.$root.$emit('bv::hide::popover');
    },
  },
  validations: {
    productName: {
      noQuotes: ValidatorsHelper.noQuotes(),
    },
  },
  methods: {
    ...mapActions('product', ['getProductPaymentOptions']),
    ...mapActions('school', ['enableFeature']),
    ...mapActions('product', ['updateProduct']),
    ...mapActions('course', ['updateCourse', 'updatePath']),
    ...mapGetters('school', ['hasFeature']),

    goToProductSetup(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$router.push({ name: 'ProductSetup', params: { id: this.product.id } });
    },
    showProductLinks(target) {
      this.isProductLinksOpen = true;
      this.$refs[target].$emit('open');
    },
    closeProductLinks(_target) {
      this.isProductLinksOpen = false;
    },
    offerPageLink(product) {
      return `https://${this.getDomain}/${product.slug}`;
    },
    getProductOffers(product) {
      return this.getProductPaymentOptions({ id: product.id });
    },
    urlLink(product, paymentOption) {
      return makeCheckoutUrl(this.hasSparkCheckout, paymentOption, product, this.getDomain);
    },

    async updatePublish(value) {
      try {
        this.isLoading = true;
        this.published = value;
        await productService.update({
          id: this.product.id,
          published: value,
        });
        ToastHelper.successMessage(this.$t('sparkmembers.product-dashboard.index.toast.success'));
        this.product.published = value;
        if (this.published) {
          analyticsService.track({
            event: 'Product published',
            props: {
              product_id: this.product.id,
              niche: this.product.niche,
              school_id: this.selectedSchool.id,
            },
          });
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.v2-product-list.sidebar.delete-product.error'));
      }
      this.isLoading = false;
    },
    async save() {
      this.isLoading = true;
      try {
        if (this.product.course) {
          await this.updateCourse({
            id: this.product.course.id,
            name: this.productName,
          });
        } else if (this.product.path) {
          await this.updatePath({
            id: this.product.path.id,
            name: this.productName,
          });
        }

        await this.updateProduct({
          id: this.product.id,
          title: this.productName,
        });

        this.product.title = this.productName;

        ToastHelper.successMessage(this.$t('sparkmembers.product-dashboard.index.toast.success'));
      } catch ({ response }) {
        if (response) {
          const errors = response.data.errors;
          const firstError = Object.keys(errors)[0];
          ToastHelper.dangerMessage(errors[firstError][0]);
        }
      }
      this.isLoading = false;
    },
    cancel() {
      this.productName = this.product.title;
    },
    goToSalesPage(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('isLoading', true);
      this.$router.push({ name: 'ProductSetup', params: { id: this.product.id } });
    },
    goToContent(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('isLoading', true);
      this.$router.push({ name: 'ProductDashboard', params: { id: this.product.id } });
    },
    async goToFunnels() {
      try {
        this.$emit('isLoading', true);
        TrackingHelper.trackFunnelSelectionScreen('product_catalog');

        if (!this.hasFeature('spark_funnel')) {
          this.isLoading = true;
          await this.enableFeature({
            featureId: parseInt(process.env.VUE_APP_SPARKFUNNELS_FEATURE_ID),
            featureKey: 'spark_funnel',
          });
        }

        TrackingHelper.trackFunnelCreationInitiated('product_catalog');
        this.$router.push({ name: 'FunnelCreate', query: { location: 'product_catalog' } });
      } catch (error) {
        throw error;
      }
    },
    openCompleteRegisterModal() {
      this.$root.$emit('showCompleteModalOnlyAddress', { showOnlyAddress: false });
    },
    async deleteProduct() {
      const confirmed = await this.showConfirmBox({
        icon: 'trash-alt',
        variant: 'cherry',
        okTitle: this.$t('sparkmembers.v2-product-list.sidebar.delete-product.title'),
        cancelTitle: this.$t('sparkmembers.v2-product-list.sidebar.delete-product.cancel-title'),
        contentTitle: this.$t('sparkmembers.v2-product-list.sidebar.delete-product.content-title'),
        contentDescription: this.$t('sparkmembers.v2-product-list.sidebar.delete-product.content-description'),
      });
      if (confirmed)
        try {
          this.isLoading = true;

          this.product.course
            ? await courseService.deleteCourse(this.product.course.id)
            : await pathService.delete(this.product.path.id);

          await productService.delete(this.product.id);

          TrackingHelper.trackProductDeleted(
            this.product.title,
            this.product.id,
            this.product.type,
            this.product.niche,
            this.product.resource_id
          );
          ToastHelper.successMessage(this.$t('sparkmembers.v2-product-list.sidebar.delete-product.success'));
          this.$emit('deleted');
        } catch (error) {
          if (error.response?.status === 403) {
            ToastHelper.dangerMessage(this.$t('sparkmembers.v2-product-list.sidebar.delete-product.error-restriction'));
          } else {
            ToastHelper.dangerMessage(error);
          }
        } finally {
          this.isLoading = false;
        }
    },
    redirectToCloneProduct() {
      this.$router.push({
        name: 'ProductCreator',
        query: { type: this.product.course.kind, location: 'product_catalog' },
        params: { product_clone: this.product, is_cloning: true },
      });
    },
    closeSidebar() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-image-button {
  width: fit-content;
}
.product-sidebar {
  width: 350px;
  height: 100%;
  position: fixed;
  display: flex;
  right: 0;
  z-index: 2;
  background-color: #000000ad;
  transition: all 0.3s ease;

  &__content {
    width: 100%;
    background-color: white;
    box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.25);
    overflow: auto;
    padding-bottom: 70px;
    &.--not_validated {
      padding-bottom: 100px;
    }
  }

  &__loading {
    width: 100%;
    height: 100%;
    box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.25);
  }

  &__close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__badge-status {
    padding-top: 1.5rem;
    padding-left: 1rem;
    left: 0;
  }

  &__publish {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 1rem;
  }

  &__clone {
    display: inline-block;

    .info-icon {
      color: #3575d4;
    }
    &:hover:enabled {
      span {
        text-decoration: underline;
      }
    }
  }

  &__delete {
    &:enabled {
      color: #ff305c;
    }
  }

  .custom-button {
    border-radius: 5px;
    float: right;
    min-width: 25.5%;
  }
  .custom-badge {
    background-color: #269353;
  }
}

.description-icon {
  width: auto;
}

@media screen and (max-width: 576px) {
  .product-sidebar {
    position: fixed;
    width: 100%;
    top: 60px;
    overflow-y: auto;

    &.--not_validated {
      top: 140px;
    }

    &__content {
      width: 85%;
      padding-bottom: 70px !important;
      &.--not_validated {
        padding-bottom: 140px !important;
      }
    }
    &__loading {
      width: 85%;
    }
  }
}

/* width */
::-webkit-scrollbar {
  display: block;
  width: 8px;
  transition: all 0.5s;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  transition: all 0.5s;
  background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition: all 0.5s;
  background: #aaa;
}

.content-button-wrapper {
  display: inline-block;
}

.custom-button-link {
  color: #7427f1;
  border: 2px solid #7427f1;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    background-color: #f2ebfe;
  }
}
</style>
