export enum ParticipationKind {
  AFFILIATION = 'affiliation',
  COPRODUCTION = 'coproduction',
}

export enum AffiliationInvitesStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  EXPIRED = 'expired',
  REFUSED = 'refused',
}

export enum AffiliationStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REFUSED = 'refused',
  REMOVED = 'removed',
  PAUSED = 'paused',
}

export interface AffiliationProgram {
  name: string;
  status: boolean;
  description: string;
  rules: string;
  expiration_date: string;
  organization_id: number;
}

export interface Affiliate {
  core_school_id: number;
  created_at: string;
  email: string;
  id: number;
  name: string;
  programs: string[];
  updated_at: string;
}

export interface Affiliation {
  affiliate: Affiliate;
  program: AffiliationProgram;
  created_at: string;
  affiliate_id: number;
  affiliation_invite_id: number;
  program_id: number;
  status: AffiliationStatus;
  updated_at: string;
}
