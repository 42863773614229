<template>
  <div class="image-sidebar d-flex justify-content-end">
    <div class="image-sidebar__content position-relative tlw-flex tlw-flex-col">
      <div>
        <MButton
          variant="link"
          icon="arrow-left"
          size="xss"
          label="Voltar"
          class="image-sidebar__back-btn tlw-mt-4 tlw-px-3 tlw-gap-2"
          data-testid="image-sidebar-back-button"
          @click="$emit('closeImageSidebar')"
        />
        <div class="h-5 image-sidebar__close-btn">
          <MButton
            variant="link"
            icon="times"
            iconPrefix="fas"
            iconSize="sm"
            size="xs"
            class="tlw-text-gray-500"
            @click="$emit('close')"
          />
        </div>
      </div>

      <div class="w-100 d-flex flex-column tlw-pt-4 tlw-pb-6 tlw-px-4 tlw-justify-between tlw-flex-grow">
        <div class="d-flex tlw-gap-6 tlw-flex-col">
          <div>
            <h4 class="tlw-font-bold">Editar foto de capa</h4>
            <hr class="tlw-w-full lg:tlw-mt-6" />
          </div>
          <div class="tlw-flex tlw-flex-col">
            <div>
              <div class="d-flex tlw-gap-2 tlw-items-center tlw-mb-1">
                <p class="tlw-mb-0 font-size-sm">Horizontal</p>
                <Icon
                  name="info-circle"
                  prefix="fal"
                  size="xxs"
                  class="informative-icon"
                  v-b-tooltip.hover.top.html="
                    $t('sparkmembers.product-creator.setup.image-input.icon-tooltip-horizontal')
                  "
                />
              </div>
              <p class="tlw-text-xs tlw-text-gray-700">
                Formatos: .jpeg, .jpg ou .png,<br />
                Dimensão ideal: 1280 x 720px
              </p>
            </div>
            <div class="d-flex tlw-gap-4 tlw-items-center tlw-flex-grow">
              <div
                class="image-preview horizontal tlw-flex tlw-justify-center tlw-items-center tlw-rounded-lg tlw-border tlw-bg-gray-100"
                :style="estiloImagemHorizontal"
              >
                <Icon
                  v-if="!imageHorizontal"
                  name="image"
                  prefix="fal"
                  size="xs"
                  class="tlw-text-gray-500 text-center"
                  variant="light"
                />
              </div>
              <div class="d-flex tlw-flex-col tlw-gap-2">
                <MButton
                  :label="horizontalImageButtonLabel"
                  :icon="horizontalImageButtonIcon"
                  variant="primary-outline"
                  :loading="isSavingHorizontal"
                  data-testid="image-sidebar-horizontal-button"
                  class="upload-button tlw-gap-2"
                  @click="e => handleImageUpload('horizontal', e)"
                />
                <hs-form-invalid-feedback
                  :state="false"
                  v-if="isImageFormatInvalid && currentImageType === 'horizontal'"
                >
                  <span class="font-size-xs tlw-text-red-500">
                    {{ $t('sparkmembers.product-creator.setup.image-input.validation.invalid-format') }}
                  </span>
                </hs-form-invalid-feedback>
              </div>
            </div>
          </div>

          <div class="tlw-flex tlw-flex-col">
            <div class="d-flex tlw-gap-2 tlw-items-center tlw-mb-1">
              <p class="tlw-mb-0 font-size-sm">Vertical <span class="tlw-text-gray-600">(Opcional)</span></p>
              <Icon
                name="info-circle"
                prefix="fal"
                size="xxs"
                class="informative-icon"
                v-b-tooltip.hover.top.html="$t('sparkmembers.product-creator.setup.image-input.icon-tooltip-vertical')"
              />
              <hs-badge pill variant="success" class="tlw-mb-0 badge-success">Novo</hs-badge>
            </div>
            <p class="tlw-text-xs tlw-text-gray-700">
              Formatos: .jpeg, .jpg ou .png,<br />
              Dimensão ideal: 417 x 720px
            </p>
            <div class="d-flex tlw-gap-4 tlw-items-center tlw-mb-1">
              <div
                class="image-preview vertical tlw-flex tlw-justify-center tlw-items-center tlw-rounded-lg tlw-border tlw-bg-gray-100"
                :style="estiloImagemVertical"
              >
                <Icon
                  v-if="!imageVertical"
                  name="image"
                  prefix="fal"
                  size="xs"
                  class="tlw-text-gray-500 text-center"
                  variant="light"
                />
              </div>
              <div class="d-flex tlw-flex-col tlw-gap-2">
                <div class="d-flex tlw-flex-row tlw-gap-2 tlw-items-center">
                  <MButton
                    :label="verticalImageButtonLabel"
                    :icon="verticalImageButtonIcon"
                    variant="primary-outline"
                    :loading="isSavingVertical"
                    class="tlw-gap-2 upload-button"
                    data-testid="image-sidebar-vertical-button"
                    @click="e => handleImageUpload('vertical', e)"
                  />
                  <MButton
                    v-if="imageVertical"
                    class="remove-image-vertical tlw-gap-2"
                    variant="link"
                    icon="trash-alt"
                    @click="removeImageVertical"
                  />
                </div>
                <hs-form-invalid-feedback :state="false" v-if="isImageFormatInvalid && currentImageType === 'vertical'">
                  <span class="font-size-xs tlw-text-red-500">
                    {{ $t('sparkmembers.product-creator.setup.image-input.validation.invalid-format') }}
                  </span>
                </hs-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>

        <input
          type="file"
          @change="onFileInputChange"
          ref="file"
          accept="image/jpg, image/jpeg, image/png"
          style="display: none"
        />

        <CropImage
          v-if="selectedImage != null"
          :aspect-ratio="currentAspectRatio"
          :subtitle="cropSubtitle"
          :src="selectedImage"
          :is-saving="isSavingCropImage"
          @close="selectedImage = null"
          @save="onFile"
        />

        <div class="tlw-w-full tlw-flex-grow md:tlw-flex tlw-flex-col lg:tlw-items-center lg:tlw-mt-6 tlw-justify-end">
          <hr class="tlw-w-full tlw-mb-6" />
          <MButton
            label="Salvar alterações"
            variant="primary"
            class="tlw-w-full"
            :loading="isSaving"
            :disabled="imageIsUploading || !hasChanges"
            data-testid="image-sidebar-save-button"
            @click="changeCourseImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MButton from '@/shared/components/MButton.vue';
import CropImage from '@/components/CropImage.vue';
import Icon from '@/components-new/Icon.vue';
import courseService from '@/sparkmembers/services/course';
import pathService from '@/sparkmembers/services/path';
import uploadService from '@/services/upload';
import productService from '@/sparkmembers/services/product';

import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';

export default {
  name: 'ImageSidebar',
  components: {
    MButton,
    CropImage,
    Icon,
  },
  props: {
    initialHorizontalImage: {
      type: String,
      default: null,
    },
    initialVerticalImage: {
      type: String,
      default: null,
    },
    product: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      isSavingCropImage: false,
      selectedImage: null,
      imageHorizontal: this.initialHorizontalImage,
      imageVertical: this.initialVerticalImage,
      currentImageType: null,
      imageIsUploading: false,
      isImageFormatInvalid: false,
    };
  },
  watch: {
    product: {
      handler(newVal) {
        this.imageHorizontal = newVal.logo;
        this.imageVertical = newVal.logo_vertical;
      },
    },
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    verticalImageButtonLabel() {
      if (this.isSavingVertical) return 'Carregando';
      return this.imageVertical ? 'Alterar' : 'Enviar imagem';
    },
    horizontalImageButtonLabel() {
      if (this.isSavingHorizontal) return 'Carregando';
      return this.imageHorizontal ? 'Alterar' : 'Enviar imagem';
    },
    cropSubtitle() {
      const dimensions = this.currentImageType === 'horizontal' ? '1280x720' : '417x720';
      return `Ajuste o tamanho da sua imagem para aproveitar o máximo dela nas dimensões de ${dimensions} pixels.`;
    },
    isSavingHorizontal() {
      return this.isSavingCropImage && this.currentImageType === 'horizontal';
    },
    isSavingVertical() {
      return this.isSavingCropImage && this.currentImageType === 'vertical';
    },
    horizontalImageButtonIcon() {
      return this.imageHorizontal ? 'arrows-rotate' : 'cloud-upload';
    },
    verticalImageButtonIcon() {
      return this.imageVertical ? 'arrows-rotate' : 'cloud-upload';
    },
    currentAspectRatio() {
      return this.currentImageType === 'horizontal' ? Number(1280 / 720) : Number(417 / 720);
    },
    estiloImagemVertical() {
      if (this.imageVertical) {
        return {
          backgroundImage: `url(${this.imageVertical})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        };
      }
      return {};
    },
    estiloImagemHorizontal() {
      if (this.imageHorizontal) {
        return {
          backgroundImage: `url(${this.imageHorizontal})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        };
      }
      return {};
    },
    hasChanges() {
      const horizontalChanged = this.imageHorizontal !== this.initialHorizontalImage;
      const verticalChanged = this.imageVertical !== this.initialVerticalImage;
      return horizontalChanged || verticalChanged;
    },
  },
  methods: {
    handleImageUpload(type, e) {
      e.preventDefault();
      this.currentImageType = type;
      this.isImageFormatInvalid = false;
      this.$refs.file.click();
    },
    onFileInputChange(e) {
      const file = e.target.files[0];

      if (!file) return;

      if (!file.type.match(/^image\/(jpeg|jpg|png)$/)) {
        this.isImageFormatInvalid = true;
        e.target.value = '';
        return;
      }

      this.loadImageToCrop(file);
      this.isImageFormatInvalid = false;
      e.target.value = '';
    },
    loadImageToCrop(file) {
      const reader = new FileReader();
      reader.onload = blob => {
        this.selectedImage = blob.target?.result;
      };
      reader.readAsDataURL(file);
    },
    removeImageVertical(e) {
      e.preventDefault();
      this.imageVertical = null;
      this.isImageFormatInvalid = false;
    },
    async onFile(file) {
      this.isSavingCropImage = true;
      try {
        const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
        const imgURL = await uploadService.upload(data.url, data.fields, file);
        if (this.currentImageType === 'horizontal') {
          this.imageHorizontal = imgURL;
        } else {
          this.imageVertical = imgURL;
        }
        this.selectedImage = null;
        ToastHelper.successMessage(this.$t('Imagem carregada com sucesso!'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.product-creator.setup.image-input.uploader.upload-error-msg'));
      } finally {
        this.isSavingCropImage = false;
      }
    },
    async changeCourseImage() {
      try {
        this.imageIsUploading = true;

        if (this.product.course) {
          await courseService.update({
            ...this.product.course,
            image_url: this.imageHorizontal,
            logo_vertical: this.imageVertical,
          });
        } else {
          await pathService.update({
            ...this.product.path,
            image_url: this.imageHorizontal,
            logo_vertical: this.imageVertical,
          });
        }

        await productService.update({
          id: this.product.id,
          logo: this.imageHorizontal,
          logo_vertical: this.imageVertical,
        });

        this.product.logo = this.imageHorizontal;
        this.product.logo_vertical = this.imageVertical;

        TrackingHelper.trackProductCoverUpdated('product_list_sidebar');
        ToastHelper.successMessage('Imagens atualizadas com sucesso!');
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.sales-page.toast.upload.error'));
      } finally {
        this.imageIsUploading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-success {
  background-color: #348535;
}
.image-sidebar {
  width: 350px;
  height: 100%;
  padding-bottom: 69px;
  position: fixed;
  display: flex;
  right: 0;
  z-index: 2;
  transition: all 0.3s ease;

  &__back-btn {
    color: $grey;
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    &:hover {
      text-decoration: underline;
    }
  }

  &__content {
    width: 100%;
    background-color: white;
    box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.25);
    overflow: auto;
  }

  &__close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.upload-button {
  padding: 8px 16px;
  font-size: 16px;
}

.remove-image-vertical {
  color: $cherry;
  padding: 8px 16px;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.image-preview {
  background-color: #f0f0f0;
  &.horizontal {
    min-width: 158px;
    height: 89px;
  }
  &.vertical {
    min-width: 90px;
    height: 156px;
  }
}

.informative-icon {
  color: #3575d4;
}

@media screen and (max-width: $screen-lg) {
  .image-sidebar {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 576px) {
  .image-sidebar {
    position: fixed;
    width: 100%;
    top: 60px;
    overflow-y: auto;
  }
}
</style>
