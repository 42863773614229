<template>
  <div class="d-flex flex-column">
    <div v-if="isLoading" class="d-flex flex-fill flex-column justify-content-center">
      <hs-loading />
    </div>
    <div v-else class="d-flex flex-fill">
      <div class="d-flex flex-fill flex-column w-100">
        <hs-alert class="m-0" variant="danger" :show="showDangerProductPublished && isOfferActive" dismissible
          >{{ $t('sparkmembers.offers.toast.published') }}
        </hs-alert>
        <hsPageHeader
          :title="$t('sparkmembers.setup.title')"
          :sub-title="$t('sparkmembers.setup.subtitle')"
          :back-text="$t('sparkmembers.v2-product-list.title')"
          back-route="ProductsList"
        >
        </hsPageHeader>
        <hs-tab
          :lazy="true"
          id="product-tabs"
          :product="product"
          :activeAll="true"
          @onSelectTab="onSelectTab"
          :tabActive="tab"
          @onSelectDisabledTab="checkProduct"
          @onSave="onSave"
          :isLoading="isLoadingTab"
          :items="tabs"
          :colors="selectedColor"
          class="tlw-pl-11 ml-custom"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { hsLoading, hsTab } from '@/components';
import SalesPage from './components/ProductSalesPage';
import Confirm from '@/shared/mixins/Confirm';
import { gatewayService } from '@/services';
import ProductAccessRules from './components/ProductAccessRules';
import OrderBumpConfig from './components/OrderBumpConfig';
import Coupons from './components/Coupons';
import Coproduction from './components/Coproduction';
import Offers from './components/Offers';
import hsPageHeader from '@/components/_structures/PageHeader';

export default {
  components: {
    hsLoading,
    hsPageHeader,
    hsTab,
  },

  mixins: [Confirm],
  data() {
    return {
      isLoading: false,
      step: 1,
      selectedTab: null,
      showDangerProductPublished: false,
      isLoadingTab: false,
      disableAllTabs: false,
      showWarningAddWalletAlert: false,
      tab: 0,
      colors: this.isSalesActive ? 'bg-transparent' : 'bg-white',
    };
  },
  computed: {
    ...mapState('breadcrumb', {
      routes: state => state.routes,
    }),
    ...mapState('product', {
      product: state => state.selectedProduct,
    }),
    ...mapState('school', {
      school: state => state.selectedSchool,
    }),
    selectedColor() {
      return this.isSalesActive ? 'bg-transparent' : '';
    },
    isSalesActive() {
      return this.selectedTab && this.selectedTab.icon === 'file-invoice-dollar';
    },
    isOfferActive() {
      return this.selectedTab && this.selectedTab.icon === 'tags';
    },
    hasSparkCoproduction() {
      return this.$FCL.hasSparkCoproduction();
    },
    canCreateOrderBump() {
      return this.$FCL.canCreateOrderBump();
    },
    tabs() {
      const tabs = [
        {
          title: this.$t('sparkmembers.setup.tabs.sales-page'),
          icon: 'file-invoice-dollar',
          component: SalesPage,
          disabled: this.disableAllTabs,
          route: 'SalesPage',
          props: [],
        },
        {
          title: this.$t('sparkmembers.setup.tabs.offers'),
          icon: 'tags',
          component: Offers,
          disabled: this.disableAllTabs,
          route: 'Offers',
          props: [],
        },
        {
          title: this.$t('sparkmembers.setup.tabs.access-rules'),
          icon: 'cog',
          component: ProductAccessRules,
          disabled: this.disableAllTabs,
          route: 'ProductAccessRules',
          props: [],
        },
        {
          title: this.$t('sparkmembers.setup.tabs.coupons'),
          icon: 'ticket',
          component: Coupons,
          disabled: this.disableAllTabs,
          route: 'Coupons',
          props: [],
        },
      ];
      this.hasSparkCoproduction &&
        tabs.push({
          title: this.$t('sparkmembers.setup.tabs.coproducao'),
          icon: 'smile',
          component: Coproduction,
          disabled: this.disableAllTabs,
          route: 'Coproducao',
          props: [],
        });

      this.canCreateOrderBump &&
        tabs.push({
          title: this.$t('sparkmembers.setup.tabs.order-bump'),
          icon: 'shopping-cart',
          component: OrderBumpConfig,
          disabled: this.disableAllTabs,
          route: 'OrderBumpConfig',
          props: [],
        });

      return tabs;
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.disableAllTabs) {
      next();
      return;
    }
    const confirmed = await this.showConfirmBox({
      icon: 'info-circle',
      variant: 'cherry',
      okTitle: this.$t('sparkmembers.product-creator.exit-modal.ok-title'),
      cancelTitle: this.$t('sparkmembers.product-creator.exit-modal.cancel-title'),
      contentTitle: this.$t('sparkmembers.product-creator.exit-modal.content-title'),
      contentDescription: this.$t('sparkmembers.product-creator.exit-modal.content-description'),
      modalClass: 'product-creator-modal',
    });

    if (confirmed) {
      next();
    }
  },
  methods: {
    ...mapActions('breadcrumb', ['setRoutes']),
    onSelectTab(tab) {
      this.selectedTab = tab;
    },
    onSave() {
      this.disableAllTabs = false;
    },
    async checkProduct(tab) {
      const confirmed = await this.showConfirmBox({
        icon: 'info-circle',
        variant: 'cherry',
        okTitle: this.$t('sparkmembers.product-creator.exit-modal.ok-title'),
        cancelTitle: this.$t('sparkmembers.product-creator.exit-modal.cancel-title'),
        contentTitle: this.$t('sparkmembers.product-creator.exit-modal.content-title'),
        contentDescription: this.$t('sparkmembers.product-creator.exit-modal.content-description'),
        modalClass: 'product-creator-modal',
      });
      if (confirmed) {
        const selectedTab = this.tabs.findIndex(item => item === tab);
        this.disableAllTabs = false;
        setTimeout(() => {
          this.tab = selectedTab;
        }, 500);
      }
    },
  },
  mounted() {
    let routes = this.routes.filter((item, index) => index !== 3);
    this.setRoutes(routes);
    if (!this.product.published) this.showDangerProductPublished = true;
    gatewayService.getGateway(this.school.id, [{ key: 'gateway', value: 'spark_pay' }]).then(res => {
      if (res.gateway_credentials.length === 0 || !res.gateway_credentials[0].split_rules[0].bank_account_infos)
        this.showWarningAddWalletAlert = true;
    });
  },
};
</script>
<style lang="scss" scoped>
.drawer-p {
  color: $grey-ba;
}
a:hover {
  text-decoration: none;
}
/deep/ {
  .modal-header {
    border-bottom: 10px solid #104b86 !important;
  }
  .tabs-list {
    position: relative;
  }
  .nav.nav-pills.card-header {
    position: sticky;
    top: 69px;
  }

  .ml-custom {
    padding-left: 2.75rem;

    .tabs {
      .tabs-list {
        padding-right: 2px;
      }
    }

    @media screen and (max-width: $screen-md) {
      padding-left: 0;
    }
  }

  @media only screen and (min-width: $screen-lg) {
    .tabs.row.no-gutters {
      margin-right: -15px;
    }
    .no-gutters > .col,
    .no-gutters > [class*='col-'] {
      padding-right: 15px;
    }
  }

  @media screen and (max-width: 1223px) and (min-width: $screen-bs-md) {
    .ml-custom {
      .tabs {
        flex-wrap: nowrap;
      }
    }
  }
}
</style>
