















import { Component, Vue } from 'vue-property-decorator';
import debounce from 'lodash.debounce';

@Component
export default class ProductSearch extends Vue {
  searchValue = debounce(value => {
    this.$emit('searchProduct', value);
  }, 300);
}
