<template>
  <b-form-checkbox v-bind="$attrs" v-on="$listeners" class="m-checkbox">
    <slot />
  </b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';
export default {
  name: 'MCheckbox',
  model: {
    prop: 'checked',
  },
  components: {
    BFormCheckbox,
  },
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.m-checkbox {
  /deep/ .custom-control-input:hover {
    border-color: $purple-dark;
  }
  /deep/ .custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
  /deep/ .custom-checkbox:hover .custom-control-input:not(:disabled) ~ .custom-control-label:before {
    border-color: $purple-dark;
  }
  /deep/.custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
  /deep/.custom-control-input:hover ~ .custom-control-label:before {
    border-color: $purple-dark;
  }
  /deep/.custom-control-input:not(:focus) ~ .custom-control-label:before {
    border: 1px solid $grey-ba;
  }
  /deep/.custom-control-input:active ~ .custom-control-label:before {
    background-color: transparent;
    border-color: $purple-dark;
  }
}
</style>
