<template>
  <div class="buttons">
    <div class="buttons__container">
      <div class="buttons__container-config">
        <h4 class="buttons__config-title">
          Se o cliente aceitar a oferta?
        </h4>
        <div class="buttons__config-input-wrapper">
          <span class="tlw-block tlw-mb-2">Texto do botão de aceite</span>
          <hs-input
            placeholder="Sim, eu aceito essa oferta especial!"
            v-model="acceptText"
            @blur="$v.acceptText.$touch()"
          />
          <span v-if="$v.acceptText.$dirty && $v.acceptText.$invalid" class="input-field-message">
            <hs-icon variant="regular" icon="info-circle" />
            Digite um texto válido para o botão
          </span>
        </div>
        <div class="buttons__config-input-wrapper">
          <span class="tlw-block tlw-mb-2">Enviar o cliente para:</span>
          <hs-multiselect
            v-model="selectAccepOfferSendClientTo"
            :options="selectOfferSendClientToOptions"
            :searchable="false"
            :show-labels="false"
            label="name"
          />
        </div>
        <div v-if="selectAccepOfferSendClientTo.value === 'custom'" class="buttons__config-input-wrapper">
          <span class="tlw-block tlw-mb-2">URL da página personalizada</span>
          <hs-input
            placeholder="https://meusite.com/paginaobrigado"
            v-model="urlAcceptThanksPage"
            @blur="$v.urlAcceptThanksPage.$touch()"
          />
          <span v-if="$v.urlAcceptThanksPage.$dirty && $v.urlAcceptThanksPage.$invalid" class="input-field-message">
            <hs-icon variant="regular" icon="info-circle" />
            Digite uma url válida
          </span>
        </div>
        <h4 class="buttons__config-title buttons__config-title">
          Se o cliente recusar a oferta?
        </h4>
        <div class="buttons__config-input-wrapper">
          <span class="tlw-block tlw-mb-2">Texto do botão de recusa</span>
          <hs-input
            placeholder="Não, eu gostaria de recusar essa oferta"
            v-model="declineText"
            @blur="$v.declineText.$touch()"
          />
          <span v-if="$v.declineText.$dirty && $v.declineText.$invalid" class="input-field-message">
            <hs-icon variant="regular" icon="info-circle" />
            Digite um texto válido para o botão
          </span>
        </div>
        <div class="buttons__config-input-wrapper">
          <span class="tlw-block tlw-mb-2">Enviar o cliente para:</span>
          <hs-multiselect
            v-model="selectDeclineOfferSendClientTo"
            :options="selectOfferSendClientToOptions"
            :searchable="false"
            :show-labels="false"
            label="name"
          />
        </div>
        <div v-if="selectDeclineOfferSendClientTo.value === 'custom'" class="tlw-mb-4 ">
          <span class="tlw-block tlw-mb-2">URL da página personalizada</span>
          <hs-input
            placeholder="https://meusite.com/paginaobrigado"
            v-model="urlDeclineThanksPage"
            @blur="$v.urlDeclineThanksPage.$touch()"
          />
          <span v-if="$v.urlDeclineThanksPage.$dirty && $v.urlDeclineThanksPage.$invalid" class="input-field-message">
            <hs-icon variant="regular" icon="info-circle" />
            Digite uma url válida
          </span>
        </div>
      </div>
      <UpsellButtonsPreview
        :accept-text="acceptText"
        :reject-text="declineText"
        :upsell-offer="upsellOffer"
        :upsell-price-text="formatInstallments(upsellInstallments)"
        :product-title="productTitle"
        class="buttons__container-view"
      />
    </div>
    <div class="buttons__switch-container">
      <MSwitch
        v-model="upsellOneClick"
        labelTitle="Habilitar upsell com 1 clique."
        labelDescription="Com o upsell de 1 clique o seu cliente realiza a compra assim que clicar no botão de aceite com as mesmas informações e método de pagamento preenchidos anteriormente no checkout, sem ter alguma confirmação ou possibilidade de troca de método de pagamento. Atenção: Ao optar por esse modelo, existem alguns riscos de solicitação de estorno."
      />

      <div class="info">
        <hs-icon icon="question-circle" :size="14" />

        <div class="text">
          Saiba mais sobre o upsell 1 clique
        </div>
      </div>
    </div>
    <div class="upsell-config-step-two__buttons-container">
      <MButton variant="primary-outline" @click="$emit('backTab')">
        Cancelar
      </MButton>
      <MButton variant="primary" @click="emitData" :disabled="!enableNext">
        Salvar
      </MButton>
    </div>
  </div>
</template>

<script>
import { required, minLength, url } from 'vuelidate/lib/validators';
import MSwitch from '@/shared/components/MSwitch.vue';
import MButton from '@/shared/components/MButton.vue';
import UpsellButtonsPreview from '../UpsellButtonsPreview.vue';
import { mapState } from 'vuex';

export default {
  name: 'ProductUpsellStepTwo',
  components: {
    MSwitch,
    MButton,
    UpsellButtonsPreview,
  },
  props: {
    upsellOffer: {
      type: Object,
      required: true,
    },
    productTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectAccepOfferSendClientTo: { name: 'Página de obrigado HeroSpark', value: 'hero' },
      selectDeclineOfferSendClientTo: { name: 'Página de obrigado HeroSpark', value: 'hero' },
      selectOfferSendClientToOptions: [
        { name: 'Página de obrigado HeroSpark', value: 'hero' },
        { name: 'Página externa customizada (URL)', value: 'custom' },
      ],
      upsellOneClick: false,
      declineText: 'Não, eu gostaria de recusar essa oferta',
      acceptText: 'Sim, eu aceito essa oferta especial!',
      urlAcceptThanksPage: '',
      urlDeclineThanksPage: '',
      isUpdate: false,
    };
  },
  computed: {
    ...mapState('product', {
      upsell_flow_steps: state => state.productPaymentOption?.upsell_flow_steps,
    }),
    upsellInstallments() {
      return this.upsell_flow_steps?.steps[0]?.installments;
    },
    enableNext() {
      let validInputs = !this.$v.declineText.$invalid && !this.$v.acceptText.$invalid;
      const inputEmptys = this.declineText && this.acceptText;

      if (
        this.selectAccepOfferSendClientTo.value === 'custom' &&
        this.selectDeclineOfferSendClientTo.value === 'custom'
      ) {
        return (
          validInputs &&
          inputEmptys &&
          !this.$v.urlAcceptThanksPage.$invalid &&
          !this.$v.urlDeclineThanksPage.$invalid &&
          this.urlAcceptThanksPage &&
          this.urlDeclineThanksPage
        );
      }

      if (this.selectAccepOfferSendClientTo.value === 'custom') {
        return validInputs && inputEmptys && !this.$v.urlAcceptThanksPage.$invalid && this.urlAcceptThanksPage;
      }

      if (this.selectDeclineOfferSendClientTo.value === 'custom') {
        return validInputs && inputEmptys && !this.$v.urlDeclineThanksPage.$invalid && this.urlDeclineThanksPage;
      }

      return validInputs && inputEmptys;
    },
  },
  mounted() {
    if (this.upsell_flow_steps !== null) {
      this.upsellOneClick = this.upsell_flow_steps.frictionless;

      this.upsell_flow_steps.steps[0].actions.forEach(item => {
        if (item.type === 'accept') {
          this.acceptText = item.label;
          this.selectAccepOfferSendClientTo =
            item.url === ''
              ? { name: 'Página de obrigado HeroSpark', value: 'hero' }
              : { name: 'Página externa customizada (URL)', value: 'custom' };
          this.urlAcceptThanksPage = item.url;
        } else if (item.type === 'reject') {
          this.declineText = item.label;
          this.selectDeclineOfferSendClientTo =
            item.url === ''
              ? { name: 'Página de obrigado HeroSpark', value: 'hero' }
              : { name: 'Página externa customizada (URL)', value: 'custom' };
          this.urlDeclineThanksPage = item.url;
        }
      });
      this.isUpdate = true;
    }
  },
  methods: {
    emitData() {
      if (this.isUpdate) {
        const stepActions = this.upsell_flow_steps.steps[0].actions;
        const [acceptAction, rejectAction] = stepActions.filter(
          item => item.type === 'accept' || item.type === 'reject'
        );

        this.$emit('updateFlow', {
          upsellOneClick: this.upsellOneClick,
          id: this.upsell_flow_steps.id,
          stepId: this.upsell_flow_steps.steps[0].id,
          actions_attributes: [
            {
              ...acceptAction,
              label: this.acceptText,
              url: this.selectAccepOfferSendClientTo === 'hero' ? '' : this.urlAcceptThanksPage,
            },
            {
              ...rejectAction,
              label: this.declineText,
              url: this.selectDeclineOfferSendClientTo === 'hero' ? '' : this.urlDeclineThanksPage,
            },
          ],
        });
        this.isUpdate = false;
      } else {
        this.$emit('endFlow', {
          upsellOneClick: this.upsellOneClick,
          actions_attributes: [
            {
              label: this.acceptText,
              type: 'accept',
              url: this.selectAccepOfferSendClientTo === 'hero' ? '' : this.urlAcceptThanksPage,
            },
            {
              label: this.declineText,
              type: 'reject',
              url: this.selectDeclineOfferSendClientTo === 'hero' ? '' : this.urlDeclineThanksPage,
            },
          ],
        });
      }
    },
    formatInstallments(installments) {
      if (!installments || installments.length === 0) {
        return '[valor da oferta]';
      }

      const lastElement = installments[installments.length - 1];
      const installmentAmount = (lastElement.installment_amount_cents / 100).toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return `${lastElement.installments}x ${installmentAmount}`;
    },
  },
  validations: {
    declineText: { required, minLength: minLength(3) },
    acceptText: { required, minLength: minLength(3) },
    urlAcceptThanksPage: { required, url },
    urlDeclineThanksPage: { required, url },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1024px) {
  .buttons__container-view {
    display: none;
  }
}
.buttons__container {
  display: flex;
  gap: 24px;

  &-config {
    flex: 1;
  }

  &-view {
    width: 337px;
    background-color: #e3e3e3;
    border-radius: 4px;
    padding: 16px;
  }
}

.info {
  display: grid;
  grid-template-columns: 14px auto;
  column-gap: 5px;
  color: $purple-dark;
  padding-left: 48px;
  align-items: center;
  margin-top: 4px;
  cursor: pointer;

  > .text {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $purple-dark;
    text-decoration: underline;

    &:hover {
      color: $purple-dark;
    }
  }
}

.upsell-config-step-two__buttons-container {
  display: flex;
  justify-content: flex-end;
  column-gap: 21px;
  margin-top: 32px;
}

.buttons__config-title {
  font-size: rem(16);
  line-height: rem(24);
  font-weight: 700;

  &:not(:first-child) {
    margin-top: 32px;
  }
}

.buttons__config-input-wrapper {
  margin-top: 16px;
}

.buttons__switch-container {
  margin-top: 32px;
}
</style>
