import { Component, Vue } from 'vue-property-decorator';
import Tracking from '@/shared/helpers/tracking';

@Component
export default class PixelTrack extends Vue {
  sendPixelTrackingData({ facebook_pixel, ga_tracking_id, google_ads_tracking_id }) {
    if (facebook_pixel) Tracking.trackFacebookPixelEnable();
    if (ga_tracking_id) Tracking.trackGoogleAnalyticsEnable();
    if (google_ads_tracking_id) Tracking.trackGoogleAdsEnable();
  }

  sendAppearanceEvents(offer) {
    const {
      id,
      thank_you_urls,
      checkout_settings: { whatsapp_settings, require_phone, require_address },
    } = offer;
    if (whatsapp_settings) Tracking.trackWhatsappWidgetEnable(whatsapp_settings?.whatsapp_text);
    if (require_phone) Tracking.trackOfferRequirePhoneEnabled(id);
    if (require_address) Tracking.trackOfferRequireAddressEnabled(id);
    if (this.hasThankYouUrl(thank_you_urls)) Tracking.trackOfferCustomThanksPageEnabled(id);
  }

  hasThankYouUrl(thankYouUrls) {
    return thankYouUrls?.some(({ url }) => !!url);
  }
}
