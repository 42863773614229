






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  @Prop({ type: Boolean, required: true }) isOpen: boolean;

  localIsOpen = false;

  constructor() {
    super();
    this.localIsOpen = this.isOpen;
  }

  beforeClose(name: string, state: boolean) {
    name === 'close' && !state && (this.localIsOpen = false);
  }

  requestClose(): void {
    this.$emit('close');
  }

  @Watch('isOpen')
  update(value, oldValue) {
    this.localIsOpen = value == true && !oldValue;
  }
}
